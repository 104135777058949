<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>

    <template #title>

      <div class='searchDivContainer'>
        <div class='searchDivContainer'>

          <table class="searchTable">
            <tr>

              <td style='width:100px'
                  class='column'>
                关键字查询
              </td>
              <td style='width:150rpx'>
                <a-input :bordered="false"
                         v-model:value="searchData.keyword"
                         :placeholder="`输入关键字`"></a-input>
              </td>

            </tr>
            <tr>

            </tr>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    type="primary"
                    @click="resetSift">重置</a-button>
        </div>
        <div class='right-buttons'>
          <!-- <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="openSearchView">{{searchData.buttonValue}}</a-button> -->

          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { checkTree } from "@/utils/regionTree";
import { decimalUtils } from "@/utils/decimalUtils";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { selector } from '@/utils/selector';
import { useRouter, useRoute } from 'vue-router';
import dayjs from "dayjs";

const url = {
  delete: '/v1/productinput_template/delete',
  page: '/v1/product/input_template/page',
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

// getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "产品名称", dataIndex: "name", key: "name", fixed: "left", width: "150px", ellipsis: true },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", width: "120px", ellipsis: true },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
    { title: "产品子类", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
    { title: "数量", dataIndex: "quantity", key: "quantity", width: "100px", width: "150px", ellipsis: true },
    { title: "是否是产品包", dataIndex: "is_package", key: "is_package", width: "100px", ellipsis: true },

    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

  ],

  //弹框字段数据
  modalData: [
    { title: "产品名称", dataIndex: "name", key: "name" },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type" },
    { title: "品牌", dataIndex: "customer_ids", key: "customer_ids" },
    { title: "地区", dataIndex: "region", key: "region" },
    { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode" },
    { title: "收费金额", dataIndex: "unit_price", key: "unit_price" },

    { title: "是否为套餐包", dataIndex: "is_package", key: "is_package" },
    { title: "是否为默认产品包", dataIndex: "isDefaultProductPackage", key: "isDefaultProductPackage" },
    { title: "子产品", dataIndex: "children_ids", key: "children_ids" },
    { title: "描述", dataIndex: "desc", key: "desc" },
  ]

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)



//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",



})

//增加或者编辑的模态框规则
const rules = {
  name: [{ required: true, message: "", }],
  // product_type: [{ required: true, message: "", }],
  customer_ids: [{ required: true, message: "", }],
  is_package: [{ required: true, message: "", }],
  // product_sub_type: [{ required: true, message: "", }],
  // billing_mode: [{ required: true, message: "", }],
  unit_price: [{ required: true, message: "", }],
  region: [{ required: true, message: "", }],
}

//选择框数据
const options = reactive({
  template: [],
  subTemplate: [],
  deviceTemplate: [],
  product_type: [],
  product_sub_type: [],
  region: [],
  customer_ids: [],
  billing_mode: [],
  product_sub_type_option: "信息化工程",
  children_ids: [], //非产品包的产品
  is_package: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
  subProductType: [],//产品子类
  isDefaultProductPackage: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
  devices: []
});

// 数据模型
const baseData = reactive({
  name: "",
  product_type: "",
  product_sub_type: "",
  customer_names: "",
  customer_ids: "",
  region: "",
  billing_mode: "",
  unit_price: "",
  // info: "",
  is_package: "",
  isDefaultProductPackage: "",
  children_ids: [],
  subProduct: [],
  devices: [],
  desc: "",
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  unit_price: false, // 收费金额是否可编辑
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  product_type: "行业信息化",
  product_sub_type: 'AIOps',
  is_package: 1,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach((item) => {

      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        product_type: item.product_type,
        product_sub_type: item.product_sub_type,
        quantity: item.quantity,
        billing_mode: item.billing_mode,
        info: item.info,
        desc: item.desc,

      });
    });
    dataSource.data = data;
    // console.log(dataSource.data);
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
    if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
      baseData[key] = [];
    }
  }

  checkedKeys.value = [];

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  // //modalShow.unit_price = true
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData.info = "";

  baseData.customer_ids = record.customer_ids.length == 0 ? ['全部'] : record.customer_ids;
  let region = [];
  record.regions.forEach((item) => {
    region.push(item.id);
  });


  checkedKeys.value = region;
  baseData.region = region;
  baseData.id = record.id;

  baseData.children_ids = record.children_ids
  console.log('basedata', baseData)
  // all_price
  baseData.devices.forEach(item => {
    item["all_price"] = item.quantity * item.unit_price
  })

  baseData.subProduct.forEach(item => {
    item['all_price'] = item.quantity * item.unit_price
  })

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.unit_price = false;
  baseData.is_package = 0;
  baseData.isDefaultProductPackage = 0;
  modalShow.addOrEdit = true;
  urlFlag = url.add
  baseData.subProduct = []
  baseData.devices = []
  baseData.subProduct.push({
    name: null,
    product_sub_type: null,
    billing_mode: null,
    quantity: null,
    unit_price: '',
    all_price: '',
    device_id: null,
  })
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
      include_children: 1
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ["name", "customer_ids", "region", "billing_mode", "unit_price",]
  let flag = commonFun.checkFields(baseData, mustInputFields)

  // await Promise.all([formRef.value.validate()])
  if (flag == true) {
    let customer_ids = []
    console.log(baseData.customer_ids, 'baseData.customer_ids')
    customer_ids = typeof (baseData.customer_ids) == 'number' ? [baseData.customer_ids] : baseData.customer_ids

    let children = baseData.subProduct.concat(baseData.devices)
    console.log('children', children)
    // children.forEach(item => {
    //   if (typeof (item.name) == 'string') { }
    //   else {
    //     item.name = item.name[0]
    //   }
    // })
    let data = {
      id: baseData.id,
      name: baseData.name,
      product_type: "行业信息化",
      product_sub_type: "AIOps",
      customer_ids: customer_ids,
      region_ids: baseData.region,
      billing_mode: baseData.billing_mode,
      unit_price: baseData.unit_price,
      operator_id: Number(localStorage.getItem("id")),
      is_default: baseData.isDefaultProductPackage,
      // children_ids: baseData.children_ids,
      desc: baseData.desc == null ? "" : baseData.desc,
      children: children
    }

    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
        queryNotPackage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }
  else {
    message.error("请填写完整信息");
  }




};








const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  region: [],
  keyword: "",
  customer_ids: null,
  is_package: null,
  searchViewFlag: false
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  queryField["region_id"] = searchData.region
  queryField["keyword"] = searchData.keyword
  if (searchData.customer_ids !== '') {
    queryField["customer_id"] = searchData.customer_ids == '全部' ? null : searchData.customer_ids

  }
  if (searchData.is_package !== '') {
    queryField["is_package"] = searchData.is_package
  }

  console.log("queryField", queryField);
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,
    product_type: "行业信息化",
    product_sub_type: 'AIOps',
    is_package: 1,
  }
  searchData.region = []
  searchData.keyword = ""
  searchData.customer_ids = null
  searchData.is_package = null
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};
//清洗区域树数据
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
      });
    }
  });
  return response;
};
//获取区域树
const getRegionTree = async () => {
  const tree = await checkTree()
  console.log("结构树数据", tree)
  options.region = clearRegionalTree(tree)
};


getRegionTree()
const getBilling_mode = () => {
  serviceAxios({
    url: interfaces.product_billing_mode,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.billing_mode = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getBilling_mode()






const getCustomer_names = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = []

      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.customer_ids = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getCustomer_names()


//查询非产品包的产品
const queryNotPackage = () => {
  let params = {
    is_package: 0,
    page_num: 1,
    page_size: 9999,
  }
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: params
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
          unitPrice: item.unit_price
        })
      })
      options.children_ids = data
    })
}

const priceChange = () => {
  let all_price = 0;
  let subProductComplutedList = baseData.subProduct.filter(item => item.unit_price && item.quantity);
  subProductComplutedList.forEach(item => {
    all_price += item.quantity * item.unit_price
  });

  let deviceComplutedList = baseData.devices.filter(item => item.unit_price && item.quantity);
  deviceComplutedList.forEach(item => {
    all_price += item.quantity * item.unit_price
  });
  baseData.unit_price = all_price;
}

//数量和价格失去焦点重新计算价格
const computedPrice = (index, type) => {
  if (type == 'subProduct') {
    if (baseData.subProduct[index].unit_price && baseData.subProduct[index].quantity) {
      baseData.subProduct[index].all_price = baseData.subProduct[index].unit_price * baseData.subProduct[index].quantity
    };
  }
  else {
    if (baseData.devices[index].unit_price && baseData.devices[index].quantity) {
      baseData.devices[index].all_price = baseData.devices[index].unit_price * baseData.devices[index].quantity
    };
  }
  console.log('index', index, baseData.subProduct[index]);

  priceChange()
}

//增加子产品
const addSub = () => {

  baseData.subProduct.push({
    name: '',
    product_sub_type: null,
    billing_mode: null,
    quantity: null,
    unit_price: '',
    all_price: '',
  })
}


//删除子产品
const removeSub = (item) => {
  if (baseData.subProduct.length > 1) {
    const index = baseData.subProduct.indexOf(item);
    if (index !== -1) {
      baseData.subProduct.splice(index, 1);
    }
    priceChange();
  }
  else {
    message.error('必须保留一个产品')
  }

}
//增加设备
const addDevice = () => {
  baseData.devices.push({
    name: '',
    product_sub_type: "设备租赁",
    billing_mode: null,
    quantity: 1,
    unit_price: '',
    all_price: '',
    devices: [{
      device_id: '',
      quantity: 1,
    }]
  })
}

//删除设备
const removeDevice = (item) => {
  const index = baseData.devices.indexOf(item);
  console.log('移除租赁产品', item)
  if (index !== -1) {
    baseData.devices.splice(index, 1);
  }
  priceChange();
}

//添加子设备
const addSubDevices = (index) => {
  baseData.devices[index]['devices'].push({
    id: "",
    device_id: '',
    quantity: 1,
  })
}

//删除子设备
const removeSubDevices = (item, itemIndex) => {
  const index = baseData.devices[itemIndex]['devices'].indexOf(item);
  console.log('移除租赁产品', item)
  if (index !== -1) {
    baseData.devices[itemIndex]['devices'].splice(index, 1);
  }
}
(async () => {
  options.subProductType = await selector.subProductType()
  options.subProductType = options.subProductType.filter(item => item.label != '设备租赁')
  options.deviceLease = options.subProductType.filter(item => item.label == '设备租赁')
  options.devices = await selector.devices();
  options.template = await selector.template();
  options.subTemplate = await selector.subTemplate();
  options.deviceTemplate = await selector.deviceTemplate();
  console.log('选择器数据', options.template, options.subTemplate, options.deviceTemplate)
})()


//模板selector改变
const templateChange = (value, option, index, type) => {
  console.log(value, option)
  if (type == 'sub') {
    baseData.subProduct[index].product_sub_type = option.data.product_sub_type
    baseData.subProduct[index].name = option.data.name
    baseData.subProduct[index].quantity = 1
    onblurSubValue.value[index] = value
    console.log('change', "onblurSubValue.value[index]", onblurSubValue.value[index])

  }
  else if (type == 'device') {
    console.log('baseData.devices', baseData.devices)
    baseData.devices[index].product_sub_type = option.data.product_sub_type
    baseData.devices[index].name = option.data.name
    baseData.devices[index].quantity = 1
    baseData.devices[index].devices = option.data.devices
    onblurDeviceValue.value[index] = value
  }
  else if (type == 'template') {
    baseData.name = option.data.name

  }
}

let onblurSubValue = ref([])  //sub产品
let onblurDeviceValue = ref([]) //设备产品
let onblurValue = ref([]) //设备产品
const onBlur = (value, index, type) => {
  console.log('失去焦点', value, index, type, onblurSubValue.value)
  console.log('baseData.subProduct[index].name', baseData.subProduct[index], onblurSubValue.value)

  if (type == 'sub') {
    baseData.subProduct[index].name = onblurSubValue.value[index]

  }
  else if (type == 'device') {
    baseData.devices[index].name = onblurDeviceValue.value[index]
  }

  else if (type == 'template') {
    baseData.name = onblurValue.value
  }

  console.log('baseData.subProduct[index].name', baseData.subProduct[index], onblurSubValue.value)

}

const onSearch = (value, index, type) => {
  console.log('搜索', value, index, type)
  if (value != '') {
    if (type == 'sub') {
      onblurSubValue.value[index] = value
      console.log('搜索sub', "onblurSubValue.value[index]", onblurSubValue.value[index])
    }
    else if (type == 'device') {
      onblurDeviceValue.value[index] = value
    }

    else if (type == 'template') {
      onblurValue.value = value
    }
  }

}

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
}

.sub-item-title {
  text-align: center;
  white-space: nowrap;
}

.template-box {
  display: flex;
  align-items: center;
}
</style>