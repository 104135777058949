<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <p v-if="connected">Connected to STOMP server</p>
    <p v-else>Not connected</p>
    <ul>
      <li v-for="message in messages"
          :key="message.id">{{ message.content }}</li>
    </ul>
    <button @click="sendMessage">Send Message</button>
  </div>
</template>  
  
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { ref, onMounted, onUnmounted } from 'vue';
import Stomp from 'stompjs'

if (typeof WebSocket == 'undefined') {
  console.log('不支持websocket')
}

// 初始化 ws 对象

var ws = new WebSocket('ws://114.94.6.104:18102/ws');

// 获得Stomp client对象
var client = Stomp.over(ws);


// 定义连接成功回调函数
var on_connect = function (x) {
  //同上/amq/queue
  client.subscribe("/queue/test", function (data) {
    var msg = data.body;
    alert("收到数据：" + msg);
  });
};

// 定义错误时回调函数
var on_error = function () {
  console.log('error');
};

// 连接RabbitMQ,输入用户名和密码,连接成功和失败函数
client.connect('admin', 'yhjk&1103', on_connect, on_error, '/');
console.log(">>>连接上http://localhost:15674");

</script>