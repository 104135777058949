<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <a-modal :maskClosable="false" v-model:open="columnShowModal" title="选择列显示" @ok="columnHandleOk"
    @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns" :key="column.key" :value="column.key">
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>

  <a-modal :maskClosable="false" v-model:open="showModal.show" title="添加合约" width="1000px" @ok="handleOk"
    @cancel="handleCancel">
    <a-form :model="formData" ref="formRef" :label-col="{ span: 5 }" :wrapper-col="{ span: 14
   }" :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in columns" :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title" :name="item.dataIndex">
              <a-input-number class="uniform-width modal-input"
                v-if="['contract_user_id','product_id','is_deleted','is_disabled','upload_limit','download_limit'].includes(item.dataIndex)"
                v-model:value="formData[item.dataIndex]" />
              <a-space v-else-if="['begin_date','end_date'].includes(item.dataIndex)" direction="vertical" :size="12">
                <a-config-provider :locale="locale">
                  <a-date-picker class="uniform-width" @change="onChange" v-model:value="dataSource[item.dataIndex]" />
                </a-config-provider>
              </a-space>
              <a-select
                v-else-if="['private_property','category','work_order_type','invoice_type'].includes(item.dataIndex)"
                v-model:value="formData[item.dataIndex]" show-search placeholder="Select a person" style="width: 200px"
                :options="options[item.dataIndex]" :filter-option="filterOption"></a-select>
              <a-cascader v-else-if="['contract_user_name'].includes(item.dataIndex)"
                v-model:value="optionsValue[item.dataIndex]" :options="options.contract_user_modal"
                placeholder="Please select" @change="cascaderOnChange" />
              <a-select v-else-if="['product_name','access_mode_name'].includes(item.dataIndex)"
                v-model:value="optionsValue[item.dataIndex]" show-search placeholder="Select a person"
                style="width: 200px" :options="options[item.dataIndex]" :filter-option="filterOption"
                @focus="handleFocus" @blur="handleBlur" @change="handleChange(item.dataIndex,$event)"></a-select>
              <a-input v-else class="uniform-width" v-model:value="formData[item.dataIndex]" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="Tab 1"></a-tab-pane>
    <a-tab-pane key="2" tab="Tab 2" force-render></a-tab-pane>
    <a-tab-pane key="3" tab="Tab 3"></a-tab-pane>

  </a-tabs>
  <a-table :columns="filteredColumns" :data-source="dataSource.data" :row-selection="rowSelection"
    :pagination="pagination" @change="handleTableChange" bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <!-- <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a @click="edit(record.key)">编辑</a>
          <a @click="deleteUser(record.key)">删除</a>
        </div>
      </template> -->
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <a-cascader
          v-model:value="value"
          :options="options"
          placeholder="Please select"
          change-on-select
        />
        <a-input style="width:150px"></a-input>
        <a-button type="primary">搜索</a-button> -->
        <a-button type="primary" @click="columnShowModal = true">筛选列显示</a-button>
        <div class="right-buttons">
          <a-button class="addDataButton" type="primary" @click="addData">添加</a-button>
          <a-button class="addDataButton" type="primary" @click="editData('')">编辑数据</a-button>
          <a-button class="addDataButton" type="primary" @click="deleteData('')">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed } from "vue";

import serviceAxios from "../../../utils/serviceAxios";

import { message } from "ant-design-vue";
import interfaces from "@/config";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
dayjs.locale('zh-cn');

//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (pagination) => {
  const { current, pageSize } = pagination;
  queryData(current, pageSize);
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref(["private_property", "contract_user_name", "product_name", "category", "dial_user", "dial_pw", "upload_limit", "download_limit", "begin_date", "end_date"],); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};




let showModal = reactive({
  show: false,
});
const columns = [
  { title: "品牌名称", dataIndex: "private_property", key: "private_property" },
  { title: "区域", dataIndex: "contract_user_name", key: "contract_user_name" },
  { title: "门店名称", dataIndex: "product_name", key: "product_name" },
  { title: "客户名称", dataIndex: "category", key: "category" },
  { title: "门店地址", dataIndex: "dial_user", key: "dial_user" },
  { title: "门店固话", dataIndex: "dial_pw", key: "dial_pw" },
  { title: "联系人", dataIndex: "access_mode_name", key: "access_mode_name" },
  { title: "联系方式", dataIndex: "work_order_type", key: "work_order_type" },
  { title: "产品服务", dataIndex: "address", key: "address" },
  { title: "计划开通时间", dataIndex: "toll_man", key: "toll_man" },
  { title: "付费方式", dataIndex: "installation_fee", key: "installation_fee" },
  { title: "付费金额", dataIndex: "payment_subject_id", key: "payment_subject_id" },
  { title: "合约签约周期", dataIndex: "deposit_amount", key: "deposit_amount" },
  { title: "发票号", dataIndex: "cost_amount", key: "cost_amount" },
];
//table数据源
let dataSource = reactive({
  data: "",
  begin_date: "",
  end_date: ""
});

// 添加弹框模型
let formData = reactive({
  id: "",
  key: "",
  private_property: "",
  contract_user_id: "",
  product_id: "",
  category: "",
  dial_user: "",
  dial_pw: "",
  access_mode_id: "",
  real_name: "",
  phone_number: "",
  toll_man: "",
  installation_fee: "",
  payment_subject_id: "",
  deposit_amount: "",
  cost_amount: "",
  receivable_amount: "",
  receipts_amount: "",
  invoice_number: "",
  invoice_type: "",
  upload_limit: "",
  download_limit: "",
  begin_date: "",
  end_date: "",
  desc: "",
  // is_deleted:0,
  // is_disabled:0
});

//产品下拉框数据
let options = reactive({
  private_property: [{
    "value": "本地",
    "label": "本地"
  }],
  category: [{
    "value": "新装",
    "label": "新装"
  }, {
    "value": "续费",
    "label": "续费"

  }],
  work_order_type: [{
    "value": "报修",
    "label": "报修"
  }, {
    "value": "拆机",
    "label": "拆机"

  }, {
    "value": "新装",
    "label": "新装"
  }, {
    "value": "业务支持",
    "label": "业务支持"

  }, {
    "value": "移机",
    "label": "移机"
  }],
  invoice_type: [{
    "value": "普通发票",
    "label": "普通发票"
  }, {
    "value": "增值税发票",
    "label": "增值税发票"

  }, {
    "value": "新装",
    "label": "新装"
  }, {
    "value": "业务支持",
    "label": "业务支持"

  }, {
    "value": "移机",
    "label": "移机"
  }],
  contract_user_name: "",
  product_name: "",
  access_mode_name: "",

  //modal的下拉数据
  contract_user_modal: "",
  product_modal: ""
})


let optionsValue = reactive({
  contract_user_name: "",
  product_name: "",
  access_mode_name: ""
})

let url = ''

//重置数据模型
const resetForm = () => {
  for (let key in formData) {
    console.log(key)
    formData[key] = ""
  }
  dataSource.begin_date = ""
  dataSource.end_date = ""
  optionsValue.contract_user_name = ""
  optionsValue.product_name = ""
  optionsValue.access_mode_name = ""
}

//添加
const addData = () => {
  // router.push({ path: "/createUser" });
  resetForm()
  console.log("addData");
  url = interfaces.contractAdd
  let id1 = options.contract_user_modal[0].id
  let id2 = options.contract_user_modal[0].children[0].id
  optionsValue.contract_user_name = [id1, id2]
  showModal.show = true;
};



const query_contractName = (id) => {
  options.contract_user_modal.forEach(item => {
    item.children.forEach(
      (item1) => {
        if (item1.id == id) {
          console.log('[item.id,id]', [item.label, item1.label])
          optionsValue.contract_user_name = [item.id, item1.id]
        }
      }
    )
  })
}


//编辑数据
const editData = () => {
  console.log();
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    url = interfaces.contractEdit
    formData["id"] = rowData[0]["id"]
    formData["key"] = rowData[0]["key"]
    formData["product_id"] = rowData[0]["product_id"]
    formData["contract_user_id"] = rowData[0]["contract_user_id"]
    formData["access_mode_id"] = rowData[0]["access_mode_id"]
    columns.forEach((item) => {
      formData[item.dataIndex] = rowData[0][item.dataIndex]
    })
    console.log('rowData[0]["due_date"]', rowData[0]["due_date"])

    dataSource.end_date = dayjs(rowData[0]["end_date"], 'YYYY-MM-DD')
    dataSource.begin_date = dayjs(rowData[0]["begin_date"], 'YYYY-MM-DD')
    // optionsValue.contract_user_name = rowData[0]["contract_user_name"]
    query_contractName(rowData[0]["contract_user_id"])
    optionsValue.product_name = rowData[0]["product_name"]
    optionsValue.access_mode_name = rowData[0]["access_mode_name"]
    console.log('formData.due_date', formData.due_date)
    showModal.show = true;
  }
};

//删除数据
const deleteData = () => {
  let deleteData = [];
  let jsonData = JSON.parse(JSON.stringify(rowData));
  jsonData.forEach((item) => {
    deleteData.push(item.id);
  });
  let params = {
    ids: deleteData,
  };

  serviceAxios({
    url: interfaces.contractDelete,
    method: "post",
    data: params,
  })
    .then((res) => {
      if (res.status == 200) {
        message.success("删除成功");
        queryData(pagination.current, pagination.pageSize);
      }
    })
    .catch((res) => {
      console.log(res);
    });
};



const handleOk = () => {
  formData.begin_date = dataSource.begin_date.format('YYYY-MM-DD');
  formData.end_date = dataSource.end_date.format('YYYY-MM-DD');

  serviceAxios({
    url: url,
    method: "post",
    data: formData,
  }).then((result) => {
    if (result.status == 200) {
      selectedRowKey.value = []
      queryData(pagination.current, pagination.pageSize);
      message.success("success")
    }
    else {
      message.error("请求异常，请联系管理员");
    }
  }).catch(() => { message.error("请求异常，请联系管理员"); })
  showModal.show = false;
  // formRef.value.resetFields()
};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

// queryData(pagination.current,pagination.pageSize);

//根据id查询用户或者产品的名称   异常报错

const queryUserProductionInfo = (name, id) => {
  console.log(name, id)
  const foundItem = name.find((item) => {
    return id === item.id;
  });

  if (foundItem) {
    console.log("item", foundItem.value);
    return foundItem.value;
  } else {
    return '1'; // 或者返回你想要的任何默认值  
  }
}

// 查询合约用户
// const query_contract_user_name= ()=>{
//   serviceAxios({
//     url:interfaces.customerUserPage,
//     method: "get",
//     params:{
//       page_num: 1,
//       page_size: 9999
//     }
//   }).then((result)=>{
//       if (result.status == 200){
//         let blockTree = []
//         result.data.data.list.forEach((item)=>{

//           blockTree.push({
//             id:item.id,
//             key: item.id,
//             value: item.name + '(' + item.id +')',
//             title:item.name+ '(' + item.id +')',
//           })   

//         })
//         options.contract_user_name = blockTree




//         let blockTrees = [];
//         let cList = [];
//         result.data.data.list.forEach((item)=>{
//           if (!cList.includes(item.customer.id)){
//             cList.push(item.customer.id);
//           blockTrees.push({
//               id:item.customer.id,
//               label:item.customer.name,
//               value: item.customer.id,
//               children:[
//               ]
//           })}
//         })
//         result.data.data.list.forEach((item)=>{
//           blockTrees.forEach((item1)=>{
//             console.log("111",item1.id)
//             console.log("222",item.customer.id)
//             if (item1.id == item.customer.id){
//               console.log("enter")
//               item1.children.push({
//                 id:item.id,
//                 label:item.name,
//                 value: item.id,
//               })
//               console.log("enter2",item1.children)

//             }
//           })
//         })
//         options.contract_user_modal = blockTrees
//         console.log("options.contract_user_modal",options.contract_user_modal)
//         queryData(pagination.current, pagination.pageSize);
//       }
//     else{
//       message.error("请求异常，请联系管理员");
//     }

//   }).catch(()=>{message.error("请求异常，请联系管理员");})
// }



// const query_contractUserInfo=(id)=>{
//   serviceAxios({
//     url:interfaces.customerUserGet,
//     method: "get",
//     params:{
//       id: Number(id)
//     }
//   }).then((result)=>{
//     if (result.status == 200){
//       console.log('result.data.data.customer.id',result.data.data.customer.id)
//       return result.data.data.customer.id
//     }
//     else{
//       message.error("请求异常，请联系管理员");
//     }
// })}

// 查询产品
const query_product_name = (customer_id) => {
  let params = ""
  if (customer_id != undefined && customer_id != '') {
    params = {
      page_num: 1,
      page_size: 9999,
      customer_id: Number(customer_id)
    }
  }
  else {
    params = {
      page_num: 1,
      page_size: 9999
    }
  }
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: params
  }).then((result) => {
    if (result.status == 200) {
      let blockTree = []
      result.data.data.list.forEach((item) => {

        blockTree.push({
          id: item.id,
          key: item.id,
          value: item.name + '(' + item.id + ')',
          label: item.name + '(' + item.id + ')',
        })

      })

      if (blockTree.length == 0) {
        optionsValue.product_name = "无产品"
        options.product_name = ""
      }
      else {
        console.log(blockTree)
        optionsValue.product_name = ""
        options.product_name = blockTree
      }

    }
    else {
      message.error("请求异常，请联系管理员");
    }

  }).catch(() => { message.error("请求异常，请联系管理员"); })
}
//查询入户方式名称
// const query_access_name=()=>{
//    serviceAxios({
//     url:interfaces.accessModePage,
//     method: "get",
//     params:{
//       page_num: 1,
//       page_size: 9999
//     }
//   }).then((result)=>{
//       if (result.status == 200){
//         let blockTree = []
//         result.data.data.list.forEach((item)=>{

//           blockTree.push({
//             id:item.id,
//             key: item.id,
//             value: item.name + '(' + item.id +')',
//             title:item.name+ '(' + item.id +')',
//           })   

//         })
//         options.access_mode_name = blockTree
//       }
//     else{
//       message.error("请求异常，请联系管理员");
//     }

//   }).catch(()=>{message.error("请求异常，请联系管理员");})

// }

// query_product_name()
// query_contract_user_name()




const queryData = (current, pageSize) => {

  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: current,
      page_size: pageSize,
    },
  })
    .then((result) => {
      let scpoeData = [];
      console.log("测试1", result)
      result.data.data.list.forEach((item) => {
        let dataObj = {};
        dataObj.id = item.id;
        dataObj.key = item.id;
        columns.forEach((col) => {
          dataObj[col.dataIndex] = item[col.dataIndex];
        });
        dataObj.contract_user_name = queryUserProductionInfo(options.contract_user_name, item.contract_user_id)
        dataObj.product_name = queryUserProductionInfo(options.product_name, item.product_id)
        dataObj.access_mode_name = queryUserProductionInfo(options.access_mode_name, item.access_mode_id)
        dataObj.contract_user_id = item.contract_user_id
        // dataObj.contract_id  = query_contractUserInfo(item.contract_user_id)
        console.log("dataObj.contract_id", dataObj.contract_id)
        dataObj.product_id = item.product_id
        // console.log("dataObj",dataObj)
        scpoeData.push(dataObj);
      });
      dataSource.data = scpoeData;
    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
  // 调用接口获取数据
  // ...
};
// queryData(pagination.current, pagination.pageSize)
// query_product_name()
// query_contract_user_name()
// queryData(pagination.current, pagination.pageSize);



const handleChange = (dataIndex, value) => {
  console.log(dataIndex, value);
  let regex = /\((\d+)\)/; // 匹配括号内的数字，并捕获它们  
  let match = value.match(regex);
  console.log(typeof (match[1]))
  if (dataIndex == 'product_name') {
    formData.product_id = Number(match[1])
  }
  else if (dataIndex == 'contract_user_name') {
    formData.contract_user_id = Number(match[1])
  }
  else if (dataIndex == 'access_mode_name') {
    formData.access_mode_id = Number(match[1])
  }

  // formData.customer_id = Number(match[1])
};


const cascaderOnChange = (value, selectedOptions) => {
  console.log("cascaderOnChange", value, selectedOptions)
  query_product_name(value[0])
  formData.contract_user_id = value[1]
}




</script>
<style lang="less" scoped>
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}
</style>