<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->

<template>
  <div v-if='EnvironChartVisible'
       class="modal-overlay">
    <div class='echart-box'>
      <div class='close-box'
           @click='closeChart'>关闭</div>
      <div class='echart-box-title'>预警发生前后12H温度曲线图</div>
      <div id='sensorFlowChart'
           class='sensorFlowChart-box'></div>
    </div>
  </div>

  <div class='container-page'
       ref='containerRef'>
    <div class="title-box">
      <div class='title'>
        传感器日志列表
      </div>
    </div>

    <div class='searchContainer'>
      <div class="keyword-box">
        <img class='keyword-image'
             src='@/static/demo/customer/搜索1.png' />
        <a-input v-model:value="searchData.keyword"
                 class='keyword'
                 :bordered='false'
                 :showArrow='false'
                 placeholder="请输入关键字（无法搜索门店）">
        </a-input>
      </div>
      <div class='sift-region-box'>
        <!-- <a-select :showArrow="false"
                       class="region-box"
                       v-model:value="searchData['region']"
                       show-search
                       :bordered='false'
                       style="width: 150px"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder="区域：请选择区域"
                       allow-clear
                       :tree-data="options['region']"
                       tree-node-filter-prop="label">
        </a-select> -->

        <a-select :showArrow="false"
                  :bordered='false'
                  v-model:value="searchData.store_id"
                  show-search
                  class="region-box"
                  :placeholder="`请选择门店`"
                  :options="options.store_id"
                  :filter-option="filterOption">
        </a-select>
      </div>

      <div class="
                  right-buttons">
        <div class='sift'
             @click="sift">
          <img class='sift-image'
               src='@/static/demo/customer/搜索1.png' />
          <div>
            搜索
          </div>
        </div>
        <div class='resetSift'
             @click="resetSift">
          <img class='reset-image'
               src='@/static/demo/customer/重置.png' />
          <div>
            重置
          </div>
        </div>
        <!-- <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button> -->
      </div>

    </div>

    <div class='table-box'>
      <a-table :scroll="ne"
               :columns="columns"
               :data-source="dataSource.data"
               :row-selection="rowSelection"
               :pagination="false"
               @change="handleTableChange"
               class="ant-table-striped"
               :row-class-name="(_record, index) => (index % 2 == 1 ? 'table-striped' : null)"
               :bordered=false
               style=" overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;height:780px">
        <template #bodyCell="{ column, record }">
          <template v-if="['operationalStatus'].includes(column.dataIndex)">
            <div class="status-light">
              <a @click="operationalStatusClick(record.id)"
                 v-if='record.operationalStatus'
                 class="green-light"></a>
              <a @click="operationalStatusClick(record.id)"
                 v-if='!record.operationalStatus'
                 class="red-light"></a>

            </div>
          </template>

          <template v-else-if="column.dataIndex === 'operation'">
            <div>
              <a class='editable-row-a'
                 @click="viewEchart(record)">查看异常信息</a>
              <!-- <a v-if='record.gateway && record.gateway.network_interfaces && record.gateway.network_interfaces.length >0'
                 class='editable-row-a'
                 @click="toMonitoring(record.gateway.network_interfaces,record.id,record.gateway.id)">监控信息</a> -->
            </div>
          </template>

          <template v-else-if="column.dataIndex === 'router'">
            <a @click=toWeb(record.web)
               class='editable-row-a'>{{record.router}}</a>
          </template>
        </template>
        <template #title>

        </template>
      </a-table>

      <div class='footer-pagination'>
        <div class='botton-style'
             @click='previous'
             :style="pagination.current > 1 ? { color: 'white' } : { color: '#999' }">上一页</div>
        <div class='pagination-text'>当前第 {{pagination.current}} 页 / 共 {{pagination.allPage}} 页 </div>
        <div class='botton-style'
             @click='nextPage'
             :style="pagination.current < pagination.allPage ? { color: 'white' } : { color: '#999' }">下一页</div>
      </div>

    </div>
  </div>
</template>
<script setup>
import { reactive, nextTick, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import dayjs from "dayjs";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import interfaces from "@/config";
import { checkTree } from "@/utils/regionTree";
import check from "@/utils/checkLocalStorage";
import { decimalUtils } from "@/utils/decimalUtils";
import { selector } from '@/utils/selector'
const locale = zhCN;
const route = useRoute();
const router = useRouter();
dayjs.locale("zh-cn");

const url = {
  logPage: '/v1/iot/influxdb_alert/page'
}
const convertDateStr = (dateStr) => {
  const dateParts = dateStr.split(' ');
  const dateString = dateParts[0];
  const timeString = dateParts[1] ? dateParts[1] : '';
  const formattedDateStr = `${dateString}T${timeString}+00:00`;
  return formattedDateStr;
}

const sensorChartOption = {
  tooltip: {
    trigger: 'axis',
    formatter: function (params) {
      var result = '';
      params.forEach(function (item) {
        const dateStr = convertDateStr(item.name); // 使用转换后的日期字符串
        var dateObj = new Date(dateStr);
        var yearMonthDay = dateObj.toLocaleDateString();
        var hourMinuteSecond = dateObj.toLocaleTimeString();
        var formattedValue = parseFloat(item.value).toFixed(2);
        result += `<div>${yearMonthDay}<br>${hourMinuteSecond}<br>${item.seriesName}: ${formattedValue} ℃</div>`;
      });
      return result;
    },
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderColor: '#ccc',
    borderWidth: 1,
    padding: 10,
    textStyle: {
      color: '#fff',
      fontSize: 14
    }
  },
  grid: {
    top: '15%',
    bottom: '10%',
    left: '10%',
    right: '10%',
    containLabel: true
  },
  xAxis: {
    data: [],
    axisLabel: {
      fontSize: 20,
      showMinLabel: true,
      showMaxLabel: true,
      // interval: 20,
      margin: 30,
      formatter: function (value, index) {
        const dateStr = convertDateStr(value); // 使用转换后的日期字符串
        var dateObj = new Date(dateStr);
        var yearMonthDay = dateObj.toLocaleDateString();
        var hourMinuteSecond = dateObj.toLocaleTimeString();
        return `${yearMonthDay}\n${hourMinuteSecond}`;
      }
    },
  },
  yAxis: {
    axisLabel: {
      fontSize: 20,
      margin: 30,
      formatter: function (value, index) {
        return `${value} ℃`; // 添加单位 "℃"
      }
    }
  },
  series: [{
    name: '温度',
    type: 'line',
    data: []
  }]
};
//定义传感器模块框显示字段
let EnvironChartVisible = ref(false)


onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  // const localMessage = check.checkData();
  // console.log("localMessage", localMessage)
  // // 检查用户信息是否存在  
  // if (!localMessage) {
  //   // 如果不存在，则跳转到登录页面  
  //   router.push('/loginView');
  // }

});
// let timer = null;
// onMounted(() => {
//   timer = setInterval(queryPage
//     , 30000);
// })
// onBeforeUnmount(() => {
//   clearInterval(timer);
// });

//按钮的显示
let buttonShow = reactive({
  contractAdd: true,
  contractDelete: true,
  contractEdit: true,
  contractPage: true,
})

const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}







//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 12, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["16", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  allPage: computed(() => {
    return Math.ceil(pagination.total / pagination.pageSize);
  })
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;

  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});


let showModal = reactive({
  show: false,
  advancedSearch: false,
  storeName: false,
  buttonShow: localStorage.getItem("name") == 'root' ? true : false, //控制删除和编辑按钮的显示
  message: false,
  isDeleteModalVisible: false,
  // test: true
});




//列名字段
const columns = [
  { title: "门店名称", dataIndex: "store_name", key: "store_name", ellipsis: true },
  // { title: "传感器名称", dataIndex: "sensor_name", key: "sensor_name", ellipsis: true },
  { title: "传感器别名", dataIndex: "sensor_alias", key: "sensor_alias", ellipsis: true },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
    ellipsis: true,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    ellipsis: true,
  },

  {
    title: "发生时间",
    dataIndex: "alert_time",
    key: "alert_time",
    ellipsis: true,
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    // fixed: "right",
    width: 200,
  },
];


//table数据源
let dataSource = reactive({
  data: [],


});



let searchData = reactive({

  region: null,
  keyword: ''

});






//页面筛选
const sift = () => {

  queryFields = {
    region_ids: searchData.region,
    keyword: searchData.keyword,
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryPage();
  message.info('搜索结束')
};

//重置筛选
const resetSift = () => {
  pagination.current = 1;
  pagination.pageSize = 12;
  let data = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryFields = data;
  queryPage();
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null;

    }
  }
  message.info('重置搜索信息成功')
};

let options = reactive({
  store_id: []
});






const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};


let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

const queryPage = () => {
  serviceAxios({
    url: url.logPage,
    timeout: 30000,
    method: "get",
    params: queryFields,
  })
    .then((res) => {
      if (res.data.message = 'success') {
        pagination.total = res.data.data.total;
        dataSource.data = res.data.data.list.map(item => ({

          id: item.id,
          key: item.id,
          store_name: item.store_name,
          sensor_alias: item.sensor_alias,
          type: item.type,
          status: item.status === 1 ? '异常' : '已恢复', // 1是正在发生异常 0是已恢复
          uuid: item.uuid,
          group_id: item.group_id,
          sensor_sn: item.sensor_sn,
          // store_id: store_id,
          // customer_id: customer_id,
          alert_time: item.alert_time,

        }))
      }
      else {
        message.error(res.data.message)
      }

    })
    .catch((err) => {
      console.log(err);
      message.error(res.data.detils);
    });
};


queryPage();

// 格式化时间
const formatTime = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


//查看传感器echart
const viewEchart = async (record) => {
  console.log(record)
  let result = await serviceAxios({
    url: '/v1/iot/temperature_sensor/get_id',
    method: 'get',
    params: {
      // customer_id: customer_id,
      // store_id: store_id,
      uuid: record.uuid,
      group_id: record.group_id,
      sensor_sn: record.sensor_sn,
    }
  })

  if (result.data.message == 'success') {


    // 解析输入的时间字符串
    const alertTimeStr = record.alert_time;
    const alertTime = new Date(alertTimeStr);

    // 计算前后五个小时的时间
    const fiveHoursInMs = 12 * 60 * 60 * 1000; // 5 hours in milliseconds

    let timeBeforeFiveHours = new Date(alertTime.getTime() - fiveHoursInMs);
    let timeAfterFiveHours = new Date(alertTime.getTime() + fiveHoursInMs);

    let start = formatTime(timeBeforeFiveHours)
    let stop = formatTime(timeAfterFiveHours)
    // 输出前后五个小时的时间
    console.log('五小时前的时间：', timeBeforeFiveHours);
    console.log('五小时后的时间：', timeAfterFiveHours);

    let sensor_id = result.data.data
    result = await serviceAxios({
      url: '/v1/iot/temperature_sensor/data',
      method: 'get',
      params: {
        sensor_id: sensor_id,
        max_point: 200,
        start: start,
        stop: stop
      }

    })
    console.log('result', result)

    // sensorChartOption.yAxis.data = result.data[0].data.map(item=>item.value)
    if (result.data.data.length == 0) {
      message.info('无传感器数据')
      return
    }
    sensorChartOption.xAxis.data = result.data.data[0].data.map(item => item.time)
    sensorChartOption.series[0].data = result.data.data[0].data.map(item => item.value)
    EnvironChartVisible.value = true
    nextTick(() => {
      console.log('渲染')
      initSensorChart();
    });


  } else {
    message.error(result.data.data.message)
  }


}

const initSensorChart = () => {
  let chartDom = document.getElementById('sensorFlowChart')
  console.log('chartDom', chartDom)
  const chart = echarts.init(chartDom);
  chart.setOption(sensorChartOption);
};

const closeChart = () => {
  EnvironChartVisible.value = false
}
// onMounted(() => {
//   nextTick(() => {
//     if (EnvironChartVisible.value) {
//       initSensorChart();
//     }
//   });
// });

// watch(EnvironChartVisible, (newValue, oldValue) => {
//   console.log(newValue, !oldValue)
//   if (newValue && !oldValue) {
//     initSensorChart();
//   }
// });


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const toMonitoring = (networkInterfaces, storeId, gatewayId) => {
  let ids = []
  networkInterfaces.forEach(item => {
    ids.push(item.id)
  })
  ids = ids.join(',')
  // storeAndGateway.value.ids = ids
  // storeAndGateway.value.storeId = storeId

  router.push({

    path: "/storeDetailPage",
    query: { ids: ids, storeId: storeId, gatewayId: gatewayId }

  })
}

const toSensorDetails = (sensorId) => {
  router.push({ path: "/sensorDetails", query: { sensorId: sensorId } })
}

const checkOline = (ids) => {
  serviceAxios({
    url: interfaces.onlineStatus,
    method: 'post',
    data: {
      gateway_ids: ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let enableGateList = dataSource.data.filter(item => item.gatewayId != null)
      result.data.data.forEach(
        item => {
          let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.eth_number)
          gatewayStatus.forEach(i => {
            i.operationalStatus = item.value == '正常' ? true : false
          })
        }
      )
    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
    message.error('请求异常')
  })
}

//路由管理跳转
const toWeb = (web) => {
  window.open(web, '_blank');
}

const back = () => {
  router.push({ path: '/homePageData' })
}

//向上翻页
const previous = () => {
  if (pagination.current > 1) {
    pagination.current -= 1
    queryFields.page_num = pagination.current
    queryPage()
  } else {

  }

}
//向下翻页
const nextPage = () => {
  if (pagination.current < pagination.allPage) {
    pagination.current += 1
    queryFields.page_num = pagination.current
    queryPage()
  }
  else {

  }
}

(async () => {
  options.store_id = await selector.store()
})()
</script>
<style lang="less" scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/antTable.css";
@import "@/assets/css/pageTitle.css";
@import "@/assets/css/mask.css";
</style>