<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <a-modal :maskClosable="false"
           v-model:open="modalShow.sensor"
           title="传感器信息"
           width="550px"
           @ok="EditSensoSubmitr"
           cancelText='取消'
           class="modal-style"
           okText='提交'>
    <div class='orderTable-box'>
      <template v-for='item in sensorDetailFields'
                :key='item.key'>

        <div class='modal-table-item'
             v-if='!["temSwitch","offlineSwitch"].includes(item.key)'>
          <div class='column'>{{item.title}}</div>
          <div>
            <a-input v-model:value="baseMessageRef[item.key]"
                     class='td-input'
                     :bordered="false"
                     :placeholder="`请输入${item.title}`" />
          </div>

        </div>

        <div class='modal-table-item'
             v-else>
          <div class='column'>{{item.title}}</div>
          <div style='margin-left:15px'>
            <a-switch v-model:checked="baseMessageRef[item.key]"
                      checked-children="开"
                      un-checked-children="关" />
            <!-- <a-input v-model:value="baseMessageRef[item.key]"
                     class='td-input'
                     :bordered="false"
                     :placeholder="`请输入${item.title}`" /> -->
          </div>

        </div>
      </template>
    </div>
  </a-modal>
  <div class='container-page'>
    <div class='title-box'>
      <div class='title'>传感器详情</div>
    </div>
    <div class='main-box'>

      <div class='sensorMessage-box'>
        <div class='right-operation-box'>
          <div>
            <img class='left-image'
                 @click='setSensor'
                 src='@/static/demo/sensorDetails/设置.png' />
            <!-- <img class='right-image'
                 src='@/static/demo/sensorDetails/解除预警.png' /> -->
          </div>
        </div>
        <div class='box-up'>
          <div class='box-up-left'>
            <div class='box-up-left-item'>
              <img class='left-image'
                   src='@/static/demo/sensorDetails/传感.png' />
              <div class='left-item-label'>
                别名：
                <div class='left-item-value'>{{baseMessageRef.alias}}</div>
              </div>
            </div>

            <div class='box-up-left-item'>
              <img class='left-image'
                   src='@/static/demo/sensorDetails/离线1.png' />
              <div class='left-item-label'>
                离线次数：
                <div class='left-item-value'>{{baseMessageRef.offline_alert}}</div>
              </div>
            </div>

            <div class='box-up-left-item'>
              <img class='left-image'
                   src='@/static/demo/sensorDetails/警告.png' />
              <div class='left-item-label'>
                报警次数：
                <div class='left-item-value'>{{baseMessageRef.threshold_alert}}</div>
              </div>
            </div>
            <div class='box-up-left-item'>
              <img class='left-image'
                   src='@/static/demo/sensorDetails/冰箱.png' />
              <div class='left-item-label'>
                备注：
                <div class='left-item-value'>{{baseMessageRef.desc}}</div>
              </div>
            </div>
          </div>

          <div class='box-up-middle'>
            <div>
              <img class='middle-image'
                   src='@/static/demo/sensorDetails/圆.png' />
            </div>
            <div class='now-temperature-value'>{{baseMessageRef.now_temperature}}℃

            </div>
            <div class='now-temperature-title'>
              当前温度
            </div>
          </div>

          <div class='box-up-right'>
            <div class='up-right-item'>
              <img class='right-image'
                   src='@/static/demo/sensorDetails/温度计.png' />
              <div class='right-item-label'>
                {{baseMessageRef.min_threshold}} 至 {{baseMessageRef.max_threshold}}
                <div class='right-item-value'>温度阈值（℃）</div>
              </div>
            </div>
            <!-- <div class='up-right-item'>
              <img class='right-image'
                   src='@/static/demo/sensorDetails/离线.png' />
              <div class='right-item-label'>
                100{{}}
                <div class='right-item-value'>设备离线阈值</div>
              </div>
            </div> -->
          </div>
        </div>

        <div class='box-down'>
          <div id='sensorChart'
               :ref='sensorChart'
               style='height:300px;width:100%'>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount, nextTick } from "vue";
import { message } from "ant-design-vue";
import * as echarts from "echarts";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import serviceAxios from "@/utils/serviceAxios";


const route = useRoute();
const router = useRouter();

const url = {
  sensorMessage: '/v1/iot/temperature_sensor/get_by_id',//传感器信息
  temFlow: '/v1/iot/temperature_sensor/data' //传感器echart数据
}
//定义传感器模块框显示字段
let modalShow = reactive({
  sensor: false
})
//定义选择器初始字段
let options = {
  type: []
}
let baseMessageRef = ref({
  id: '',
  alias: '',
  desc: '',
  threshold_alert: '',
  offline_alert: '',
  min_threshold: '',
  max_threshold: '',
  // duration: '',
  sms_alert_phones: '',
  tel_alert_phones: '',
  temSwitch: '',
  offlineSwitch: '',


})

//传感器可修改字段
const sensorDetailFields = [
  {
    title: '别名',
    key: 'alias'
  },
  {
    title: '备注',
    key: 'desc'
  },

  {
    title: '最大温度',
    key: 'max_threshold'
  },
  {
    title: '最小温度',
    key: 'min_threshold'
  },
  // {
  //   title: '联系人',
  //   key: 'contactName'
  // },
  {
    title: '预警通知手机号',
    key: 'sms_alert_phones'
  },
  // {
  //   title: '离线预警',
  //   key: 'offlineSwitch'
  // },
  {
    title: '温度预警',
    key: 'temSwitch'
  }
]
let sensorChart = ref()
//定义echarts图表数据
let sensorChartOption = {
  grid: {
    top: '15%',
    bottom: '10%',
    left: '5%',
    right: '10%',
    containLabel: true
  },
  xAxis: {
    data: [],
    axisLabel: {
      fontSize: 13,
      color: '#FFFFFF',
      showMinLabel: true,
      showMaxLabel: true,
      interval: 20,
      margin: 15,
      formatter: function (value, index) {
        const dateObj = new Date(value);
        const yearMonthDay = dateObj.toLocaleDateString(); // 获取年月日
        const hourMinuteSecond = dateObj.toLocaleTimeString(); // 获取时分秒
        return `${yearMonthDay}\n${hourMinuteSecond}`; // 使用换行符 \n 进行换行
      }
    },

  },
  yAxis: {
    axisLabel: {
      fontSize: 19,
      color: '#FFFFFF'
    },
    name: "℃",
    nameTextStyle: {
      color: "#FFFFFF",
      nameLocation: "start",
      fontSize: 19,
    },
    axisLine: {
      lineStyle: {
        color: '#FFFFFF'
      }
    },
    splitLine: {
      lineStyle: {
        color: ['rgba(255, 255, 255, 0.1)'],
        width: 1,
        type: 'solid'
      }
    },
  },

  series: [{
    name: '温度',
    type: 'line',
    smooth: true,
    symbol: 'none', // 不显示数据点标记
    lineStyle: {
      width: 3, // 增加线条宽度
      color: '#4DFCD5'
    },
    areaStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(
          0, 0, 0, 1,
          [
            { offset: 0, color: 'rgba(77, 252, 213, 0.2)' }, // 与线条颜色相匹配的渐变起始颜色
            { offset: 1, color: 'rgba(77, 252, 213, 0.05)' } // 渐变更淡的颜色
          ],
          false
        )
      }
    },
    data: [],
    markLine: {
      data: [
        {
          yAxis: 4,
          lineStyle: {
            color: 'rgba(255, 0, 0, 0.7)', // 刻度线颜色
            width: 1, // 刻度线宽度
            type: 'solid' // 刻度线样式，可选 'solid', 'dashed', 'dotted'
          },
          label: {
            show: true,
            position: 'end',
            // formatter: '10℃',
            color: '#FF0000'
          }
        },
        {
          yAxis: 9,
          lineStyle: {
            color: 'rgba(255, 0, 0, 0.7)', // 刻度线颜色
            width: 1, // 刻度线宽度
            type: 'solid' // 刻度线样式，可选 'solid', 'dashed', 'dotted'
          },
          label: {
            show: true,
            position: 'end',
            // formatter: '10℃',
            color: '#FF0000'
          }
        }
      ]
    }
  }],

};


// 获取 URL 中的 sensorId 参数
const sensorId = ref(route.query.sensorId || null);



//获取传感器信息 
const getSensorMessage = async () => {
  let response = await serviceAxios({
    url: url.sensorMessage,
    method: 'get',
    params: {
      sensor_id: sensorId.value
    }
  })
  try {
    if (response.data.message == 'success') {
      let data = response.data.data
      baseMessageRef.value.id = data.id
      baseMessageRef.value.alias = data.alias
      baseMessageRef.value.desc = data.desc
      baseMessageRef.value.threshold_alert = data.threshold_alert
      baseMessageRef.value.offline_alert = data.offline_alert
      baseMessageRef.value.max_threshold = data.max_threshold
      baseMessageRef.value.min_threshold = data.min_threshold
      baseMessageRef.value.sms_alert_phones = data.sms_alert_phones[0]
      baseMessageRef.value.temSwitch = data.threshold_alert == 0 ? false : true
      // baseMessageRef.value.offlineSwitch: '',
    }
    else {
      message.error(response.data.message)
    }
  }

  catch (error) {
    message.error(response.data.detail)
  }
}
//获取传感器温度数据 
const getTemSensorFlowData = async () => {
  let response = await serviceAxios({
    url: url.temFlow,
    method: 'get',
    params: {
      sensor_id: sensorId.value,
      max_point: 200
    }
  })
  try {
    if (response.data.message == 'success') {
      let data = response.data.data
      console.log('传感器数据', data)
      if (data.length == 0) {
        message.error('该传感器无温度数据')
        return
      }
      sensorChartOption.xAxis.data = data[0].data.map(item => item.time)
      sensorChartOption.series[0].data = data[0].data.map(item => item.value)
      baseMessageRef.value.now_temperature = data[0].data[data[0].data.length - 1].value.toFixed(1)

      sensorChartOption.series[0].markLine.data[0].yAxis = baseMessageRef.value.min_threshold
      sensorChartOption.series[0].markLine.data[1].yAxis = baseMessageRef.value.max_threshold

      console.log('echart', sensorChartOption)
    }
    else {
      message.error(response.data.message)
    }
  }
  catch (error) {
    message.error(response.data.detail)
    console.log(error)
  }


}

//设置修改传感器
const setSensor = () => {
  modalShow.sensor = !modalShow.sensor

}

const EditSensoSubmitr = async () => {
  let result = await serviceAxios({
    url: '/v1/iot/tenant/temperature_sensor/edit',
    method: 'post',
    data: {
      id: baseMessageRef.value.id,
      alias: baseMessageRef.value.alias,
      desc: baseMessageRef.value.desc,
      threshold_alert: baseMessageRef.value.temSwitch == true ? 1 : 0,
      // offline_alert: baseMessageRef.value.offlineSwitch == true ? 1 : 0,
      min_threshold: baseMessageRef.value.min_threshold,
      max_threshold: baseMessageRef.value.max_threshold,
      // duration: baseMessageRef.value.duration,
      sms_alert_phones: baseMessageRef.value.sms_alert_phones ? [baseMessageRef.value
        .sms_alert_phones
      ] : [],
      // tel_alert_phones: item.tel_alert_phones
    }
  })
  let data = result.data
  if (data.message == 'success') {
    getSensorMessage()
    modalShow.sensor = !modalShow.sensor
    message.success('修改成功')
  } else {
    message.error('修改失败')
  }

}

onMounted(async () => {
  if (!sensorId.value) {
    console.error("sensorId is not provided in the URL.");
    // 可以选择跳转到错误页面或提示用户
    message.error("未在 URL 中找到 sensorId 参数");
    router.push({ path: "/sensorList", })
    return
  }

  nextTick(async () => {
    await getTemSensorFlowData()
    let chartDom = document.getElementById('sensorChart')
    let chart = echarts.init(chartDom)
    chart.setOption(sensorChartOption)
  })
  // 在组件挂载时检查 sensorId 是否存在

});


//初始化
const init = () => {
  getSensorMessage()

}
init()
</script>

<style lang='less' scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/customer/sensorDetails.css";
</style>