<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <div class="container-page">
    <div class="left-panel">
      <a-tree :height="400"
              v-model:expandedKeys="expandedKeys"
              v-model:selectedKeys="selectedKeys"
              v-model:checkedKeys="checkedKeys"
              checkable
              :checkStrictly="true"
              :multiple="false"
              :tree-data="treeData.data"
              @check="handleCheck">
        <template #title="{ title, key }">
          <span v-if="key === '0-0-1-0'"
                style="color: #1890ff">{{ title }}</span>
          <template v-else>{{ title }}</template>
        </template>
      </a-tree>
    </div>
    <div class="right-panel">
      <a-form :model="formData"
              v-bind="formItemLayout"
              :rules=rules
              ref="formRef">
        <a-form-item v-for="item in formItems"
                     :key="item.key"
                     :label="item.label"
                     :name="item.key"
                     :v-model="formData"
                     class="custom-form-item">
          <a-input v-if="!['parent_id','order','node_id'].includes(item.key)"
                   v-model:value="formData[item.key]" />
          <a-input-number v-else-if="['parent_id','node_id'].includes(item.key)"
                          v-model:value="formData[item.key]" />
          <a-input-number v-else
                          v-model:value="formData[item.key]" />
        </a-form-item>
        <a-form-item :class='right-button'>
          <a-button type="primary"
                    @click="submitForm">添加</a-button>
          <a-button type="primary"
                    style="margin:5px"
                    @click="editForm">修改</a-button>
          <a-button type="danger"
                    @click="deleteForm">删除</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { ref, watch, reactive } from "vue";
import serviceAxios from "../../utils/serviceAxios";
import { toRaw } from "@vue/reactivity";
import { message } from "ant-design-vue";
import interfaces from "@/config";


let formRef = ref();
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 14,
    },
  },
};
// 表单字段结构初始化数据  '
const formData = reactive({
  id: "",
  key: "",
  label: "",
  title: "",
  order: "",
  parent_id: "",
  node_id: "",
  keyPath: ""
});

// 表单项(label)结构
const formItems = ref([
  {
    label: "悬浮提示",
    title: "悬浮提示",
    key: "title",
  },
  {
    label: "菜单名称",
    title: "菜单名称",
    key: "label",
  },
  {
    label: "排序",
    title: "排序",
    key: "order",
  },
  {
    label: "节点ID",
    title: "节点ID",
    key: "node_id",
  },
  {
    label: "父节点ID",
    title: "父节点ID",
    key: "parent_id",
  },
  {
    label: "路径",
    title: "路径",
    key: "keyPath",
  },
]);

const rules = {
  label: [{ required: true, message: "必填", trigger: "blur" }],
  title: [{ required: true, message: "必填", trigger: "blur" }],
  order: [
    { required: true, message: "", trigger: "blur" },
    { type: "number", message: "请输入数字", trigger: "blur" },
  ],

  keyPaht: [
    { required: true, message: "二级菜单才有效，一级无效", trigger: "blur" },
  ],
};

const treeData = reactive({
  data: [],
});
const expandedKeys = ref([]);
// const selectedKeys = ref(['0-0-0', '0-0-1']);
const selectedKeys = ref([]);
const checkedKeys = ref([]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});



// 处理复选框点击事件，实现单选功能
const handleCheck = (checked, { node }) => {
  console.log(node);
  console.log(checked);

  if (node) {
    if (checked.checked.length > 0) {
      // 如果节点被选中，清空checkedKeys数组并添加当前节点的key
      checkedKeys.value = [node.key];
      formData.label = node.dataRef.label;
      formData.title = node.dataRef.title;
      formData.order = node.dataRef.order;
      formData.id = node.dataRef.id;
      formData.keyPath = node.dataRef.keyPath;
      formData.parent_id = node.parent_id;
      formData.node_id = node.dataRef.node_id;
      console.log("formData", formData)

    } else {
      // 如果节点被取消选中，清空checkedKeys数组
      checkedKeys.value = [];

      formRef.value.resetFields();
      formData.parent_id = 0;
      formData.node_id = 0;
      formData.id = "";
      console.log(formData);
    }
  }
};

const deleteForm = () => {
  serviceAxios({
    url: interfaces.navigatorDelete,
    method: "post",
    data: {
      ids: [formData.id],
    },
  })
    .then((result) => {
      console.log(result.data.message);


      if (result.data.message == 'success') {
        formRef.value.resetFields();
        queryTree();
        message.success("删除成功");
      }
      else {
        message.error(result.data.message);
      }

      // getData();
      // dialogVisible.value = false;
      // formRef.resetFields()

    })
    .catch((err) => {
      message.error("删除导航栏发生异常，请等待修复")
      console.log(err);
    });
};

// 提交表单的方法
const submitForm = () => {


  let data = {
    label: formData.label,
    title: formData.title,
    order: formData.order,
    parent_id: formData.node_id == "" ? 0 : formData.node_id,
    key: formData.keyPath
  }
  formRef.value
    .validate()
    .then(() => {
      serviceAxios({
        url: interfaces.navigatorAdd,
        method: "post",
        data: data,
      })
        .then((result) => {
          if (result.data.message == "success") {
            formRef.value.resetFields();
            queryTree();
            checkedKeys.value = [];
            message.success("成功");
          }
          else {
            message.error(result.data.message);
          }

        })
        .catch((err) => {
          message.error(err);
        });
    })
    .catch(() => {
      // 验证失败，处理验证错误
      message.error("表单验证失败，请检查输入信息");
    });
};

const editForm = () => {
  let data = {
    "id": formData.id,
    "label": formData.label,
    "title": formData.title,
    "order": formData.order,
    "parent_id": formData.parent_id,
    "key": formData.keyPath
  }
  formRef.value
    .validate()
    .then(() => {
      serviceAxios({
        url: interfaces.navigatorEdit,
        method: "post",
        data: data,
      })
        .then((result) => {
          message.success("success");
          formRef.value.resetFields();
          queryTree();
          checkedKeys.value = []
        })
        .catch((err) => {
          console.log(err);
        });
    })
    .catch(() => {
      // 验证失败，处理验证错误
      message.error("表单验证失败，请检查输入信息");
    });
};

//清洗字段
const clearTree = (data) => {
  let childrenData = []
  if (data != undefined) {
    data.forEach((item) => {
      childrenData.push({
        id: item.id,
        key: item.id,
        keyPath: item.key,
        is_bottom: item.is_bottom,
        title: item.label, // 树的标题
        label: item.title, //标题的悬浮显示
        order: item.order, //排序
        parent_id: item.parent_id, //父节点
        node_id: item.id,
        children: clearTree(item.children),
      });

    })
    return childrenData;
  }
  else {
    return []
  }

};
// 获取所有菜单数据的方法
const queryTree = () => {
  serviceAxios({
    url: interfaces.navigatorAll,
    method: "post",
    data: "",
  })
    .then((result) => {
      let newTreeData = clearTree(result.data.data)
      treeData.data = newTreeData;

      //初始化节点ID和父节点ID
      formData.parent_id = 0;
      formData.node_id = 0;
    })
    .catch((err) => {
      console.log(err);
    });
};
queryTree();
</script>


<style lang='less' scoped>
.container-page {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9; /* 淡灰色背景 */
  padding: 20px; /* 内边距 */
  box-sizing: border-box; /* 确保内边距不会增加总宽度 */
}

.left-panel {
  flex: 1; /* 占据剩余空间的一部分 */
  margin-right: 20px; /* 右边距 */
  background-color: #fff; /* 白色背景 */
  border-radius: 4px; /* 圆角 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
  overflow: auto; /* 确保内容滚动 */
}

.right-panel {
  flex: 1; /* 占据剩余空间的一部分 */
  background-color: #fff; /* 白色背景 */
  border-radius: 4px; /* 圆角 */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
  padding: 20px; /* 内边距 */
  box-sizing: border-box; /* 确保内边距不会增加总宽度 */
}

.custom-form-item .ant-form-item-label {
  /* 调整标签样式 */
  text-align: right; /* 右对齐 */
}

.custom-form-item .ant-input,
.custom-form-item .ant-input-number {
  /* 调整输入框样式 */
  width: 100%; /* 宽度100% */
}

.right-button {
  text-align: right; /* 提交和删除按钮右对齐 */
}

/* 响应式布局 */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* 竖直方向排列 */
  }

  .left-panel,
  .right-panel {
    margin-right: 0; /* 移除右边距 */
    margin-bottom: 20px; /* 底部边距 */
  }
}
</style>