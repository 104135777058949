<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->

<template>

  <a-modal :maskClosable="false"
           v-model:open="showModal.confirmDeleteModel"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="showModal.accountModal"
           title="账户信息"
           @ok="accountSubmit">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <a-col :span="12">
          <a-form-item label="账号">
            <a-input style='width:200px'
                     v-model:value="accountData['username']" />
          </a-form-item>

        </a-col>

        <a-col :span="12">

          <a-form-item label="密码">
            <a-input style='width:200px'
                     v-model:value="accountData['password']" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.addOrEditModel"
           title="租户信息"
           width="1000px"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in formName"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item v-if="!['type','brand'].includes(item.key)"
                         :label="item.title"
                         :name="item.key">
              <a-input v-model:value="baseData[item.dataIndex]" />
            </a-form-item>
            <a-form-item v-else-if="['type'].includes(item.key)"
                         :label="item.title"
                         :name="item.key">
              <a-select v-model:value="baseData[item.dataIndex]"
                        :options="options.tenantType" />

            </a-form-item>

            <a-form-item v-else-if="['brand'].includes(item.key)"
                         :label="item.title"
                         :name="item.key">
              <a-select v-model:value="baseData[item.dataIndex]"
                        :disabled="baseData.type != '客户'"
                        :options="options.brand"
                        :filter-option="filterOption" />

            </a-form-item>

          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          <a @click="storeNameClick(record.id)">{{ record.name }}</a>
        </div>
      </template>
      <template v-if="['operations'].includes(column.dataIndex)">
        <div>
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="bindAccount(record.id)">点秀账号</a>

          <a class='editable-row-a'
             @click="enableAccount(record.id)">启用</a>
          <a class='editable-row-a'
             @click="banAccount(record.id)">禁用</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <a-select v-model:value="searchData.searchFiled" show-search placeholder="Select a person" style="width: 100px"
          :options="options.search" @change="handleChangeSearch"></a-select> -->
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>

          <a-button class="addDataButton"
                    type="primary"
                    @click="reflush">刷新</a-button>
          <a-button class="addDataButton"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed } from "vue";
import serviceAxios from "../../utils/serviceAxios";
import { message } from "ant-design-vue";
import interfaces from "@/config";
import { selector } from "@/utils/selector"

import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "ant-design-vue/es/date-picker/locale/zh_CN";
import encrypt from '@/utils/encrypt'
dayjs.locale("zh-cn");



const accountData = reactive({
  tenant_id: "",
  username: "",
  password: ""
})


//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  getData();
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([

  "storeName",
  "brand",
  "officialName",
  "telephone",
  "contactName",
  "phone",
  "planBeginDate",
  "actualDate",
  "endDate",
  "region",
  "address",
  "paymentType",
  "duration",
  "payment_status",
  "paymentAmount",
  "invoice_type",
  "invoice_number",
  "desc",
  // "productService",
  "owner",
  "operationalStatus",


]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);
const ne = reactive({
  x: "",
  y: ''
})
// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  console.log()
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

let showModal = reactive({
  addOrEditModel: false,
  confirmDeleteModel: false,
  accountModal: false,

});



//列名字段
const columns = [
  // { title: "ID", dataIndex: "id", key: "id" },
  { title: "名称", dataIndex: "name", key: "name" },
  { title: "全称", dataIndex: "full_name", key: "full_name" },
  { title: "类型", dataIndex: "type", key: "type" },
  { title: "操作", dataIndex: "operations", key: "operations" },
];
//model表格字段
const formName = [
  { title: "名称", dataIndex: "name", key: "name" },
  { title: "全称", dataIndex: "full_name", key: "full_name" },
  { title: "类型", dataIndex: "type", key: "type" },
  { title: "品牌", dataIndex: "brand", key: "brand" },
  { title: "账号", dataIndex: "account", key: "account" },
  { title: "密码", dataIndex: "password", key: "password" },
];

const options = reactive({
  tenantType: [{ label: "客户", value: "客户" },
  { label: "供应商", value: "供应商" },
  { label: "外包", value: "外包" },],
  brand: []
})
const rules = {
  name: [
    {
      required: true,
      message: "",
    },
  ],
  full_name: [
    {
      required: true,
      message: "",
    },
  ],
  type: [
    {
      required: true,
      message: "",
    },
  ],
  account: [
    {
      required: true,
      message: "",
    },

  ],
  password: [
    {
      required: true,
      message: "",
    },

  ],
};
//table数据源
let dataSource = reactive({
  data: []
});

// 数据模型
let baseData = reactive({
  id: "",
  name: "",
  full_name: "",
  type: "",
  brand_id: "",
  password: "",
  encryptPassword: "",
  account: "",
});

let queryPage = () => {
  serviceAxios({
    url: interfaces.tenantPage,
    method: "get",
    params: {
      page_num: pagination.current,
      page_size: pagination.pageSize,
    }

  }).then(result => {
    pagination.total = result.data.data.total
    let data = []

    result.data.data.list.forEach(item => {
      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        full_name: item.full_name,
        type: item.type,
      })
    })
    dataSource.data = data
  }

  ).catch(err => {
    console.log(err)
    message.error("发生异常错误，请联系管理员")
  })
}

queryPage()

let url = "";   //判断是编辑按钮还是添加按钮
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
};



//添加
const addData = () => {
  showModal.addOrEditModel = true
  url = interfaces.tenantAdd
  resetForm()
};

//编辑数据
const editData = (rowData) => {
  showModal.addOrEditModel = true
  url = interfaces.tenantEdit
  baseData.id = rowData.id
  baseData.name = rowData.name
  baseData.full_name = rowData.full_name
  baseData.type = rowData.type

};
//删除数据
const deleteData = () => {
  if (rowData.length != 0) {

    showModal.confirmDeleteModel = true
  }
  else {
    message.error("至少选择一条数据进行删除")
  }
};

const handleOkDelete = () => {
  let deleteData = []
  rowData.forEach(item => {
    deleteData.push(item.id)
  })
  serviceAxios({
    url: interfaces.tenantDelete,
    method: "post",
    data: {
      ids: deleteData
    }
  }).then(
    result => {
      if (result.data.message == 'success') {
        showModal.confirmDeleteModel = false
        queryPage()
      }
    }
  ).catch(err => {
    console.log(err)
    message.error("发生异常错误，请联系管理员")
  })
};
const handleCancelDelete = () => {
};

//增加或编辑租户信息
const handleOk = () => {
  const encryptAccount = encrypt.addUserCrypto(baseData.password)
  serviceAxios({
    url: url,
    method: "post",
    data: {
      id: baseData.id,
      name: baseData.name,
      full_name: baseData.full_name,
      type: baseData.type,
      brand_id: baseData.brand,
      tenant_admin_name: baseData.account,
      tenant_admin_password: encryptAccount,
    }


  }).then(result => {
    if (result.data.message == 'success') {
      selectedRowKey.value = []
      showModal.addOrEditModel = false
      message.success("成功")
      queryPage()
    } else {
      message.error(result.data.message)
    }

    queryPage()
  }).catch(err => { console.log(err) })
};


const bindAccount = (id) => {
  console.log("绑定账号")
  accountData.tenant_id = id
  showModal.accountModal = true
}

const accountSubmit = () => {
  let data = {
    tenant_id: String(accountData.tenant_id),
    username: accountData.username,
    password: accountData.password
  }
  serviceAxios(
    {
      url: '/v1/info_dis/point_show/account/set',
      method: 'post',
      data: data
    }
  ).then(
    result => {
      if (result.data.message == 'success') {
        message.success("success")
        showModal.accountModal = false
      }
      else {
        message.error(result.data.message)
      }
    }
  ).catch(
    error => {
      console.log(error)
      message.error("请求异常")
    }
  )
}

const enableAccount = () => {

}


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

//刷新
const reflush = () => {
  queryPage()
}
//自执行 获取品牌选择器
(async () => {
  options.brand = await selector.brand()
})()
</script>
<style lang="less" >
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.mailTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.mailTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.mailTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 18%;
}
// .mailTable tr td.longColumn {
//     // background-color: #EFF3F6;
//     // color: #393C3E;
//     width: 85%;
// }

p {
  font-size: 12px;
}

a {
  color: black;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}
</style>