<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->


<template>

  <a-modal :maskClosable="false"
           width="400px"
           v-model:open="showModal.show"
           title="用户信息"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="baseData"
            ref="formRef"
            :rules="rules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span:10 }">
      <a-form-item v-for="item in addfields"
                   :key="item.key"
                   :label="item.title"
                   :name="item.dataIndex">

        <a-select v-if="['role_name'].includes(item.dataIndex)"
                  v-model:value="baseData['role_id']"
                  show-search
                  placeholder=""
                  style="width: 200px"
                  :options="options['role_name']"
                  :filter-option="filterOption"
                  @change="handleChange"></a-select>

        <a-tree-select v-else-if="['jobTitle'].includes(item.dataIndex)"
                       v-model:value="baseData[item.dataIndex]"
                       show-search
                       style="width: 200px"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder=""
                       allow-clear
                       multiple
                       tree-default-expand-all
                       :tree-data="options['treeData']"
                       tree-node-filter-prop="label"
                       @change='treeChange'>
        </a-tree-select>
        <a-input style="width: 200px"
                 v-else
                 v-model:value="baseData[item.dataIndex]" />

      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 选择列 -->
  <a-modal :maskClosable="false"
           v-model:visible="columnShowModal"
           title="选择列显示"
           @ok="columnHandleOk"
           @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns"
                      :key="column.key"
                      :value="column.key">
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['customer_name'].includes(column.dataIndex)">
        <div>
          <!-- <a @click="1">{{ record.customer_ids }}</a>
           -->
          <a-tooltip>
            <template #title>{{record.customersNameList}}</template>
            {{record.customer_name}}
          </a-tooltip>
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <a-input addonBefore='关键字'
                 style="width:150px"
                 v-model:value='searchRef.keyword'></a-input>

        <!-- <a-input-group compact>
          <div class='searchLabel'>
            <label>角色</label>
          </div>

        </a-input-group> -->

        <a-button type="primary"
                  style='margin: 2px;'
                  @click='sift'>搜索</a-button>
        <a-button style='margin: 2px;'
                  @click='resetSift'>重置</a-button>
        <!-- <a-button type="primary" @click="columnShowModal = true">筛选列显示</a-button> -->
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed } from "vue";
import serviceAxios from "../../utils/serviceAxios";
import { message } from "ant-design-vue";
import interfaces from "@/config";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import encrypt from "../../utils/encrypt"




let formRef = ref();
dayjs.locale('zh-cn');
let showModal = reactive({
  show: false,
});
//表格字段
const columns = [
  { title: "用户名", dataIndex: "username", key: "username" },
  { title: "真实姓名", dataIndex: "real_name", key: 'real_name' },
  { title: "职位", dataIndex: "jobTitle", key: "jobTitle" },
  { title: "角色", dataIndex: "role_name", key: 'role_name' },
  // { title: "是否被删除", dataIndex: "isDeleted", key: 'isDeleted' },

];

//增加用户弹框字段
const addfields = [
  { title: "用户名", dataIndex: "username", key: "username" },
  { title: "密码", dataIndex: "password", key: 'password' },
  { title: "真实姓名", dataIndex: "real_name", key: 'real_name' },
  { title: "职位", dataIndex: "jobTitle", key: "jobTitle" },
  { title: "角色", dataIndex: "role_name", key: 'role_name' },

];
//绑定字段
let baseData = reactive({
  id: "",
  username: "",
  password: null,
  hashed_password: "",
  real_name: "",
  role_name: "",
  role_id: "",
  jobTitle: "",
  jobTitle_name: "",//简略的jobTitle名称
  jobTitle_id: "",
})

//表格数据
let dataSource = reactive({
  data: [],
});
//选择框字段
let options = reactive({
  role_name: [],
  treeData: [],
})
//规则
const rules = {

}

//分页
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});
//搜索字段
let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage();

};

// 选择框
let rowData = [];
let selectedRowKey = ref([]);
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    if (key != 'customer_ids')
      baseData[key] = null
  }
  baseData.jobTitle = []
  dataSource.due_date = ""
  dataSource.customerModal = []
}
//判断是添加还是修改数据
let url = ""
//添加
const addData = () => {
  resetForm()
  showModal.show = true
  url = interfaces.systemUserAdd
};


//提交
const handleOk = () => {
  let data = {
    id: baseData.id,
    username: baseData.username,
    hashed_password: baseData.password != null && baseData.password != '' ? encrypt.addUserCrypto(baseData.password) : null,
    real_name: baseData.real_name,
    role_id: baseData.role_id,
    job_title_ids: baseData.jobTitle,
  }


  formRef.value.validate().then(() => {
    serviceAxios({
      url: url,
      method: "post",
      data: data
    }).then((result) => {
      message.success("success")
      if (result.data.message = 'success') {
        queryPage();
        selectedRowKey.value = [];
        showModal.show = false;
      }
      else {
        message.error(result.data.message)
      }

    }).catch((error) => {
      console.log(error)
      message.error("请求异常，请联系管理员")
    })
  }).catch(() => { message.error("表单验证失败，请检查输入信息") })

};
const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};
//编辑数据
const editData = () => {
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    url = interfaces.systemUserEdit
    for (let key in baseData) {
      baseData[key] = rowData[0][key]
    }
    baseData.jobTitle = rowData[0]['jobList']
    baseData.role_id = rowData[0].role.id
    showModal.show = true;
  }
};
//删除数据
const deleteData = () => {
  let jsonData = JSON.parse(JSON.stringify(rowData));

  const deleteData = jsonData.map(item => item.id)
  let params = {
    ids: deleteData,
    // "real_delete": 0  是否真实删除0为假 1为真
  };

  serviceAxios({
    url: interfaces.systemUserDelete,
    method: "post",
    data: params,
  })
    .then((res) => {
      if (res.status == 200) {
        message.success("删除成功");
        queryPage();
      }
    })
    .catch((res) => {
      console.log(res);
    });
};




const queryPage = () => {
  serviceAxios({
    url: interfaces.systemUserPage,
    method: "get",
    params: queryField
  })
    .then((result) => {
      console.log(result);
      if (result.data.message == "success") {
        pagination.total = result.data.data.total;
        let data = []
        result.data.data.list.forEach((item) => {
          let jobTitle = []
          let jobList = []
          console.log('item', item)

          item.job_titles.forEach(job => {
            jobTitle.push(job.name)
            jobList.push(job.id)
          })
          jobTitle = jobTitle.join(" ")


          data.push({
            id: item.id,
            key: item.id,
            username: item.username,
            real_name: item.real_name,
            role: item.role,
            role_name: item.role.name,
            is_disabled: item.is_disabled,
            is_hidden: item.is_hidden,
            is_outworker: item.is_outworker,
            is_root: item.is_root,
            job_titles: item.job_titles,
            jobTitle: jobTitle,
            jobList: jobList,
            tenant_id: item.tenant_id, //租户id
            create_time: item.create_time,
            latest_login: item.latest_login,
            isDeleted: item.is_disabled == 0 ? '否' : '是',
          })

        });

        dataSource.data = data;
      }
      else {
        message.error(result.data.message)
      }
    })
    .catch((err) => {
      console.log(err);
    });

};


queryPage();

// 用户选择的列键的数组
const selectedColumns = ref([1, 3, "jobTitle", 4]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) =>
    selectedColumns.value.includes(column.key)
  );
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};


const getRolePage = () => {
  dataSource.customerList = []
  serviceAxios({
    url: interfaces.rolePage,
    method: 'post',
    data: {
      page_num: 1,
      page_size: 999
    }
  }).then((result) => {
    if (result.status == 200) {
      let data = [];
      result.data.data.list.forEach((item) => {
        data.push({
          id: item.id,
          value: item.id,
          label: item.name,
        })
      })
      options.role_name = data
      console.log("options.role_name", options.role_name)
    }
    else {
      message.error("请求异常，请联系管理员")
    }


  }).catch(() => { message.error("请求异常，请联系管理员") })
}
getRolePage()
const handleChange = (value, option) => {
  console.log(`selected ${value}`, option);
  baseData.role_id = value
}


const clearTree = (data) => {
  let childrenData = []
  if (data.length != 0) {
    data.forEach((item) => {
      let mnumObj = {
        id: item.id,
        label: item.name,
        value: item.id,
        disabled: true,
        parent_id: item.parent_id == undefined ? 0 : item.parent_id, //父节点

      };

      if (item.children != undefined) {
        mnumObj.children = clearTree(item.children)

      }

      if (item.job_titles != undefined) {
        if (mnumObj.children == undefined) {
          mnumObj.children = []
        }
        item.job_titles.forEach(job => {
          mnumObj.children.push({
            id: job.id,
            label: job.name,
            value: job.id,
          }
          )
        })

      }

      childrenData.push(mnumObj)
    });
  } else {

    console.log("no clear0");
  }
  return childrenData;
};
const getDepartmentTreeData = () => {
  serviceAxios({
    url: interfaces.departmentTree,
    method: 'get',
    params: {
      include_job_titles: 1, //是否包含岗位信息
      include_accounts: 1, //是否包含账户信息
    }
  })
    .then(result => {
      let departmentTree = clearTree(result.data.data)

      options.treeData = departmentTree
      console.log("departmentTree", departmentTree)
    }).catch(err => {
      console.log(err)
      message.error("请求异常，请联系管理员")
    });
}


getDepartmentTreeData()

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

const treeChange = (value) => {
  console.log('value', value)
  if (value.length != 0) {
    serviceAxios({
      url: interfaces.jobTitleGetById,
      method: 'get',
      params: {
        id: value[0]
      }
    }).then(result => {

      if (result.data.data.related_role != null) {
        let id = result.data.data.related_role.id
        baseData.role_id = id
      }
    }).catch(error => {
      console.log(error)
    })
  }

}
const searchRef = ref({
  keyword: ''
})
const sift = () => {
  pagination.current = 1
  pagination.pageSize = 10
  queryField['keyword'] = searchRef.value.keyword

  queryPage()
}

const resetSift = () => {
  pagination.current = 1
  pagination.pageSize = 10
  queryField = {
    page_num: pagination.current,
    page_size: pagination.pageSize
  }
  queryPage()

  searchRef.value.keyword = null
}
</script>



<style lang="less" scpoed>
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.searchLabel {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  background-color: #f5f4f4;
}
</style>