<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- 增加门店和主机的绑定关系，查看门店绑定的主机信息-->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="绑定主机"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">
                <a-cascader :disabled="editFlag"
                            v-if="['storeId'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options.storeList"
                            :show-search="{ filter }"
                            placeholder="请选择门店"
                            @change="storeChange" />
                <a-input-number :disabled="editFlag"
                                v-else-if="['interfacePort'].includes(item.dataIndex)"
                                style='width:auto'
                                defaultValue=161
                                :controls='false'
                                v-model:value="baseData[item.dataIndex]"
                                :placeholder="`请输入${item.title}(数字)`" />
                <a-input :disabled="editFlag"
                         v-else-if="['vpnIp','interfaceIp','host'].includes(item.dataIndex)"
                         v-model:value="baseData['host']"
                         :placeholder="`请输入${item.title}`" />
                <a-input v-else-if="['desc'].includes(item.dataIndex)"
                         v-model:value="baseData['desc']"
                         :placeholder="`请输入${item.title}`" />
                <a-input-number v-else-if="['gateway_port'].includes(item.dataIndex)"
                                v-model:value="baseData['gateway_port']"
                                style='width:200px'
                                :placeholder="`请输入${item.title}`" />
                <a-input :disabled="editFlag"
                         v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <a-modal width="400px"
           v-model:open="showModal.bindGateway"
           title="关联网关"
           ok-text="确认"
           cancel-text="取消"
           @ok="gatewayOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="gateway"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="网关"
                         :name='gatewayId'>
              <a-cascader style='width:200px'
                          v-model:value="gateway.gatewayId"
                          :options="options.gateway"
                          placeholder="请选择网关" />
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <a-modal a-modal
           width="1400px"
           v-model:open="showModal.trigger"
           title="绑定触发器"
           ok-text="确认"
           cancel-text="取消"
           @ok="triggerOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <table class='trigger-table'>
        <tr>
          <td class='column'>触发器ID</td>
          <td>
            <span style='margin-left:12px'>{{triggerData.gatewayId}}</span>
          </td>

        </tr>
        <tr>
          <td class='column'><span class='mustRequired'>*</span>端口数量</td>
          <td>
            <a-select :showArrow="false"
                      v-model:value="triggerData.physical_interfaces_count"
                      :bordered="false"
                      style="width:100px"
                      show-search
                      :placeholder="`请选择端口数量`"
                      :options="options['interfaceNumber']"
                      :filter-option="filterOption"
                      @change='interfaceNumberChange'></a-select>
          </td>

        </tr>

        <tr>
          <td class='column'>基础接口列表</td>
          <td>
            <thead>
              <tr class='interface-name'>
                <td>运行时长</td>
                <td>内存使用率</td>
                <td>ping</td>
              </tr>
            </thead>

            <tbody>
              <td>
                <a-select :showArrow="false"
                          v-model:value="triggerData.runtime_trigger_id"
                          :bordered="false"
                          class="interface-selector"
                          show-search
                          :placeholder="``"
                          :options="options['trigger']"
                          :filter-option="filterOption"></a-select>
              </td>
              <td>
                <a-select :showArrow="false"
                          v-model:value="triggerData.mem_trigger_id"
                          :bordered="false"
                          class="interface-selector"
                          show-search
                          :placeholder="``"
                          :options="options['trigger']"
                          :filter-option="filterOption"></a-select>
              </td>
              <td>
                <a-select :showArrow="false"
                          v-model:value="triggerData.ping_trigger_id"
                          :bordered="false"
                          class="interface-selector"
                          show-search
                          :placeholder="``"
                          :options="options['trigger']"
                          :filter-option="filterOption"></a-select>
              </td>
            </tbody>
          </td>
        </tr>
        <tr>
          <td class='column'
              rowspan="2">物理接口列表

          </td>
          <td>
            <thead>
              <tr class='interface-name'>
                <!-- <td>id</td> -->
                <td>接口编号</td>
                <td>接口名</td>
                <td>上传流量</td>
                <td>下载流量</td>
                <td>端口状态</td>
                <td>信号强度</td>
                <td>接口描述</td>
                <td><a @click='addPhysicalInterface'>增加</a></td>
              </tr>
            </thead>

            <tbody>
              <tr v-for='(item,index) in physicalInterfaceList'
                  :key='item'>
                <!-- <td>{{}}</td> -->
                <td><a-input-number class='interface-input-number'
                                  :controls='false'
                                  v-model:value="item.eth_number"
                                  :bordered="false"></a-input-number></td>
                <td><a-input class='interface-input'
                           v-model:value="item.name"
                           :bordered="false"></a-input></td>
                <td>
                  <a-select :showArrow="false"
                            v-model:value="item.upload_trigger_id"
                            :bordered="false"
                            class="interface-selector"
                            show-search
                            :placeholder="``"
                            :options="options['trigger']"
                            :filter-option="filterOption"></a-select>
                </td>
                <td>
                  <a-select :showArrow="false"
                            v-model:value="item.download_trigger_id"
                            :bordered="false"
                            class="interface-selector"
                            show-search
                            :placeholder="``"
                            :options="options['trigger']"
                            :filter-option="filterOption"></a-select>
                </td>
                <td>
                  <a-select :showArrow="false"
                            v-model:value="item.status_trigger_id"
                            :bordered="false"
                            class="interface-selector"
                            show-search
                            :placeholder="``"
                            :options="options['trigger']"
                            :filter-option="filterOption"></a-select>
                </td>
                <td>
                  <a-select :showArrow="false"
                            v-model:value="item.signal_trigger_id"
                            :bordered="false"
                            class="interface-selector"
                            show-search
                            :placeholder="``"
                            :options="options['trigger']"
                            :filter-option="filterOption"></a-select>
                </td>

                <td><a-input class='interface-input'
                           style='width:150px'
                           v-model:value="item.desc"
                           :bordered="false"></a-input></td>
                <td> <a @click='deletePhysicalInterface(index)'>删除</a></td>
              </tr>
            </tbody>
          </td>
        </tr>

      </table>
    </a-spin>
  </a-modal>
  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">

      <template v-if="['host'].includes(column.dataIndex)">
        <a class='editable-row-a'
           @click="toWeb(record.web)">{{record.host}}</a>
      </template>
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>

      <template v-if="['operationalStatus'].includes(column.dataIndex)">
        <div class="status-light">
          <a @click="operationalStatusClick(record.id)"
             v-if='record.operationalStatus'
             class="green-light"></a>
          <a @click="operationalStatusClick(record.id)"
             v-if='!record.operationalStatus'
             class="red-light"></a>

        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a v-if='record.host != null'
             class='editable-row-a'
             @click="editData(record)">编辑</a>
          <!-- <a class='editable-row-a' @click="deleteData(record.key)">删除</a> -->
          <a v-if='record.host == null'
             class='editable-row-a'
             @click="bindGateway(record.key)">关联网关</a>
          <a v-if='record.host != null'
             class='editable-row-a'
             @click="un_relate(record.key,record.gateway.id)">解除关联</a>
          <a class='editable-row-a'
             @click="toGlopt(record.storeId)">拓扑图</a>
          <!-- <a class='editable-row-a' @click="bindTrigger(record.key,record.zabbix_host_id)">触发器</a> -->
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>

        <template v-for="item in searchFields"
                  :key='item.key'>

          <label style='margin-right:5px'>{{item.title}}</label>
          <a-tree-select class='searchItem'
                         v-if='item.title=="区域"'
                         v-model:value="searchData[item.key]"
                         show-search
                         :placeholder="`请选择${item.title}`"
                         allow-clear
                         :tree-data="treeData"
                         tree-node-filter-prop="label"></a-tree-select>
          <a-select class='searchItem'
                    v-else-if='item.title=="关联状态"'
                    :options="options.freeStore"
                    v-model:value="searchData[item.key]"
                    allow-clear
                    :placeholder="`请选择${item.title}`"></a-select>
          <a-input class='searchItem'
                   v-else
                   v-model:value='searchData[item.key]'
                   :placeholder="`请输入${item.title}`"></a-input>

          <!-- </a-form-item> -->

        </template>

        <!-- <a-select style='width:100px' v-model:value="searchData.field" :options="options.search" defaultValue=''
          placeholder="搜索字段" change-on-select />

        <a-input v-model:value="searchData.input" style="width:150px"></a-input> -->
        <a-button style="margin:2px"
                  type="primary"
                  @click="sift">搜索</a-button>
        <a-button style="margin:2px"
                  @click="resetSift">重置</a-button>
        <div class='right-buttons'>
          <a-button class="addDataButton"
                    v-if="buttonShow.edit"
                    @click="refresh">刷新</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加网关</a-button>

          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import { commonFun } from '@/utils/commonFun'
import { storeMessage } from '@/utils/commonData'
import dayjs from "dayjs"
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: interfaces.hostAdd,
  delete: interfaces.hostDelete,
  edit: interfaces.hostEdit,
  page: interfaces.hostPage,
  refresh: interfaces.hostRefresh,
}
const router = useRouter()

//获取门店信息
const getStoreMessage = (typeId) => {
  storeMessage(typeId).then(result => {
    console.log(result)
    options.storeList = result
  }).catch(error => {
    console.log(error)
  })

}
//确实加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const searchData = reactive({
  keyword: null,
  regionId: null,
  freeStore: null
})

const searchFields = [{
  title: "关键字",
  key: 'keyword'
},

{
  title: "区域",
  key: 'regionId'
},

{
  title: "关联状态",
  key: 'freeStore',
  desc: '是否只显示未关联门店: 1.只显示未关联网关的门店， 0. 只显示关联了网关的门店 None.两者'
}]
const getButton = () => {
  let route = useRoute()
  buttonShow = commonFun.getButtonRules(route.fullPath, url)
}
getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "品牌",
      dataIndex: "brand",
      key: "brand",
      ellipsis: true,
    },
    {
      title: "地区",
      dataIndex: "regionName",
      key: "regionName",
      ellipsis: true,
    },
    {
      title: "门店名称",
      dataIndex: "storeName",
      key: "storeName",
      ellipsis: true,

    },
    {
      title: "网关IP",
      dataIndex: "host",
      key: "host",
      ellipsis: true,
    },
    {
      title: "端口号",
      dataIndex: "interfacePort",
      key: "interfacePort",
      ellipsis: true,
    },
    {
      title: "网关端口号",
      dataIndex: "gateway_port",
      key: "gateway_port",
      ellipsis: true,
    },

    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true,
    },
    {
      title: "运行状态",
      dataIndex: "operationalStatus",
      key: "operationalStatus"
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [{
    title: "门店ID",
    dataIndex: "storeId",
    key: "storeId"
  },


  {
    title: "品牌",
    dataIndex: "brand",
    key: "brand"
  },
  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName"
  },
  {
    title: "主机IP",
    dataIndex: "host",
    key: "host"
  },
  {
    title: "VPN IP",
    dataIndex: "vpnIp",
    key: "vpnIp"
  },
  {
    title: "接口IP",
    dataIndex: "interfaceIp",
    key: "interfaceIp"
  },
  {
    title: "接口端口",
    dataIndex: "interfacePort",
    key: "interfacePort"
  },
  {
    title: "网关端口",
    dataIndex: "gateway_port",
    key: "gateway_port"
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc"
  },



  ]

})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})

//增加或者编辑的模态框规则
const rules = {
  brand: [
    {
      required: true,
      message: "请输入品牌",
    },
  ],
  storeId: [
    {
      required: true,
      message: "请选择门店",
    },
  ],
  // vpnIp: [
  //   {
  //     required: true,
  //     message: "请输入VPN IP",
  //   },
  // ],
  storeName: [
    {
      required: true,
      message: "请输入门店名称",
    },
  ],
  host: [
    {
      required: true,
      message: "请输入主机IP",
    },
  ],
  gateway_port: [
    {
      required: true,
      message: "请输入网关端口",
    },
  ]
  // interfaceIp: [
  //   {
  //     required: true,
  //     message: "请输入接口IP",
  //   },
  // ]
}

//选择框数据
const options = reactive({
  freeStore: [{ label: "未关联", value: 1 }, { label: "已关联", value: 0 }],
  interfaceNumber: [{
    label: '4',
    value: 4
  },
  {
    label: '8',
    value: 8
  }],
  brand: [],

  storeList: [],
  trigger: [],
  gateway: [],
});

// 数据模型
const baseData = reactive({
  id: "",
  storeId: "", //门店id
  vpnIp: "", //vpn ip 用于判断主线路是否离线
  desc: "", //描述
  brand: "", //品牌
  storeName: "", //门店名称
  host: "", //一般情况请填写接口的ip，二级路由映射请填写 网关ip+空格+二级路由wan口ip
  interfaceIp: "", //接口ip
  interfacePort: '', //接口端口
  gateway_port: '',
  //一般情况下 vpn ip、接口ip、host ip一致
})
const gateway = reactive({
  storeId: "",
  gatewayId: ""
}) // 关联网关绑定的变量
let physicalInterfaceList = ref([
  // {
  //   id: null,
  //   eth_number: "",
  //   name: "",
  //   upload_trigger_id: "",
  //   download_trigger_id: "",
  //   status_trigger_id: "",
  //   signal_trigger_id: "",
  //   desc: ""
  // }
])
//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false,
  bindGateway: false,
})

//触发器模型数据
const triggerData = reactive({
  interfaces: [{
    id: 0,
    eth_number: 0,
    name: "string",
    upload_trigger_id: null,
    download_trigger_id: null,
    status_trigger_id: null,
    signal_trigger_id: null,
    desc: "string"

  }],//接口信息
  gatewayId: '',//触发器ID
  runtime_trigger_id: null,
  ping_trigger_id: null,
  mem_trigger_id: null,
  physical_interfaces_count: "",
})


let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {
      console.log('item', item);
      let storeId = [String(item.customer.id), item.id];
      let host = null;
      let interfacePort = null;
      let desc = null;
      let zabbix_host_id = null;

      if (item.gateway != undefined) {
        host = item.gateway.host;
        desc = item.gateway.desc;
        zabbix_host_id = item.gateway.zabbix_host_id
        if (item.gateway.interfaces.length != 0) {
          interfacePort = item.gateway.interfaces[0].port
        }
      }
      data.push({
        id: item.id,
        key: item.id,
        storeId: storeId,
        brand: item.customer.name,
        storeName: item.name,
        regionName: item.region.friendly_name.data,
        host: host,
        interfacePort: interfacePort,
        desc: desc,
        zabbix_host_id: zabbix_host_id,
        gateway: item.gateway,
        web: item.gateway ? item.gateway.web : "",
        gatewayId: item.gateway != undefined ? item.gateway.id : null,
        gateway_port: item.gateway ? item.gateway.gateway_port : ""
      })
    });
    dataSource.data = data;



    let gatewayIdList = dataSource.data.filter(item => item.gatewayId != null)
    let ids = []
    gatewayIdList.forEach(item => {
      ids.push(item.gatewayId)
    })
    checkOline(ids)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData['interfacePort'] = 161
};

const formRef = ref()

const editFlag = ref(false)
//编辑数据
const editData = (record) => {
  editFlag.value = true
  getStoreMessage(null)
  console.log("record", record);
  urlFlag = url.edit
  showModal.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }

};


//增加数据
const addData = () => {
  editFlag.value = false
  resetForm();
  showModal.addOrEdit = true;
  urlFlag = url.add
  getStoreMessage(0)
};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};





//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      store_id: baseData.storeId[1],
      vpn_ip: baseData.host,
      desc: baseData.desc,
      brand: baseData.brand,
      store_name: baseData.storeName,
      host: baseData.host,
      interface_ip: baseData.host,
      interface_port: baseData.interfacePort,
      gateway_port: baseData.gateway_port,
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false


    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch {
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  queryField['keyword'] = searchData.keyword == '' ? null : searchData.keyword;
  queryField['region_id'] = searchData.regionId == '' ? null : searchData.regionId;
  queryField['free_store'] = searchData.freeStore == '' ? null : searchData.freeStore;
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  for (let key in searchData) {
    searchData[key] = null
  }
  queryPage()
}

const storeChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  baseData['brand'] = selectedOptions[0].label
  baseData['storeName'] = selectedOptions[1].label
  // baseData.storeId = selectedOptions[1].value
}


const refresh = () => {
  serviceAxios({
    url: url.refresh,
    method: 'get'

  }).then(
    result => {
      if (result.data.message == 'success') {
        message.success('刷新成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }
  )
}




const addPhysicalInterface = () => {
  physicalInterfaceList.value.push({
    id: null,
    eth_number: "",
    name: "",
    upload_trigger_id: null,
    download_trigger_id: null,
    status_trigger_id: null,
    signal_trigger_id: null,
    desc: ""
  })
}
const deletePhysicalInterface = (index) => {
  // console.log(index)
  physicalInterfaceList.value.splice(index, 1)
}


const triggerOk = () => {
  let data = {
    interfaces: [],
    gateway_id: triggerData.gatewayId,
    runtime_trigger_id: triggerData.runtime_trigger_id,
    ping_trigger_id: triggerData.ping_trigger_id,
    mem_trigger_id: triggerData.mem_trigger_id,
    physical_interfaces_count: triggerData.physical_interfaces_count,
  }
  data.interfaces = physicalInterfaceList.value
  spinning.value = true
  serviceAxios({
    url: interfaces.interfaceTriggerSet,
    method: 'post',
    data: data
  }).then(result => {

    if (result.data.message = 'success') {
      message.success("绑定成功")
      showModal.trigger = false
      // indicator.spin = false
    }
    else {
      message.success(result.data.message)
    }
    spinning.value = false

  }).catch(error => {
    console.log(error)
    message.error("请求异常")
    spinning.value = false
  })
}

// 选择框搜索
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};


const interfaceNumberChange = (value, option) => {
  console.log(value, option)
  physicalInterfaceList.value = []
  for (let i = 0; i < value; i++) {
    physicalInterfaceList.value.push({
      id: null,
      eth_number: i,
      name: "eth" + i,
      upload_trigger_id: null,
      download_trigger_id: null,
      status_trigger_id: null,
      signal_trigger_id: null,
      desc: ""
    })
  }

}


const bindGateway = (id) => {
  gateway.storeId = id
  getGateway()
  gateway.gatewayId = null
  showModal.bindGateway = true

}
const gatewayOk = () => {
  relate()
}


//关联
const relate = () => {
  console.log('gateway.gatewayId', gateway.gatewayId)
  if (gateway.gatewayId != "" && gateway.gatewayId != null) {
    serviceAxios({
      url: interfaces.store_and_zabbix_relate,
      method: 'get',
      params: {
        store_id: gateway.storeId,
        // gateway_id: gateway.gatewayId,
        gateway_id: gateway.gatewayId[0]
      }
    }).then((res) => {
      if (res.data.message == 'success') {
        message.success("关联成功")
        showModal.bindGateway = false
        queryPage()
      } else {
        message.error(res.data.message)
      }
    }).catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    })
  }
  else {
    message.error("请选择网关")
  }



}


//解除关联
const un_relate = (storeId, gatewayId) => {

  serviceAxios({
    url: interfaces.store_and_zabbix_unrelate,
    method: 'get',
    params: {
      store_id: storeId,
      host_id: gatewayId,
    }
  }).then((res) => {
    if (res.data.message == 'success') {
      message.success("解除关联成功")
      queryPage()
    } else {
      message.error(res.data.message)
    }
  }).catch((err) => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  })
}




//获取未绑定的网关设备
const getGateway = () => {
  serviceAxios({
    url: '/v1/ops/zabbix_host/page',
    method: "get",
    params: {
      only_free: 1,
      page_num: 1,
      page_size: 9999
    }
  }).then(
    result => {
      if (result.data.message == 'success') {
        options.gateway = result.data.data.list.map(item => ({
          label: item.name,
          value: item.id
        }))
      }
      else {
        message.error(result.data.message)
      }
    }
  ).catch(error => {
    message.error("请求异常")
    console.log(error)
  })
}

const toGlopt = (storeMessage) => {
  router.push({ path: "/pic", query: { brand: storeMessage[0], storeId: storeMessage[1] } })
}

const treeData = ref()
const getTreeData = () => {
  let tree = localStorage.getItem('region')
  treeData.value = JSON.parse(tree)[0]['children']
}
getTreeData()


const checkOline = (ids) => {
  serviceAxios({
    url: interfaces.onlineStatus,
    method: 'post',
    data: {
      gateway_ids: ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let enableGateList = dataSource.data.filter(item => item.gatewayId != null)
      console.log('enableGateList', enableGateList)
      result.data.data.forEach(
        item => {
          let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.gateway_id)
          gatewayStatus.forEach(i => {
            i.operationalStatus = item.value == '正常' ? true : false
          })
        }
      )
    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
    message.error('请求异常')
  })
}
// to云控平台

const toWeb = (web) => {
  window.open(web, '_blank');
}
</script>
<style lang="less" scoped>
.searchDivContainer {
  padding-top: 0px;
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
  margin-bottom: 2px;
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}

.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
.searchItem {
  width: 150px;
  margin-right: 10px;
}
</style>