<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <!-- 新装工单分配运维 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="newInstallModal.dispatch"
           title="新装工单分配运维"
           ok-text="确认"
           cancel-text="取消"
           @ok="newinstallDispatach">
    <table class="showTabel">

      <tr>
        <td class="column">分配地区</td>
        <td> <a-tree-select show-search
                         style="width: 300px"
                         :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder="选择区域"
                         allow-clear
                         :tree-data="treeDataAll"
                         tree-node-filter-prop="label"
                         @change="(value, label, extra)=>treeChange(value, label, extra,index,'assign')">

          </a-tree-select>

        </td>

      </tr>
      <tr>
        <td class="column">运维</td>
        <td>
          <a-select notFoundContent="该地区无运维人员"
                    v-model:value="newInstallRef.account_id "
                    style="width:300px"
                    placeholder="请选择运维人员"
                    :options="options.workerSelector"></a-select>
        </td>
      </tr>

    </table>

  </a-modal>
  <!-- 新装工单预处理 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="newInstallModal.preProcess"
           title="新装工单预处理"
           ok-text="确认"
           cancel-text="取消"
           @ok="newInstallPreprocess">
    <table class="showTabel">

      <tr>
        <td class="column">结果<span class='red-flag'>*</span></td>
        <td>
          <a-radio-group v-model:value="newInstallRef.success">
            <a-radio value="true">批准</a-radio>
            <a-radio value="false">拒绝</a-radio>
          </a-radio-group>
        </td>
      </tr>

      <tr>
        <td class="column">客服意见</td>
        <td>
          <a-input v-model:value="newInstallRef.customer_service_result"
                   placeholder="请输入" />
        </td>
      </tr>

      <tr>
        <td class="column">计划安装时间</td>
        <td>
          <a-config-provider :locale="locale">
            <a-date-picker v-model:value="newInstallRef.plan_install_time"
                           format="YYYY-MM-DD HH:mm:ss"
                           valueFormat="YYYY-MM-DD HH:mm:ss"
                           :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
          </a-config-provider>
        </td>
      </tr>

      <tr v-if='newInstallRef.success != "true"'>
        <td class="column">拒绝原因<span class='red-flag'>*</span></td>
        <td>
          <a-input v-model:value="newInstallRef.reject_reason"
                   placeholder="请输入" />
        </td>
      </tr>

      <tr>
        <td class="column">需要线路资源</td>
        <td>
          <a-input v-model:value="newInstallRef.need_resource"
                   placeholder="请输入" />
        </td>
      </tr>

    </table>

  </a-modal>

  <!-- 新装工单资源勘察 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="newInstallModal.resourceExploration"
           title="新装工单资源勘察"
           ok-text="确认"
           cancel-text="取消"
           @ok="newInstallResource">
    <table class="showTabel">

      <tr>
        <td class="column">结果<span class='red-flag'>*</span></td>
        <td>
          <a-radio-group v-model:value="newInstallRef.success">
            <a-radio value="true">批准</a-radio>
            <a-radio value="false">拒绝</a-radio>
          </a-radio-group>
        </td>
      </tr>
      <tr>
        <td class="column">商圈<span class='red-flag'>*</span></td>
        <td>
          <a-select v-model:value="newInstallRef.business_cluster_id"
                    placeholder="请选择"
                    style="width:300px"
                    :options="options.businessCluster" />

        </td>
      </tr>

      <tr v-if='newInstallRef.success != "true"'>
        <td class="column">拒绝原因<span class='red-flag'>*</span></td>
        <td>
          <a-input v-model:value="newInstallRef.reject_reason"
                   placeholder="请输入" />
        </td>
      </tr>

      <tr>
        <td class="column">资源勘查描述</td>
        <td>
          <a-input v-model:value="newInstallRef.desc"
                   placeholder="请输入" />
        </td>
      </tr>

    </table>

  </a-modal>

  <!-- 新装工单调度网工 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="newInstallModal.networkEngineer"
           title="新装工单调度网工"
           ok-text="确认"
           cancel-text="取消"
           @ok="newInstallNetworkEngineer">
    <table class="showTabel">

      <tr>
        <td class="column">网工<span class='red-flag'>*</span></td>
        <td>
          <a-select notFoundContent="无网工"
                    v-model:value="newInstallRef.account_id"
                    style="width:300px"
                    placeholder="请选择网工"
                    :options="options.networkEngineer"></a-select>
        </td>
      </tr>

    </table>

  </a-modal>

  <!-- 新装工单备货 -->
  <!-- <a-modal :maskClosable="false"
           width="600px"
           v-model:open="newInstallModal.readyDevice"
           title="新装工单备货"
           ok-text="确认"
           cancel-text="取消"
           @ok="newInstallReadyDevice">
    <table class="showTabel">

      <tr>
        <td class="column">结果<span class='red-flag'>*</span></td>
        <td>
          <a-radio-group v-model:value="newInstallRef.success">
            <a-radio value="true">成功</a-radio>
            <a-radio value="false">失败</a-radio>
          </a-radio-group>
        </td>
      </tr>
      <tr v-if='newInstallRef.success != "true"'>
        <td class="column">拒绝原因<span class='red-flag'>*</span></td>
        <td>
          <a-input v-model:value="newInstallRef.reject_reason"
                   placeholder="请输入" />
        </td>
      </tr>
      <tr>
        <td class="column">运输方式<span class='red-flag'>*</span></td>
        <td>
          <a-select v-model:value="newInstallRef.transport_method"
                    placeholder="请选择"
                    style="width:300px"
                    :options="[{value:'工程师携带',label:'工程师携带'},{value:'快递',label:'快递'}]" />

        </td>
      </tr>

      <tr>
        <td class="column">快递单号</td>
        <td>
          <a-input v-model:value="newInstallRef.express_number"
                   placeholder="请输入" />
        </td>
      </tr>
      <tr>
        <td class="column">快递收件人</td>
        <td>
          <a-input v-model:value="newInstallRef.express_receiver"
                   placeholder="请输入" />
        </td>
      </tr>
      <tr>
        <td class="column">快递收件人电话</td>
        <td>
          <a-input v-model:value="newInstallRef.express_receiver_phone"
                   placeholder="请输入" />
        </td>
      </tr>
      <tr>
        <td class="column">出库时间</td>
        <td>

          <a-config-provider :locale="locale">
            <a-date-picker v-model:value="newInstallRef.outbound_time"
                           format="YYYY-MM-DD HH:mm:ss"
                           valueFormat="YYYY-MM-DD HH:mm:ss"
                           :show-time="{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }" />
          </a-config-provider>
        </td>
      </tr>

    </table>

  </a-modal> -->

  <!-- 分配网工 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="modalShow.assignNetworkEngineer"
           title="分配网工"
           ok-text="确认"
           cancel-text="取消"
           @ok="assignNetworkEngineerHandleOk">
    <table class="showTabel"
           cellspacing="0"
           cellpadding="0">

      <tr>
        <td class="column">网工</td>
        <td>
          <a-select notFoundContent="无网工"
                    v-model:value="assignNetworkEngineerRef.accountId"
                    style="width:300px"
                    placeholder="请选择网工"
                    :options="options.networkEngineer"
                    :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 13}"></a-select>
        </td>
      </tr>

    </table>

  </a-modal>
  <!-- 审核工作内容单 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.examineWorkContent"
           title="审核"
           ok-text="确认"
           cancel-text="取消"
           @ok="examineWorkContentrHandleOk">
    <table class="showTabel"
           cellspacing="0"
           cellpadding="0">
      <tr>

      </tr>
      <tr>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>审核状态</td>
        <td>
          <a-radio-group v-model:value="examineWorkContentRef.status">
            <a-radio value="true">通过</a-radio>
            <a-radio value="false">拒绝</a-radio>

          </a-radio-group>
        </td>
      </tr>
      <tr v-if='examineWorkContentRef.status=="true"'>
        <td class="column">审核备注</td>
        <td colspan="3">
          <a-input style="width:500px;"
                   v-model:value="examineWorkContentRef.desc"
                   :placeholder="`请输入审核备注`"></a-input>
        </td>
      </tr>
      <tr v-if='examineWorkContentRef.status=="false"'>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>拒绝原因</td>
        <td colspan="3">
          <a-input style="width:500px;"
                   v-model:value="examineWorkContentRef.reject_reason"
                   :placeholder="`请输入拒绝原因`">
          </a-input>
        </td>
      </tr>
    </table>
  </a-modal>
  <!-- 分配运维 -->
  <a-modal :maskClosable="false"
           width="600px"
           v-model:open="modalShow.assignMaintenance"
           title="分配运维"
           ok-text="确认"
           cancel-text="取消"
           @ok="assignMaintenanceHandleOk">
    <table class="showTabel"
           cellspacing="0"
           cellpadding="0">

      <tr>
        <td class="column">分配地区</td>
        <td> <a-tree-select show-search
                         style="width: 300px"
                         :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder="选择区域"
                         allow-clear
                         :tree-data="treeDataAll"
                         tree-node-filter-prop="label"
                         @change="(value, label, extra)=>treeChange(value, label, extra,index,'assign')">

          </a-tree-select>

        </td>

      </tr>
      <tr>
        <td class="column">装维人员</td>
        <td>
          <a-select notFoundContent="该地区无装维人员"
                    v-model:value="assignMaintenanceRef.accountId "
                    style="width:300px"
                    placeholder="请选择装维人员"
                    :options="options.workerSelector"
                    :label-col="{ span: 7 }"
                    :wrapper-col="{ span: 13}"></a-select>
        </td>
      </tr>

    </table>

  </a-modal>
  <!-- 审核申请工单 预处理操作 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.checkOrder"
           title="审核"
           ok-text="确认"
           cancel-text="取消"
           @ok="checkOrderHandleOk">
    <table class="showTabel"
           cellspacing="0"
           cellpadding="0">

      <tr>
        <td class="column">工单号</td>
        <td>{{detailMessage.work_sn}}</td>
        <td class="column">品牌</td>
        <td>{{detailMessage.brand}}</td>
      </tr>

      <tr>
        <td class="column">类型</td>
        <td>{{detailMessage.type}}</td>
        <td class="column">状态</td>
        <td>{{detailMessage.status}}</td>
      </tr>

      <tr>
        <td class="column">门店名称</td>
        <td colspan="3">{{detailMessage.storeName}}</td>

      </tr>
      <tr>
        <td class="column">地址</td>
        <td colspan="3">{{detailMessage.complete_address}}</td>

      </tr>

      <tr>
        <td class="column">联系人</td>
        <td>{{detailMessage.contactName}}</td>
        <td class="column">联系人手机号</td>
        <td>{{detailMessage.contactPhone}}</td>
      </tr>
      <tr>
        <td class="column">故障描述</td>
        <td colspan="3">{{detailMessage.desc}}</td>
      </tr>
    </table>

    <table class="showTabel"
           cellspacing="0"
           cellpadding="0">
      <!-- 审核申请工单字段 -->
      <tr v-if='checkOrderFields.show'>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>审核状态</td>
        <td>
          <a-radio-group v-model:value="checkOrderFields.status">
            <a-radio value="true">批准</a-radio>
            <a-radio value="false">拒绝</a-radio>
          </a-radio-group>
        </td>
      </tr>
      <tr v-if='checkOrderFields.status=="true" && checkOrderFields.show'>
        <td class="column">审核备注</td>
        <td colspan="3">
          <a-input style="width:500px;"
                   v-model:value="checkOrderFields.desc"
                   :placeholder="`请输入审核备注`"></a-input>
        </td>
      </tr>
      <tr v-if='checkOrderFields.status=="false" && checkOrderFields.show'>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>拒绝原因</td>
        <td colspan="3">
          <a-input style="width:500px;"
                   v-model:value="checkOrderFields.reject_reason"
                   :placeholder="`请输入拒绝原因`">
          </a-input>
        </td>
      </tr>
      <!-- 预处理字段 -->
      <tr v-if='pretreatmentRef.show'>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>是否预处理成功</td>
        <td>
          <a-radio-group v-model:value="pretreatmentRef.status">
            <a-radio value="true">是</a-radio>
            <a-radio value="false">否</a-radio>
          </a-radio-group>
        </td>

      </tr>

      <tr v-if='pretreatmentRef.show'>
        <td class="column"><span style="color: red;margin-left: 0px;">*</span>处理意见</td>
        <td>
          <a-input v-model:value="pretreatmentRef.customer_service_result"
                   :placeholder="`请输入处理意见`">
          </a-input>
        </td>
      </tr>
    </table>
  </a-modal>
  <!--查看工单数据 -->
  <a-modal :maskClosable="false"
           width="1050px"
           v-model:open="modalShow.addOrEdit"
           title="工单信息"
           ok-text="确认"
           cancel-text="取消"
           :ok-button-props="{ style: { display: modalShow.okCancelButton } }"
           :cancel-button-props="{ style: { display: modalShow.okCancelButton } }"
           @ok="addOrEditHandleOk"
           :afterClose='queryPage'>

    <!-- 基本信息 -->
    <table class="commonTabel"
           cellspacing="0"
           cellpadding="0">
      <tr>
        <td class="column"
            rowspan="7">基本信息</td>
        <td class="column">合同<span style="color: red;margin-left: 0px;">*</span></td>
        <td colspan="6">

          <a-cascader class='td-select'
                      :bordered="false"
                      style="width:auto"
                      v-model:value="baseData.contact_message"
                      :options="options.contract"
                      placeholder="请选择合同"
                      @change="contractChange" />
        </td>
      </tr>
      <tr>
        <td class="column">产品服务</td>
        <td colspan="2">

          {{baseData.product}}
        </td>

        <td class="column">工单类型<span style="color: red;margin-left: 0px;">*</span>

        </td>
        <td colspan="2">
          <a-select class='td-select'
                    :bordered="false"
                    style="width:120px"
                    v-model:value="baseData.type"
                    show-search
                    placeholder="请选择数据"
                    :options="options.type"
                    :filter-option="filterOption"
                    @change="handleChangeSelect('type',$event)"></a-select>

          <a-button style="margin:5px"
                    @click="createWorkPackage">自动生成工作内容</a-button>
        </td>
      </tr>
      <tr>
        <td class="column">品牌</td>
        <td colspan="2">{{baseData.brand}}</td>
        <td class="column">门店名称</td>
        <td colspan="2">{{baseData.storeName}}</td>

      </tr>

      <tr>
        <td class="column">门店地址</td>
        <td colspan="2">{{baseData.region}}{{baseData.address}}</td>
        <td class="column">地区</td>
        <td colspan="2">{{baseData.region}}</td>

      </tr>

      <tr>
        <td class="column">联系人</td>
        <td colspan="2">{{baseData.contactName}}</td>
        <td class="column">联系电话</td>
        <td colspan="2">{{baseData.contactPhone}}</td>

      </tr>
      <tr>
        <td class="column">添加工作内容</td>
        <td colspan="2">
          <a-select class='td-select'
                    :bordered="false"
                    v-model:value='baseData.workType'
                    show-search
                    placeholder="请选择数据"
                    style="width: 180px"
                    :options="options.workType"
                    :filter-option="filterOption">
          </a-select>
          <a-button style="margin:5px"
                    @click="addWork">添加</a-button>

        </td>
        <td class="column">工单状态</td>
        <td colspan="2">
          {{baseData.status}}
          <!-- <a-select class='td-select'
                    :bordered="false"
                    v-model:value="baseData.status"
                    show-search
                    placeholder="请选择数据"
                    style="width: 180px"
                    :options="options.status"
                    :filter-option="filterOption"
                    @change="handleChangeSelect('status',$event)"></a-select> -->
        </td>
      </tr>

      <tr>
        <td class='column'>备注</td>
        <td colspan="4">{{}}</td>
      </tr>
    </table>

    <tr>
      <td colspan="5"
          style="height: 5px;"></td>
    </tr>

    <!-- 资源勘查 -->
    <!-- <div class='order-box'
         v-for="(item1, index) in workTableData.sourceExplorate"
         :key="item1.id">

      <table class="commonTabel"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td class="sourceColumn">工单类型</td>
          <td style="font-size:16px">资源勘查 <a-button size="small"
                      style="margin:5px;color:red"
                      @click="removeWork('sourceExplorate',item1)">删除工单</a-button>
          </td>
          <td class="column">工作号</td>
          <td>{{item1.work_content_sn}}</td>
          <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            {{item1.status}}
          </td>

        </tr>
        <tr>
          <td class="column">装维人员</td>
          <td colspan="5">
            {{item1.worker_name}}
          </td>
        </tr>
        <tr>
          <td class="column">资源信息</td>
          <td colspan="5">
            <div style="display: flex;">
              <a-checkbox v-model:checked="item1.power">是否有电源</a-checkbox>
              <a-checkbox v-model:checked="item1.space">是否有空间</a-checkbox>
              <a-checkbox v-model:checked="item1.need_line">是否需要布线</a-checkbox>
              <a-checkbox v-model:checked="item1.weak_current_well">弱电井是否可用</a-checkbox>
              <a-checkbox v-model:checked="item1.is_monopolized">是否代理管控</a-checkbox>
            </div>
          </td>
        </tr>
        <tr v-if="item1.is_monopolized">

          <td class="column">代理信息</td>
          <td colspan="5">
            <div style="display: flex; flex-direction: column;">
              <div style="display: flex; align-items: center;margin:4px">
                <label style="width: 100px; display: inline-block; text-align: right;">物业联系人</label>
                <a-input style="width:180px;margin:4px"
                         v-model:value="item1.pmc_contact_name"
                         :placeholder="`请输入物业联系人`"></a-input>
              </div>
              <div style="display: flex; align-items: center;margin:4px">
                <label style="width: 100px;">物业联系人电话</label>
                <a-input style="width:180px;margin:4px"
                         v-model:value="item1.pmc_contact_phone"
                         :placeholder="`请输入物业联系人电话`"></a-input>
              </div>
              <div style="display: flex; align-items: center;margin:4px">
                <label style="width: 100px; display: inline-block; text-align: right;">代理管控价格</label>
                <a-input style="width:180px;margin:4px"
                         v-model:value="item1.monopolized_prices"
                         :placeholder="`请输入代理管控价格`"></a-input>
              </div>

            </div>
          </td>

        </tr>
        <tr>
          <td class="column">信号强度</td>
          <td colspan="5">
            <div style="display: flex;">
              <div style="display: flex; align-items: center;margin:4px">

                <a-checkbox v-model:checked="item1.provider_resources_available_telegraphy">电信</a-checkbox>
                <a-select v-model:value="item1.provider_resources_rssi_telegraphy"
                          style="width: 100px;maigin-left:30px;margin:1px"
                          placeholder="请选择信号强度"
                          :options="options.provider_resources"></a-select>
              </div>
              <div style="display: flex; align-items: center;margin:4px">
                <a-checkbox v-model:checked="item1.provider_resources_available_link">联通</a-checkbox>
                <a-select v-model:value="item1.provider_resources_rssi_link"
                          style="width: 100px;maigin-left:30px;margin:1px"
                          placeholder="请选择信号强度"
                          :options="options.provider_resources"></a-select>
              </div>
              <div style="display: flex; align-items: center;margin:4px">
                <a-checkbox v-model:checked="item1.provider_resources_available_move">移动</a-checkbox>
                <a-select v-model:value="item1.provider_resources_rssi_move"
                          style="width: 100px;maigin-left:30px;margin:1px"
                          placeholder="请选择信号强度"
                          :options="options.provider_resources"></a-select>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="column">附件</td>
          <td colspan="5"
              style="dispaly:flex;align-items: center;">

            <uploadComponent :freshFlag='uploadFresh'
                             :fileIds='item1.fileIds'
                             @update:fileIds='(event)=>fileChange(event,"sourceExplorate",index)'>

            </uploadComponent>
          </td>
        </tr>
        <tr>
          <td class="column">备注</td>
          <td colspan="5">
            <a-textarea :bordered="false"
                        v-model:value="item1.desc"
                        placeholder="请输入备注"
                        :rows="2" />
          </td>
        </tr>

        <tr v-if='item1.status =="审核中"'>
          <td class='column'
              style='color:red'>审核</td>
          <td colspan="5"> <a-button @click='examineWorkContent(item1.id,"sourceExplorate",index)'>审核</a-button></td>
        </tr>
      </table>

      <tr>
        <td colspan="5"
            style="height: 5px;"></td>
      </tr>
    </div> -->

    <!-- 宽带接入 -->

    <!-- <div class='order-box'
         v-for="(item, index) in workTableData.internet"
         :key="item.id">
      <table class="commonTabel"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td class="column">工单类型</td>
          <td style="font-size:16px">宽带接入 <a-button size="small"
                      style="margin:5px;color:red"
                      @click="removeWork('internet',item)">删除工单</a-button>
          </td>
          <td class="column">工作号</td>
          <td>{{item.work_content_sn}}</td>
          <td class="column"><span style="color: red;margin-left: 0px;">*</span>工单状态</td>
          <td>
            {{item.status}}

          </td>
        </tr>

        <tr>
          <td class="column">装维人员</td>
          <td colspan="5">
            {{item.worker_name}}

          </td>
        </tr>
        <template v-for="item1 in groupedItems(modalInterMust)"
                  :key="item1.key">
          <tr>
            <template v-for="(item2) in item1"
                      :key="item2.key">

              <td v-if="['begin_date','end_date'].includes(item2.dataIndex)"
                  class="column">
                {{item2.title}}
              </td>
              <td v-if="['begin_date','end_date'].includes(item2.dataIndex)">
                <a-space direction="vertical"
                         :size="12">
                  <a-config-provider :locale="locale">
                    <a-date-picker valueFormat='YYYY-MM-DD'
                                   @change="time=>onOpenChange('internet',time,item2.dataIndex,index)"
                                   v-model:value="item[item2.dataIndex]" />
                  </a-config-provider>
                </a-space>
              </td>

              <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
              <td v-if="['payment_type','service_provider'].includes(item2.dataIndex)">
                <a-select v-model:value="item[item2.dataIndex]"
                          show-search
                          :placeholder="`请选择${item2.title}`"
                          style="width: 200px"
                          :options="options[item2.dataIndex]"
                          :filter-option="filterOption"
                          @change="handleChangeSelect(item.dataIndex,$event)">
                </a-select>
              </td>

              <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                  class="column">
                {{item2.title}}</td>
              <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                <a-input-number :bordered="false"
                                style="width:200px"
                                v-model:value="item[item2.dataIndex]"
                                :placeholder="`请输入${item2.title}`" />
              </td>
              <td v-if="['signing_company','service_product'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
              <td v-if="['signing_company','service_product'].includes(item2.dataIndex)">
                <a-input :bordered="false"
                         v-model:value="item[item2.dataIndex]"
                         :placeholder="`请输入${item2.title}`" />
              </td>
              <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}</td>
              <td v-if="!['service_provider','signing_company','service_product','begin_date','end_date','payment_type','duration','payment_amount'].includes(item2.dataIndex)">
                <a-input :bordered="false"
                         v-model:value="item[item2.dataIndex]"
                         :placeholder="`请输入${item2.title}`" />
              </td>
            </template>
          </tr>
        </template>
        <tr>
          <td class="column">附件</td>
          <td colspan="5">
            <div style="dispaly:flex;align-items: center;">

              <uploadComponent :freshFlag='uploadFresh'
                               :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"internet",index)'>

              </uploadComponent>
            </div>
          </td>
        </tr>
        <tr v-if='item.status =="审核中"'>
          <td class='column'>审核</td>
          <td colspan="5"> <a-button @click='examineWorkContent(item.id,"internet",index)'>审核</a-button></td>
        </tr>
      </table>
    </div> -->

    <!-- 网关设备安装 -->
    <!-- v-if="item.status=='审核中'" -->
    <!-- <div class='order-box'
         v-for="(item, index) in workTableData.installWork"
         :key="item.id">
      <table class="commonTabel"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td class="column">工单类型</td>
          <td style="font-size:16px">网关设备安装 <a-button size="small"
                      style="margin:5px;color:red"
                      @click="removeWork('installWork',item)">删除工单</a-button>
          </td>
          <td class="column">工作号</td>
          <td>{{item.work_content_sn}}</td>
          <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            {{item.status}}
          </td>
        </tr>

        <tr>
          <td class="column">装维人员</td>
          <td colspan="5">
            {{item.worker_name}}

          </td>

        </tr>
        <template v-for="item1 in groupedItems(installWork)"
                  :key="item1.key">
          <tr>
            <template v-for="(item2) in item1"
                      :key="item2.key">

              <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)"
                  class="column">
                {{item2.title}}<span style="color: red;margin-left: 0px;">*</span>
              </td>
              <td v-if="['outbound_time','receipt_time','plan_install_time','arrival_time','complete_time'].includes(item2.dataIndex)">
                <a-space direction="vertical"
                         :size="12">
                  <a-config-provider :locale="locale">
                    <a-date-picker valueFormat='YYYY-MM-DD'
                                   @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                   v-model:value="item[item2.dataIndex]" />
                  </a-config-provider>
                </a-space>
              </td>

              <td v-if="['express_received_time'].includes(item2.dataIndex)"
                  class="column">
                {{item2.title}}
              </td>
              <td v-if="['express_received_time'].includes(item2.dataIndex)">
                <a-space direction="vertical"
                         :size="12">
                  <a-config-provider :locale="locale">
                    <a-date-picker valueFormat='YYYY-MM-DD'
                                   @change="time=>onOpenChange('installWork',time,item2.dataIndex,index)"
                                   v-model:value="item[item2.dataIndex]" />
                  </a-config-provider>
                </a-space>
              </td>

              <td v-if="['payment_type'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
              <td v-if="['payment_type'].includes(item2.dataIndex)">
                <a-select v-model:value="item[item2.dataIndex]"
                          show-search
                          :placeholder="`请选择${item2.title}`"
                          style="width: 200px"
                          :options="options[item2.dataIndex]"
                          :filter-option="filterOption"
                          @change="handleChangeSelect(item.dataIndex,$event)">
                </a-select>
              </td>

              <td v-if="['duration','payment_amount'].includes(item2.dataIndex)"
                  class="column">
                {{item2.title}}</td>
              <td v-if="['duration','payment_amount'].includes(item2.dataIndex)">
                <a-input-number :bordered="false"
                                style="width:200px"
                                v-model:value="item[item2.dataIndex]"
                                :placeholder="`请输入${item2.title}`" />
              </td>
              <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}<span style="color: red;margin-left: 0px;">*</span></td>
              <td v-if="['service_provider','signing_company','service_product'].includes(item2.dataIndex)">
                <a-input :bordered="false"
                         v-model:value="item[item2.dataIndex]"
                         :placeholder="`请输入${item2.title}`" />
              </td>
              <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)"
                  class="column">{{item2.title}}</td>
              <td v-if="['transport_method','express_number','express_receiver'].includes(item2.dataIndex)">
                <a-input :bordered="false"
                         v-model:value="item[item2.dataIndex]"
                         :placeholder="`请输入${item2.title}`" />
              </td>
            </template>
          </tr>
        </template>
        <tr>
          <td>备注</td>
          <td colspan="5">
            <a-input :bordered="false"
                     v-model:value="item['desc']"
                     :placeholder="`请输入备注`" />

          </td>
        </tr>

        <tr>
          <td>设备</td>
          <td colspan="5">
            <thead>
              <tr>
                <td v-if="item.devices.length != 0"
                    scope="col">设备名称<span style="color: red;margin-left: 0px;">*</span>
                </td>
                <td v-if="item.devices.length != 0"
                    scope="col">数量<span style="color: red;margin-left: 0px;">*</span>
                </td>
                <td scope="col"><a style="margin:2px;color: #1884ea;"
                     @click="addDevice(index)">添加设备</a></td>
              </tr>
            </thead>

            <tbody v-for="(device,index1) in item.devices"
                   :key="device.id">
              <tr>
                <td scope="row">

                  <a-select style='margin-left:-10px;width:100%'
                            v-model:value="device.device_id"
                            show-search
                            placeholder="选择产品"
                            :options="options['device_selector']"
                            :filter-option="filterOption"></a-select>
                </td>
                <td>
                  <a-input-number style="width:100%;margin-left:-10px;"
                                  :bordered="false"
                                  v-model:value="device.quantity"
                                  :placeholder="`请输入数量`" />
                </td>
                <td style="margin:2px;color: #1884ea;"
                    @click="removeUser(index1,device)">删除设备</td>
              </tr>

            </tbody>
          </td>
        </tr>
        <tr>
          <td>附件</td>
          <td colspan="5">
            <div style="dispaly:flex;align-items: center;">

              <uploadComponent :freshFlag='uploadFresh'
                               :fileIds='item.fileIds'
                               @update:fileIds='(event)=>fileChange(event,"installWork",index)'>

              </uploadComponent>
            </div>
          </td>
        </tr>
        <tr v-if='item.status =="审核中"'>
          <td class='column'>审核</td>
          <td colspan="5"> <a-button @click='examineWorkContent(item.id,"installWork",index)'>审核</a-button></td>
        </tr>
      </table>

    </div> -->
    <!-- 故障维修 -->
    <!-- <div class='order-box'
         v-for="(item, index) in workTableData.faultOrder"
         :key="item.id">
      <table class="commonTabel"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td class="sourceColumn">工单类型</td>
          <td style="font-size:16px">故障维修 <a-button size="small"
                      style="margin:5px;color:red"
                      @click="removeWork('faultOrder',item)">删除工单</a-button>

          </td>
          <td class="column">工作号</td>
          <td>{{item.work_content_sn}}</td>
          <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            {{item.status}}

          </td>

        </tr>

        <tr>
          <td class="column">装维人员</td>
          <td colspan="5">
            {{item.worker_name}}
          </td>
        </tr>
        <tr>
          <td class="column">故障原因</td>
          <td colspan="5">
            <a-input :bordered="false"
                     v-model:value="item.fault_desc"
                     :placeholder="`请输入故障原因`" />
          </td>
        </tr>
        <tr>

          <td class="column">处理结果</td>
          <td colspan="5">
            <a-input :bordered="false"
                     v-model:value="item.process_result"
                     :placeholder="`请输入处理结果`" />
          </td>

        </tr>

        <tr>
          <td class="column">附件</td>
          <td colspan="5"
              style="dispaly:flex;align-items: center;">

            <uploadComponent :freshFlag='uploadFresh'
                             :fileIds='item.fileIds'
                             @update:fileIds='(event)=>fileChange(event,"faultOrder",index)'>

            </uploadComponent>
          </td>
        </tr>
        <tr>
          <td class="column">故障描述</td>
          <td colspan="5">
            <a-textarea :bordered="false"
                        v-model:value="item.desc"
                        placeholder="请输入备注"
                        :rows="2" />
          </td>
        </tr>
        <tr v-if='item.status =="审核中"'>
          <td class='column'>审核</td>
          <td colspan="5"> <a-button @click='examineWorkContent(item.id,"faultOrder",index)'>审核</a-button></td>
        </tr>
      </table>

      <tr>
        <td colspan="5"
            style="height: 5px;"></td>
      </tr>
    </div> -->

    <!-- 测试报告 -->
    <!-- <div class='order-box'
         v-for="(item, index) in workTableData.testReport"
         :key="item.id">

      <table class="commonTabel"
             cellspacing="0"
             cellpadding="0">
        <tr>
          <td class="sourceColumn">工单类型</td>
          <td style="font-size:16px">测试报告 <a-button size="small"
                      style="margin:5px;color:red"
                      @click="removeWork('testReport',item)">删除工单</a-button>

          </td>
          <td class="column">工作号</td>
          <td>{{item.work_content_sn}}</td>
          <td class="column">工单状态<span style="color: red;margin-left: 0px;">*</span></td>
          <td>
            {{item.status}}
          </td>

        </tr>

        <tr>
          <td class="column">装维人员</td>
          <td colspan="5">
            {{item.worker_name}}
          </td>
        </tr>
        <tr>
          <td class="column">线路策略</td>
          <td colspan="5">
            <a-input :bordered="false"
                     v-model:value="item.line_policy"
                     :placeholder="`请输入线路策略`" />
          </td>
        </tr>
        <tr>

          <td class="column">接入线路方式</td>
          <td colspan="5">
            <a-input :bordered="false"
                     v-model:value="item.line_access_mode"
                     :placeholder="`请输入接入线路方式`" />
          </td>

        </tr>

        <tr>
          <td class="column">测试报告文件</td>
          <td colspan="5"
              style="dispaly:flex;align-items: center;">

            <uploadComponent :freshFlag='uploadFresh'
                             :fileIds='item.fileIds'
                             @update:fileIds='(event)=>fileChange(event,"testReport",index)'>

            </uploadComponent>
          </td>
        </tr>
        <tr v-if='item.status =="审核中"'>
          <td class='column'>审核</td>
          <td colspan="5"> <a-button @click='examineWorkContent(item.id,"testReport",index)'>审核</a-button></td>
        </tr>

      </table>

 
    </div> -->
  </a-modal>

  <!-- 显示详情 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.detailMessage"
           width="800px"
           title="工单详情  "
           @ok="handleOkDelete">

    <div style="display: flex; align-items: center;">
      <a-tabs v-model:activeKey="baseOrFlowMessageActiveKey"
              style='width:100%'>
        <a-tab-pane key="1">
          <template #tab>
            <span>
              <apple-outlined />
              工单信息
            </span>
          </template>
          <div>
            <h4>基础信息</h4>
            <table class="showTabel">

              <tr>
                <td class="column">工单号</td>
                <td>{{detailMessage.work_sn}}</td>
                <td class="column">品牌</td>
                <td>{{detailMessage.brand}}</td>
              </tr>

              <tr>
                <td class="column">类型</td>
                <td>{{detailMessage.type}}</td>
                <td class="column">状态</td>
                <td>{{detailMessage.status}}</td>
              </tr>

              <tr>
                <td class="column">门店名称</td>
                <td colspan="3">{{detailMessage.storeName}}</td>

              </tr>
              <tr>
                <td class="column">地址</td>
                <td colspan="3">{{detailMessage.complete_address}}</td>

              </tr>

              <tr>
                <td class="column">联系人</td>
                <td>{{detailMessage.contactName}}</td>
                <td class="column">联系人手机号</td>
                <td>{{detailMessage.contactPhone}}</td>
              </tr>

            </table>
            <!-- <h4>工作内容信息</h4>
            <div v-for="item1 in detailMessage.work_contents"
                 :key="item1.id">
              <div v-if="item1.type == '资源勘查'">
                <h4>工单信息：资源勘查</h4>
                <table class="showTabel"
                       cellspacing="0"
                       cellpadding="0">
                  <tr>
                    <td class="column">工作号</td>
                    <td>{{item1.work_content_sn}}</td>
                    <td class="column">状态</td>
                    <td>{{item1.status}}</td>
                  </tr>

                  <tr>
                    <td class="column">装维人员</td>
                    <td>{{item1.worker_name}}</td>
                    <td class="column"></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td class="column">物业联系人</td>
                    <td>{{item1.pmc_contact_name}}</td>
                    <td class="column">物业电话</td>
                    <td>{{item1.pmc_contact_phone}}</td>

                  </tr>

                  <tr>
                    <td class="column">资源勘查</td>
                    <td colspan="3">
                      <thead>
                        <tr>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">是否有电源</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">是否有空间</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">是否需要布线</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">弱电井是否可用</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">是否代理管控</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row">{{item1.power==1?"是":"否"}}</td>
                          <td>{{item1.space==1?"是":"否"}}</td>
                          <td>{{item1.need_line==1?"是":"否"}}</td>
                          <td>{{item1.weak_current_well==1?"是":"否"}}</td>
                          <td>{{item1.is_monopolized==1?"是":"否"}}</td>
                        </tr>

                      </tbody>
                    </td>

                  </tr>
                  <tr v-if='item1.is_monopolized==1'>
                    <td class="column">代理管控价格</td>
                    <td colspan="3">{{item1.monopolized_prices}}</td>

                  </tr>
                  <tr>
                    <td class="column">信号强度</td>
                    <td colspan="3">
                      <thead>
                        <tr>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">
                            {{item1.provider_resources[0]["name"]}}</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">
                            {{item1.provider_resources[1]["name"]}}</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">
                            {{item1.provider_resources[2]["name"]}}</td>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td scope="row">
                            {{item1.provider_resources[0]["available"]==1?"有信号":"无信号"}}{{item1.provider_resources[0]["rssi"]}}
                          </td>
                          <td scope="row">
                            {{item1.provider_resources[1]["available"]==1?"有信号":"无信号"}}{{item1.provider_resources[1]["rssi"]}}
                          </td>
                          <td scope="row">
                            {{item1.provider_resources[2]["available"]==1?"有信号":"无信号"}}{{item1.provider_resources[2]["rssi"]}}
                          </td>

                        </tr>

                      </tbody>
                    </td>

                  </tr>

                </table>
              </div>
              <div v-if="item1.type == '宽带接入'">
                <h4>工单信息：宽带接入</h4>
                <table class="showTabel"
                       cellspacing="0"
                       cellpadding="0">
                  <tr>
                    <td class="column">工作号</td>
                    <td>{{item1.work_content_sn}}</td>
                    <td class="column">状态</td>
                    <td>{{item1.status}}</td>
                  </tr>

                  <tr>
                    <td class="column">装维人员</td>
                    <td>{{item1.worker_name}}</td>
                    <td class="column">运营商</td>
                    <td>{{item1.cost.service_provider}}</td>
                  </tr>

                  <tr>
                    <td class="column">下单抬头</td>
                    <td>{{item1.cost.signing_company}}</td>
                    <td class="column">产品套餐</td>
                    <td>{{item1.cost.service_product}}</td>

                  </tr>
                  <tr>
                    <td class="column">周期类型</td>
                    <td>{{item1.cost.payment_type}}</td>
                    <td class="column">周期</td>
                    <td>{{item1.cost.duration}}</td>

                  </tr>

                  <tr>
                    <td class="column">付款金额</td>
                    <td>{{item1.cost.payment_amount}}</td>
                    <td class="column"></td>
                    <td></td>

                  </tr>
                  <tr>
                    <td class="column">开始时间</td>
                    <td>{{item1.cost.begin_date}}</td>
                    <td class="column">结束时间</td>
                    <td>{{item1.cost.end_date}}</td>

                  </tr>

                  <tr>
                    <td class="column">帐号/专线编号</td>
                    <td>{{item1.ad_number}}</td>
                    <td class="column">SN号</td>
                    <td>{{item1.sn}}</td>
                  </tr>

                  <tr>
                    <td class="column">账号/专线密码</td>
                    <td>{{item1.password}}</td>
                    <td class="column">分帐序号/KD号</td>
                    <td>{{item1.kd_number}}</td>
                  </tr>

                  <tr>
                    <td class="column">客户经理名称</td>
                    <td>{{item1.provider_customer_manager_name}}</td>
                    <td class="column">客户经理手机号</td>
                    <td>{{item1.provider_customer_manager_phone}}</td>
                  </tr>
                  <tr>
                    <td class="column">安装人名字</td>
                    <td>{{item1.installer_name}}</td>
                    <td class="column">安装人手机</td>
                    <td>{{item1.installer_phone}}</td>
                  </tr>
                  <tr>
                    <td class="column">维护人名字</td>
                    <td>{{item1.maintenance_name}}</td>
                    <td class="column">维护人手机</td>
                    <td>{{item1.maintenance_phone}}</td>
                  </tr>

                </table>
              </div>
              <div v-if="item1.type == '网关设备安装'">
                <h4>工单信息：网关设备安装</h4>
                <table class="showTabel"
                       cellspacing="0"
                       cellpadding="0">
                  <tr>
                    <td class="column">工作号</td>
                    <td>{{item1.work_content_sn}}</td>
                    <td class="column">状态</td>
                    <td>{{item1.status}}</td>
                  </tr>

                  <tr>
                    <td class="column">装维人员</td>
                    <td>{{item1.worker_name}}</td>
                    <td class="column">完成安装时间</td>
                    <td>{{item1.complete_time}}</td>
                  </tr>

                  <tr>
                    <td class="column">出库时间</td>
                    <td>{{item1.outbound_time}}</td>
                    <td class="column">收货时间</td>
                    <td>{{item1.receipt_time}}</td>

                  </tr>
                  <tr>
                    <td class="column">计划安装时间</td>
                    <td>{{item1.plan_install_time}}</td>
                    <td class="column">到达现场时间</td>
                    <td>{{item1.arrival_time}}</td>

                  </tr>
                  <tr>
                    <td class="column">运输方式</td>
                    <td>{{item1.transport_method}}</td>
                    <td class="column">快递单号</td>
                    <td>{{item1.express_number}}</td>

                  </tr>

                  <tr>
                    <td class="column">设备信息</td>
                    <td colspan="3">
                      <thead>
                        <tr>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">设备名称</td>
                          <td style="width:200px;background-color: rgb(188, 188, 217);  "
                              scope="col">数量</td>

                        </tr>
                      </thead>
                      <tbody v-for="devices in item1.devices"
                             :key=devices.id>
                        <tr>
                          <td scope="row">{{devices.device_name}}</td>
                          <td>{{devices.quantity}}</td>

                        </tr>

                      </tbody>
                    </td>

                  </tr>

                </table>
              </div>
              <div v-if="item1.type == '故障维修'">
                <table class="showTabel">
                  <tr>
                    <td class="column">工作类型</td>
                    <td style="font-size:16px">故障维修
                    </td>
                  </tr>

                  <tr>
                    <td class="column">附件</td>
                    <td style="dispaly:flex;align-items: center;">
                      <uploadComponent :fileIds='item1.fileIds'
                                       :isButtonShow='false'
                                       :inputShow='false'
                                       @update:fileIds='(event)=>fileChange(event,"faultOrder",index)'>

                      </uploadComponent>
                    </td>
                  </tr>
                </table>
              </div>
            </div> -->
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <template #tab>
            <span>
              <android-outlined />
              流程信息
            </span>
          </template>

          <div>
            <a-timeline mode="alternate">
              <a-timeline-item v-for="item in detailMessage.workflow.steps"
                               :key="item.id">
                <p>{{item.step}}</p>
                <p>{{item.create_time}}</p>
                <p>{{item.after_result}}</p>
              </a-timeline-item>
            </a-timeline>
          </div>
        </a-tab-pane>
      </a-tabs>
      <!-- <a-button @click="modalEditData"
                style="margin-left: auto;">编辑工单</a-button> -->
    </div>

    <template #footer>

    </template>
  </a-modal>
  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='search-box'>
    <div class='search-input-box'>
      <a-input class='input-item'
               addonBefore='关键字'
               :placeholder="`请输入关键字`"
               v-model:value="searchRef.keyword"
               style="width:190px"></a-input>
      <a-input class='input-item'
               addonBefore='工单号'
               :placeholder="`请输入工单号`"
               v-model:value="searchRef.work_sn"
               style="width:190px"></a-input>
      <a-input class='input-item'
               addonBefore='品牌名称'
               :placeholder="`请输入品牌名称`"
               v-model:value="searchRef.brand_name"
               style="width:200px"></a-input>
      <a-input class='input-item'
               addonBefore='门店名称'
               :placeholder="`请输入门店名称`"
               v-model:value="searchRef.contract_user_name"
               style="width:200px"></a-input>
      <!-- <a-input class='input-item'
               addonBefore='工单状态'
               v-model:value="searchRef.work_order_status"
               style="width:200px"></a-input> -->

      <!-- <a-input class='input-item'
               addonBefore='工单类型'
               v-model:value="searchRef.work_order_type"
               style="width:200px"></a-input> -->

      <div style='margin-left:5px'>
        <a-input-group compact>
          <div class='searchLabel'>
            <label>工单类型</label>
          </div>
          <a-select style='width:150px'
                    :show-search="{ filter }"
                    :options="options.workOrderType"
                    :placeholder="`请选择工单类型`"
                    v-model:value="searchRef.work_order_type"
                    @change="workTypeHandleChange">
          </a-select>
        </a-input-group>
      </div>
      <div style='margin-left:5px'>
        <a-input-group compact>
          <div class='searchLabel'>
            <label style=''>工单状态</label>
          </div>
          <a-select style='width:130px'
                    :show-search="{ filter }"
                    :options="options.workOrderStatus"
                    :placeholder="`请选择工单状态`"
                    v-model:value="searchRef.work_order_status"
                    @change="handleChange">
          </a-select>
        </a-input-group>
      </div>
      <a-button style='margin:0px;margin-left:10px'
                type="primary"
                @click="sift">搜索</a-button>
      <a-button style='margin:0px;margin-left:5px'
                @click="resetSift">重置</a-button>
    </div>

  </div>
  <a-table :columns=" columns"
           :data-source="tableSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['work_sn'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record)">{{ record.work_sn }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a viewUserMessage'
             @click="showUserMessage(record)">查看工单</a>
          <!-- <a class='editable-row-a edit'
             v-if='buttonShow.edit'
             @click="editData(record)">编辑</a> -->
          <a class='editable-row-a delete'
             v-if='buttonShow.delete'
             @click="deleteData(record.key)">删除</a>
          <a class='editable-row-a assignMaintenance'
             v-if='buttonShow.assignMaintenance && record.buttons.includes("分配运维") && record.type=="报修"'
             @click="assignMaintenance(record)">分配运维</a>
          <a class='editable-row-a assignNetworkEngineer'
             v-if='record.buttons.includes("分配网工") && buttonShow.assignNetworkEngineer && record.type=="报修"'
             @click="assignNetworkEngineer(record)">分配网工</a>
          <a class='editable-row-a orderExamine'
             v-if="['已申请'].includes(record.status) && buttonShow.orderExamine"
             @click="checkOrder(record)">审核</a>
          <a class='editable-row-a workOrderPretreatment'
             v-if='buttonShow.workOrderPretreatment &&  record.buttons.includes("客服预处理")&& record.type=="报修"'
             @click="pretreatment(record)">预处理</a>

          <a class='editable-row-a workOrderPretreatment'
             v-if='record.buttons.includes("客服预处理") && record.type=="新装"'
             @click="preprocessClick(record.id)">预处理2</a>

          <a class='editable-row-a workOrderPretreatment'
             v-if='record.status=="需要资源勘查" && record.type=="新装"'
             @click="resourceClick(record.id)">资源勘探</a>

          <a class='editable-row-a workOrderPretreatment'
             v-if='record.status=="需要制作方案" && record.type=="新装"'
             @click="networkEngineerClick(record.id)">分配</a>

          <a class='editable-row-a workOrderPretreatment'
             v-if='record.buttons.includes("分配运维") && record.type=="新装"'
             @click="newinstallDispatachClick(record.id)">分配</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <a-button type="primary"
                  style="margin-top:0px"
                  @click="waitDealOrder">待处理工单</a-button>
        <div class='right-buttons'>
          <!-- <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加工单</a-button> -->
          <a-button class='addDataButton'
                    @click="refresh">刷新</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除工单</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import uploadComponent from '@/components/uploadComponent.vue'

import serviceAxios from "@/utils/serviceAxios";
import removeNullValues from "@/utils/removeNullValues";

import { message } from "ant-design-vue";
import { reactive, ref, onMounted, onUpdated, watch, computed } from "vue";
import interfaces from "@/config";
// import router from "@/router";
import { useRouter, useRoute } from 'vue-router';
// import serviceWebsocket from "@/utils/serviceWebsocket";
import { checkTree } from "@/utils/regionTree";
import check from "../../utils/checkLocalStorage";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { DatabaseOutlined } from "@ant-design/icons-vue";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { useScreenSize } from '@/utils/useScreenSize';
import { selector } from '@/utils/selector'

const { windowWidth, windowHeight } = useScreenSize();

const uploadFresh = ref(true)

const locale = zhCN;
dayjs.locale("zh-cn");

let route = useRoute()
let router = useRouter()

//定义新装接口字段
let newInstallInterface = {
  preProcess: '/v1/work_order/new_install/prev_process/complete', //预处理
  resourceExploration: '/v1/work_order/new_install/resource_exploration/complete', //资源勘察
  networkEngineer: '/v1/work_order/new_install/network_engineer/dispatch',//调度网工
  remoteProcess: '/v1/work_order/new_install/remote_process/complete',//远程处理
  openAccount: '/v1/work_order/new_install/open_broadband_access/complete',//开户
  readyDevice: '/v1/work_order/new_install/ready_devices/complete',//设备准备
  dispatch: '/v1/work_order/new_install/onset_engineer/dispatch',//调度运维
  schedule: '/v1/work_order/new_install/onsite_engineer/schedule',//预约时间
  arrived: '/v1/work_order/new_install/onsite_engineer/arrived',//到达门店
  submitResult: '/v1/work_order/new_install/onsite_engineer/submit_result' //提交工作内容单

}





const checkStatus = ref()
//审核申请工单字段
const checkOrderFields = reactive({
  // work_order_id: "",
  show: true,
  status: "false",
  desc: "",
  reject_reason: "",
})
//预处理字段
const pretreatmentRef = ref({
  id: '',
  show: true,
  customer_service_result: '',
  status: 'true',
})

//检测缓存数据是否存在
onMounted(async () => {
  const localMessage = check.checkData();
  console.log("localMessage", localMessage)
  // 检查用户信息是否存在  
  if (!localMessage) {
    // 如果不存在，则跳转到登录页面  
    router.push('/login');
  }
  // tableHeight()
});

//搜索字段
let searchRef = ref({
  keyword: "",
  contract_user_name: "",

  work_order_status: null,
  work_order_type: null,
  work_sn: "",
  brand_name: "",
});

//上传 下载文件url信息
const uploadUrl = process.env.VUE_APP_URL + interfaces.commonUpload
const lookPic = process.env.VUE_APP_URL + interfaces.fileGet
const headers = reactive({
  authorization: "Bearer " + localStorage.getItem("token"),
  // "Content-Type": "multipart/form-data"
})


//区域树 
let treeData = reactive([])
let treeDataAll = reactive([])
const getTreeData = async () => {
  // let region = JSON.parse(localStorage.getItem("region"))
  treeData = await checkTree()
  treeDataAll = treeData
  treeData = treeData[0]["children"]

  console.log("treeData", treeData)
}
getTreeData()


//统一管理接口变量
const url = {
  add: interfaces.ticketsAdd,
  delete: interfaces.ticketDelete,
  edit: interfaces.ticketEdit,
  page: interfaces.ticketPage,
  getById: interfaces.deviceGetById,
  ticketsType: interfaces.ticketsSelector,
  contractPage: interfaces.contractPage,
  workSelector: interfaces.workSelector,
  assign: '/v1/work_order/assign',//分配
  workExamine: '/v1/work_order/outworker/work_content/submit/review',//工作内容单审核
  orderExamine: '/v1/work_order/tenant/apply/review', //工单审核
  workOrderPretreatment: interfaces.workOrderPretreatment, //预处理
  assignNetworkEngineer: interfaces.assignNetworkEngineer,  //分配网工
  assignMaintenance: interfaces.assignMaintenance,//分配运维
}



//按钮的显示，对不同用户进行权限控制，普通用户一般不具有增删改查的权限，具体需要看角色配置
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
  // assign: true,//分配运维
  workExamine: true,//工作内容单审核
  orderExamine: true, //申请工单审核
  workOrderPretreatment: true, //预处理
  assignNetworkEngineer: true, //分配网工
  assignMaintenance: true,//分配运维
})




//从缓存中获取按钮权限
//所需数据：浏览器缓存rules字段的规则数据、url中的页面路由、统一管理接口的数据 
//具体逻辑：从rules中的字段中找到与url路由中一致的字段的数据，将一致字段的数据和统一管理接口的数据比对，如果有相同的就说明按钮存在
const getButtonRules = () => {

  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}



getButtonRules()

//定义查看工单tab栏字段
const baseOrFlowMessageActiveKey = ref('1')
// table列名数据
const columns = reactive([

  {
    title: "工单号",
    dataIndex: "work_sn",
    key: "work_sn",
    width: '180px',
  },
  {
    title: "工单类型",
    width: '100px',
    dataIndex: "type",
    key: "type"
  },
  {
    title: "工单状态",
    width: '100px',
    dataIndex: "status",
    key: "status"
  },

  {
    title: "品牌",
    dataIndex: "brand",
    key: "brand",
    width: '150px',
    ellipsis: true
  },
  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName",
    width: '150px',
    ellipsis: true
  },

  {
    title: "门店地址",
    dataIndex: "complete_address",
    key: "complete_address",
    // width: '200px',
    ellipsis: true
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName",
    width: '100px',
    ellipsis: true
  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone",
    width: '120px',
    ellipsis: true
  },
  // {
  //   title: "备注",
  //   dataIndex: "desc",
  //   key: "desc",
  //   width: '120px',
  // },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: '160px',
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    width: '200px',
  }
]);

//增加或者编辑的模态框规则
const rules = {
  sourceExplorate: {},
  base: {

    contact_message: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    product: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    type: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
  },

  internet: {
    provider_customer_manager_name: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    provider_customer_manager_phone: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    service_provider: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    signing_company: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    service_product: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

    payment_type: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    duration: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    payment_amount: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    service_provider: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    begin_date: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    end_date: [
      {
        required: false,
        message: "请输入数据",
      },
    ],
    workType: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

  },

  installWork: {
    outbound_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    receipt_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    plan_install_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    arrival_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],
    complete_time: [
      {
        required: true,
        message: "请输入数据",
      },
    ],

  }

}
//table数据源
const tableSource = reactive(
  {
    data: [],
    address: "",
  }
)


//选择框数据源
const options = reactive({
  networkEngineer: [],//网工
  attachment: [],//附件类型选择框数据
  order_status: [], //工作内容状态
  status: [], //工单状态
  device_selector: [
  ],
  search: [
    {

      label: "品牌",
      value: "brand_name"

    },
    {

      label: "区域",
      value: "region_id"

    },
    {

      label: "工单号",
      value: "work_sn"

    },
    {

      label: "门店名称",
      value: "contract_user_name"

    }, {

      label: "日期",
      value: "date"

    },],
  region: [],
  provider_resources: [
    {

      label: "强",
      value: "强"

    },
    {

      label: "中",
      value: "中"

    },
    {

      label: "弱",
      value: "弱"

    },
  ],
  worker: [
    //   {

    //   label: "张三",
    //   value: 1

    // },
    // {

    //   label: "李四",
    //   value: 3

    // },
  ],
  type: [{
    label: "网络接入",
    value: "网络接入"
  },
  ],
  workType: [{
    label: "宽带接入",
    value: "宽带接入"
  },
  ],//工作内容
  contract: [],
  product: [],
  payment_type: [{
    label: "包年",
    value: "包年"
  },
  {
    label: "包月",
    value: "包月"
  }],
  workerSelector: [],
  service_provider: [],

  workOrderStatus: [],
  workOrderType: [],
  businessCluster: []
});

// 数据模型
const baseData = reactive({

  contact_message: "", //合同
  region: "",//地区
  status: "",
  contractId: "",
  customerId: "",
  product: "",
  type: "",
  desc: "",
  brand: "",
  storeName: "",
  address: "",
  contactName: "",
  contactPhone: "",

  ad_number: "",
  password: "",
  kd_number: "",
  sn: "",
  provider_customer_manager_name: "",
  provider_customer_manager_phone: "",
  installer_name: "",
  installer_phone: "",
  maintenance_name: "",
  maintenance_phone: "",

  workType: "",
  service_provider: "",
  signing_company: "",
  service_product: "",
  payment_type: "",
  duration: "",
  payment_amount: "",
  begin_date: "",
  end_date: "",

  // begin_date_str: "",
  // end_date_str: "",








})

//弹框字段
const modalData = reactive([

  {
    title: "合同",
    dataIndex: "contact_message",
    key: "contact_message"
  },
  {

    title: "产品服务",
    dataIndex: "product",
    key: "product"
  },
  {
    title: "工单类型",
    dataIndex: "type",
    key: "type"
  },


  {
    title: "品牌",
    dataIndex: "brand",
    key: "brand"
  },

  {
    title: "门店名称",
    dataIndex: "storeName",
    key: "storeName"
  },
  {
    title: "地区",
    dataIndex: "region",
    key: "region"
  },


  {
    title: "门店地址",
    dataIndex: "address",
    key: "address"
  },
  {
    title: "联系人",
    dataIndex: "contactName",
    key: "contactName"
  },
  {
    title: "联系电话",
    dataIndex: "contactPhone",
    key: "contactPhone"
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status"
  },
])



//网关设备服务字段
const installWork = reactive([


  {
    title: '出库时间',
    dataIndex: 'outbound_time',
    key: 'outbound_time',
  },
  {
    title: '收货时间',
    dataIndex: 'receipt_time',
    key: 'receipt_time',
  },
  {
    title: '计划安装时间',
    dataIndex: 'plan_install_time',
    key: 'plan_install_time',
  },
  {
    title: '到达现场时间',
    dataIndex: 'arrival_time',
    key: 'arrival_time',
  },
  {
    title: '完成安装时间',
    dataIndex: 'complete_time',
    key: 'complete_time',
  },
  //   devices: [],
  // data_attachment_ids: [],
  // e_signature_ids: [],


  {
    title: '运输方式',
    dataIndex: 'transport_method',
    key: 'transport_method',
  },
  {
    title: '快递单号',
    dataIndex: 'express_number',
    key: 'express_number',
  },
  {
    title: '快递收件人',
    dataIndex: 'express_receiver',
    key: 'express_receiver',
  },
  {
    title: '快递签收时间',
    dataIndex: 'express_received_time',
    key: 'express_received_time',
  },
  // {
  //   title: '附件类型',
  //   dataIndex: 'attachment',
  //   key: 'attachment',
  // },
  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },
  {
    title: '设备信息',
    dataIndex: 'devices',
    key: 'devices',
  },
  {
    title: '附件',
    dataIndex: 'attachment_file',
    key: 'attachment_file',
  },


])

//宽带服务必填字段
const modalInterMust = reactive([

  {
    title: '运营商',
    dataIndex: 'service_provider',
    key: 'service_provider',
  },
  {
    title: '下单抬头',
    dataIndex: 'signing_company',
    key: 'signing_company',
  },
  {
    title: '产品套餐',
    dataIndex: 'service_product',
    key: 'service_product',
  },
  {
    title: '周期类型',
    dataIndex: 'payment_type',
    key: 'payment_type',
  },
  {
    title: '周期',
    dataIndex: 'duration',
    key: 'duration',
  },
  {
    title: '付款金额',
    dataIndex: 'payment_amount',
    key: 'payment_amount',
  },
  {
    title: '开始时间',
    dataIndex: 'begin_date',
    key: 'begin_date',
  },
  {
    title: '结束时间',
    dataIndex: 'end_date',
    key: 'end_date',
  },
  {
    title: '账号/专线编号',
    dataIndex: 'ad_number',
    key: 'ad_number',
  },
  {
    title: '账号/专线密码',
    dataIndex: 'password',
    key: 'password',
  },
  {
    title: '分帐序号/KD号',
    dataIndex: 'kd_number',
    key: 'kd_number',
  },
  {
    title: 'SN号',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: '客户经理名称',
    dataIndex: 'provider_customer_manager_name',
    key: 'provider_customer_manager_name',
  },
  {
    title: '客户经理手机号',
    dataIndex: 'provider_customer_manager_phone',
    key: 'provider_customer_manager_phone',
  },
  {
    title: '安装人名字',
    dataIndex: 'installer_name',
    key: 'installer_name',
  },
  {
    title: '安装人手机',
    dataIndex: 'installer_phone',
    key: 'installer_phone',
  },
  {
    title: '维护人名字',
    dataIndex: 'maintenance_name',
    key: 'maintenance_name',
  },
  {
    title: '维护人手机',
    dataIndex: 'maintenance_phone',
    key: 'maintenance_phone',
  },
  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },


])


// 将宽带服务分成三个一组
const groupedItems = (item) => {
  const chunkSize = 3;
  const result = [];
  for (let i = 0; i < item.length; i += chunkSize) {
    const chunk = item.slice(i, i + chunkSize);
    result.push(chunk);
  }
  // console.log("result", result)
  return result;
};

//资源勘察字段
const sourceExplorate = reactive([


  {
    title: '是否有电源',
    dataIndex: 'power',
    key: 'power',
  },
  {
    title: '是否有空间',
    dataIndex: 'space',
    key: 'space',
  },
  {
    title: '是否需要布线',
    dataIndex: 'need_line',
    key: 'need_line',
  },
  {
    title: '弱电井是否可用',
    dataIndex: 'weak_current_well',
    key: 'weak_current_well',
  },

  {
    title: '是否代理管控',
    dataIndex: 'is_monopolized',
    key: 'is_monopolized',
  },
  {
    title: '物业联系人',
    dataIndex: 'pmc_contact_name',
    key: 'pmc_contact_name',
  },

  {
    title: '物业联系人电话',
    dataIndex: 'pmc_contact_phone',
    key: 'pmc_contact_phone',
  },
  {
    title: '代理管控价格',
    dataIndex: 'monopolized_prices',
    key: 'monopolized_prices',
  },

  {
    title: '信号强度',
    dataIndex: 'provider_resources',
    key: 'provider_resources',
  },

  {
    title: '备注',
    dataIndex: 'desc',
    key: 'desc',
  },

])

//故障维修字段
const faultOrder = [
  {
    title: '故障描述',
    dataIndex: 'fault_desc',
    key: 'fault_desc',
  },
  {
    title: '处理结果',
    dataIndex: 'process_result',
    key: 'process_result',
  },
]

//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  detailMessage: false,
  checkOrder: false,
  assignMaintenance: false,
  examineWorkContent: false,
  okCancelButton: '',
  examine: false,
  pretreatment: false,
  assignNetworkEngineer: false,
})

//新装工单弹框显示
const newInstallModal = reactive({
  preProcess: false, //预处理
  resourceExploration: false, //资源勘察
  networkEngineer: false, //调度网工
  remoteProcess: false, //远程处理
  openAccount: false, //开户
  readyDevice: false, //设备准备
  dispatch: false, //调度运维
  schedule: false, //预约时间
  arrived: false, //到达门店
  submitResult: false //提交工作内容单
})



let urlFlag = "" //判断是增加数据还是编辑数据




//分页模型数据
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows; //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
});

let installDevices = ref([])

//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData.type = '报修'
  for (let key in workTableData) {
    workTableData[key] = [];
  }
  tableSource.address = ""



};

const formRef = ref()

const edit = (detailData) => {
  //刷新upload组件
  uploadFresh.value = !uploadFresh.value

  let storeNameId = ""
  let productEdit = []
  console.log("contractData", contractData)
  contractData[detailData['customer_id']]["children"].forEach(item => {
    if (item.contract_id == detailData['contract_id']) {
      storeNameId = item.value
      baseData.region = item.region.friendly_name
      tableSource.address = item.region.friendly_name
      item.productServices.forEach(
        item1 => {

          productEdit.push(item1.product.name)
        }
      )
      baseData.product = productEdit.join('、')
    }
  })
  baseData.id = detailData['id']


  let internet = []
  let installWork = []
  let sourceExplorate = []
  let faultOrder = []
  let testReport = []
  detailData["work_contents"].forEach((item, index) => {

    const worksRegionIds = item.workers.length != 0 ? item.workers[0].region_ids : detailData.regionPath
    workerRegion.value = worksRegionIds[worksRegionIds.length - 1]


    console.log('workerRegion', workerRegion.value)
    for (let key in baseData) {
      try {
        baseData[key] = detailData[key]
      }
      catch {
      }
    }



    let worker_ids = []
    let worker_name = []
    item.workers.forEach((item1) => {
      worker_ids.push(item1.id)
      worker_name.push(item1.real_name)
    })
    item["worker_ids"] = worker_ids
    item["worker_name"] = worker_name
    item.worker_name = item.worker_name.length != 0 ? item.worker_name.join() : ''
    let fileData = []
    let fileIds = []
    if (item.data_attachments.length != 0) {
      item.data_attachments.forEach(item_file => {
        fileData.push(item_file.id)
        fileIds.push({
          file_id: item_file.id,
          owner_class: item_file.owner_class,
          owner_id: item.id
        })
      })
    }
    if (item.e_signatures.length != 0) {
      item.e_signatures.forEach(item_file => {
        fileData.push({
          uid: item_file.id,
          name: item_file.name,
          is_old: 1,
          type: item_file.type,
          status: 'done',
          url: lookPic + item_file.id
        })
      })
    }
    item.fileList = fileData
    item.fileIds = fileIds

    item.workerRegion = workerRegion.value
    if (item.type == "宽带接入") {

      item.service_provider = item.cost.service_provider
      item.signing_company = item.cost.signing_company
      item.service_product = item.cost.service_product
      item.payment_type = item.cost.payment_type
      item.duration = item.cost.duration
      item.payment_amount = item.cost.payment_amount
      item.begin_date = item.cost.begin_date
      item.end_date = item.cost.end_date
      item.begin_date = item.begin_date
      item.end_date = item.end_date
      internet.push(item)


      const index = internet.length - 1
      treeChange(workerRegion.value, null, null, index, 'internet')

    }
    else if (item.type == "网关设备安装") {
      installWork.push(item)

      const index = installWork.length - 1
      treeChange(workerRegion.value, null, null, index, 'installWork')
    }
    else if (item.type == "资源勘查") {
      sourceExplorate.push(item)

      const index = sourceExplorate.length - 1
      treeChange(workerRegion.value, null, null, index, 'sourceExplorate')
    }
    else if (item.type == '故障维修') {
      faultOrder.push(item)

      const index = faultOrder.length - 1
      treeChange(workerRegion.value, null, null, index, 'faultOrder')
    }

    else if (item.type == '测试报告') {
      item.report_file = 208
      testReport.push(item)
      const index = testReport.length - 1
      treeChange(workerRegion.value, null, null, index, 'testReport')
    }
  })

  workTableData.internet = internet
  workTableData.installWork = installWork
  workTableData.sourceExplorate = sourceExplorate
  workTableData.faultOrder = faultOrder
  workTableData.testReport = testReport


  baseData.contact_message = [String(detailData['customer_id']), Number(storeNameId)]
  baseData.status = detailData["status"]
  baseData.contractId = detailData["contract_id"]
  baseData.customerId = detailData["customer_id"]
  baseData.desc = detailData["desc"]
}

//审核申请工单状态的数据
const examine = (record) => {
  urlFlag = url.edit
  // modalShow.addOrEdit = true;
  modalShow.addOrEdit = true
  modalShow.okCancelButton = 'none'
  examineWorkContentRef.value.workId = record.id
  edit(record)
};
//查看数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  modalShow.okCancelButton = 'inline'
  examineWorkContentRef.value.workId = record.id
  edit(record)
};
const modalEditData = () => {
  urlFlag = url.edit
  modalShow.addOrEdit = true
  modalShow.detailMessage = false
  edit(detailMessage)
};

//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  modalShow.okCancelButton = ''
  urlFlag = url.add
};


//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }
};

//确认删除数据
const handleOkDelete = () => {

  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
      real_delete: 0,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功");
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};

const workRef = ref()
const internetRef = ref()
const sourceRef = ref()
const formRefDevice = ref()

//modal框按钮点击事件 提交数据
const addOrEditHandleOk = async () => {
  // baseData.contact_message baseData.product baseData.type
  // baseData.workType
  let work_content_flag = 1

  let work_contents = []
  for (let key in workTableData) {
    console.log(key, workTableData[key])
    if (key == 'sourceExplorate') {
      workTableData['sourceExplorate'].forEach(item => {
        let e_signature_ids = []
        let data_attachment_ids = item.fileList
        console.log(item)
        work_contents.push({
          id: item.id != undefined ? item.id : null,
          type: "资源勘查",
          desc: item.desc,
          worker_name: item.worker_name,
          worker_ids: item.worker_ids,
          status: item.status,
          power: item.power == true ? 1 : 0,
          space: item.space == true ? 1 : 0,
          need_line: item.need_line == true ? 1 : 0,
          weak_current_well: item.weak_current_well == true ? 1 : 0,
          pmc_contact_name: item.pmc_contact_name,
          pmc_contact_phone: item.pmc_contact_phone,
          is_monopolized: item.is_monopolized == true ? 1 : 0,
          monopolized_prices: item.monopolized_prices,
          data_attachment_ids: data_attachment_ids,
          e_signature_ids: e_signature_ids,
          provider_resources: [
            {
              name: "电信",
              available: item.provider_resources_available_telegraphy == true ? 1 : 0,
              rssi: item.provider_resources_rssi_telegraphy,
            },
            {
              name: "联通",
              available: item.provider_resources_available_link == true ? 1 : 0,
              rssi: item.provider_resources_rssi_link,
            },
            {
              name: "移动",
              available: item.provider_resources_available_move == true ? 1 : 0,
              rssi: item.provider_resources_rssi_move,
            }
          ]
        })
      })
    }
    else if (key == "installWork") {
      workTableData['installWork'].forEach(item => {
        console.log("item", item)
        let e_signature_ids = []
        let data_attachment_ids = item.fileList
        let checkFields = ["outbound_time", "receipt_time", "plan_install_time", "arrival_time",
          "complete_time", 'express_received_time'
        ]
        for (let field in checkFields) {
          if (item[checkFields[field]] == "") {
            item[checkFields[field]] = null

          }
        }


        if (item.devices.length > 0) {
          console.log("item.devices", item.devices)

          item.devices.forEach(deviceItem => {
            if (deviceItem.quantity == null || deviceItem.quantity == "" || deviceItem.device_id == null || deviceItem.device_id == "") {
              //work_content_flag = 0
              console.log('设备检测不通过')
            }
          })
        }

        work_contents.push({
          id: item.id != undefined ? item.id : null,
          type: '网关设备安装',
          status: item.status,
          desc: item.desc,
          express_number: item.express_number,
          transport_method: item.transport_method,
          worker_name: item.worker_name,
          worker_ids: item.worker_ids,
          outbound_time: item.outbound_time,
          receipt_time: item.receipt_time,
          plan_install_time: item.plan_install_time,
          arrival_time: item.arrival_time,
          complete_time: item.complete_time,
          express_received_time: item.express_received_time,
          express_receiver: item.express_receiver,
          devices: item.devices,
          data_attachment_ids: data_attachment_ids,
          e_signature_ids: e_signature_ids,
        })
      })
    }

    //宽带接入
    else if (key == "internet") {
      workTableData['internet'].forEach(item => {
        let e_signature_ids = []
        let data_attachment_ids = item.fileList
        work_contents.push({
          id: item.id != undefined ? item.id : null,
          type: '宽带接入',
          desc: item.desc,

          // service_provider: item.service_provider,
          // signing_company: item.signing_company,
          // service_product: item.service_product,
          // payment_type: item.payment_type,
          // duration: item.duration,
          // payment_amount: item.payment_amount,
          // begin_date: item.begin_date,
          // end_date: item.end_date,

          cost: {
            service_provider: item.service_provider,
            signing_company: item.signing_company,
            service_product: item.service_product,
            payment_type: item.payment_type,
            duration: item.duration == '' ? null : item.duration,
            payment_amount: item.payment_amount == '' ? null : item.payment_amount,
            begin_date: item.begin_date == '' ? null : item.begin_date,
            end_date: item.end_date == '' ? null : item.end_date,
          },



          ad_number: item.ad_number,
          password: item.password,
          kd_number: item.kd_number,
          sn: item.sn,
          provider_customer_manager_name: item.provider_customer_manager_name,
          provider_customer_manager_phone: item.provider_customer_manager_phone,
          installer_name: item.installer_name,
          installer_phone: item.installer_phone,
          maintenance_name: item.maintenance_name,
          maintenance_phone: item.maintenance_phone,



          worker_name: item.worker_name,
          worker_ids: item.worker_ids,
          data_attachment_ids: data_attachment_ids,
          e_signature_ids: e_signature_ids,
        })
      })
    }
    //故障维修
    else if (key == "faultOrder") {
      workTableData['faultOrder'].forEach(item => {
        let e_signature_ids = []
        item['data_attachment_ids'] = item.fileList
        work_contents.push(item)
      })
    }
    else {
      workTableData[key].forEach(item => {
        item['data_attachment_ids'] = item.fileList
        work_contents.push(item)
      })
    }


  }
  let data = {
    id: baseData.id,
    "type": baseData.type,
    status: baseData.status,
    region: baseData.region,
    "contract_id": baseData.contractId,
    "customer_id": baseData.customerId,
    // "product_service_id": baseData.product,
    "desc": baseData.desc,
    "customer_name": baseData.brand,
    "contract_user_name": baseData.storeName,
    "contract_user_address": baseData.address,
    "contract_user_contact_name": baseData.contactName,
    "contract_user_phone_number": baseData.contactPhone,
    work_contents: work_contents
  }
  if (work_content_flag == 1) {
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        modalShow.addOrEdit = false
        queryPage()
        selectedRowKey.value = []
        message.success("成功")
      }
    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请检查数据")
    })
  }
  else {
    message.error("请填写完整信息（工作内容）")
  }



  // }


};

//工作内容数据
const workTableData = reactive({
  internet: [],
  installWork: [],
  sourceExplorate: [],
  faultOrder: [],
  testReport: []
})

//生成工作包
const createWorkPackage = () => {
  if (baseData.type == '报修') {
    // if (workTableData.faultOrder.length == 0) {
    workTableData.faultOrder.push({
      //基础信息
      type: '故障维修',
      desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区

      //工单字段
      fault_desc: "",
      process_result: "",

      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    treeChange(workerRegion.value, null, null, workTableData.faultOrder.length - 1, 'faultOrder')
    // }
    // else {
    //   message.error('已存在故障维修工作内容，无法生成')
    // }

  }
  else if (baseData.type == '新装') {
    //宽带接入
    workTableData.internet.push({
      type: '宽带接入',
      status: '未开始',
      work_content_sn: "",
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      // worker_name: [],
      worker_ids: [],
      service_provider: '',
      signing_company: '',
      service_product: '',
      payment_type: null,
      duration: '',
      payment_amount: '',
      begin_date: '',
      end_date: '',
      begin_date: '',
      end_date: '',
      ad_number: '',
      password: '',
      kd_number: '',
      sn: '',
      provider_customer_manager_name: '',
      provider_customer_manager_phone: "",
      installer_name: '',
      installer_phone: '',
      maintenance_name: '',
      maintenance_phone: '',
      desc: '',
      //附件  
      data_attachment_ids: [],
      e_signature_ids: [],
      // id: Math.round(Date.now() / 1000),
    });
    treeChange(workerRegion.value, null, null, workTableData.internet.length - 1, 'internet')

    //网关设备安装
    workTableData.installWork.push({
      type: '网关设备安装',
      status: '未开始',
      // attachment: "",
      fileList: [],
      // worker_name: [],
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      worker_ids: [],
      outbound_time: "",
      receipt_time: "",
      plan_install_time: "",
      arrival_time: "",
      complete_time: "",
      devices: [],
      desc: '',
      //附件
      express_number: "",//快递单号
      transport_method: "",//运输方式
      express_receiver: "", //快递收件人
      express_received_time: "", //快递签收时间
      data_attachment_ids: [],
      e_signature_ids: [],

      // id: Math.round(Date.now() / 1000),
    });
    treeChange(workerRegion.value, null, null, workTableData.installWork.length - 1, 'installWork')

    //资源勘查
    workTableData.sourceExplorate.push({
      type: '资源勘查',
      desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      power: false,
      space: false,
      need_line: false,
      // worker_name: [],
      worker_ids: [],
      weak_current_well: false,
      pmc_contact_name: "",
      pmc_contact_phone: "",
      is_monopolized: false,
      monopolized_prices: "",
      //电信
      provider_resources_name_telegraphy: "",
      provider_resources_available_telegraphy: false,
      provider_resources_rssi_telegraphy: "",

      //联通
      provider_resources_name_link: "",
      provider_resources_available_link: false,
      provider_resources_rssi_link: "",

      //移动
      provider_resources_name_move: "",
      provider_resources_available_move: false,
      provider_resources_rssi_move: "",
      // id: Math.round(Date.now() / 1000),

      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    treeChange(workerRegion.value, null, null, workTableData.sourceExplorate.length - 1, 'sourceExplorate')

    //测试报告
    workTableData.testReport.push({
      //基础信息
      type: '测试报告',
      // desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区

      //工单字段
      line_policy: "",
      line_access_mode: "",
      report_file: '',
      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    treeChange(workerRegion.value, null, null, workTableData.testReport.length - 1, 'testReport')
  }
}

//添加工作
const addWork = () => {
  console.log(baseData.workType)
  let addFlag = true; //判断是否存在工作内容单，工作单与工作内容一对一
  for (let key in workTableData) {
    if (workTableData[key].length != 0) {
      addFlag = false
      break
    }
  }
  // if (addFlag == true) {
  if (baseData.workType == "宽带接入") {

    workTableData.internet.push({
      type: '宽带接入',
      status: '未开始',
      work_content_sn: "",
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      // worker_name: '',
      worker_ids: [],
      service_provider: '',
      signing_company: '',
      service_product: '',
      payment_type: null,
      duration: '',
      payment_amount: '',
      begin_date: '',
      end_date: '',
      begin_date: '',
      end_date: '',
      ad_number: '',
      password: '',
      kd_number: '',
      sn: '',
      provider_customer_manager_name: '',
      provider_customer_manager_phone: "",
      installer_name: '',
      installer_phone: '',
      maintenance_name: '',
      maintenance_phone: '',
      desc: '',
      //附件  
      data_attachment_ids: [],
      e_signature_ids: [],
      // id: Math.round(Date.now() / 1000),
    });
    const index = workTableData.internet.length - 1
    treeChange(workerRegion.value, null, null, index, 'internet')
  }
  else if (baseData.workType == "网关设备安装") {

    workTableData.installWork.push({
      type: '网关设备安装',
      status: '未开始',
      // attachment: "",
      fileList: [],
      // worker_name: '',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      worker_ids: [],
      outbound_time: "",
      receipt_time: "",
      plan_install_time: "",
      arrival_time: "",
      complete_time: "",
      devices: [],
      desc: '',
      //附件
      express_number: "",//快递单号
      transport_method: "",//运输方式
      express_receiver: "", //快递收件人
      express_received_time: "", //快递签收时间
      data_attachment_ids: [],
      e_signature_ids: [],

      // id: Math.round(Date.now() / 1000),
    });
    const index = workTableData.installWork.length - 1
    treeChange(workerRegion.value, null, null, index, 'installWork')
  }
  else if (baseData.workType == "资源勘查") {
    workTableData.sourceExplorate.push({
      type: '资源勘查',
      desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区
      power: false,
      space: false,
      need_line: false,
      // worker_name: '',
      worker_ids: [],
      weak_current_well: false,
      pmc_contact_name: "",
      pmc_contact_phone: "",
      is_monopolized: false,
      monopolized_prices: "",
      //电信
      provider_resources_name_telegraphy: "",
      provider_resources_available_telegraphy: false,
      provider_resources_rssi_telegraphy: "",

      //联通
      provider_resources_name_link: "",
      provider_resources_available_link: false,
      provider_resources_rssi_link: "",

      //移动
      provider_resources_name_move: "",
      provider_resources_available_move: false,
      provider_resources_rssi_move: "",
      // id: Math.round(Date.now() / 1000),

      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    const index = workTableData.sourceExplorate.length - 1
    treeChange(workerRegion.value, null, null, index, 'sourceExplorate')
  }

  else if (baseData.workType == "故障维修") {
    workTableData.faultOrder.push({
      //基础信息
      type: '故障维修',
      desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区

      //工单字段
      fault_desc: "",
      process_result: "",

      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    const index = workTableData.faultOrder.length - 1
    treeChange(workerRegion.value, null, null, index, 'faultOrder')
  }

  else if (baseData.workType == "测试报告") {
    workTableData.testReport.push({
      //基础信息
      type: '测试报告',
      // desc: "",
      status: '未开始',
      workerSelector: options.worker,  //装维人员选择器
      workerRegion: workerRegion.value, //装维人员地区

      //工单字段
      line_policy: "",
      line_access_mode: "",
      report_file: '',
      //附件
      data_attachment_ids: [],
      e_signature_ids: [],
    });
    const index = workTableData.testReport.length - 1
    treeChange(workerRegion.value, null, null, index, 'testReport')
  }


  if (['宽带接入', '网关设备安装', '资源勘查', '故障维修', '测试报告'].includes(baseData.workType)) {
    message.success(`添加${baseData.workType}成功`)
  }
  // }
  // else {
  //   message.error("只能存在一个工作单")
  // }


}


//移除工作
const removeWork = (type, item) => {
  console.log("123123", item, type, workTableData)
  const index = workTableData[type].indexOf(item);
  console.log(index)
  if (index !== -1) {
    console.log("移除")
    workTableData[type].splice(index, 1);
  }
}

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})
//table请求
const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {


      const buttonsShow = []
      for (let key in item.buttons) {
        if (item.buttons[key] == 1) {
          buttonsShow.push(key)
        }
      }

      let assign = false
      let examine = false
      item.work_contents.forEach(item1 => {
        const worker_ids = []
        const worker_name = []
        // item1.workers.forEach((item2) => {
        //   worker_ids.push(item2.id)
        //   worker_name.push(item2.real_name)
        // })
        item1["worker_ids"] = worker_ids
        item1["worker_name"] = worker_name
        if (worker_name.length == 0) {
          assign = true
        }
        console.log('item1.status', item1.status, item1)
        if (item1.status == '审核中') {
          examine = true
        }
      })

      data.push({
        id: item.id,
        key: item.id,
        work_sn: item.work_sn,
        type: item.type,
        status: item.status,
        brand: item.customer_name,
        storeName: item.contract_user_name,
        region: item.region,
        buttons: buttonsShow,
        regionPath: item.region_path,
        reject_reason: item.reject_reason,
        address: item.contract_user_address,
        complete_address: item.region + item.contract_user_address,
        contactName: item.contract_user_contact_name,
        contactPhone: item.contract_user_phone_number,
        work_contents: item.work_contents,
        customer_id: item.customer_id,
        contract_id: item.contract_id,
        devices: item.devices,
        desc: item.desc,
        flow_steps: item.flow_steps,
        product_service_id: item.product_service_id,
        data_attachments: item.data_attachments,
        e_signatures: item.e_signatures,
        last_modified: item.last_modified,
        create_time: item.create_time,
        assign: assign,//是否存在未分配的工作内容单
        examine: examine,
        workflow: item.workflow,

      })
    });
    tableSource.data = data;
    console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}



const handleChangeSelect = (dataIndex, value) => {
  console.log(dataIndex, value)
};

const contractData = {}

//获取合同信息
const contractPage = () => {
  serviceAxios({
    url: url.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    result.data.data.list.forEach(item => {
      if (contractData[item.customer.id] != undefined) {

        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
          region: item.region,
          fullPathIds: item.region.full_path_ids,
        })
      }
      else {

        contractData[item.customer.id] = {}
        contractData[item.customer.id].children = []
        contractData[item.customer.id].brand = item.customer.name
        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
          region: item.region,
        })
      }
    })

    let opContract = []
    for (let key in contractData) {
      opContract.push({
        label: contractData[key].brand,
        value: key,
        children: contractData[key].children
      }
      )
    }

    options.contract = opContract
    watchContract.value = opContract
    checkFlage.value = true
    console.log("contractData", contractData)
  }).catch((err) => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  })
}





let workerRegion = ref()
//合同
const contractChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  if (selectedOptions != undefined) {
    console.log(value, selectedOptions)
    workerRegion.value = selectedOptions[1].region.full_path_ids[selectedOptions[1].region.full_path_ids.length - 1]

    for (let key in workTableData) {
      workTableData[key].forEach((item, index) => {
        if (item.type == "宽带接入") {
          workTableData.internet[index].workerRegion = workerRegion.value
          treeChange(workerRegion.value, null, null, index, 'internet')
        }

        else if (item.type == "网关设备安装") {
          workTableData.installWork[index].workerRegion = workerRegion.value
          treeChange(workerRegion.value, null, null, index, 'installWork')
        }
        else if ((item.type == "资源勘查")) {
          workTableData.sourceExplorate[index].workerRegion = workerRegion.value
          treeChange(workerRegion.value, null, null, index, 'sourceExplorate')
        }
        else if ((item.type == "故障维修")) {
          workTableData.faultOrder[index].workerRegion = workerRegion.value
          treeChange(workerRegion.value, null, null, index, 'faultOrder')
        }
        else if ((item.type == "测试报告")) {
          workTableData.testReport[index].workerRegion = workerRegion.value
          treeChange(workerRegion.value, null, null, index, 'testReport')
        }
      })
    }

    baseData.contractId = selectedOptions[1].contract_id
    baseData.customerId = selectedOptions[1].customer_id
    baseData.brand = selectedOptions[1].brand
    baseData.storeName = selectedOptions[1].storeName
    baseData.contactName = selectedOptions[1].contactName
    baseData.contactPhone = selectedOptions[1].contactPhone
    baseData.region = selectedOptions[1].region.friendly_name
    baseData.address = selectedOptions[1].address
    // tableSource.address = selectedOptions[1].region.friendly_name
    tableSource.address = ''
    let product = []
    selectedOptions[1].productServices.forEach(
      item => {
        // product.push({
        //   value: item.id,
        //   label: item.product.name
        // })
        product.push(item.product.name)
      }
    )
    baseData.product = product.join("、")
    // options.product = product
  }


  console.log("baseData", baseData)


}
const onOpenChange = (type, time, dataIndex, index) => {

  console.log("onOpenChange.status", time, dataIndex, index);

  if (time != null) {
    workTableData[type][index][dataIndex] = time
    console.log("workTableData[type][index][newStr]", dataIndex, workTableData[type][index][dataIndex]);
  }
  else {
    workTableData[type][index][dataIndex] = ""
  }


};

let detailMessage = reactive({})

const showUserMessage = (record) => {

  baseOrFlowMessageActiveKey.value = '1'
  modalShow.detailMessage = true;

  for (let key in record) {
    detailMessage[key] = record[key]
  };
  detailMessage.work_contents.forEach(item => {
    item.worker_name = item.worker_name.length != 0 ? item.worker_name.join() : ''
    item.fileIds = item.data_attachments.map(item => ({
      file_id: item.id,
      owner_class: item.owner_class,
      owner_id: item.owner_id,
    }))
  });

  console.log('detailMessage', detailMessage)


}


const showAddOrEdit = () => {
  modalShow.addOrEdit = true
  urlFlag = url.add
  console.log("showAddOrEdit")
}


const checkFlage = ref(false)
//根据url判断是否需要打开添加工单模态框
const checkUrlOpenModel = () => {
  if (route.query.brand != undefined) {
    modalShow.addOrEdit = true;
    urlFlag = url.add
    baseData.contact_message = [String(route.query.brand), Number(route.query.storeId)]

    console.log("baseData.contact_message", baseData.contact_message)
    console.log("options.contract", options.contract)
    let product = []
    options.contract.forEach(item => {
      if (item.value == Number(route.query.brand)) {
        baseData.brand = item.label


        item.children.forEach(item1 => {
          if (item1.value == Number(route.query.storeId)) {
            baseData.contractId = item1.contract_id,
              baseData.customerId = item1.customer_id,
              baseData.storeName = item1.label
            baseData.address = item1.address
            baseData.contactName = item1.contactName
            baseData.contactPhone = item1.contactPhone
            baseData.region = item1.region.friendly_name
            tableSource.address = item1.region.friendly_name
            item1.productServices.forEach(item2 => {
              product.push({
                value: item2.id,
                label: item2.product.name
              })
            })
          }
        })
      }
    })
    options.product = product
    baseData.type = '开通'
    checkFlage.value = false
  }

}
const watchContract = ref()
// checkUrlOpenModel()
watch(watchContract, () => {
  console.log("options.contract", options.contract);
  checkUrlOpenModel()
});



const workHandlChange = (value, option, type, index) => {
  console.log(value, option, type, index)
  console.log(workTableData)
  workTableData[type][index]['worker_ids'] = [value]
}


const getOutWorer = () => {
  serviceAxios({
    url: interfaces.outWorkerPage,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.real_name,
        value: item.id
      })
    })
    options.worker = data
  }).catch()
}
// getOutWorer()

const getDeviceSelector = () => {
  serviceAxios({
    url: interfaces.deviceSelector,
    method: "get",
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    let data = []
    result.data.data.forEach(item => {
      data.push({
        label: item.name,
        value: item.id
      })
    })
    options.device_selector = data
  })
}



const addDevice = (index) => {
  workTableData.installWork[index].devices.push({
    quantity: "",
    device_id: null,
  })

};

const removeUser = (index1, item) => {
  const index = workTableData.installWork[index1].devices.indexOf(item);
  if (index !== -1) {
    workTableData.installWork[index1].devices.splice(index, 1);
  }
};



const fileHandleChange = (info) => {
  console.log(info)
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    message.success(`${info.file.name} file uploaded successfully`);
  } else if (info.file.status === 'error') {
    message.error(`${info.file.name} file upload failed.`);
  }
}


//刷新
const refresh = () => {
  queryPage()
}

//获取区域树
const getRegionTree = async () => {
  const tree = await checkTree()
  options.region = tree[0]["children"]
}

//region区域变化事件
const regionOnChange = (value, selectedOptions) => {
  console.log("selectedOptions", selectedOptions);
  if (selectedOptions != undefined) {
    let region = ''
    selectedOptions.forEach(item => {
      region += item.label
    })
  }
  else {
  }
  console.log("value", value);
  if (value != undefined) {
    baseData.region = value;
    let address = "";
    selectedOptions.forEach((item) => {
      address += item.label + "/";
    });
    tableSource.address = address;
  }
};

const handleChangeSearch = (value) => {
  console.log(value)

}
const sift = () => {

  pagination.current = 1
  pagination.pageSize = 10
  for (let key in searchRef.value) {
    queryField[key] = searchRef.value[key]
  }
  console.log('queryField', queryField)
  queryPage()
}
const resetSift = () => {
  for (let key in searchRef.value) {
    searchRef.value[key] = null
  }
  pagination.current = 1
  pagination.pageSize = 10
  queryField = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  }
  queryPage()
}

//选择框搜索事件
const filterOption = (input, option) => {

  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

//装维人员区域树change事件

const treeChange = (value, label, extra, index, type) => {
  // console.log(value, index, type, workTableData)
  serviceAxios({
    url: interfaces.outWorkerPage,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999,
      region_id: value
    }
  }).then(result => {
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        label: item.real_name,
        value: item.id
      })
    })
    if (type != 'assign') {
      workTableData[type][index]['workerSelector'] = data
    }
    else {
      options.workerSelector = data
    }

  }

  ).catch(error => {
    console.log(error)
    message.error(error)
  })
}





//预处理 
const pretreatment = (record) => {
  modalShow.checkOrder = true //显示模态框
  checkOrderFields.show = false
  pretreatmentRef.value.show = true
  for (let key in record) {
    detailMessage[key] = record[key]
  }
  pretreatmentRef.value.customer_service_result = ''
}



//审核申请工单
const checkOrder = (record) => {
  modalShow.checkOrder = true
  console.log("selectedRowKey", selectedRowKey.value)
  console.log(record)
  checkOrderFields.reject_reason = ''
  checkOrderFields.show = true
  pretreatmentRef.value.show = false

  for (let key in record) {
    detailMessage[key] = record[key]
  }

  checkOrderFields.status = record.status == '已拒绝' ? "false" : "true"
  checkOrderFields.reject_reason = record.reject_reason

}
//审核申请工单提交操作和预处理提交操作
const checkOrderHandleOk = () => {
  //审核申请工单
  if (checkOrderFields.show == true) {
    const data = {
      work_order_id: detailMessage.id,
      status: checkOrderFields.status == 'true' ? 1 : 2,
      desc: checkOrderFields.desc,
      reject_reason: checkOrderFields.reject_reason,
    }
    data.status === 1 ? delete data.reject_reason : delete data.desc;


    if (data.status == '' || (data.status == 2 && data.reject_reason == '')) {
      message.error('请检查数据')
    }
    else {
      serviceAxios({
        url: interfaces.checkOrder,
        method: 'post',
        data: data,
      }).then(
        result => {
          message.success('处理成功')
          queryPage()
          modalShow.checkOrder = false
        }
      ).catch(
        error => {
          console.log(error)
        }
      )
    }
  }

  //预处理
  else {
    const data = {
      id: detailMessage.id,
      success: pretreatmentRef.value.status == "true" ? 1 : 0,
      customer_service_result: pretreatmentRef.value.customer_service_result
    }
    let checkFlag = true
    for (let key in data) {
      if (data[key] === '') {
        checkFlag = false
      }
    }
    checkFlag == true ?
      serviceAxios({
        url: url.workOrderPretreatment,
        method: 'post',
        data: data
      }).then(result => {
        modalShow.checkOrder = false
        queryPage()
        message.success('处理成功')
      }).catch(error => {
        console.log(error)
      }) : message.error('请检查数据')
  }

}

//审核工作内容单
const checkWorkContentOrder = async () => {
  const response = await serviceAxios({
    url: '/v1/work_order/outworker/work_content/submit/review',
    method: 'post',
    data: {
      work_content_id: [
        0
      ],
      status: 0,
      desc: "",
      reject_reason: ""
    }
  })
}
//定义待分配工单
let assignOrderList = []
//定义分配运维字段
const assignMaintenanceRef = ref({
  id: '',
  accountId: ''
})

//分配运维
const assignMaintenance = (record) => {
  //筛选未分配运维的工单

  modalShow.assignMaintenance = true
  assignMaintenanceRef.value.id = record.id
  assignMaintenanceRef.value.accountId = ''

}
const assignMaintenanceHandleOk = async () => {
  const data = {
    id: assignMaintenanceRef.value.id,
    account_id: assignMaintenanceRef.value.accountId
  }

  if (data.id === '' || data.account_id === '') {
    message.error('请检查数据')
  }
  else {
    const response = await serviceAxios({
      url: interfaces.assignMaintenance,
      method: 'post',
      data: data
    })

    if (response.data.message == 'success') {
      modalShow.assignMaintenance = false
      message.success('分配成功')
      queryPage()
    }
    else {
      message.error(response.data.message)
    }
  }
}

//分配网工
const assignNetworkEngineer = (record) => {
  modalShow.assignNetworkEngineer = true
  assignNetworkEngineerRef.value.id = record.id
  assignNetworkEngineerRef.value.accountId = []
}

const assignNetworkEngineerRef = ref({
  id: "",
  accountId: ''
})

const assignNetworkEngineerHandleOk = async () => {
  const data = {
    id: assignNetworkEngineerRef.value.id,
    account_id: assignNetworkEngineerRef.value.accountId
  }


  if (data.id === "" || data.account_id == '') {
    message.error('请检查数据')
  }
  else {
    const response = await serviceAxios({
      url: interfaces.assignNetworkEngineer,
      method: 'post',
      data: data
    })

    if (response.data.message == 'success') {
      modalShow.assignNetworkEngineer = false
      message.success('分配成功')
      queryPage()
    }
    else {
      message.error(response.data.message)
    }
  }
}




//定义工作内容单审核字段
const examineWorkContentRef = ref({
  status: '',
  desc: '',
  reject_reason: '',
  workContentId: '',
  workId: '',
  type: '',
  index: '',
})
//工作内容单审核
const examineWorkContent = (workContentId, type, index) => {
  console.log('id', workContentId)
  modalShow.examineWorkContent = true

  examineWorkContentRef.value.desc = ''
  examineWorkContentRef.value.reject_reason = ''

  examineWorkContentRef.value.workContentId = workContentId
  examineWorkContentRef.value.type = type
  examineWorkContentRef.value.index = index
}


const examineWorkContentrHandleOk = async () => {
  const response = await serviceAxios({
    url: interfaces.workContentSubmitReview,
    method: 'post',
    data: {
      work_content_id: [examineWorkContentRef.value.workContentId],
      status: examineWorkContentRef.value.status == 'true' ? 1 : 2,
      desc: examineWorkContentRef.value.desc,
      reject_reason: examineWorkContentRef.value.reject_reason
    }
  })


  const workStatusResponse = await serviceAxios({
    url: interfaces.workGetById,
    method: 'get',
    params: {
      work_order_id: examineWorkContentRef.value.workId
    }
  })

  const WorkContentStatusResponse = await serviceAxios({
    url: interfaces.workContentGetById,
    method: 'post',
    params: {
      id: examineWorkContentRef.value.workContentId
    }
  })

  baseData.status = workStatusResponse.data.data.status
  workTableData[examineWorkContentRef.value.type][examineWorkContentRef.value.index].status = WorkContentStatusResponse.data.data.status


  if (response.data.message == 'success') {
    modalShow.examineWorkContent = false

  }
  else {
    message.error(response.data.message)
  }

}

//上传组件文件变化事件
const fileChange = (event, type, index, fileType) => {
  const fileList = []
  event.forEach(item => {
    fileList.push(item.isOld == true ? item.uid : item.response.data[0].id)
  })

  workTableData[type][index].fileList = fileList

}

//工单类型change
const workTypeHandleChange = async (value) => {
  options.workOrderStatus = await selector.workOrderStatus(value);
}

//待处理中的工单
const waitDealOrder = () => {
  queryField['show_mode'] = 1
  queryPage()
}



//获取选择器数据
(async () => {
  try {
    getDeviceSelector()
    getRegionTree()
    queryPage()//获取页面数据
    contractPage() //获取合同数据
    //选择器数据
    options.service_provider = await selector.networkProvider();
    options.workOrderType = await selector.workOrderType();
    options.type = options.workOrderType;
    options.workType = await selector.workOrderContentType();
    options.networkEngineer = await selector.networkEngineer()
    options.businessCluster = await selector.businessCluster()
  } catch (error) {
    // 处理错误情况
    console.error('Error fetching data:', error);

  }
})();



//定义新装接口字段
let newInstallRef = ref({
  //预处理
  id: 0,
  success: 1,
  customer_service_result: "",
  plan_install_time: "",
  reject_reason: "",
  need_resource: "",

  //资源勘察
  work_order_id: '',
  // success: 0,
  // reject_reason: "",
  business_cluster_id: null,
  desc: "",

  //调度网工
  // id:"",
  account_id: null,


  //备货
  // id: 0,
  // success: 0,
  // reject_reason: "string",
  transport_method: "",
  express_number: "",
  express_receiver: "",
  express_receiver_phone: "",
  outbound_time: ""
})


//新装工单

//预处理
const preprocessClick = (id) => {
  newInstallRef.value.id = id
  newInstallModal.preProcess = true
  newInstallRef.value.success = 'true'
}
const newInstallPreprocess = () => {
  let data = newInstallRef.value
  if (data.success != 'true' && data.reject_reason == '') {
    message.error("请输入拒绝原因")
    return
  }
  serviceAxios({
    url: newInstallInterface.preProcess,
    method: 'post',
    data: {
      id: data.id,
      success: data.success == 'true' ? 1 : 0,
      customer_service_result: data.customer_service_result,
      plan_install_time: data.plan_install_time == '' ? null : data.plan_install_time,
      reject_reason: data.reject_reason,
      need_resource: data.need_resource,
    }
  }).then(result => {
    console.log(result.data.message)
    if (result.data.message == 'success') {
      message.success('成功')
      newInstallModal.preProcess = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }
  })
}


//资源勘察
const resourceClick = (id) => {
  newInstallRef.value.id = id
  newInstallModal.resourceExploration = true
  newInstallRef.value.success = 'true'
}
const newInstallResource = () => {
  let data = newInstallRef.value
  if (data.success != 'true' && data.reject_reason == '') {
    message.error("请输入拒绝原因")
    return
  }

  if (data.business_cluster_id == '' || data.business_cluster_id == null) {
    message.error("请选择商圈")
    return
  }
  serviceAxios({
    url: newInstallInterface.resourceExploration,
    method: 'post',
    data: {
      work_order_id: data.id,
      success: data.success == 'true' ? 1 : 0,
      reject_reason: data.reject_reason,
      business_cluster_id: data.business_cluster_id,
      desc: data.desc
    }
  }).then(result => {
    console.log(result.data.message)
    if (result.data.message == 'success') {
      message.success('成功')
      newInstallModal.resourceExploration = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }
  })
}

//调度网工
const networkEngineerClick = (id) => {
  newInstallRef.value.id = id
  newInstallModal.networkEngineer = true
}

const newInstallNetworkEngineer = () => {
  let data = newInstallRef.value


  if (data.account_id == '' || data.account_id == null) {
    message.error("请选择人员")
    return
  }
  serviceAxios({
    url: newInstallInterface.networkEngineer,
    method: 'post',
    data: {
      id: data.id,
      account_id: data.account_id
    }
  }).then(result => {
    console.log(result.data.message)
    if (result.data.message == 'success') {
      message.success('成功')
      newInstallModal.networkEngineer = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }
  })
}


//备货
const readyDeviceClick = (id) => {
  newInstallRef.value.id = id
  newInstallRef.value.success = 'true'
  newInstallRef.value.transport_method = '工程师携带'
  newInstallModal.readyDevice = true
}

const newInstallReadyDevice = () => {
  let data = newInstallRef.value


  if (data.success != 'true' && data.reject_reason == '') {
    message.error("请输入拒绝原因")
    return
  }
  serviceAxios({
    url: newInstallInterface.readyDevice,
    method: 'post',
    data: {
      id: data.id,
      success: data.success == 'true' ? 1 : 0,
      reject_reason: data.reject_reason,
      transport_method: data.transport_method,
      express_number: data.express_number,
      express_receiver: data.express_receiver,
      express_receiver_phone: data.express_receiver_phone,
      outbound_time: data.outbound_time
    }
  }).then(result => {
    console.log(result.data.message)
    if (result.data.message == 'success') {
      message.success('成功')
      newInstallModal.networkEngineer = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }
  })
}



//分配运维
const newinstallDispatachClick = (id) => {
  newInstallRef.value.id = id
  newInstallModal.dispatch = true
}

const newinstallDispatach = () => {
  let data = newInstallRef.value



  serviceAxios({
    url: newInstallInterface.dispatch,
    method: 'post',
    data: {
      id: data.id,
      account_id: data.account_id,

    }
  }).then(result => {
    console.log(result.data.message)
    if (result.data.message == 'success') {
      message.success('成功')
      newInstallModal.dispatch = false
      queryPage()
    }
    else {
      message.error(result.data.message)
    }
  })
}

</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  // justify-content: space-between; /* 子元素之间的空间平均分布 */
}
.left-button {
  display: flex;
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin: 5px 5px 5px 0px;
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.separator-line {
  border-bottom: 1px solid rgb(148, 146, 146);
  margin: 20px 0;
}
.provider_resources_box {
  width: 400px;
  height: 150px;
  border-radius: 3px;
  border-style: groove;
  // background-color: rgb(217, 243, 217);
}

.commonTabel {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.commonTabel tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.commonTabel tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 12%;
}

.commonTabel tr td.sourceColumn {
  background-color: #eff3f6;
  color: #393c3e;
  width: 11%;
}

.showTabel {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.showTabel tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.showTabel tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 20%;
  white-space: nowrap; /* 禁止换行 */
}

.td-input {
  padding-left: 0px;
}

.td-select {
  width: auto;
  margin-left: -11px;
}
.search-box {
  display: flex;
  padding: 10px 0px 10px 0px;
  .search-input-box {
    margin-right: auto;
  }
  .input-item {
    margin-left: 5px;
  }
  .button-item {
    margin: 0px 5px 0px 0px;
  }
}

/* 自定义 addonBefore 的背景色 */
::v-deep(.ant-input-group-addon) {
  background-color: #eff3f6; /* 更改背景色 */
}

.order-box {
  margin-top: 10px;
}

.red-flag {
  color: red;
}
</style>