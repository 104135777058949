<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- <div>Use G6 in Vue</div> -->
  <a-modal :maskClosable="false" width="400px" v-model:open="modalShow" title="节点信息" ok-text="确认" cancel-text="取消"
    @ok="handleOk">
    <a-form :ref="deviceRef" :model="devices" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="设备类型" :name='deviceType' :rules="[
    {
      required: true,
      message: '请输入设备类型',
    },
  ]">
        <a-select v-model:value="devices.deviceType" show-search placeholder="请选择类型" style="width: 200px"
          :options="options.deviceType" :filter-option="filterOption"></a-select>
      </a-form-item>
      <a-form-item label="标签" :name='label' :rules="[
    {
      required: true,
      message: '请输入标签',
    },
  ]">
        <a-input style="width:200px" v-model:value="devices.label" placeholder="请输入标签" />
      </a-form-item>

      <a-form-item label="图形尺寸" :name='size' :rules="[
    {
      required: false,
      message: '请输入标签',
    },
  ]">
        <a-input style="width:200px" v-model:value="devices.size" placeholder="请输入尺寸（长度*宽度）" />
      </a-form-item>
    </a-form>

  </a-modal>
  <div>
    <div>
      <table class="commonTable">
        <tr>

          <td class="colunm">门店名称<span style="color: red;margin-left: 0px;">*</span></td>
          <td> <a-cascader :bordered="false" style="width:auto" v-model:value="baseData.contact"
              :options="options.contract" placeholder="请选择门店" @change='onContractChange' /></td>
          <td>主机地址</td>
          <td> <a-select :bordered="false" :showArrow="false" v-model:value="baseData.zabbix_host" show-search
              :placeholder="`请选择主机地址`" style="width: 200px" :options="options.zabbix_host" :filter-option="filterOption"
              @change="hostChange"></a-select></td>
          <td>名称</td>
          <td><a-input :bordered="false" v-model:value="baseData.name" :placeholder="`请输入拓扑图名称`" /></td>
          <td>操作</td>
          <td> <a @click="relate" style="margin:2px;color: #1884ea">关联</a>
            <a @click="un_relate" style="margin:2px;color: #1884ea">解除关联</a>
            <a @click="saveGplot" style="margin:2px;color: #1884ea">保存拓扑图</a>
          </td>
        </tr>

      </table>

    </div>
    <div style="width: 100%; height: 100%;" id="container"></div>

  </div>

</template>

<script setup>
import { onMounted, nextTick, reactive, ref } from "vue";
import G6 from "@antv/g6";
import { message } from "ant-design-vue";
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import svg from "@/utils/svgData";
import interfaces from "@/config";
const deviceRef = ref(null) //添加设备表单
const modalShow = ref(null)
const baseData = reactive({
  zabbix_host: null,
  contact: null,
  name: null,
  size: []
})
let graphInstance = ''
const otherData = reactive({
  host_name: ""
})
let devices = reactive({
  id: "",
  deviceType: "",
  type: "",
  size: "",
  label: "",
})
const options = reactive({
  zabbix_host: [],
  contract: '',
  deviceType: [{ label: '路由器', value: 'router' },
  { label: '交换机', value: 'exchange' },
  { label: '摄像头', value: 'camera' },
  { label: '打印机', value: 'printer' },
  { label: '主机', value: 'host' }],

})

let addedCount = 0;
// Register a custom behavior: add a node when user click the blank part of canvas
G6.registerBehavior('click-add-node', {

  // Set the events and the corresponding responsing function for this behavior
  getEvents () {
    // The event is canvas:click, the responsing function is onClick
    return {
      'canvas:click': 'onClick',
    };
  },
  // Click event
  onClick (ev) {
    console.log('增加节点')

    // Add a new node
    const newNodeData = {
      x: ev.canvasX,
      y: ev.canvasY,
      deviceType: '',
      id: `node-${addedCount}`, // Generate the unique id
      label: '未定义节点',
      // img: img
    }
    graphInstance.addItem('node', newNodeData);

    data.nodes.push(newNodeData);

    addedCount++;


  },
});

const defaultLayout = {
  type: 'compactBox',
  direction: 'TB',
  getId: function getId (d) {
    return d.id;
  },
  getHeight: function getHeight () {
    return 16;
  },
  getWidth: function getWidth () {
    return 16;
  },
  getVGap: function getVGap () {
    return 40;
  },
  getHGap: function getHGap () {
    return 70;
  },
};
// Register a custom behavior: click two end nodes to add an edge
G6.registerBehavior('click-add-edge', {
  // Set the events and the corresponding responsing function for this behavior
  getEvents () {
    return {
      'node:click': 'onClick', // The event is canvas:click, the responsing function is onClick
      mousemove: 'onMousemove', // The event is mousemove, the responsing function is onMousemove
      'edge:click': 'onEdgeClick', // The event is edge:click, the responsing function is onEdgeClick
    };
  },
  // The responsing function for node:click defined in getEvents
  onClick (ev) {
    const self = this;
    const node = ev.item;
    const graph = self.graph;
    // The position where the mouse clicks
    const point = { x: ev.x, y: ev.y };
    const model = node.getModel();
    if (self.addingEdge && self.edge) {
      graph.updateItem(self.edge, {
        target: model.id,
      });

      self.edge = null;
      self.addingEdge = false;
    } else {
      // Add anew edge, the end node is the current node user clicks
      self.edge = graph.addItem('edge', {
        source: model.id,
        target: model.id,
      });
      self.addingEdge = true;
    }
  },
  // The responsing function for mousemove defined in getEvents
  onMousemove (ev) {
    const self = this;
    // The current position the mouse clicks
    const point = { x: ev.x, y: ev.y };
    if (self.addingEdge && self.edge) {
      // Update the end node to the current node the mouse clicks
      self.graph.updateItem(self.edge, {
        target: point,
      });
    }
  },
  // The responsing function for edge:click defined in getEvents
  onEdgeClick (ev) {
    const self = this;
    const currentEdge = ev.item;
    if (self.addingEdge && self.edge === currentEdge) {
      self.graph.removeItem(self.edge);
      self.edge = null;
      self.addingEdge = false;
    }
  },
});
G6.registerBehavior('click-node', {
  getEvents () {
    return {
      'node:click': 'doubleClick',
    };
  },
  doubleClick (ev) {
    // console.log(self, graph)
    const node = ev.item; // 从事件中获取边
    console.log("双击节点", node);
    showNodeInfoInput(node)
  },
});
G6.registerBehavior('contextmenu-remove-node', {
  getEvents () {
    return {
      'node:click': 'onClick', // 修改为监听节点右键点击
    };
  },
  onClick (ev) {
    const node = ev.item; // 直接从事件中获取节点
    console.log("移除节点", node);
    graphInstance.removeItem(node); // 通过this.graph移除边
    //移除边
    // const edgeIdRemove = []
    // node._cfg.edges.forEach(item => {
    //   edgeIdRemove.push(item.id)
    // })
    // data = data.edges.filter(item => !edgeIdRemove.includes(item.id));
    // //移除节点
    // const nodeIdToRemove = node._cfg.model.id;
    // data = data.nodes.filter(item => item.id !== nodeIdToRemove);
    // graphInstance.data(data);
    // graphInstance.render();

    // graphInstance.removeItem(node); // 通过this.graph访问图实例

  },
});
G6.registerBehavior('contextmenu-remove-edge', {
  getEvents () {
    return {
      'edge:click': 'onEdgeContextMenu',
    };
  },
  onEdgeContextMenu (ev) {
    const edge = ev.item; // 从事件中获取边
    console.log("移除边", edge);
    graphInstance.removeItem(edge); // 通过this.graph移除边
  },
});
onMounted(() => {
  const container = document.getElementById("container");
  // Add a selector to DOM
  const selector = document.createElement('select');
  selector.id = 'selector';
  selector.style.position = 'relative';
  selector.style.top = '0px'; // 调整距离顶部的距离，可根据需要更改
  selector.style.right = '0px'; // 调整距离左侧的距离，可根据需要更改


  const selection1 = document.createElement('option');
  selection1.value = 'default';
  selection1.innerHTML = '默认模式';
  const selection2 = document.createElement('option');
  selection2.value = 'addNode';
  selection2.innerHTML = '添加节点(通过单击画布)';
  const selection3 = document.createElement('option');
  selection3.value = 'addEdge';
  selection3.innerHTML = '添加边缘(通过点击两个端点节点)';

  const selection4 = document.createElement('option');
  selection4.value = 'remove';
  selection4.innerHTML = '删除(点击节点或边)';




  selector.appendChild(selection1);
  selector.appendChild(selection2);
  selector.appendChild(selection3);
  selector.appendChild(selection4);
  container.appendChild(selector);

  selector.addEventListener('change', (e) => {
    const value = e.target.value;
    console.log("模式：", value)
    // change the behavior mode
    graphInstance.setMode(value);
  });

  graphInstance = new G6.Graph({
    linkCenter: true,
    // 设置为true，启用 redo & undo 栈功能
    enabledStack: true,
    // plugins: [toolbar],
    container: document.getElementById("container"),
    width: 1500,
    height: 700,
    modes: {
      // Defualt mode
      default: ['drag-node', 'drag-canvas', 'click-select', 'click-node', 'zoom-canvas'],  // 'zoom-canvas',
      // Adding node mode
      addNode: ['click-add-node', 'click-select'],
      // Adding edge mode
      addEdge: ['click-add-edge', 'click-select'],
      remove: ['contextmenu-remove-node', 'contextmenu-remove-edge', 'click-select'],
    },
    // The node styles in different states
    nodeStateStyles: {
      // The node styles in selected state
      selected: {
        stroke: '#666',
        lineWidth: 2,
        fill: 'steelblue',
      },
    },
    layout: {
      type: 'dagre', // 使用dagre布局实现上下结构
      rankdir: 'TB', // TB代表从上到下布局
    },

    defaultNode: {
      type: 'image',
      size: [40, 40] // 节点大小

      // style: {
      //   fill: 'pink',
      // },
    },
    defaultEdge: {
      type: 'polyline',
    },


  });
  // const graph = graphInstance
  // 使图表适应容器尺寸的函数
  function resizeGraph () {
    try {
      if (graphInstance) {
        const container = document.getElementById('container');
        console.log("宽高:", container.clientWidth, container.clientHeight)
        graphInstance.changeSize(container.clientWidth, container.clientHeight); // 动态设置图表的宽高
        graphInstance.fitView(); // 适应视图，确保所有的节点都在可视范围内
      }
    }
    catch {

    }
  }
  // 添加窗口resize事件监听，当窗口大小改变时重新调整图表尺寸
  window.addEventListener('resize', () => {
    resizeGraph();
  });
  resizeGraph()

  graphInstance.data(data);
  graphInstance.render();
});







G6.registerNode('exchangeNode', {
  draw (cfg, group) {
    const { size = [100, 40], img, ports = [] } = cfg; // 假设ports数组定义了端口位置
    // 添加标签
    const label = group.addShape('text', { // 或使用G6 提供的 Text shape
      attrs: {
        text: cfg.label, // 使用cfg中的label属性作为文本内容
        x: cfg.size[0] / 2, // 文本水平居中
        y: cfg.size[1], // 文本垂直居中
        fill: '#333', // 文字颜色
        fontSize: 12, // 文字大小
        textAlign: 'center', // 水平对齐方式
        textBaseline: 'middle', // 垂直对齐方式
      },
    });


    // 绘制交换机主体（使用提供的图片）
    const node = group.addShape('image', {
      attrs: {
        x: -size[0] / 2,
        y: -size[1] / 2,
        width: size[0],
        height: size[1],
        img,
      },
    });

    // 绘制端口（这里简化为绘制圆形端口）
    ports.forEach((portPos, index) => {
      const portRadius = 5;
      const portColor = index % 2 === 0 ? '#05D57B' : '#58626b'; // 示例颜色交替
      group.addShape('circle', {
        attrs: {
          x: portPos.x,
          y: portPos.y,
          r: portRadius,
          fill: portColor,
          stroke: '#fff',
          lineWidth: 1,
        },
        name: `port-${index}`, // 为端口命名，便于后续交互处理
      });
    });

    return node;
  },
});

// cubic-vertical-running
const lineDash = [10, 10, 5, 10];  //实线段、空隙、实线段、空隙的长度
G6.registerEdge(
  'cubic-vertical-running',
  {
    afterDraw (cfg, group) {
      const shape = group.get('children')[0];
      let offsetIndex = 0; // 用于控制lineDashOffset的步进
      const totalSteps = lineDash.reduce((sum, value) => sum + value, 0); // 计算lineDash总长度，用于确定动画周期

      // 定义动画逻辑，确保每次动画结束时偏移量回到起点，形成连续循环
      shape.animate(
        () => {
          offsetIndex = (offsetIndex + 1) % totalSteps; // 循环增加偏移量，实现连续流动
          return {
            lineDash,
            lineDashOffset: -offsetIndex, // 使用负数偏移实现向前流动的效果
          };
        },
        {
          repeat: true, // 重复执行动画
          duration: 6000, // 延长动画周期以适应更大的间距，使流动更平滑
        },
      );
    },
  },
  'cubic', // 扩展自内置边类型 'cubic'
);

G6.registerEdge(
  'circle-running',
  {
    afterDraw (cfg, group) {
      // get the first shape in the group, it is the edge's path here=
      const shape = group.get('children')[0];
      // the start position of the edge's path
      const startPoint = shape.getPoint(0);

      // add red circle shape
      const circle = group.addShape('circle', {
        attrs: {
          x: startPoint.x,
          y: startPoint.y,
          fill: '#1890ff',
          r: 3,
        },
        // must be assigned in G6 3.3 and later versions. it can be any string you want, but should be unique in a custom item type
        name: 'circle-shape',
      });

      // animation for the red circle
      circle.animate(
        (ratio) => {
          // the operations in each frame. Ratio ranges from 0 to 1 indicating the prograss of the animation. Returns the modified configurations
          // get the position on the edge according to the ratio
          const tmpPoint = shape.getPoint(ratio);
          // returns the modified configurations here, x and y here
          return {
            x: tmpPoint.x,
            y: tmpPoint.y,
          };
        },
        {
          repeat: true, // Whether executes the animation repeatly
          duration: 3000, // the duration for executing once
        },
      );
    },
  },
  'cubic', // extend the built-in edge 'cubic'
);

let data = {
  nodes: [
    {
      id: 'server', label: 'Server', size: [150, 40],
      img: svg.svgData.echangeRuijieImg,
      deviceType: "exchangeNode",
      label: 'exchange 1',
      ports: [
        { id: 'port1', x: -25, y: 7 },
        { id: 'port2', x: -15, y: 7 },
        { id: 'port3', x: -5, y: 7 },
        { id: 'port4', x: 5, y: 7 },
        { id: 'port3', x: 15, y: 7 },
        { id: 'port4', x: 25, y: 7 },
      ],
    },

    {
      id: 'exchange1', label: 'exchange 1', deviceType: 'exchange', size: [100, 40]
    },
    { id: 'exchange2', label: 'exchange 2', deviceType: 'exchange', size: [100, 40] },
    { id: 'router1', label: 'Router 1', deviceType: 'router' },
    { id: 'router2', label: 'Router 2', deviceType: 'router' },
    { id: 'router3', label: 'Router 3', deviceType: 'router' },
    { id: 'host1-1', label: 'Host 1-1', deviceType: 'host' },
    { id: 'host1-2', label: 'Host 1-2', deviceType: 'host' },
    { id: 'host1-3', label: 'Host 1-3', deviceType: 'host' },
    { id: 'host2-1', label: 'Host 2-1', deviceType: 'host' },
    { id: 'host2-2', label: 'Host 2-2', deviceType: 'host' },
    { id: 'host2-3', label: 'Host 2-3', deviceType: 'host' },


    { id: 'host3-1', label: 'Host 3-1', deviceType: 'camera' },
    { id: 'host3-2', label: 'Host 3-2', deviceType: 'camera' },
    { id: 'host3-3', label: 'Host 3-3', deviceType: 'printer' },

  ],
  edges: [
    // 正常连接用绿色线条表示
    { source: 'server', target: 'exchange1', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'server', target: 'exchange2', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'exchange1', target: 'router1', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'exchange2', target: 'router2', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'exchange2', target: 'router3', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router1', target: 'host1-1', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router1', target: 'host1-2', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router1', target: 'host1-3', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },

    { source: 'router2', target: 'host2-1', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router2', target: 'host2-2', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router2', target: 'host2-3', label: 'disable', type: 'radial', style: { stroke: '#FF4D4F' } },



    { source: 'router3', target: 'host3-1', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router3', target: 'host3-2', label: 'Connection', type: 'circle-running', style: { stroke: '#2FC25B' } },
    { source: 'router3', target: 'host3-3', label: 'disable', type: 'radial', style: { stroke: '#FF4D4F' } },
    // 可以在这里添加异常连接的例子，使用红色线条表示
    // { source: 'router2', target: 'host2-1', label: 'Error Connection', style: { stroke: '#FF4D4F' } },
  ],
};

const getSvg = (item) => {

  if (item.deviceType == 'router') {
    item.img = svg.svgData.routerImg
  }
  else if (item.deviceType == 'exchange') {
    item.img = svg.svgData.exchangeImg
  }
  else if (item.deviceType == 'host') {
    item.img = svg.svgData.hostImg
  }
  else if (item.deviceType == 'camera') {
    item.img = svg.svgData.cameraImg
  }
  else if (item.deviceType == 'printer') {
    item.img = svg.svgData.printer
  }
  else if (item.deviceType == 'exchangeNode') {
    item.img = svg.svgData.echangeRuijieImg
    item.type = 'exchangeNode'
    item.ports = [
      { id: 'port1', x: -25, y: 7 },
      { id: 'port2', x: -15, y: 7 },
      { id: 'port3', x: -5, y: 7 },
      { id: 'port4', x: 5, y: 7 },
      { id: 'port3', x: 15, y: 7 },
      { id: 'port4', x: 25, y: 7 },
    ]
  }

  return item
}


data.nodes.forEach(item => {
  item = getSvg(item)
})


let nodeItem = ""

function showNodeInfoInput (node) {
  modalShow.value = true
  nodeItem = node
  // console.log(node._cfg.model.label, node._cfg.model.deviceType)
  devices.label = node._cfg.model.label
  devices.deviceType = node._cfg.model.deviceType
  devices.size = node._cfg.model.size.join("*")
};

const handleOk = () => {
  const str = devices.size;
  const dimensions = str.split('*');

  const width = parseInt(dimensions[0], 10);
  const height = parseInt(dimensions[1], 10);
  nodeItem.update({
    label: devices.label,
    deviceType: devices.deviceType,
    size: [width, height]
  });
  modalShow.value = false
  data.nodes.forEach(item => {
    item = getSvg(item)
  })
  graphInstance.data(data);
  graphInstance.render();

}

//获取zabbix主机地址
const getZabbixHostSelector = () => {
  serviceAxios({
    url: interfaces.zabbix_host_page,
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    options.zabbix_host = result.data.data.list.map(item => ({
      label: item.host,
      value: item.id
    }


    ))

    console.log(options.zabbix_host)
  }).catch(err => { console.log(err) })
}
getZabbixHostSelector()

//获取门店合同信息
const contractData = {}
const contractPage = () => {
  serviceAxios({
    url: interfaces.contractPage,
    method: "post",
    data: {
      page_num: 1,
      page_size: 99999
    }
  }).then(result => {
    result.data.data.list.forEach(item => {
      if (contractData[item.customer.id] != undefined) {

        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
          region: item.region,
        })
      }
      else {

        contractData[item.customer.id] = {}
        contractData[item.customer.id].children = []
        contractData[item.customer.id].brand = item.customer.name
        contractData[item.customer.id].children.push({
          id: item.id,
          value: item.contract_user.id,
          label: item.contract_user.name,
          contract_id: item.id,
          customer_id: item.customer.id,
          brand: item.customer.name,
          storeName: item.contract_user.name,
          address: item.contract_user.address,
          contactName: item.contract_user.contact_name,
          contactPhone: item.contract_user.phone_number,
          productServices: item.product_services,
          region: item.region,
        })
      }
    })

    let opContract = []
    for (let key in contractData) {
      opContract.push({
        label: contractData[key].brand,
        value: key,
        children: contractData[key].children
      }
      )
    }

    options.contract = opContract
    console.log("contractData", contractData)
  }).catch((err) => {
    message.error("请求异常，请联系管理员");
    console.log(err);
  })
}
contractPage()

const relate = () => {
  let mustInputFields = ['zabbix_host', 'contact']
  let flag = commonFun.checkFields(baseData, mustInputFields)
  if (flag == true) {
    serviceAxios({
      url: interfaces.store_and_zabbix_relate,
      method: 'get',
      params: {
        store_id: baseData.contact[1],
        host_id: baseData.zabbix_host,
      }
    }).then((res) => {
      if (res.data.message == 'success') {
        message.success("关联成功")
      } else {
        message.error(res.data.message)
      }
    }).catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    })
  }
  else {
    message.error("请填写门店名称和主机地址");
  }

}



const un_relate = () => {
  let mustInputFields = ['zabbix_host', 'contact']
  let flag = commonFun.checkFields(baseData, mustInputFields)
  if (flag == true) {
    serviceAxios({
      url: interfaces.store_and_zabbix_unrelate,
      method: 'get',
      params: {
        store_id: baseData.contact,
        host_id: baseData.store_id,
      }
    }).then((res) => {
      if (res.data.message == 'success') {
        message.success("关联成功")
      } else {
        message.error(res.data.message)
      }
    }).catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    })
  }
  else {
    message.error("请填写门店名称和主机地址");
  }

}

const saveGplot = () => {


  let mustInputFields = ['zabbix_host', 'contact']
  let flag = commonFun.checkFields(baseData, mustInputFields)
  // let flag2 = commonFun.checkFields(otherData, ['host_name'])
  let nodes = graphInstance.getNodes()
  let edges = graphInstance.getEdges()

  let newNodes = []
  let newEdges = []
  nodes.forEach(node => {
    newNodes.push({
      id: node._cfg.model.id,
      label: node._cfg.model.label,
      x: node._cfg.model.x,
      y: node._cfg.model.y,
      size: node._cfg.model.size,
      deviceType: node._cfg.model.deviceType,
      port: node._cfg.model.ports
    })
  })
  edges.forEach(edge => {
    newEdges.push({
      id: edge._cfg.model.id,
      source: edge._cfg.model.source,
      target: edge._cfg.model.target,
      type: edge._cfg.model.type,
      style: { stroke: edge._cfg.model.style.stroke }
    })
  })

  if (flag == true) {
    console.log("开始请求")
    let gplotData = {
      store_id: baseData.contact[1],
      ip: otherData.host_name,
      name: baseData.name,
      topological_structure: { nodes: newNodes, edges: newEdges }
    }

    console.log("gplotData", gplotData)
    // console.log("gplotData", JSON.stringify(gplotData))
    serviceAxios({
      url: interfaces.save_gplot,
      method: 'post',
      data: gplotData
    }).then(
      result => {
        console.log(result)
        if (result.data.message == 'success') {

          message.success("保存成功")
        }
        else {
          message.error(result.data.message)
        }
      }
    ).catch(error => { console.log(error), message.error("请求异常，请联系管理员") })
  }
  else {
    message.error("请填写门店名称和主机地址");
  }
}

const getGplot = (id) => {
  serviceAxios({
    url: interfaces.get_gplot,
    method: 'get',
    params: {
      store_id: id
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let gplotData = result.data.data
      console.log('gplotData', gplotData)
      data = gplotData.topological_structure
      baseData.zabbix_host = gplotData.ip
      otherData.host_name = gplotData.ip
      baseData.name = gplotData.name
      data.nodes.forEach(item => {
        item = getSvg(item)
      })
      console.log('data', data)
      graphInstance.data(data);
      graphInstance.render();
    }
    else {
      message.error(result.data.message)
      baseData.zabbix_host = null
      baseData.name = null
      graphInstance.data({ nodes: [], edges: [] });
      graphInstance.render(); // 触发重新渲染
    }
  }).catch()
}

const hostChange = (value, option) => {
  console.log(value, option)
  otherData.host_name = option.label
  console.log(otherData.host_name)

}

const onContractChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  let store_id = value[1]
  getGplot(store_id)
}

</script>
