<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:visible="showModal.show"
           title="角色信息"
           :afterClose="handleAfterClose"
           @ok="submit"
           @cancel="handleCancel"
           width=620px>
    <table class="customerTable">
      <tr>
        <td class='column'>页面路径<span class='icon'>*</span></td>
        <td>
          <a-input v-model:value="baseData.pagePath"
                   placeholder="请输入页面路径" />
        </td>
      </tr>
      <tr>
        <td class='column'>标题<span class='icon'>*</span></td>
        <td>
          <a-input v-model:value="baseData.title"
                   placeholder="请输入标题" />
        </td>
      </tr>
      <tr>
        <td class='column'>内容<span class='icon'>*</span></td>
        <td>
          <uploadComponent :maxCount=1
                           :fileIds='baseData.contentFileIds'
                           :freshFlag='uploadFresh'
                           fileType='帮助文档'
                           @update:fileIds='(event)=>fileChange(event,"contentId")'
                           buttonName='上传内容'></uploadComponent>
        </td>
      </tr>
      <tr>
        <td class='column'>标签</td>
        <td>
          <a-select v-model:value="baseData.tagIds"
                    style='width:444px'
                    :options="options.tags"
                    mode="multiple"
                    placeholder="请选择标签">

          </a-select>
        </td>
      </tr>
      <tr>
        <td class='column'>附件</td>
        <td>
          <uploadComponent :fileIds='baseData.attachmentsFileIds'
                           :freshFlag='uploadFresh'
                           fileType='帮助文档'
                           @update:fileIds='(event)=>fileChange(event,"attachments")'
                           buttonName='上传附件'></uploadComponent>
        </td>
      </tr>
      <tr>
        <td class='column'>相关文档</td>
        <td>
          <a-select v-model:value="baseData.relatedDocIds"
                    style='width:444px'
                    :options="options.doc"
                    mode="multiple"
                    placeholder="请选择文档">

          </a-select>
        </td>
      </tr>
    </table>

    <!-- 权限树组件 -->

  </a-modal>

  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           ref="tableRef"
           bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>

      <template v-if="['operations'].includes(column.dataIndex)">
        <div>
          <a class='editable-row-a'
             @click="edit(record)">编辑</a>
          <a class='editable-row-a'
             @click="del(record.id)">删除</a>
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div>
          <a-input addonBefore='关键字'
                   placeholder="请输入关键字"
                   v-model:value='siftRef.keyword'
                   style='width:180px'>
          </a-input>

          <a-button class="addDataButton"
                    type="primary"
                    @click="sift">搜索</a-button>

          <a-button class="addDataButton"
                    @click="resetSift">重置</a-button>
        </div>
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    @click="batchDel">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, watch } from "vue";
import serviceAxios from "@/utils/serviceAxios";
import { selector } from "@/utils/selector";
import { message } from "ant-design-vue";
import uploadComponent from '@/components/uploadComponent.vue'
import interfaces from "@/config";
//定义upload刷新字段
const uploadFresh = ref(true)
let baseUrl = {
  add: interfaces.docAdd,
  delete: interfaces.docDelete,
  edit: interfaces.docEdit,
  queryPage: interfaces.docPage,
}

let baseData = reactive({
  id: '',
  pagePath: "",
  title: "",
  contentId: null,

  tagIds: [],
  attachments: [],
  relatedDocIds: [],

  //文件
  contentFileIds: [],
  attachmentsFileIds: [],
  relatedDocIdsFileIds: []
});

let options = reactive({
  tags: [],
  doc: [],

});

//是对 a-table 的引用
const tableRef = ref(null);

//a-form 的引用
let formRef = ref("");

const rules = {
  pagePath: [{ required: true, message: "必填", trigger: "blur", name: '页面路径' }],
  title: [{ required: true, message: "必填", trigger: "blur", name: '标题' }],
  contentId: [{ required: true, message: "必填", trigger: "blur", name: '内容' }],
};
const treeData = reactive({
  data: "",
});
const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});




//添加弹框
let modalType = ref('add');
let showModal = reactive({
  show: false,
  isDeleteModalVisible: false,
});
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "页面路径",
    dataIndex: "pagePath",
    key: "pagePath",
  },
  {
    title: "标签",
    dataIndex: "tagsName",
    key: "tagsName",
  },


  {
    title: "操作",
    dataIndex: "operations",
    key: "operations",
  }

];

//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 16, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (newPagination) => {
  const { current, pageSize } = newPagination;
  console.log('pagination change', current)
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();
};

let dataSource = reactive({
  data: "",
});

// 选择框
let rowData = reactive([]);
let selectedRowKey = ref();
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    (selectedRowKey.value = selectedRowKeys),
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

//定义筛选字段
const siftRef = ref({
  keyword: ''
})

const sift = () => {
  queryFields['keyword'] = siftRef.value.keyword
  queryFields['page_num'] = 1
  queryFields['page_size'] = 10
  queryPage()
}
const resetSift = () => {
  siftRef.value.keyword = ''
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryPage()
}


//querypage查询字段
let queryFields = {
  page_num: pagination.current,
  page_size: pagination.pageSize,
}



// 查询数据
const queryPage = () => {

  serviceAxios({
    url: baseUrl.queryPage,
    method: "get",
    params: queryFields,
  })
    .then((result) => {
      if (result.status == 200) {
        //修改分页模型
        pagination.total = result.data.data.total;

        dataSource.data = result.data.data.list.map(item => ({
          id: item.id,
          key: item.id,
          title: item.title,
          pagePath: item.page_path,
          tags: item.tags,
          tagsName: item.tags.map(tags => tags.name).join(" "),
          relatedDocs: item.related_docs,
          attachments: item.attachments,
          content: item.content,
          createTime: item.create_time
        }));

        selectedRowKey.value = [];
        console.log("dataSource.data", dataSource.data)

      }
    })
    .catch((err) => {
      console.log(err);
    });
};
queryPage();
//添加
const addData = () => {
  uploadFresh.value = !uploadFresh.value;
  modalType.value = 'add';
  baseData.pagePath = '';
  baseData.title = '';
  baseData.contentId = null;
  baseData.tagIds = [];
  baseData.attachments = [];
  baseData.relatedDocIds = [];
  showModal.show = true;

};

const files = ref({
  contents: [],
  attenments: []
})


//编辑
const edit = (record) => {
  //刷新上传组件
  uploadFresh.value = !uploadFresh.value

  for (let key in baseData) {
    baseData[key] = record[key]
  }
  baseData.contentFileIds = [{
    file_id: record.content.id,
    owner_class: record.content.owner_class,
    owner_id: record.content.owner_id
  }];
  baseData.contentId = [record.content.id];
  baseData.attachments = record.attachments.map(item => item.id);
  baseData.attachmentsFileIds = record.attachments.map(item => ({
    file_id: item.id,
    owner_class: item.owner_class,
    owner_id: item.owner_id
  }));


  baseData.relatedDocIds = record.relatedDocs.map(item => item.id);

  showModal.show = true;
  modalType.value = 'edit';
}

//定义删除字段
let deleteData = {
  flag: false, //判断是批量删除还是单独删除
  id: '', //要删除的id
}

//删除
const del = (id) => {
  showModal.isDeleteModalVisible = true
  deleteData.flag = false
  deleteData.id = id

}


//批量删除数据
const batchDel = () => {
  showModal.isDeleteModalVisible = true
  deleteData.flag = true

};


//执行删除
const handleOkDelete = () => {
  console.log('handleOkDelete', handleOkDelete)
  if (deleteData.flag) {
    const params = {
      ids: rowData.map((item) => item.id),
    };
    serviceAxios({
      url: baseUrl.delete,
      method: "post",
      data: params,
    })
      .then((result) => {
        if (result.status === 200) {
          message.success("删除成功");
          queryPage();
          showModal.isDeleteModalVisible = false
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  else {
    serviceAxios({
      url: baseUrl.delete,
      method: "post",
      data: {
        ids: [deleteData.id]
      },
    })
      .then((result) => {
        if (result.status === 200) {
          message.success("删除成功");
          queryPage();
          showModal.isDeleteModalVisible = false


        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}


//提交
const submit = () => {

  let flag = true;
  let data = {
    id: baseData.id,
    page_path: baseData.pagePath,
    title: baseData.title,
    content_id: baseData.contentId[0],
    tag_ids: baseData.tagIds,
    attachments: baseData.attachments,
    related_doc_ids: baseData.relatedDocIds
  };
  if (!baseData.pagePath || !baseData.title || baseData.contentId === 0) {
    message.error("请确保页面路径、标题和内容已填写！");
    flag = false;
  }
  let url = modalType.value == 'add' ? baseUrl.add : baseUrl.edit

  if (flag) {
    serviceAxios({
      url: url,
      method: "post",
      data: data,
    })
      .then((result) => {
        if (result.data.message == 'success') {
          message.success("成功");
          showModal.show = false
          queryPage()
        }
        else {
          message.error(result.data.message)
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  else {
    message.error('请输入标签名称')
  }



};



//文件上传change事件
const fileChange = (event, type) => {
  console.log(event, type)
  baseData[type] = []

  event.forEach(item => {
    baseData[type].push(item.isOld == true ? item.uid : item.response.data[0].id)
  })

}





const filterOption = (input, option) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};



(async () => {
  options.tags = await selector.docTags()
  options.doc = await selector.doc()
})()
</script>
<style lang="less">
@import "@/static/css/table.css";
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}
</style>