<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="container">
        <div class="search">
            <a-select
                :options="options.brandId"
                v-model:value="searchData.brandId"
                placeholder="请选择品牌"
                :filter-option="filterOption"
                show-search
                style="width: 150px"
                @change="brandChange"
            ></a-select>
            <a-select
                :options="options.storeId"
                v-model:value="searchData.storeId"
                placeholder="请选择门店"
                :filter-option="filterOption"
                show-search
                style="width: 150px"
            ></a-select>
            <a-button style="margin:1px" @click="sift">搜索</a-button>
            <a-button style="margin:1px" @click="resetSift">重置</a-button>
        </div>
        <div id="map" ref="map" style="width: 100%; height: 100%"></div>
    </div>
</template>

<script setup>
import serviceAxios from "@/utils/serviceAxios";
import interfaces from "@/config";
import { ref, onMounted ,reactive} from "vue";
import { useRouter, useRoute } from "vue-router";
import bluePoint from '@/assets/bluePoint.png';
const router = useRouter();

const mapContainer = ref(null);
let map = null;
let mapInstance;
let markerLayer;
let label;
let latlngBounds;
let labelGeometries = [];
let markerGeometries = [];
let coords = []; //扩大的坐标，用于显示所有的标记

//初始化地图 获取所有门店坐标
const initMap = () => { 
    serviceAxios({
        url: interfaces.coordinateAll,
        method: "get",
    })
        .then((result) => {
            console.log("result", result);
            if (result.data.message == "success") {
                labelGeometries = [];
                markerGeometries = [];
                coords = [];
                let data = result.data.data;
                data.forEach((item) => {
                    coords.push(
                        new TMap.LatLng(item.location.lat, item.location.lng),
                        new TMap.LatLng(
                            item.location.lat + 0.05,
                            item.location.lng + 0.05
                        ),
                        new TMap.LatLng(
                            item.location.lat - 0.05,
                            item.location.lng - 0.05
                        )
                    );
                    console.log('normal',normal)
                    markerGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id)?"normalStyle":"myStyle",
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ),
                        properties: {
                            title: "marker2",
                        },
                    });
                    labelGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id) ?"label":"redLabel", //样式id
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ), //标注点位置
                        content: item.name.includes(item.brand_name)
                            ? item.name
                            : item.brand_name + item.name, //标注文本
                    });
                });

                var center = new window.TMap.LatLng(31.140696, 121.358921);
                mapInstance = new window.TMap.Map("map", {
                    zoom: 10, //设置地图缩放级别
                    viewMode: "2D",
                    // center: center,//设置地图中心点坐标
                    baseMap: {
                        // 设置矢量图
                        type: "vector",
                    },
                });

                markerLayer = new TMap.MultiMarker({
                    map: mapInstance, //指定地图容器
                    //样式定义
                    styles: {
                        //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                        myStyle: new TMap.MarkerStyle({
                            width: 25, // 点标记样式宽度（像素）
                            height: 35, // 点标记样式高度（像素）
                            size: 35,
                            // "src": '../img/marker.png',  //图片路径
                            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                            anchor: { x: 16, y: 32 },
                        }),
                        normalStyle:new TMap.MarkerStyle({
                            width: 42, // 点标记样式宽度（像素）
                            height: 35, // 点标记样式高度（像素）
                            size: 35,
                            src: bluePoint,  //图片路径
                            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                            anchor: { x: 16, y: 32 },
                        }),
                    },
                    //点标记数据数组
                    geometries: markerGeometries,
                });
                label = new TMap.MultiLabel({
                    id: "label-layer",
                    map: mapInstance, //设置折线图层显示到哪个地图实例中
                    //文字标记样式
                    styles: {
                        // 'label': new TMap.LabelStyle({
                        //   'color': '#3777FF', //颜色属性
                        //   'size': 17, //文字大小属性
                        //   'offset': { x: 0, y: 0 }, //文字偏移属性单位为像素
                        //   'angle': 0, //文字旋转属性
                        //   'alignment': 'center', //文字水平对齐属性
                        //   'verticalAlignment': 'middle' //文字垂直对齐属性
                        // })
                        label: new TMap.LabelStyle({
                            color: "#40514e", // 文字颜色
                            size: 15, // 文字大小
                            fontFamily: "Arial, sans-serif", // 字体家族
                            fontWeight: "bold", // 字体粗细
                            textShadow: {
                                // 文字阴影
                                color: "#333333", // 阴影颜色
                                blur: 3, // 阴影模糊半径
                                offset: { x: 1, y: 1 }, // 阴影偏移
                            },
                            offset: { x: 0, y: 0 }, // 文字偏移，y 值为负数表示向上偏移
                            angle: 0, // 文字旋转角度
                            alignment: "center", // 水平对齐方式
                            verticalAlignment: "middle", // 垂直对齐方式
                            // 'background': { // 文字背景
                            //   'color': '#3777FF', // 背景颜色
                            //   'radius': 5, // 圆角半径
                            //   'offset': { x: -5, y: -5 }, // 背景偏移
                            //   'padding': { left: 5, top: 5, right: 5, bottom: 5 } // 背景内边距
                            // }
                        }),
                        redLabel: new TMap.LabelStyle({
                            color: "#e23e57", // 文字颜色
                            size: 15, // 文字大小
                            fontFamily: "Arial, sans-serif", // 字体家族
                            fontWeight: "bold", // 字体粗细
                            textShadow: {
                                // 文字阴影
                                color: "#333333", // 阴影颜色
                                blur: 3, // 阴影模糊半径
                                offset: { x: 1, y: 1 }, // 阴影偏移
                            },
                            offset: { x: 0, y: 0 }, // 文字偏移，y 值为负数表示向上偏移
                            angle: 0, // 文字旋转角度
                            alignment: "center", // 水平对齐方式
                            verticalAlignment: "middle", // 垂直对齐方式
                            // 'background': { // 文字背景
                            //   'color': '#3777FF', // 背景颜色
                            //   'radius': 5, // 圆角半径
                            //   'offset': { x: -5, y: -5 }, // 背景偏移
                            //   'padding': { left: 5, top: 5, right: 5, bottom: 5 } // 背景内边距
                            // }
                        }),
                    },
                    //文字标记数据
                    geometries: labelGeometries,
                });
                // markerLayer.on('click', function (evt) {
                //   console.log('evt', evt)
                //   let ids = []
                //   serviceAxios({
                //     url: interfaces.storeGetGatewayId,
                //     method: 'get',
                //     params: {
                //       store_id: evt.geometry.id

                //     }
                //   }).then(result => {
                //     if (result.data.message == 'success') {
                //       try {
                //         let data = result.data.data.gateway.network_interfaces
                //         console.log('data', data)
                //         data.forEach(item => {
                //           ids.push(item.id)
                //         })
                //         ids = ids.join(',')
                //         router.push({

                //           path: "/show",
                //           query: { ids: ids, storeId: evt.geometry.id ,gatewayId:result.data.data.gateway.id}

                //         })

                //       }
                //       catch (error) {

                //         console.log(error)

                //         message.error('该门店未绑定网关探测事件')
                //       }
                //     }
                //     else {

                //       message.error('该门店未绑定网关探测事件')
                //     }

                //   }).catch(
                //     error => {
                //       console.log(error)
                //     }
                //   )
                // })
                latlngBounds = new TMap.LatLngBounds();
                //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
                for (var i = 0; i < coords.length; i++) {
                    latlngBounds.extend(coords[i]);
                }
                //调用fitBounds自动调整地图显示范围
                mapInstance.fitBounds(latlngBounds);
                console.log("初始化完成");
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
onMounted(() => {
    // initMap();
    storeGatewayStatus()
    // storeOverview()
});

//总览
const storeOverview = () => {
    //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
    for (var i = 0; i < coords.length; i++) {
        latlngBounds.extend(coords[i]);
    }
    //调用fitBounds自动调整地图显示范围
    mapInstance.fitBounds(latlngBounds);
};
const options = ref({
    brandId: [],
    storeId: [],
    brandAndStore: [],
});

//搜索
const searchData = ref({
    brandId: null,
    storeId: null,
});
const sift = () => {
    // console.log('markerLayer',markerLayer)
    markerLayer.setGeometries([]);
    label.setGeometries([]);

    serviceAxios({
        url: interfaces.coordinateAll,
        method: "get",
        params: {
            brand_id: searchData.value.brandId,
            store_id: searchData.value.storeId,
        },
    })
        .then((result) => {
            console.log("result", result);
            if (result.data.message == "success") {
                let labelGeometries = [];
                markerGeometries = [];
                coords = [];
                let data = result.data.data;
                data.forEach((item) => {
                    coords.push(
                        new TMap.LatLng(item.location.lat, item.location.lng),
                        new TMap.LatLng(
                            item.location.lat + 0.05,
                            item.location.lng + 0.05
                        ),
                        new TMap.LatLng(
                            item.location.lat - 0.05,
                            item.location.lng - 0.05
                        )
                    );
                    markerGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id) ?"normalStyle":"myStyle",
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ),
                        properties: {
                            title: "marker2",
                        },
                    });
                    labelGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id) ?"label":"redLabel", //样式id
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ), //标注点位置
                        content: item.name.includes(item.brand_name)
                            ? item.name
                            : item.brand_name + item.name, //标注文本
                    });
                });

                markerLayer.updateGeometries(markerGeometries);
                label.updateGeometries(labelGeometries);

                if (data.length == 1) {
                    console.log(111);
                    mapInstance.panTo(
                        new TMap.LatLng(
                            data[0].location.lat,
                            data[0].location.lng
                        )
                    );
                } else {
                    for (var i = 0; i < coords.length; i++) {
                        latlngBounds.extend(coords[i]);
                    }
                    //调用fitBounds自动调整地图显示范围
                    mapInstance.fitBounds(latlngBounds);
                }
                //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
const resetSift = () => {
    searchData.value.brandId = null;
    searchData.value.storeId = null;
    markerLayer.setGeometries([]);
    label.setGeometries([]);
    options.value.storeId = storeSelectorAll;
    serviceAxios({
        url: interfaces.coordinateAll,
        method: "get",
    })
        .then((result) => {
            console.log("result", result);
            if (result.data.message == "success") {
                let labelGeometries = [];
                markerGeometries = [];
                coords = [];
                let data = result.data.data;
                data.forEach((item) => {
                    coords.push(
                        new TMap.LatLng(item.location.lat, item.location.lng),
                        new TMap.LatLng(
                            item.location.lat + 0.05,
                            item.location.lng + 0.05
                        ),
                        new TMap.LatLng(
                            item.location.lat - 0.05,
                            item.location.lng - 0.05
                        )
                    );
                    markerGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id) ?"normalStyle":"myStyle",
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ),
                        properties: {
                            title: "marker2",
                        },
                    });
                    labelGeometries.push({
                        id: item.id,
                        styleId: normal.includes(item.id) ?"label":"redLabel", //样式id
                        position: new TMap.LatLng(
                            item.location.lat,
                            item.location.lng
                        ), //标注点位置
                        content: item.name.includes(item.brand_name)
                            ? item.name
                            : item.brand_name + item.name, //标注文本
                    });
                });

                markerLayer.updateGeometries(markerGeometries);
                label.updateGeometries(labelGeometries);

                //将坐标逐一做为参数传入extend方法，latlngBounds会根据传入坐标自动扩展生成
                for (var i = 0; i < coords.length; i++) {
                    latlngBounds.extend(coords[i]);
                }
                //调用fitBounds自动调整地图显示范围
                mapInstance.fitBounds(latlngBounds);
            }
        })
        .catch((error) => {
            console.log(error);
        });
};
//门店名称发生变化
const brandChange = (value) => {
    options.value.storeId = brandAndStore[value];
};

let brandAndStore = {};
let storeSelectorAll = [];
//获取选择器options中的brandId storeId数据
const getBrand = () => {
    serviceAxios({
        url: interfaces.coordinateAll,
        method: "get",
    })
        .then((result) => {
            let data = result.data.data;
            let brand = [];
            let brandSelector = [];
            const storeSelector = [];
            data.forEach((item) => {
                if (!brand.includes(item.brand_name)) {
                    brandSelector.push({
                        label: item.brand_name,
                        value: item.brand_id,
                    });
                    brand.push(item.brand_name);
                }
                storeSelector.push({
                    label: item.name,
                    value: item.id,
                    brandId: item.brand_id,
                });
            });
            brandSelector.forEach((item) => {
                brandAndStore[item.value] = [];
            });
            storeSelector.forEach((item) => {
                brandAndStore[item.brandId].push(item);
            });
            storeSelectorAll = storeSelector;
            options.value.brandId = brandSelector;
            options.value.storeId = storeSelector;
            console.log("12333", options.value);
        })
        .catch((error) => {
            console.log(error);
        });
};

getBrand();

const filterOption = (input, option) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

//获取门店网关状态
let normal = []
const storeGatewayData = {}
const storeGatewayStatus = () => {
    serviceAxios({
        url: interfaces.hostPage,
        method: "get",
        params: {
            page_num: 1,
            page_size: 99999,
        },
    }).then((result) => {
        let gatewayIds = [];
        if (result.data.message == "success") {
            let data = result.data.data.list;
            data.forEach((item) => {
                try {
                    gatewayIds.push(item.gateway.id);
                } catch (error) {
                    console.log(error);
                }
            });
            serviceAxios({
        url: interfaces.onlineStatus,
        method: "post",
        data: {
            gateway_ids: gatewayIds
        },
    }).then(res=>{
        if (res.data.message=='success'){
            res.data.data.forEach(element => {
                if (element.value =='正常'){
                    try{
                        let ga = data.filter(item=>item.gateway.id == element.gateway_id)
                        console.log('正常',element,ga)
                        normal.push(ga[0].id)
                    }
                    catch(error){
                        console.log(error)
                    }
                    
                }

                
                
            });
        initMap()
        console.log('statusss',status)
        }
    }).catch(error=>{
        console.log(error)
    })
        }
    });
};

// storeGatewayStatus()
</script>
<style lang='less' scoped>
// .search{
//     display:flex
// }
.container {
    position: relative; /* 设置容器为相对定位 */
    width: 100%;
    height: 100%;
}

.search {
    position: absolute; /* 设置搜索框为绝对定位 */
    // top: 10px; /* 距离顶部的距离 */
    // left: 2%; /* 水平居中 */
    // transform: translateX(-50%); /* 使搜索框水平居中 */
    background-color: white; /* 背景颜色 */
    padding: 2px; /* 内边距 */
    z-index: 1000; /* 确保搜索框位于地图之上 */
}

#map {
    position: relative; /* 设置地图为相对定位 */
    z-index: 1; /* 地图的 z-index 应该低于搜索框 */
}
</style>