<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal :maskClosable="false"
           width="800px"
           v-model:open="modalShow.addOrEdit"
           title="标签"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 13
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in modalData"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.dataIndex">
              <a-select v-if="['category'].includes(item.dataIndex)"
                        v-model:value="baseData[item.dataIndex]"
                        show-search
                        placeholder="请选择数据"
                        style="width: 200px"
                        :options="options['tagSelector']"
                        :filter-option="filterOption"
                        @change="handleChangeSelect(item.dataIndex,$event)"></a-select>

              <a-tree-select v-else-if="['region_ids'].includes(item.dataIndex)"
                             v-model:value="value"
                             show-search
                             style="width: 100%"
                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                             placeholder="选择区域"
                             allow-clear
                             multiple
                             tree-default-expand-all
                             :tree-data="treeData"
                             tree-node-filter-prop="label">
                <template #title="{ value: val, label }">
                  <b v-if="val === 'parent 1-1'"
                     style="color: #08c">{{ val }}</b>
                  <template v-else>{{ label }}</template>
                </template>
              </a-tree-select>

              <a-input v-else
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="columns"
           :scroll="ne"
           :data-source="tableSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a @click="editData(record.key)">编辑</a>
          <a @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div class='right-buttons'>
          <!-- <a-cascader v-model:value="cascaderData.casc" :options="options" placeholder="Please select" change-on-select />
        <a-input v-model:value="cascaderData.value" style="width:150px"></a-input>
        <a-button type="primary" @click="searchCasc">搜索</a-button> -->
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.edit"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
// import {checkTree} from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, onMounted } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import encrypt from "../../utils/encrypt"
import { useScreenSize } from '@/utils/useScreenSize';
const { windowWidth, windowHeight } = useScreenSize();
const ne = reactive({
  x: "",
  y: ''
})
const tableHeight = () => {
  // const searchDivContainer = document.querySelector('.searchDivContainer');
  // const divHeight = searchDivContainer.offsetHeight + 3;
  // const searchTableContainer = document.querySelector('.ant-table-title');
  // const searchTableHeight = searchTableContainer.offsetHeight;
  ne.y = windowHeight.value - 328
  // console.log(windowHeight.value, divHeight, searchTableHeight)
}

onMounted(() => {
  tableHeight()

})
const url = {
  add: interfaces.tagAdd,
  delete: interfaces.tagDelete,
  edit: interfaces.tagEdit,
  page: interfaces.tagPage,
  tagSelector: interfaces.tagSelector
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


//区域树
// let treeData = reactive()
// const getTreeData = () => {
//   // let region = JSON.parse(localStorage.getItem("region"))
//   treeData = regionTree.getTree()
//   console.log("treeData", treeData)
// }
// getTreeData()


const value = ref([]);



const filterOption = (input, option) => {

}
// table列名数据
const columns = reactive([
  {
    title: "id",
    dataIndex: "id",
    key: "id"
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "分类",
    dataIndex: "category",
    key: "category"
  },
  {
    title: "备注",
    dataIndex: "desc",
    key: "desc"
  }
  // {
  //   title: "操作",
  //   dataIndex: "operation",
  //   key: "operation"
  // }
]);

//增加或者编辑的模态框规则
const rules = {
  category: [
    {
      required: true,
      message: "请输入分类",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],
  model: [
    {
      required: true,
      message: "请输入设备类型",
    },
  ],
  quantity: [
    {
      required: true,
      message: "请输入设备数量",
    },
  ]
}
//table数据源
const tableSource = reactive(
  {
    data: ""
  }
)
//选择框数据
const options = reactive({
  brand: "",
  is_disabled: [{ value: 1, label: "是" }, { value: 0, label: "否" }],
  tagSelector: []
})

// 搜索框数据
const cascaderData = reactive({
  casc: [],
  value: "",
})

// 搜索框数据
const searchData = reactive({
  brand: "",
  name: "",
  model: "",
  quantity: "",
  desc: "",
})

// 搜索框数据
const searchData2 = reactive({
});

// 数据模型
const baseData = reactive({
  id: "",
  name: "",
  key: "",
  category: "",
  desc: "",

})

//弹框字段数据
const modalData = reactive([{
  title: "名称",
  dataIndex: "name",
  key: "name"
},
{
  title: "分类",
  dataIndex: "category",
  key: "category"
},
{
  title: "备注",
  dataIndex: "desc",
  key: "desc"
},


])

//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
})
let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});


const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: {
      page_num: pagination.current,
      page_size: pagination.pageSize,
    }
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        category: item.category,
        desc: item.desc,
        // operation: item.operation,
      })
    });
    tableSource.data = data;
    selectedRowKey.value = []; // 重置选中的行
    console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }

};

const formRef = ref()


//编辑数据
const editData = () => {
  if (selectedRowKey.value.length === 1) {
    urlFlag = url.edit
    modalShow.addOrEdit = true;
    for (let key in baseData) {
      baseData[key] = rowData[0][key]
    }
  } else {
    message.error("请选择一条数据进行编辑");
  }
};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};

//删除数据
const deleteData = () => {
  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    modalShow.isDeleteModalVisible = true;

  }
};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        queryPage()
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const addOrEditHandleOk = () => {
  // baseData.ModalText = "The modal will be closed after two seconds";
  // baseData.confirmLoading = true;
  console.log(baseData);
  let data = {
    //后端所需数据
  }
  formRef.value
    .validate()
    .then(() => {
      let data = {
        id: baseData.id,
        name: baseData.name,
        category: baseData.category,
        desc: baseData.desc,

      }
      serviceAxios({
        url: urlFlag,
        method: "post",
        data: data
      }).then(result => {
        if (result.data.message == 'success') {
          modalShow.addOrEdit = false
          queryPage()
          message.success("提交成功")
        }
        else {
          message.info(result.data.message)
        }


      }).catch(err => {
        console.log(err)
        message.error("处理数据发生异常，请联系管理员")
      })
    })
    .catch((err) => {
      console.log(err);
      message.error("数据输入错误");
    });
};


const getTicketsSelector = () => {
  serviceAxios(
    {
      url: "",
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      return result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}


const tagSelector = () => {
  serviceAxios({
    url: interfaces.tagSelector,
    method: "get",
    params: {

    },
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.tagSelector = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
tagSelector();


const handleChangeSelect = (dataIndex, value) => {

};
</script>
<style lang="less">
提交 .right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}
</style>