<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <!-- 高级搜索 -->
  <a-modal :maskClosable="false" width="400px" v-model:open="modalShow.searchModal" title="高级搜索" ok-text="确认"
    cancel-text="取消" @ok="searchHandleOk">
    <a-form :model="searchData" ref="formRef" :label-col="{ span: 7 }" :wrapper-col="{ span: 13
   }" :rules="rules">
      <a-form-item v-for="item in advanceSearchFileds" :key="item.id" :label="item.title">
        <a-input v-model:value="searchData[item.dataIndex]" :placeholder="`请输入${item.title}`" />
      </a-form-item>
    </a-form>
  </a-modal>

  <!-- 添加或者删除数据弹窗 -->
  <a-modal :maskClosable="false" width="1000px" v-model:open="modalShow.addOrEdit" title="资源信息" ok-text="确认"
    cancel-text="取消" @ok="addOrEditHandleOk">

    <table class='commonTable'>
      <tr>
        <td colspan="5" style="text-align:center;font-size:20px">资源信息</td>
      </tr>
      <tr>
        <td class='column' rowspan="5">线路成本信息</td>
        <td class='column'>运营商<span style="color: red;margin-left: 0px;">*</span></td>

        <td><a-input v-model:value="baseData.service_provider" :bordered="false" placeholder="请输入运营商"></a-input></td>
        <td class='column'>下单抬头<span style="color: red;margin-left: 0px;">*</span></td>
        <td><a-input v-model:value="baseData.signing_company" :bordered="false" placeholder="请输入下单抬头"></a-input></td>
      </tr>
      <tr>
        <td class='column'>产品套餐<span style="color: red;margin-left: 0px;">*</span></td>
        <td><a-input v-model:value="baseData.service_product" :bordered="false" placeholder="请输入产品套餐"></a-input></td>
        <td class='column'>金额（元）</td>
        <td><a-input-number v-model:value="baseData.payment_amount" style="width:100%" :bordered="false"
            placeholder="请输入金额"></a-input-number></td>

      </tr>

      <tr>

        <td class='column'>付费模式<span style="color: red;margin-left: 0px;">*</span></td>
        <td>

          <a-select :bordered="false" style="width:auto" v-model:value="baseData.payment_type" show-search
            placeholder="请选择付费模式" :options="options.payment_type" :filter-option="filterOption"
            @change="handleChangeSelect('type',$event)"></a-select>

        </td>
        <td class='column'>签约时长</td>
        <td><a-input-number v-model:value="baseData.duration" style="width:100%" :bordered="false"
            placeholder="请输入合同签约周期"></a-input-number></td>

      </tr>

      <tr>
        <td class='column'>开始日期</td>
        <td>
          <a-space direction="vertical" :size="12">
            <a-config-provider :locale="locale">
              <a-date-picker v-model:value="baseData.begin_date_time" @change="time=>dateChange('begin_date',time)" />
            </a-config-provider>
          </a-space>
        </td>

        <td class='column'>结束日期</td>
        <td>

          <a-space direction="vertical" :size="12">
            <a-config-provider :locale="locale">
              <a-date-picker v-model:value="baseData.end_date_time" @change="time=>dateChange('end_date',time)" />
            </a-config-provider>
          </a-space>
        </td>
      </tr>

      <tr>
        <td class="column">备注</td>
        <td colspan="3"><a-input v-model:value="baseData.desc" style="width:100%" :bordered="false"
            placeholder="请输入备注"></a-input></td>
      </tr>

      <tr>
        <td class='column' rowspan="1">客户信息</td>
        <td class='column'>品牌</td>
        <td>{{baseData.work_order.customer_name}}</td>

        <td class='column'>门店名称</td>
        <td>{{baseData.work_order.contract_user_name}}</td>
      </tr>

      <tr>
        <td class='column' rowspan="1">工单信息</td>
        <td class='column'>工单号</td>
        <td>{{baseData.work_order.work_sn}}</td>

        <td class='column'>工作号</td>
        <td>{{baseData.work_content.work_content_sn}}</td>
      </tr>

    </table>

  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false" v-model:open="modalShow.isDeleteModalVisible" title="确认删除" @ok="handleOkDelete"
    @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="fieldAll.columns" :data-source="tableSource.data" :row-selection="rowSelection"
    :pagination="pagination" @change="handleTableChange" bordered
    style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a' @click="editData(record)">编辑</a>
          <!-- <a class='editable-row-a' @click="deleteData(record.key)">删除</a> -->
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <a-cascader v-model:value="searchData.field" :options="options.search" placeholder="搜索字段" change-on-select />
        <a-input v-model:value="searchData.input" style="width:150px"></a-input> -->
        <a-button style="margin:2px" type="primary" @click="sift">搜索</a-button>
        <a-button style="margin:2px" @click="resetSearch">重置</a-button>
        <div class='right-buttons'>

          <!-- <a-button class="addDataButton" v-if="buttonShow.add" type="primary" @click="addData">添加</a-button> -->
          <!-- <a-button class="addDataButton" v-if="buttonShow.edit" type="primary" @click="editData">编辑数据</a-button> -->
          <a-button class="addDataButton" v-if="buttonShow.delete"  @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import dayjs from "dayjs";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
const url = {
  add: interfaces.network_access_cost_add,
  delete: interfaces.network_access_cost_delete,
  edit: interfaces.network_access_cost_edit,
  page: interfaces.network_access_cost_page,
  getById: interfaces.deviceGetById,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "品牌",
      dataIndex: "customer_name",
      key: "customer_name"
    },
    {
      title: "门店",
      dataIndex: "contract_user_name",
      key: "contract_user_name"
    },
    {
      title: "运营商",
      dataIndex: "service_provider",
      key: "service_provider"
    },
    {
      title: "产品套餐",
      dataIndex: "service_product",
      key: "service_product"
    },
    // {
    //   title: "付费模式",
    //   dataIndex: "payment_type",
    //   key: "payment_type"
    // },
    // {
    //   title: "合约签约周期",
    //   dataIndex: "duration",
    //   key: "duration"
    // },
    {
      title: "金额",
      dataIndex: "payment_amount",
      key: "payment_amount"
    },
    // {
    //   title: "开始日期",
    //   dataIndex: "begin_date",
    //   key: "begin_date"
    // },
    // {
    //   title: "结束日期",
    //   dataIndex: "end_date",
    //   key: "end_date"
    // },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [{
    title: "品牌",
    dataIndex: "brand",
    key: "brand"
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "设备型号",
    dataIndex: "model",
    key: "model"
  },
  {
    title: "设备数量",
    dataIndex: "quantity",
    key: "quantity"
  },
  {
    title: "描述",
    dataIndex: "desc",
    key: "desc"
  }]

})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: "",


})

//增加或者编辑的模态框规则
const rules = {
  brand: [
    {
      required: true,
      message: "请输入品牌",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],
  model: [
    {
      required: true,
      message: "请输入设备类型",
    },
  ],
  quantity: [
    {
      required: true,
      message: "请输入设备数量",
    },
  ]
}

//选择框数据
const options = reactive({
  brand: "",
  search: "",
  payment_type: [{
    label: "包年",
    value: "包年"
  },
  {
    label: "包月",
    value: "包月"
  }]
});

// 数据模型
const baseData = reactive({

  id: '',
  key: '',
  service_provider: '',
  service_product: '',
  signing_company: '',
  payment_type: '',
  duration: '',
  payment_amount: '',
  begin_date: '',
  end_date: '',
  desc: "",
  work_id: "",

  work_order: "",
  work_content: "",

  //date格式的数据
  begin_date_time: "",
  end_date_time: "",
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchModal: false,
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {
      console.log("item", item)
      data.push({
        id: item.id,
        key: item.id,
        service_provider: item.service_provider,
        signing_company: item.signing_company,
        service_product: item.service_product,
        payment_type: item.payment_type,
        duration: item.duration,
        payment_amount: item.payment_amount,
        begin_date: item.begin_date,
        end_date: item.end_date,
        work_id: item.work_id,
        desc: item.desc,

        customer_name: item.work_order.customer_name,
        contract_user_name: item.work_order.contract_user_name,
        work_content: item.work_content,
        work_order: item.work_order,
        contract_user: item.contract_user,
        customer: item.customer,
      })
    });
    tableSource.data = data;
    console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  console.log("record", record);
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }
  console.log(baseData.begin_date, (baseData.begin_date != "" || baseData.begin_date != null), "1")
  baseData.begin_date_time = (baseData.begin_date != "" && baseData.begin_date != null) ? dayjs(baseData.begin_date, "YYYY-MM-DD") : ''
  baseData.end_date_time = baseData.end_date != "" && baseData.end_date != null ? dayjs(baseData.end_date, "YYYY-MM-DD") : ''
};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};

//删除数据
const deleteData = () => {

  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    modalShow.isDeleteModalVisible = true;
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        queryPage()
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const addOrEditHandleOk = async () => {
  // baseData.ModalText = "The modal will be closed after two seconds";
  // baseData.confirmLoading = true;
  console.log(baseData);
  try {
    let data = {
      id: baseData.id,
      service_provider: baseData.service_provider,
      service_product: baseData.service_product,
      signing_company: baseData.signing_company,
      payment_type: baseData.payment_type,
      duration: baseData.duration,
      payment_amount: baseData.payment_amount,
      begin_date: baseData.begin_date,
      end_date: baseData.end_date,
      desc: baseData.desc,

      work_id: baseData.work_id
    }
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })


  }
  catch {
    message.error("请填写完整信息");
  }


};


const getTicketsSelector = () => {
  serviceAxios(
    {
      url: "",
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      return result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}


const getBrand = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.name,
        });
      });
      options.brand = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};



const handleChangeSelect = (dataIndex, value) => {

};
const searchData = reactive({
  service_provider: "",
  signing_company: "",
  service_product: ""
})


//搜索
const sift = () => {
  modalShow.searchModal = true
}

const advanceSearchFileds = [

  // {
  //   title: "工作内容id",
  //   dataIndex: "work_content_id",
  //   key: "work_content_id",
  // },
  // {
  //   title: "工单id",
  //   dataIndex: "work_order_id",
  //   key: "work_order_id",
  // },
  // {
  //   title: "关联小合同id",
  //   dataIndex: "contract_id",
  //   key: "contract_id",
  // },
  { title: "运营商", dataIndex: "service_provider", key: "service_provider" },
  { title: "下单抬头", dataIndex: "signing_company", key: "signing_company" },
  { title: "服务产品", dataIndex: "service_product", key: "service_product" },

];

const searchHandleOk = () => {
  queryField["service_provider"] = searchData.service_provider
  queryField["signing_company"] = searchData.signing_company
  queryField["service_product"] = searchData.service_product
  modalShow.searchModal = false
  queryPage()
}
const resetSearch = () => {
  for (let key in searchData) {
    searchData[key] = ""
  }

  queryField = reactive({
    page_num: 1,
    page_size: 10,
  })
  queryPage()
}

//时间变化事件
const dateChange = (type, time) => {
  if (time != null || time != '') {
    baseData[type] = time.format("YYYY-MM-DD")
  }
}
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.commonTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.commonTable tr td {
  width: auto;
  min-width: 100px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.commonTable tr td.column {
  background-color: #eff3f6;
  color: #040000;
  width: 15%;
}
</style>