<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->

<template>

  <a-modal :maskClosable="false"
           v-model:open="showModal.confirmDeleteModel"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.addOrEditModel"
           title="职位信息"
           width="1000px"
           @ok="handleOk">
    <a-form :model="formData"
            ref="formRef"
            :label-col="{ span: 7 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in formName"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.key">
              <a-tree-select v-if="item.dataIndex === 'department'"
                             v-model:value="formData.department"
                             show-search
                             style="width: 100%"
                             :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                             placeholder=""
                             allow-clear
                             tree-default-expand-all
                             :tree-data="options.departmentTree"
                             tree-node-filter-prop="label">
                <template #title="{ value: val, label }">
                  <b v-if="val === 'parent 1-1'"
                     style="color: #08c">sss</b>
                  <template v-else>{{ label }}</template>
                </template>
              </a-tree-select>

              <a-select v-else-if="item.dataIndex === 'whetherManager'"
                        v-model:value="formData.whetherManager"
                        show-search
                        placeholder=""
                        style="width: 270px"
                        :options="options.whetherManager"
                        :filter-option="filterOption"></a-select>

              <a-select v-else-if="item.dataIndex === 'relatedRole'"
                        v-model:value="formData.related_role_id"
                        show-search
                        placeholder="请选择角色"
                        style="width: 270px"
                        :options="options.role"
                        :filter-option="filterOption"></a-select>
              <a-input v-else
                       v-model:value="formData[item.key]" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div>
          <a-input addonBefore='关键字'
                   style='width:200px'
                   v-model:value="searchRef.keyword"
                   placeholder="输入关键字查询">
          </a-input>

          <a-button style='margin:1px'
                    type="primary"
                    @click='sift'>搜索</a-button>
          <a-button style='margin:1px'
                    @click='resetSift'>重置</a-button>
        </div>
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed } from "vue";

import serviceAxios from "../../utils/serviceAxios";
import { message } from "ant-design-vue";
import interfaces from "@/config";


import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from "ant-design-vue/es/date-picker/locale/zh_CN";
dayjs.locale("zh-cn");






//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  console.log(current, pageSize)
  pagination.current = current;
  pagination.pageSize = pageSize;


  queryPage();
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([

  "storeName",
  "brand",
  "officialName",
  "telephone",
  "contactName",
  "phone",
  "planBeginDate",
  "actualDate",
  "endDate",
  "region",
  "address",
  "paymentType",
  "duration",
  "payment_status",
  "paymentAmount",
  "invoice_type",
  "invoice_number",
  "desc",
  // "productService",
  "owner",
  "operationalStatus",


]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  console.log()
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

let showModal = reactive({
  addOrEditModel: false,
  confirmDeleteModel: false,
});



//列名字段
const columns = [
  // { title: "ID", dataIndex: "id", key: "id" },
  { title: "部门", dataIndex: "department", key: "department" },
  { title: "职位", dataIndex: "name", key: "name" },
  { title: "管理人员", dataIndex: "whetherManager", key: "whetherManager" },
  { title: "关联角色", dataIndex: "relatedRole", key: "relatedRole" }
];
//添加或者编辑model字段
const formName = [
  { title: "部门", dataIndex: "department", key: "department" },
  { title: "职位", dataIndex: "name", key: "name" },
  { title: "管理人员", dataIndex: "whetherManager", key: "whetherManager" },
  { title: "关联角色", dataIndex: "relatedRole", key: "relatedRole" }
];

//添加或者编辑model字段表单规则
const rules = {
  department: [
    {
      required: true,
      message: "",
    },
  ],
  name: [
    {
      required: true,
      message: "",
    },
  ],
  whetherManager: [
    {
      required: true,
      message: "",
    },
  ]

};
//table数据源
let dataSource = reactive({
  data: []
});

// 数据模型
let formData = reactive({
  id: "",
  name: "",
  department: "",
  whetherManager: "",
  related_role_id: ""
});


let options = reactive({
  departmentTree: "",
  whetherManager: [{
    value: 1,
    label: "是"
  }, {
    value: 0,
    label: "否"
  }],
  role: []
})

let queryFields = {
  page_num: pagination.current,
  page_size: pagination.pageSize,
}

let searchRef = ref({
  keyword: ''
})
let sift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10,
    keyword: searchRef.value.keyword
  }
  queryPage()
}
let resetSift = () => {
  queryFields = {
    page_num: 1,
    page_size: 10
  }
  queryPage()
}
let queryPage = () => {
  serviceAxios({
    // url: interfaces.jobTitleGetById,
    url: interfaces.jobTitlePage,
    method: "get",
    params: queryFields

  }).then(result => {

    pagination.total = result.data.data.total
    let data = []

    result.data.data.list.forEach(item => {
      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        department: item.department ? item.department.name : "",
        departmentId: item.department ? item.department.id : "",
        whetherManager: item.is_manager == 1 ? "是" : "否",
        related_role_id: item.related_role,
        relatedRole: item.related_role != null ? item.related_role.name : null
      })
    })
    dataSource.data = data
  }

  ).catch(err => {
    console.log(err)
    message.error("发生异常错误，请联系管理员")
  })
}
queryPage()

let url = "";   //判断是编辑按钮还是添加按钮
//重置数据模型
const resetForm = () => {

  for (let key in formData) {
    // console.log(key);
    formData[key] = null;
  }
};



//添加
const addData = () => {
  showModal.addOrEditModel = true
  resetForm()
  url = interfaces.jobTitleAdd
};

//编辑数据
const editData = () => {
  if (rowData.length == 1) {
    showModal.addOrEditModel = true
    url = interfaces.jobTitleEdit
    formData.id = rowData[0].id
    formData.name = rowData[0].name
    formData.department = rowData[0].departmentId
    formData.whetherManager = rowData[0].whetherManager == "是" ? 1 : 0
    formData.related_role_id = rowData[0].related_role_id != null ? rowData[0].related_role_id.id : null
  }
  else {
    message.error("请选择一条数据进行编辑")
  }


};
//删除数据
const deleteData = () => {
  if (rowData.length != 0) {
    showModal.confirmDeleteModel = true
  }
  else {
    message.error("至少选择一条数据进行删除")
  }

};

const handleOkDelete = () => {
  let deleteData = []
  rowData.forEach(item => {
    deleteData.push(item.id)
  })
  serviceAxios({
    url: interfaces.jobTitleDelete,
    method: "post",
    data: {
      ids: deleteData
    }
  }).then(
    result => {
      if (result.data.message == 'success') {
        showModal.confirmDeleteModel = false
        queryPage()
      }
    }
  ).catch(err => {
    console.log(err)
    message.error("发生异常错误，请联系管理员")
  })
};
const handleCancelDelete = () => {
};

//提交
const handleOk = () => {
  formRef.value
    .validate().then(
      () => {
        serviceAxios({
          url: url,
          method: "post",
          data: {
            id: formData.id,
            name: formData.name,
            department_id: formData.department,
            is_manager: formData.whetherManager,
            related_role_id: formData.relatedRole,

          }
        }).then(result => {
          if (result.data.message == 'success') {
            selectedRowKey.value = []
            message.success("成功")
            showModal.addOrEditModel = false
            queryPage()
          }
          else {
            message.error(result.data.message)
          }

        }).catch(err => { console.log(err), message.error("发生异常错误，请联系管理员") })

      }
    ).catch(err => {
      console.log(err)
      message.error("数据验证错误，请检查数据")
    })

};



//清洗部门树
const clearTree = (data) => {
  let childrenData = []
  if (data.length != 0) {
    data.forEach((item) => {
      let mnumObj = {
        id: item.id,
        value: item.id,
        label: item.name,
        part: "department",
        parent_id: item.parent_id, //父节点
      };

      if (item.children != undefined) {
        mnumObj.children = clearTree(item.children)
      }

      childrenData.push(mnumObj)
    });
  } else {

    console.log("no clear0");
  }
  return childrenData;
};
//获取部门树
const getDepartmentTreeData = () => {
  serviceAxios({
    url: interfaces.departmentTree,
    method: 'get',
    params: {
      include_job_titles: 1, //是否包含岗位信息
      include_accounts: 1, //是否包含账户信息
    }
  })
    .then(result => {
      let departmentTree = []
      result.data.data.forEach((item, index) => {

        if (item.children != undefined) {
          departmentTree.push({
            id: item.id,
            label: item.name,
            value: item.id,
            part: "department",
            children: clearTree(item.children)
          })
        }
        else {
          departmentTree.push({
            id: item.id,
            label: item.name,
            value: item.id,
            part: "department",
          })
        }
      });
      options.departmentTree = departmentTree
    }).catch(err => {
      console.log(err)
      message.error("请求异常，请联系管理员")
    });
}

getDepartmentTreeData()


const createRoleSelector = () => {
  serviceAxios({
    url: interfaces.rolePage,
    method: 'post',
    data: {
      size_num: 1,
      size_size: 99999
    }
  }).then(result => {
    let data = result.data.data.list
    options.role = data.map(item => ({
      label: item.name,
      value: item.id,
    }))
    console.log('options.role', options.role)
  }).catch(error => {
    console.log(error)
  })
}
createRoleSelector()

const filterOption = () => {

}
</script>
<style lang="less" >
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.mailTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.mailTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.mailTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 18%;
}
// .mailTable tr td.longColumn {
//     // background-color: #EFF3F6;
//     // color: #393C3E;
//     width: 85%;
// }

p {
  font-size: 12px;
}

a {
  color: black;
}
</style>