<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->

<template>
  <a-modal :maskClosable="false"
           v-model:open="isDeleteModalVisible"
           title="确认删除"
           :ok-text="okText"
           :cancel-text="cancelText"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:open="showModal.show"
           title="品牌信息"
           width="1000px"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="baseData"
            ref="formRef"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 14
   }"
            :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in fields"
                  :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title"
                         :name="item.dataIndex">
              <a-input :placeholder="`请输入${item.title}`"
                       class="uniform-width modal-input"
                       v-if="['type'].includes(item.dataIndex)"
                       v-model:value="baseData[item.dataIndex]"
                       disabled=true />

              <a-cascader ref="myCascader"
                          v-else-if="['industry_id'].includes(item.dataIndex)"
                          v-model:value="baseData.industry_ids"
                          :options="options.industry_name"
                          :load-data="loadData"
                          placeholder="选择行业"
                          change-on-select
                          @change="cascaderHandleChange" />

              <a-input :placeholder="`请输入${item.title}`"
                       v-else
                       class="uniform-width"
                       v-model:value="baseData[item.dataIndex]" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <a-table :columns="filteredColumns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <label style="margin-right:5px">关键字</label> -->
        <a-input addonBefore='关键字'
                 v-model:value="searchData.input"
                 style="width:200px"></a-input>
        <a-button style="margin:2px"
                  type="primary"
                  @click="sift">搜索</a-button>
        <a-button style="margin:2px"
                  @click="reSift">重置</a-button>

        <div class="right-buttons">
          <a-button v-if="buttonShow.customerAdd"
                    class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.customerEdit"
                    type="primary"
                    @click="editData">编辑数据</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.customerDelete"
                    @click="deleteData">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed, nextTick } from "vue";

import serviceAxios from "@/utils/serviceAxios";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";
import interfaces from "@/config";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;


const route = useRoute();
console.log("route", route.fullPath)
//按钮的显示
let buttonShow = reactive({
  customerAdd: true,
  customerDelete: true,
  customerEdit: true,
  customerPage: true,
})
const searchData = reactive({
  field: "",
  input: "",
})
const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}
// getButtonRules()
let formRef = ref();
//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (newPagination) => {
  const { current, pageSize } = newPagination;
  pagination.current = current
  pagination.pageSize = pageSize
  queryField.page_num = current
  queryField.page_size = pageSize
  queryPage();

};

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "name",
  "type",
  "desc",
  "industry_name",
  "region_id",
]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) => selectedColumns.value.includes(column.key));
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};

const tabItem = reactive([
  {
    key: "1",
    tab: "品牌管理",
  },
  //       {
  //     key: "2",
  //     tab:"区域管理"
  //     },
  //       {
  //     key: "3",
  //     tab:"门店录入"
  // },
]);

const tabChange = (activeKey) => {
  console.log("activeKey", activeKey);
};

let showModal = reactive({
  show: false,

});
const columns = [
  { title: "品牌名称", dataIndex: "name", key: "name" },
  { title: "类型", dataIndex: "type", key: "type" },
  { title: "行业", dataIndex: "industry_name", key: "industry_name" },
  { title: "备注", dataIndex: "desc", key: "desc" },

];
//弹框字段
const fields = [
  { title: "品牌名称", dataIndex: "name", key: "name" },
  { title: "类型", dataIndex: "type", key: "type" },
  { title: "行业", dataIndex: "industry_id", key: "industry_id" },
  { title: "备注", dataIndex: "desc", key: "desc" },

];

const rules = {
  name: {
    required: true,
    message: "请输入品牌",
  },
  type: {
    required: true,
    message: "",
  },

  industry_id: {
    required: true,
    message: "请选择行业",
  },
};
//table数据源
let dataSource = reactive({
  data: "",
});

// 添加弹框模型
let baseData = reactive({
  id: "",
  key: "",
  name: "",
  type: "行业客户",
  desc: "",
  industry_name: '',
  sub_industry_id: "",
  industry_id: '',
  industry_ids: "",
});

//产品下拉框数据
let options = reactive({
  industry_name: [],
  industry_names: []
});

let url = "";

//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    baseData[key] = "";
  }
  baseData.type = "行业客户";
};

//添加
const addData = () => {
  // router.push({ path: "/createUser" });
  resetForm();
  console.log("addData");
  url = interfaces.customerAdd;
  // baseData.type = '行业客户'
  showModal.show = true;
};

//编辑数据
const editData = () => {
  console.log();
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    showModal.show = true;
    url = interfaces.customerEdit;
    baseData.id = rowData[0]['id']
    baseData.name = rowData[0]['name']
    baseData.type = rowData[0]['type']
    baseData.industry_id = rowData[0]['industry_id']
    baseData.sub_industry_id = rowData[0]['sub_industry_id']
    console.log(baseData.industry_id, baseData.sub_industry_id)
    baseData.industry_ids = baseData.sub_industry_id == null ? [rowData[0]['industry_id']] : [rowData[0]['industry_id'], rowData[0]['sub_industry']["name"]]
    console.log("baseData.industry_ids", baseData.industry_ids)
    baseData.desc = rowData[0]['desc']
  }

};

//删除数据
let isDeleteModalVisible = ref()
const deleteData = () => {
  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    console.log('11111')
    isDeleteModalVisible.value = true
  }
};


const handleOkDelete = () => {
  let deleteData = [];
  rowData.forEach((item) => {
    deleteData.push(item.id);
  });
  let params = {
    ids: deleteData,
  };

  serviceAxios({
    url: interfaces.customerDelete,
    method: "post",
    data: params,
  })
    .then((res) => {
      if (res.status == 200) {
        message.success(res.data.message);
        isDeleteModalVisible.value = false;
        queryPage();

      }
    })
    .catch((res) => {
      console.log(res);
    });
};


const handleCancelDelete = () => {
  // 取消删除操作，关闭模态框
  isDeleteModalVisible.value = false;
};





//提交
const handleOk = () => {
  console.log("baseData", baseData)
  formRef.value
    .validate()
    .then(() => {
      let data = {
        id: baseData.id,
        name: baseData.name,
        type: "行业客户",
        desc: baseData.desc,
        industry_id: baseData.industry_id,
        sub_industry_id: baseData.sub_industry_id,
        region_id: null,
        parent_id: null,
      };
      serviceAxios({
        url: url,
        method: "post",
        data: data,
      })
        .then(() => {
          selectedRowKey.value = [];
          queryPage();
        })
        .catch((err) => {
          message.error("请求异常，请联系管理员");
          console.log(err)
        });
      showModal.show = false;
      // formRef.value.resetFields()
    }).catch(() => {
      message.error("表单验证失败，请检查输入信息");
    })

};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

const getIndustryAll = () => {
  serviceAxios({
    url: interfaces.getIndustryAll,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          value: item.id,
          label: item.name,
          isLeaf: false,
        });
      });
      options.industry_name = data;
    })
    .catch();
};

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  type: "行业客户",
})

const queryPage = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: queryField
  })
    .then((res) => {
      let data = [];
      pagination.total = res.data.data.total;

      res.data.data.list.forEach((item) => {
        let industry_name = item.industry.name + " " + (item.sub_industry.name == undefined ? "" : item.sub_industry.name)
        data.push({
          id: item.id,
          key: item.id,
          type: item.type,
          name: item.name,
          sn: item.sn,
          desc: item.desc,
          industry_id: item.industry.id,
          sub_industry_id: item.sub_industry_id,
          sub_industry: item.sub_industry,
          industry_name: industry_name
        });
      });
      dataSource.data = data;
    })
    .catch();
};
getIndustryAll();
queryPage();


//模态框多级联动组件加载事件
const loadData = selectedOptions => {

  const targetOption = selectedOptions[selectedOptions.length - 1];
  // console.log("selectedOptions ", selectedOptions, targetOption)
  targetOption.loading = true;
  let value = targetOption.value
  // load options lazily
  setTimeout(() => {



    serviceAxios({
      url: interfaces.subTradePage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999,
        parent_id: value
      }
    }).then(result => {

      // 更新表格数据
      let data = []
      if (result.data.data.list.length == 0) {
        targetOption.isLeaf = true
        targetOption.loading = false;
        options.industry_name = [...options.industry_name];
        if (myCascader) {
          console.log("myCascader.value", myCascader.value[0])
          myCascader.value[0].blur();
        }
      }
      else {
        result.data.data.list.forEach(item => {
          data.push({
            label: item.name,
            value: item.id,
          })
        });
        targetOption.children = data
        targetOption.loading = false;
        options.industry_name = [...options.industry_name];
      }
      console.log("targetOption", targetOption)



    }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })


  }, 1000);
};

const myCascader = ref()

const cascaderHandleChange = (value, selectedOptions) => {
  console.log("value", value, "selectedOptions", selectedOptions)
  console.log(baseData.industry_ids, "baseData.industry_ids")
  if (value != undefined) {
    // baseData.industry_ids = value.length == 2 ? [value[0], value[1]] : [value[0]]

    baseData.industry_id = value[0]
    baseData.sub_industry_id = value.length == 2 ? value[1] : null
  }

}


//搜索
const sift = () => {
  queryField['keyword'] = searchData.input
  queryPage()
}

const reSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,
    type: "行业客户",
  }
  searchData.input = null
  queryPage()
}
</script>



<style lang="less">
.searchDivContainer {
  display: flex;
  // height: 500px;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}
</style>