//选择器数据

import interfaces from "@/config"
import serviceAxios from "./serviceAxios"
import { Header } from "ant-design-vue/es/layout/layout"
// import { Template } from "webpack"


//运营商
export const networkProvider = async () => {
  const response = serviceAxios({
    url: interfaces.networkProviderSelector,
    method: 'get',
  })
  const result = response.data.data.map(item => ({
    label: item.name,
    value: item.value
  }))
  return result
}

export const selector = {
  //运营商
  networkProvider: async function () {
    const response = await serviceAxios({
      url: interfaces.networkProviderSelector,
      method: 'get',
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },

  //品牌
  brand: async function () {
    const response = await serviceAxios({
      url: interfaces.customerPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))
    return result
  },

  //工单状态
  workOrderStatus: async function (orderType) {
    const response = await serviceAxios({
      url: interfaces.statusSelector,
      method: 'get',
      params: {
        type: orderType
      }
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },


  //工单类型
  workOrderType: async function () {
    const response = await serviceAxios({
      url: interfaces.ticketsSelector,
      method: 'get',
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value
    }))
    return result
  },

  //工作内容类型
  workOrderContentType: async function () {
    const response = await serviceAxios({
      url: interfaces.workSelector,
      method: 'get',

    })
    return response.data.data
  },


  //运维人员所属公司
  workOrderCompany: async function () {
    const response = await serviceAxios({
      url: interfaces.tenantPage,
      method: 'get',
      params: {
        type: "外包",
        page_num: 1,
        page_size: 99999
      }

    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))
    return result
  },

  store: async function () {
    const response = await serviceAxios({
      url: interfaces.customerUserPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  },
  role: async function () {
    const response = await serviceAxios({
      url: interfaces.rolePage,
      method: 'post',
      data: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  },

  //网工
  networkEngineer: async function () {
    const response = await serviceAxios({
      url: interfaces.queryNetworkEngineer,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.real_name,
      value: item.id
    }))

    return result;
  },
  //基础角色

  baseRole: async function () {

    const response = await serviceAxios({
      url: interfaces.rolePage,
      method: 'post',
      data: {
        page_num: 1,
        page_size: 99999,
        is_base: 1
      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id
    }))

    return result;
  },

  //品牌下的门店，二级选择
  storesUnderTheBrand: async function (only_show_has_tenant) {
    only_show_has_tenant = only_show_has_tenant ? only_show_has_tenant : 0
    const response = await serviceAxios({
      url: interfaces.storesUnderTheBrand,
      method: 'get',
      params: {
        only_show_owned: 0, //是否只显示自己负责的品牌的门店，仅对销售人员有效
        only_show_has_tenant: only_show_has_tenant
      }
    })
    const result = response.data.data.map(item => ({
      label: item.brand_name,
      value: item.brand_name,
      data: item,
      children: item.stores.map(childrens => ({
        label: childrens.store_name,
        value: childrens.store_id,
      }))
    }))

    return result;
  },
  //文档标签
  docTags: async function () {
    const response = await serviceAxios({
      url: interfaces.docTagPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999,

      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id,

    }))

    return result;
  },
  doc: async function () {
    const response = await serviceAxios({
      url: interfaces.docPage,
      method: 'get',
      params: {
        page_num: 1,
        page_size: 99999,

      }
    })

    const result = response.data.data.list.map(item => ({
      label: item.title,
      value: item.id,

    }))

    return result;
  },
  subProductType: async function () {
    const response = await serviceAxios({
      url: '/v1/product/industry_product_sub_type/selector',
      method: 'get',

    })

    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value,

    }))

    return result;
  },
  //设备
  devices: async function () {
    const response = await serviceAxios({
      url: interfaces.deviceSelector,
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999
      }
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.id,

    }))

    return result;
  },
  //产品计量单位 
  billingMode: async function () {
    const response = await serviceAxios({
      url: interfaces.product_billing_mode,
      method: "get",
    })
    const result = response.data.data.map(item => ({
      label: item.name,
      value: item.value,
    }))
    return result;
  },
  //商圈
  businessCluster: async function () {
    const response = await serviceAxios({
      url: '/v1/prospective_customer/business_cluster/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id,
    }))
    return result;
  },
  subTemplate: async function () {
    const response = await serviceAxios({
      url: '/v1/product/input_template/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
        is_package: 0,
      }
    })
    let result = response.data.data.list.filter(item => item.devices == null)
    result = result.map(item => ({
      label: item.name,
      value: item.name,
      data: item
    }))
    return result;

  },

  deviceTemplate: async function () {
    const response = await serviceAxios({
      url: '/v1/product/input_template/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
        is_package: 0,
      }
    })
    let result = response.data.data.list.filter(item => item.devices != null)
    result = result.map(item => ({
      label: item.name,
      value: item.name,
      data: item
    }))

    return result;
  },
  template: async function () {
    const response = await serviceAxios({
      url: '/v1/product/input_template/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
        is_package: 1,
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.name,
      data: item
    }))
    return result;
  },
  tenant: async function () {
    const response = await serviceAxios({
      url: '/v1/organization/tenant/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.name,
      value: item.id,
      data: item
    }))
    return result;
  },
  temperature_sensor_host: async function () {
    const response = await serviceAxios({
      url: '/v1/iot/temperature_sensor_host/page',
      method: "get",
      params: {
        page_num: 1,
        page_size: 99999,
      }
    })
    const result = response.data.data.list.map(item => ({
      label: item.alias,
      value: item.id,
      data: item
    }))
    return result;
  }
}