<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal :maskClosable="false" width="800px" v-model:open="modalShow.addOrEdit" title="子行业信息" ok-text="确认"
    cancel-text="取消" @ok="addOrEditHandleOk">
    <a-form :model="baseData" ref="formRef" :label-col="{ span: 7 }" :wrapper-col="{ span: 13
   }" :rules="rules">
      <a-row :gutter="24">
        <template v-for="item in fieldAll.modalData" :key="item.key">
          <a-col :span="12">
            <a-form-item :label="item.title" :name="item.dataIndex">
              <a-select v-if="['brand'].includes(item.dataIndex)" v-model:value="baseData[item.dataIndex]" show-search
                placeholder="请选择数据" style="width: 200px" :options="options[item.dataIndex]"
                :filter-option="filterOption" @change="handleChangeSelect(item.dataIndex,$event)"></a-select>
              <a-input-number style="width:200px" v-if="['order'].includes(item.dataIndex)"
                v-model:value="baseData[item.dataIndex]" :placeholder="`请输入${item.title}`" />
              <a-input v-else style="width: 200px" v-model:value="baseData[item.dataIndex]"
                :placeholder="`请输入${item.title}`" />
            </a-form-item>
          </a-col>
        </template>
      </a-row>
    </a-form>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false" v-model:open="modalShow.isDeleteModalVisible" title="确认删除" @ok="handleOkDelete"
    @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="fieldAll.columns" :data-source="tableSource.data" :row-selection="rowSelection"
    :pagination="pagination" @change="handleTableChange" bordered
    style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a' @click="editData(record)">编辑</a>
          <!-- <a class='editable-row-a' @click="subTradePush(record.id)">子行业</a> -->
        </div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <label style="margin-right:5px">关键字</label>
        <a-input v-model:value="searchData.input" style="width:150px"></a-input>
        <a-button style="margin:2px" type="primary" @click="sift">搜索</a-button>
        <a-button style="margin:2px" type="primary" @click="reSift">重置</a-button>
        <div class='right-buttons'>
          <a-button class="addDataButton" v-if="buttonShow.add" type="primary" @click="addData">添加</a-button>
          <!-- <a-button class="addDataButton" v-if="buttonShow.edit" type="primary" @click="editData">编辑数据</a-button> -->
          <a-button class="addDataButton" v-if="buttonShow.delete" type="primary" @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const tradeId = route.query.tradeId
const url = {
  add: interfaces.subTradeAdd,
  edit: interfaces.subTradeEdit,
  delete: interfaces.subTradeDelete,
  page: interfaces.subTradePage,
  all: interfaces.subTradeSelector,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

const searchData = reactive({
  field: "",
  input: "",
})

// const getButtonRules = () => {
//   let route = useRoute()
//   if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
//     let rules = JSON.parse(localStorage.getItem("rules"))
//     rules = rules[route.fullPath]
//     if (rules != undefined) {
//       for (let key in buttonShow) {
//         buttonShow[key] = rules.includes(url[key]) ? true : false
//       }
//     }
//     else {
//       for (let key in buttonShow) {
//         buttonShow[key] = false
//       }
//     }
//   }
// }

// getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "行业",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "排序",
      dataIndex: "order",
      key: "order"
    },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [{
    title: "行业",
    dataIndex: "name",
    key: "name"
  },
  {
    title: "排序",
    dataIndex: "order",
    key: "order"
  },

  ]

})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: "",


})

//增加或者编辑的模态框规则
const rules = {
  order: [
    {
      required: true,
      message: "请输入品牌",
    },
  ],
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],

}

//选择框数据
const options = reactive({
  brand: "",
  search: "",
});

// 数据模型
const baseData = reactive({
  id: "",
  name: "",
  order: "",
  desc: ""
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  parent_id: tradeId
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach(item => {
      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        order: item.order,

      })
    });
    tableSource.data = data;
    console.log("表格数据源", tableSource.data)
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  console.log("record", record);
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }

};


//增加数据
const addData = () => {
  resetForm();
  baseData.order = 0;
  modalShow.addOrEdit = true;
  urlFlag = url.add
};

//删除数据
const deleteData = () => {

  if (selectedRowKey.value.length === 0) {
    message.error("请至少勾选一条数据");
  } else {
    modalShow.isDeleteModalVisible = true;
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  rowData.forEach((item) => {
    ids.push(item.id);
  });
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == "success") {
          queryPage()
        }
        else {
          message.error(result.data.message)
        }

      }


    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const addOrEditHandleOk = async () => {
  // baseData.ModalText = "The modal will be closed after two seconds";
  // baseData.confirmLoading = true;
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      name: baseData.name,
      order: baseData.order,
      parent_id: Number(tradeId),
      desc: baseData.desc == null ? '' : baseData.desc,
    }
    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })


  }
  catch {
    message.error("请填写完整信息");
  }


};


const getTicketsSelector = () => {
  serviceAxios(
    {
      url: "",
      method: "get",
      params: {

      }
    }
  ).then(
    result => {
      return result.data.data
    }
  ).catch(err => {
    console.log(err)
    message.error("请求异常，请联系管理员")
  })
}


const getBrand = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.name,
        });
      });
      options.brand = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};



const handleChangeSelect = (dataIndex, value) => {

};

//搜索
const sift = () => {
  queryField['keyword'] = searchData.input
  queryPage()
}

const reSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,
    parent_id: tradeId
  }
  searchData.input = null
  queryPage()
}

const subTradePush = (tradeId) => {
  router.push({
    path: "/subTrades",
    query: {
      tradeId: tradeId
    }
  })
}

getBrand();
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}
</style>