import { message } from "ant-design-vue";
// import websocketData from "../store/websocketData";
// 初始化WebSocket 具备自动重联功能
let idList = [];
const init_websocket = (reflush) => {

  let userId = Number(localStorage.getItem("id"))
  if (idList.includes(userId) && userId != 0) { }
  else {
    idList.push(userId)
    let webSockerUrl = process.env.VUE_APP_WEBSOCKET;
    // let webSockerUrl = 'http://61.169.129.195:18088'
    let reconnect_interval = 3000;
    let ws = new WebSocket("ws://" + webSockerUrl + "/ws?id=" + userId);


    // 显示消息
    const show_message = (data) => {
      // console.log("接收到消息")
      data = JSON.parse(data)
      // console.log("data", data)
      if (data.worker_ids != undefined) {
        console.log(data.worker_ids.includes(userId))
        if (data.worker_ids.includes(userId) || userId == 1) {
          // console.log("websocketData", websocketData)
          // websocketData = data
          // console.log("websocket", websocketData)
          reflush()
          message.info(`工单号:${data.work_sn},工作号:${data.work_content_sn},状态由${data.old_status}变为${data.new_status}`, 10)
        }

      }

    }
    // 当连接成功时
    ws.onopen = function (event) {
      console.log("websocket 连接成功");
    }

    // 当连接失败时
    ws.onerror = function (event) {
      console.error(`检测到连接错误`);
    }
    // 当断开连接时
    ws.onclose = function (event) {
      console.log("websocket 连接断开");
      setTimeout(init_websocket, reconnect_interval);

    };
    // 发送消息
    function sendMessage (event) {
      var input = document.getElementById("messageText")
      ws.send(input.value)
      input.value = ''
      event.preventDefault()
    }


    // 当 接收到消息时
    ws.onmessage = function (event) {
      show_message(event.data);
      // queryPage()
    };
  }

}
// init_websocket();  // 启动



export default { init_websocket }