<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-model-argument -->

<template>

  <!-- style=" white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" -->
  <div class='container-page'
       ref='containerRef'>
    <div class="title-box">
      <div class='title'>
        门店列表
      </div>
    </div>

    <div class='searchContainer'>
      <div class="keyword-box">
        <img class='keyword-image'
             src='@/static/demo/customer/搜索1.png' />
        <a-input v-model:value="searchData.keyword"
                 class='keyword'
                 :bordered='false'
                 :showArrow='false'
                 placeholder="请输入相关关键字">
        </a-input>
      </div>
      <div class='sift-region-box'>
        <a-tree-select :showArrow="false"
                       class="region-box"
                       v-model:value="searchData['region']"
                       show-search
                       :bordered='false'
                       style="width: 150px"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder="区域：请选择区域"
                       allow-clear
                       :tree-data="options['region']"
                       tree-node-filter-prop="label">
        </a-tree-select>
      </div>

      <div class="right-buttons">
        <div class='sift'
             @click="sift">
          <img class='sift-image'
               src='@/static/demo/customer/搜索1.png' />
          <div>
            搜索
          </div>
        </div>
        <div class='resetSift'
             @click="resetSift">
          <img class='reset-image'
               src='@/static/demo/customer/重置.png' />
          <div>
            重置
          </div>
        </div>
        <!-- <a-button type="primary"
                  @click="sift"
                  style="margin: 5px">搜索</a-button>
        <a-button type="primary"
                  @click="resetSift"
                  style="margin: 5px 0px 5px 0px">重置</a-button> -->
      </div>

    </div>

    <div class='table-box'>
      <a-table :scroll="ne"
               :columns="columns"
               :data-source="dataSource.data"
               :row-selection="rowSelection"
               :pagination="false"
               @change="handleTableChange"
               class="ant-table-striped"
               :row-class-name="(_record, index) => (index % 2 == 1 ? 'table-striped' : null)"
               :bordered=false
               style=" overflow: hidden; white-space: nowrap;  text-overflow: ellipsis;height:780px">
        <template #bodyCell="{ column, record }">
          <template v-if="['operationalStatus'].includes(column.dataIndex)">
            <div class="status-light">
              <a @click="operationalStatusClick(record.id)"
                 v-if='record.operationalStatus'
                 class="green-light"></a>
              <a @click="operationalStatusClick(record.id)"
                 v-if='!record.operationalStatus'
                 class="red-light"></a>

            </div>
          </template>

          <template v-else-if="column.dataIndex === 'operation'">
            <div>
              <a class='editable-row-a'
                 @click="toGlopt(record.regionId,record.id)">拓扑图</a>
              <a v-if='record.gateway && record.gateway.network_interfaces && record.gateway.network_interfaces.length >0'
                 class='editable-row-a'
                 @click="toMonitoring(record.gateway.network_interfaces,record.id,record.gateway.id)">监控信息</a>
            </div>
          </template>

          <template v-else-if="column.dataIndex === 'router'">
            <a @click=toWeb(record.web)
               class='editable-row-a'>{{record.router}}</a>
          </template>
        </template>
        <template #title>

        </template>
      </a-table>

      <div class='footer-pagination'>
        <div class='botton-style'
             @click='previous'
             :style="pagination.current > 1 ? { color: 'white' } : { color: '#999' }">上一页</div>
        <div class='pagination-text'>当前第 {{pagination.current}} 页 / 共 {{pagination.allPage}} 页 </div>
        <div class='botton-style'
             @click='nextPage'
             :style="pagination.current < pagination.allPage ? { color: 'white' } : { color: '#999' }">下一页</div>
      </div>

    </div>
  </div>
</template>
<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount } from "vue";

import { message } from "ant-design-vue";
import dayjs from "dayjs";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import zhCN from 'ant-design-vue/es/locale/zh_CN';

import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
// import { storeAndGateway } from '@/store'
import interfaces from "@/config";
import { checkTree } from "@/utils/regionTree";
import check from "@/utils/checkLocalStorage";
import { decimalUtils } from "@/utils/decimalUtils";

const locale = zhCN;
const route = useRoute();
const router = useRouter();


// const containerRef = ref(null);

// const originalWidth = 1920; // 原始宽度
// const originalHeight = 1080; // 原始高度

// function init (width, height) {
//   const element = containerRef.value;

//   if (!element) {
//     console.error('Container element not found.');
//     return;
//   }


//   console.log('window.inner宽高', window.innerWidth, window.innerHeight)
//   const hScale = window.innerHeight / height;
//   const wScale = window.innerWidth / width;
//   console.log('高宽比', hScale, wScale)
//   element.style.transform = `scale(${wScale}, ${hScale})`;
//   element.style.transformOrigin = "left top";
//   element.style.overflow = 'hidden';

// }

// onMounted(() => {
//   init(1920, 1080);
//   window.addEventListener('resize', handleResize);
// });
// const lazyFun = ref(null);

// const handleResize = () => {
//   clearTimeout(lazyFun);
//   lazyFun.value = setTimeout(() => {
//     init(1920, 1080);
//   }, 600);
// };


// onUnmounted(() => {
//   window.removeEventListener('resize', handleResize);
// });


const defaultDate = ref(dayjs()); // 使用moment获取当前日期时间作为默认面板日期
console.log("defaultDate", defaultDate)
onMounted(async () => {
  // 假设你存储用户信息的键是'user'  
  // const localMessage = check.checkData();
  // console.log("localMessage", localMessage)
  // // 检查用户信息是否存在  
  // if (!localMessage) {
  //   // 如果不存在，则跳转到登录页面  
  //   router.push('/loginView');
  // }

});
let timer = null;
onMounted(() => {
  timer = setInterval(queryPage
    , 30000);
})
onBeforeUnmount(() => {
  clearInterval(timer);
});
dayjs.locale("zh-cn");

//按钮的显示
let buttonShow = reactive({
  contractAdd: true,
  contractDelete: true,
  contractEdit: true,
  contractPage: true,
})

const getButtonRules = () => {
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(interfaces[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}







//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 12, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["16", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  allPage: computed(() => {
    return Math.ceil(pagination.total / pagination.pageSize);
  })
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;

  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

const formRef = ref(); //表单
const formRefDynamic = ref(); //表单

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

// 用户选择的列键的数组
const selectedColumns = ref([
  "id",
  "storeName",
  "brand",
  "officialName",
  "telephone",
  "contactName",
  "phone",
  "planBeginDate",
  "actualDate",
  "endDate",
  "region",
  "full_address",
  "paymentType",
  "duration",
  "payment_status",
  "paymentAmount",
  "invoice_type",
  "invoice_number",
  // "desc",
  // "productService",
  "create_time",
  // "owner",
  "operationalStatus",
  "operation"


]); // 初始选择所有列

const ne = reactive({
  x: "",
  y: ''
})





let showModal = reactive({
  show: false,
  advancedSearch: false,
  storeName: false,
  buttonShow: localStorage.getItem("name") == 'root' ? true : false, //控制删除和编辑按钮的显示
  message: false,
  isDeleteModalVisible: false,
  // test: true
});




//列名字段
const columns = [
  // { title: "ID", dataIndex: "id", key: "id", fixed: "left", width: 80 },
  { title: "门店名称", dataIndex: "storeName", key: "storeName", width: 200, ellipsis: true, },

  // {
  //   title: "品牌名称",
  //   dataIndex: "brand",
  //   key: "brand",
  //   width: 150,
  //   ellipsis: true,
  // },
  {
    title: "区域",
    dataIndex: "region",
    key: "region",
    // fixed: "left",
    width: 200,
    ellipsis: true,
  },
  {
    title: "地址",
    dataIndex: "address",
    key: "address",
    // fixed: "left",
    // width: 200,
    ellipsis: true,
  },


  {
    title: "运行状态",
    dataIndex: "operationalStatus",
    key: "operationalStatus",
    width: 100,
  },
  {
    title: "路由管理",
    dataIndex: "router",
    key: "router",
    width: 150,
    ellipsis: true,
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    width: 200,
  },
  {
    title: "操作",
    dataIndex: "operation",
    key: "operation",
    // fixed: "right",
    width: 200,
  },
];


//table数据源
let dataSource = reactive({
  data: [],


});



let searchData = reactive({

  region: null,
  keyword: ''

});






//页面筛选
const sift = () => {

  queryFields = {
    region_ids: searchData.region,
    keyword: searchData.keyword,
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryPage();
  message.info('搜索结束')
};

//重置筛选
const resetSift = () => {
  pagination.current = 1;
  pagination.pageSize = 12;
  let data = {
    page_num: pagination.current,
    page_size: pagination.pageSize,
  };
  queryFields = data;
  queryPage();
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null;

    }
  }
  message.info('重置搜索信息成功')
};

let options = reactive({
  region: [], //地区

});


const getRegionTree = async () => {
  const tree = await checkTree()
  options.region = tree[0]["children"]
}




const filter = (inputValue, path) => {
  return path.some(
    (option) =>
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  );
};


let queryFields = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
});

const queryPage = () => {
  serviceAxios({
    url: interfaces.hostPage,
    timeout: 30000,
    method: "get",
    params: queryFields,
  })
    .then((res) => {
      if (res.data.message = 'success') {
        pagination.total = res.data.data.total;
        let data = [];
        res.data.data.list.forEach((item) => {
          data.push({
            id: item.id,
            key: item.id,
            brand: item.customer,
            storeName: item.name,
            region: item.region.friendly_name.data,
            regionId: item.region.full_path.data,
            operationalStatus: false,
            create_time: item.create_time,
            gatewayId: item.gateway != undefined ? item.gateway.id : null,
            gateway: item.gateway != undefined ? item.gateway : null,
            web: item.gateway ? item.gateway.web : "",
            address: item.address,

            router: item.gateway != undefined ? item.gateway.vpn_ip : null
          });
        });

        let gatewayIdList = data.filter(item => item.gatewayId != null)
        let ids = []
        gatewayIdList.forEach(item => {
          ids.push(item.gatewayId)
        })

        //网关状态监测
        serviceAxios({
          url: interfaces.onlineStatus,
          method: 'post',
          data: {
            gateway_ids: ids
          }
        }).then(result => {
          if (result.data.message == 'success') {
            let enableGateList = data.filter(item => item.gatewayId != null)

            result.data.data.forEach(
              item => {
                let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.gateway_id)
                gatewayStatus.forEach(i => {
                  i.operationalStatus = item.value == '正常' ? true : false
                })
              }
            )
            // let copy = data
            // data.push(copy[0])
            // data.push(copy[1])


            dataSource.data = data;
            console.log('dataSource.data', dataSource.data)
          }
          else {
            message.error(result.data.message)
          }
        }).catch(error => {
          console.log(error)
          message.error('请求异常')
        })

      }
      else {
        message.error(res.data.message)
      }

    })
    .catch((err) => {
      console.log(err);
      message.error("请求异常，请联系管理员");
    });
};


getRegionTree();
queryPage();


const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


const toMonitoring = (networkInterfaces, storeId, gatewayId) => {
  let ids = []
  networkInterfaces.forEach(item => {
    ids.push(item.id)
  })
  ids = ids.join(',')
  // storeAndGateway.value.ids = ids
  // storeAndGateway.value.storeId = storeId

  router.push({

    path: "/storeDetailPage",
    query: { ids: ids, storeId: storeId, gatewayId: gatewayId }

  })
}

const toGlopt = (regionIds, storeId) => {
  console.log(regionIds, storeId)
  let region = regionIds.join(',')
  router.push({ path: "/glopt", query: { region: region, storeId: storeId } })
}

const checkOline = (ids) => {
  serviceAxios({
    url: interfaces.onlineStatus,
    method: 'post',
    data: {
      gateway_ids: ids
    }
  }).then(result => {
    if (result.data.message == 'success') {
      let enableGateList = dataSource.data.filter(item => item.gatewayId != null)
      result.data.data.forEach(
        item => {
          let gatewayStatus = enableGateList.filter(gateway => gateway.gatewayId == item.eth_number)
          gatewayStatus.forEach(i => {
            i.operationalStatus = item.value == '正常' ? true : false
          })
        }
      )
    }
    else {
      message.error(result.data.message)
    }
  }).catch(error => {
    console.log(error)
    message.error('请求异常')
  })
}

//路由管理跳转
const toWeb = (web) => {
  window.open(web, '_blank');
}

const back = () => {
  router.push({ path: '/homePageData' })
}

//向上翻页
const previous = () => {
  if (pagination.current > 1) {
    pagination.current -= 1
    queryFields.page_num = pagination.current
    queryPage()
  } else {

  }

}
//向下翻页
const nextPage = () => {
  if (pagination.current < pagination.allPage) {
    pagination.current += 1
    queryFields.page_num = pagination.current
    queryPage()
  }
  else {

  }

}
</script>
<style lang="less" scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/antTable.css";
@import "@/assets/css/pageTitle.css";
</style>