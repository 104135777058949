<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-modal :maskClosable="false"
           v-model:visible="showModal.show"
           title="角色信息"
           :afterClose="handleAfterClose"
           @ok="handleOk"
           @cancel="handleCancel"
           width=600px>
    <a-form :model="baseData"
            :rules=rules
            ref='formRef'
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 14 }">
      <a-form-item label="角色名称"
                   name='name'>
        <a-input v-model:value="baseData.name"
                 placeholder="请输入角色名称" />
      </a-form-item>

      <a-form-item v-if='modalType=="add"'
                   label="父角色"
                   name='parentRole'>
        <a-select v-model:value="baseData.parentRole"
                  mode="multiple"
                  :filter-option="filterOption"
                  style="width: 100%"
                  :options="options.parentRole"
                  placeholder="请选择父角色"></a-select>
      </a-form-item>
      <a-form-item label="备注"
                   name='desc'>
        <a-input v-model:value="baseData.desc"
                 placeholder="请输入备注" />
      </a-form-item>

      <a-form-item label="选择权限"
                   name='1'>
        <a-tree :height="300"
                v-model:expandedKeys="expandedKeys"
                v-model:selectedKeys="selectedKeys"
                v-model:checkedKeys="checkedKeys"
                checkable
                defaultExpandAll=true
                :tree-data="treeData.data"
                @check="handleCheck">
          <template #title="{ title, key }">
            <span v-if="key === '0-0-1-0'"
                  style="color: #1890ff">{{ title }}</span>
            <template v-else>{{ title }}</template>
          </template>
        </a-tree>
      </a-form-item>
    </a-form>

    <!-- 权限树组件 -->

  </a-modal>

  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           ref="tableRef"
           bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>

      <template v-if="['operations'].includes(column.dataIndex)">
        <div>
          <a class='editable-row-a'
             @click="edit(record)">编辑</a>
          <a class='editable-row-a'
             @click="del(record.id)">删除</a>
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div>
          <a-input addonBefore='关键字'
                   placeholder="请输入关键字"
                   v-model:value='siftRef.keyword'
                   style='width:180px'>
          </a-input>

          <a-button class="addDataButton"
                    type="primary"
                    @click="sift">搜索</a-button>

          <a-button class="addDataButton"
                    @click="resetSift">重置</a-button>
        </div>
        <div class="right-buttons">
          <a-button class="addDataButton"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    @click="batchDel">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, watch } from "vue";
import serviceAxios from "@/utils/serviceAxios";
import { selector } from "@/utils/selector";
import { message } from "ant-design-vue";

import interfaces from "@/config";


let baseData = reactive({
  name: "",
  id: "",
  desc: '',
  parentRole: [],
  role_ids: [],
  menu_ids: [],

});

let options = reactive({
  parentRole: [],
  all_roles_id: [],
  all_menu_id: [],
  rulesList: [] //编辑时角色权限信息 包含菜单权限和规则权限
});

//是对 a-table 的引用
const tableRef = ref(null);

//a-form 的引用
let formRef = ref("");

const rules = {
  name: [{ required: true, message: "必填", trigger: "blur" }],
  parentRole: [{ required: true, message: "必填", trigger: "blur" }],
};
const treeData = reactive({
  data: "",
});
const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});

// 树的点击事件
const handleCheck = (checked, { checkedNodes }) => {
  console.log(checked);
  console.log("111111111", checkedNodes);
  if (checkedNodes.length > 0) {

    let rulesData = [];
    let menuData = [];
    checkedNodes.forEach((item) => {
      if (typeof item.key === "number") {
        rulesData.push(item.key);
        item.parent_menu.forEach((item1) => {
          if (!menuData.includes(item1)) {
            menuData.push(item1);
          }

        })
      }
      else {
        let match = item.key.match(/\d+/);
        let menu = match ? Number(match[0]) : null;
        if (!menuData.includes(menu)) {
          menuData.push(menu);
        }
        if (item.parent_menu != undefined) {
          item.parent_menu.forEach((item1) => {
            if (!menuData.includes(item1)) {
              menuData.push(item1);
            }

          })
        }
      }

    })
    baseData.role_ids = rulesData;
    baseData.menu_ids = menuData;
    console.log(rulesData, menuData)
  }


};


//添加弹框
let modalType = ref('add');
let showModal = reactive({
  show: false,
  isDeleteModalVisible: false,
});
const columns = [
  {
    title: "角色",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "是否是基础角色",
    dataIndex: "is_base",
    key: "is_base",
  },
  {
    title: "备注",
    dataIndex: "desc",
    key: "desc",
  },
  {
    title: "操作",
    dataIndex: "operations",
    key: "operations",
  }

];

//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 16, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (newPagination) => {
  const { current, pageSize } = newPagination;
  console.log('pagination change', current)
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryFields.page_num = current;
  queryFields.page_size = pageSize;
  queryPage();
};

let dataSource = reactive({
  data: "",
});

// 选择框
let rowData = reactive([]);
let selectedRowKey = ref();
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    (selectedRowKey.value = selectedRowKeys),
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

//定义筛选字段
const siftRef = ref({
  keyword: ''
})

const sift = () => {
  queryFields['keyword'] = siftRef.value.keyword
  queryFields['page_num'] = 1
  queryFields['page_size'] = 10
  queryPage()
}
const resetSift = () => {
  siftRef.value.keyword = ''
  queryFields = {
    page_num: 1,
    page_size: 10,
  }
  queryPage()
}


//querypage查询字段
let queryFields = {
  page_num: pagination.current,
  page_size: pagination.pageSize,
}



// 查询数据
const queryPage = () => {

  serviceAxios({
    url: interfaces.rolePage,
    method: "post",
    data: queryFields,
  })
    .then((result) => {
      if (result.status == 200) {
        //修改分页模型
        pagination.total = result.data.data.total;

        dataSource.data = result.data.data.list.map(item => ({
          id: item.id,
          key: item.id,
          name: item.name,
          desc: item.desc,
          create_time: item.create_time,
          is_base: item.is_base === 1 ? '是' : "否", //是否是基础角色

        }));
        selectedRowKey.value = [];

      }
    })
    .catch((err) => {
      console.log(err);
    });
};
queryPage();
//添加
const addData = () => {
  modalType.value = 'add';
  baseData.name = "";
  baseData.desc = '';
  baseData.parentRole = [];
  expandedKeys.value = [];
  selectedKeys.value = [];
  checkedKeys.value = [];
  showModal.show = true;

};


const query_rules = (data) => {

  data.forEach((item) => {
    if (item.is_bottom != 0) {
      if (item.rules != undefined) {
        item.rules.forEach((item1) => {
          options.rulesList.push(item1.rule_id)
        })
      } else {
        options.rulesList.push(item.title + item.id)
      }
    }
    else {
      query_rules(item.children)
    }
  })

}

//编辑
const edit = async (record) => {
  console.log(record);
  baseData.id = record.id;
  baseData.desc = record.desc;
  baseData.name = record.name;

  showModal.show = true;
  modalType.value = 'edit';
  options.rulesList = []
  const response = await serviceAxios({
    url: interfaces.roleId,
    method: "get",
    params: {
      role_id: baseData.id,
    }
  })
  if (response.data.message == 'success') {
    query_rules(response.data.data);
    checkedKeys.value = options.rulesList;
  }
  else {
    message.error(response.data.message)
  }
}

//定义删除字段
let deleteData = {
  flag: false, //判断是批量删除还是单独删除
  id: '', //要删除的id
}

//删除
const del = (id) => {
  showModal.isDeleteModalVisible = true
  deleteData.flag = false
  deleteData.id = id

}


//批量删除数据
const batchDel = () => {
  showModal.isDeleteModalVisible = true
  deleteData.flag = true

};


//执行删除
const handleOkDelete = () => {
  console.log('handleOkDelete', handleOkDelete)
  if (deleteData.flag) {
    const params = {
      ids: rowData.map((item) => item.id),
    };
    serviceAxios({
      url: interfaces.roleDelete,
      method: "post",
      data: params,
    })
      .then((result) => {
        if (result.status === 200) {
          message.success("删除成功");
          queryPage();
          showModal.isDeleteModalVisible = false
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  else {
    serviceAxios({
      url: interfaces.roleDelete,
      method: "post",
      data: {
        ids: [deleteData.id]
      },
    })
      .then((result) => {
        if (result.status === 200) {
          message.success("删除成功");
          queryPage();
          showModal.isDeleteModalVisible = false


        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
// 提交角色权限规则信息

const submitRoleRule = () => {
  serviceAxios({
    url: interfaces.navigationAndRoleSet,
    method: "post",
    data: {
      role_id: baseData.id,
      rule_ids: baseData.role_ids,
      navigation_ids: baseData.menu_ids,
      // value: 1, //0是删除权限关联 1是添加关联
    },
  })
    .then((result) => {
      queryPage();
      showModal.show = false;
    })
    .catch(() => {
      message.success("请求异常，请联系管理员");
    });

};

//提交
const handleOk = () => {
  let url = "";
  let flag = true;
  let data = ''
  if (modalType.value == 'add') {
    // 添加
    url = interfaces.roleAdd;
    data = {
      name: baseData.name,
      parent_ids: baseData.parentRole,
      desc: baseData.desc,
    };
    if (data.name == '' || data.parent_ids.length === 0) {
      flag = false;
    }
    // console.log("名称", baseData.name);
  } else if (modalType.value == 'edit') {
    //编辑数据
    url = interfaces.roleEdit;
    data = {
      id: baseData.id,
      name: baseData.name,
      desc: baseData.desc,
    };
    if (data.name == '') {
      flag = false;
    }
  }
  if (flag) {
    serviceAxios({
      url: url,
      method: "post",
      data: data,
    })
      .then((result) => {
        if (result.data.message == 'success') {
          message.success("成功");
          if (modalType.value == 'edit') {
            submitRoleRule();
          } else {
            queryPage();
            showModal.show = false;
          }
        }
        else {
          message.error(result.data.message)
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }
  else {
    message.error('请检查数据')
  }



};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};



const handleAfterClose = () => {
  console.log("关闭");
};

//success:获取父ID
// 清洗tree的children数据
const clearTree = (data, menuid) => {
  console.log(data)
  let childrenData = [];
  if (data != undefined && data.length != 0) {
    data.forEach((item) => {
      // item.children = item.rules
      let parentList = []
      menuid.forEach(item => {
        parentList.push(item)
      })
      parentList.push(item.id)

      if (item.is_bottom == 0) {
        childrenData.push({
          id: item.id,
          key: item.title + item.id,
          title: item.title,
          parent_menu: menuid,
          children: clearTree(item.children, parentList),
        });
      }
      else {
        if (item.rules != undefined) {
          childrenData.push({
            id: item.id,
            key: item.title + item.id,
            title: item.title,
            parent_menu: menuid,
            children: clearRules(item.rules, parentList),
          })
        }
        else {
          childrenData.push({
            id: item.id,
            key: item.title + item.id,
            title: item.title,
            parent_menu: menuid,
          })
        }

      }

      if (!options.all_menu_id.includes[item.id]) {
        options.all_menu_id.push(item.id);
      }
    });
  } else {
    console.log("no clear0");
  }
  return childrenData;
};

const clearRules = (data, parentList) => {
  let childrenData = [];
  console.log("dataconsole", data)
  if (data != undefined && data.length != 0) {
    data.forEach((item) => {
      // item.children = item.rules
      let mnumObj = {
        id: item.id,
        key: item.id,
        title: item.name,
        parent_menu: parentList
      };
      if (!options.all_roles_id.includes[item.id]) {
        options.all_roles_id.push(item.id);
      }
      childrenData.push(mnumObj);
    });
  } else {
    console.log("no clear0");
  }
  return childrenData;
};

const queryTree = () => {
  serviceAxios({
    url: "/v1/permission/role_access/all",
    method: "get",
    data: "",
  })
    .then((result) => {
      if (result.status == 200) {
        let blockTree = [];
        result.data.data.forEach((item) => {
          blockTree.push({
            id: item.id,
            key: item.title + item.id,
            title: item.title,
            children: clearTree(item.children, [item.id]),
          });
          if (!options.all_menu_id.includes[item.id]) {
            options.all_menu_id.push(item.id);
          }
        });
        console.log("options.all_menu_id", options.all_menu_id);
        treeData.data = blockTree;
        console.log("treeData", treeData);
      }
    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err)
    });
};
queryTree();

const filterOption = (input, option) => {
  return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};



(async () => {
  options.parentRole = await selector.baseRole()
  // console.log('options.parentRole', options.parentRole)
})()
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}
</style>