<template>
  <div class="area-manger">
    <div class="header-title">
      部门管理
    </div>
    <div class="body-box">
      <div class="view-box">
        <div class="box-title">
          部门视图
        </div>
        <div class="view-content">
          <!-- <a-directory-tree></a-directory-tree> -->
          <a-tree v-model:expandedKeys="expandedKeys" v-model:selectedKeys="selectedKeys"
            v-model:checkedKeys="checkedKeys" checkable :checkStrictly="true" :multiple="false"
            :tree-data="treeData.data" @check="handleCheck">
            <template #title="{ title, key }">
              <span v-if="key === '0-0-1-0'" style="color: #1890ff">{{ title }}</span>
              <template v-else>{{ title }}</template>
            </template>
          </a-tree>
        </div>
      </div>
      <div class="action-box">
        <div class="box-title">
          操作
        </div>
        <div class="action-content">
          <div class="row-box" style="height: 20%;">
            <div class="left-half">
              <div class="row-title">添加部门</div>
              <div class="left-content">

                <a-input class="input_css" v-model:value="formModel.departmentName" placeholder=""
                  :disabled="showStatus.root"></a-input>
                <a-button type="primary" @click="addDepartment" :disabled="showStatus.root">添加</a-button>

              </div>
            </div>
            <div class="right-half">
              <div class="row-title">编辑名称</div>
              <div class="right-content">
                <a-input class="input_css" v-model:value="formModel.title" placeholder=""
                  :disabled="showStatus.edit"></a-input>
                <a-button type="primary" @click="editAreaName" :disabled="showStatus.edit">修改</a-button>

              </div>
            </div>

          </div>
          <div class="row-box" style="height: 30%;">
            <div class="left-half">
              <div class="row-title">添加职位</div>
              <!-- <div class="tip-text">说明：请先点击要添加子区域的区域，然后进行操作。如果选中的区域下已有注册用户，则禁止添加该区域的子区域</div> -->
              <div class="left-content">
                <a-input class="input_css" v-model:value="formModel.departmentName" placeholder=""
                  :disabled="showStatus.son"></a-input>
                <a-button type="primary" @click="addDepartment" :disabled="showStatus.son">添加</a-button>
              </div>
            </div>

            <div class="right-half">
              <div class="row-title">删除</div>
              <!-- <div class="tip-text">说明：请先点击要删除的区域，然后进行操作。若所选区域下有注册用户或所选区域有下级区域，则不允许删除。</div> -->
              <div class="right-content">

                <a-button type="primary" @click="deleteAreaName" :disabled="showStatus.delete">删除</a-button>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script  setup>
import interfaces from "@/config";
import serviceAxios from "@/utils/serviceAxios";
import { SHOW_ALL } from "ant-design-vue/es/vc-tree-select/utils/strategyUtil";
import { ref, reactive, watch } from "vue";
import { message } from "ant-design-vue";
import getPlacements from "ant-design-vue/es/_util/placements";
const rootRegion = ref(null);

const treeData = reactive({
  data: [
    {
      title: "parent 1",
      key: "0-0",
      children: [
        {
          title: "parent 1-0",
          key: "0-0-0",
          children: [
            {
              title: "leaf",
              key: "0-0-0-0",
              children: [],
            },
            {
              title: "leaf",
              key: "0-0-0-1",
              children: [],
            },
          ],
        },
        {
          title: "parent 1-1",
          key: "0-0-1",
          children: [
            {
              key: "0-0-1-0",
              title: "sss",
              children: [],
            },
          ],
        },
      ],
    },
  ],
});

const expandedKeys = ref([]);
const selectedKeys = ref([]);
const checkedKeys = ref([]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});

// 表单字段结构初始化数据  
const formModel = reactive({
  tenantName: "",
  tenantId: null,
  departmentName: "",
  parent_id: "",
  part: "" //表示点击的数据是属于哪一个模块

});

// 处理复选框点击事件，实现单选功能
const handleCheck = (checked, { checkedNodes, halfCheckedNodes, node }) => {
  console.log(node);
  console.log(checked);
  console.log("formModel", formModel)


  if (node) {
    if (checked.checked.length > 0) {
      // 如果节点被选中，清空checkedKeys数组并添加当前节点的key
      checkedKeys.value = [node.key];
      if (formModel.part != undefined && node.part == 'department') {
        formModel.parent_id = node.key;
        formModel.part = node.part;
      }

      showStatus.edit = false;
      showStatus.son = false;
    } else {
      // 如果节点被取消选中，清空checkedKeys数组
      checkedKeys.value = [];
      formModel.parent_id = null;
      showStatus.edit = true;
      showStatus.son = true;

      console.log(formModel);
    }
  }
};


let showStatus = reactive({
  root: false,
  edit: true,
  son: true,
  delete: true
})

//编辑区域名称
const editAreaName = () => {
  if (formModel.title == '') {
    message.error('请勾选区域')
  }
  else {
    serviceAxios({
      url: interfaces.regianlEdit,
      method: 'post',
      data: {
        id: formModel.id,
        name: formModel.title,
      }
    })
      .then((result) => {
        getTreeData()



      }).catch((err) => {
        message.error("请求异常，请联系管理员")
      })
  }
}



//添加部门
const addDepartment = () => {
  serviceAxios({
    url: interfaces.departmentAdd,
    method: 'post',
    data: {
      name: formModel.departmentName,
      tenant_id: formModel.tenantId,
      parent_id: formModel.parent_id == "" ? null : formModel.parent_id

    }
  })
    .then((result) => {
      getDepartmentTreeData()

    }).catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员")
    })
}
const clearTree = (data) => {
  let childrenData = []
  if (data.length != 0) {
    data.forEach((item) => {
      let mnumObj = {
        id: item.id,
        key: item.id,
        title: item.name,
        part: "department",
        parent_id: item.parent_id, //父节点

      };

      if (item.children != undefined) {
        mnumObj.children = clearTree(item.children)
      }

      childrenData.push(mnumObj)
    });
  } else {

    console.log("no clear0");
  }
  return childrenData;
};
const getDepartmentTreeData = () => {
  serviceAxios({
    url: interfaces.departmentTree,
    method: 'get',
    params: {
      include_job_titles: 1, //是否包含岗位信息
      include_accounts: 1, //是否包含账户信息
    }
  })
    .then(result => {
      let departmentTree = []
      result.data.data.forEach((item, index) => {

        if (item.children != undefined) {
          departmentTree.push({
            title: item.name,
            key: item.id,
            part: "department",
            children: clearTree(item.children)
          })
        }
        else {
          departmentTree.push({
            title: item.name,
            key: item.id,
            part: "department",
          })
        }
      });
      treeData.data = departmentTree
    }).catch(err => {
      console.log(err)
      message.error("请求异常，请联系管理员")
    });
}

getDepartmentTreeData()
const deleteAreaName = () => {

  serviceAxios({
    url: interfaces.departmentDelete,
    method: 'post',
    data: {
      // id:formModel.id,
      ids: [formModel.id]
    }
  })
    .then((result) => {

      if (result.status == 200) {
        message.success('删除成功')
        showStatus.delete = true;
        getTreeData()
      }
      else {
        message.error('删除失败')
      }


    }).catch((err) => {
      message.error("请求异常，请联系管理员")
    })
}



</script>

<style lang="less">
.area-manger {
  width: 100%;
  height: 100vh;
  padding: 24px;
  color: #000;
  background: #fff;

  .header-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border: 1px solid #c3c3c3;
    padding-left: 40px;
    font-size: 18px;
    font-weight: 500;
    background: #f0f5f7;
  }

  .body-box {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 40px);
    border: 1px solid #c3c3c3;
    border-top: none;

    .box-title {
      height: 40px;
      line-height: 40px;
      border-bottom: 1px solid #c3c3c3;
      padding-left: 10px;
      background-color: #e2ebff;
    }

    // .view-content, .action-content {
    //   padding: 10px;
    // }
    .view-box {
      width: 25%;
      height: 100%;
      border-right: 1px solid #c3c3c3;

      .view-content {
        // width: 100%;
        height: 600px;
        margin: 10px 20px 0 15px;
        background: #f0f6e4;
        overflow-y: scroll;
      }
    }

    .action-box {
      width: 75%;
      height: calc(100% - 100px);

      .action-content {
        padding: 10px;
        height: 100%;

        .row-box {
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-bottom: 20px;
          border: 1px solid #c3c3c3;
          .left-half {
            width: 50%;
            border-right: 1px solid #c3c3c3;
          }
          .right-half {
            width: 50%;
          }
          .row-title {
            height: 40px;
            line-height: 40px;
            background: #f0f5f7;
            padding-left: 40px;
            font-size: 16px;
            font-weight: 600;
          }
          .tip-text {
            height: 50px;
            background: #e2ebff;
          }
          .left-content {
            display: flex;
            justify-content: flex-start;
          }
          .right-content {
            display: flex;
            justify-content: flex-start;
          }
        }
        .row-box:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}
.input_css {
  // width: calc(80% - 200px)
  width: 150px;
}
</style>