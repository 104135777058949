<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->

<template>
  <a-modal :maskClosable="false"
           v-model:visible="showModal.show"
           title="权限规则"
           @ok="handleOk"
           @cancel="handleCancel">
    <a-form :model="formData"
            :rules=rules
            ref='formRef'>
      <a-form-item v-for="item in columns"
                   :key=item.key
                   :label=item.title
                   :name=item.dataIndex>
        <a-input v-if="item.dataIndex!=='navigation_title'"
                 v-model:value="formData[item.dataIndex]"
                 placeholder="" />

        <a-tree-select v-else
                       v-model:value="treeData.value"
                       show-search
                       style="width: 100%"
                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                       placeholder="选择字段"
                       allow-clear
                       tree-default-expand-all
                       :tree-data="treeData.data"
                       @select="onSelect"
                       tree-node-filter-prop="label">

        </a-tree-select>

        <template #title="{ value: val, label }">
          <b v-if="val === 'parent 1-1'"
             style="color: #08c">sss</b>
          <template v-else>{{ label }}</template>
        </template>
      </a-form-item>
    </a-form>

  </a-modal>

  <a-table :columns="columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           ref="tableRef"
           bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          <!-- <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a> -->
          {{ record.name }}
        </div>
      </template>

    </template>
    <template #title>
      <div class='searchDivContainer'>
        <a-cascader v-model:value="value"
                    :options="options"
                    placeholder="选择字段"
                    change-on-select />
        <a-input style="width:150px"></a-input>
        <a-button type="primary"
                  style='margin:2px'>搜索</a-button>
        <div class="right-buttons">
          <!-- <a-button
            class="addDataButton"
            type="primary"
            @click="addData"
          >添加</a-button> -->
          <a-button class="addDataButton"
                    type="primary"
                    @click="editData()">编辑数据</a-button>
          <!-- <a-button
            class="addDataButton"
            type="primary"
            @click="deleteUser()"
          >删除数据</a-button> -->
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, watch } from "vue";
import serviceAxios from "../../utils/serviceAxios";
import { message } from "ant-design-vue";

import interfaces from "@/config";


//是对 a-table 的引用
const tableRef = ref(null);

//a-form 的引用
let formRef = ref("");

const rules = {
  name: [{ required: true, message: "必填", trigger: "blur" }],
};

//添加弹框
let showModal = reactive({
  show: false,
});
const columns = [
  {
    title: "权限名称", dataIndex: "name", key: 1,
  },
  {
    title: "中文名称", dataIndex: "desc", key: 2,
  },
  {
    title: "所属菜单", dataIndex: "navigation_title", key: 3,
  },
];

const formData = reactive({
  id: "",
  key: "",
  name: "",
  desc: "",
  navigation_id: "",
  navigation_title: ""

});
//分页模型
let pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  hideOnSinglePage: true,
});

// 处理表格分页变化
const handleTableChange = async (pagination) => {
  const { current, pageSize } = pagination;
  queryData(current, pageSize);
};

let dataSource = reactive({
  data: "",
});

const treeData = reactive({
  data: "",
  value: ""
});

// 选择框
let rowData = [];
const selectedRowKey = ref([]);
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
    console.log("gaibianl")
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = selectedRows;
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = selectedRows;
    console.log(selected, selectedRows, changeRows);
  },
  onSelectNone: (selected, selectedRowKeys, selectedRows, changeRows) => {
    selectedRows = [];
    selectedRowKeys = [];
    rowData = [];
    console.log("11111", selected, selectedRowKeys, selectedRows, changeRows)
  },
});

// 查询数据
const queryData = (current, pageSize) => {
  let params = {
    keyword: null,
    page_num: current,
    page_size: pageSize,
  };
  serviceAxios({
    url: interfaces.systermPage,
    method: "post",
    data: params,
  })
    .then((result) => {
      if (result.status == 200) {
        selectedRowKey.value = []
        //更改翻页组件数据
        pagination.total = result.data.data.total;
        pagination.current = current;
        pagination.pageSize = pageSize;

        console.log("result", result);
        //更改dataSource数据
        let scpoeData = [];
        result.data.data.list.forEach((item) => {
          let dataObj = {};
          dataObj.id = item.id;
          dataObj.key = item.id;
          columns.forEach((item1) => {
            dataObj[item1.dataIndex] = item[item1.dataIndex];
          });
          dataObj.navigation_id = item["navigation_id"]

          scpoeData.push(dataObj);
        });
        dataSource.data = scpoeData;
        selectedRowKey.value = [];
      }
    })
    .catch((err) => {
      console.log("发生错误", err);
    });
};
queryData(pagination.current, pagination.pageSize);

//编辑数据
const editData = () => {
  console.log();
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    showModal.show = true;
    formData.key = rowData[0]["key"];
    formData.id = rowData[0]["id"];
    treeData.value = rowData[0]["navigation_id"]
    columns.forEach((item) => {
      if (item.dataIndex != "load_rate") {
        formData[item.dataIndex] = rowData[0][item.dataIndex];
      }
    });
    showModal.show = true;
  }
};

const handleOk = () => {
  serviceAxios({
    url: interfaces.systermSet,
    method: "post",
    data: formData,
  })
    .then((result) => {
      // console.log(result);
      if (result.status == 200) {
        message.success("修改成功");
        queryData(pagination.current, pagination.pageSize);
      } else {
        message.error("发生异常，请联系管理员");
      }

      showModal.show = false;
      // formRef.value.resetFields()
    })
    .catch(() => {
      message.error("发生异常错误，请联系管理员");
    });
  console.log("handleOk");
  // console.log('formRef',formRef.value)
};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};

// 处理复选框点击事件，实现单选功能
// const handleCheck = (checked, { checkedNodes, halfCheckedNodes, node }) => {
//   console.log(node);
//   console.log(checked);
// };

// const handleAfterClose = () => {
//   console.log("关闭");
// };

// 清洗tree的children数据
const clearTree = (data) => {
  let childrenData = []
  if (data.length != 0) {
    data.forEach((item) => {
      let mnumObj = {
        id: item.id,
        key: item.id,
        title: item.title,
        value: item.id,
        children: clearTree(item.children),
      };
      childrenData.push(mnumObj)
    });
  } else {

    console.log("no clear0");
  }
  return childrenData;
};

const queryTree = () => {
  serviceAxios({
    url: "/v1/permission/navigation/all",
    method: "post",
    data: ""
  }).then((result) => {

    if (result.status == 200) {
      let blockTree = []
      console.log("查询成功");
      result.data.data.forEach((item) => {
        blockTree.push({
          id: item.id,
          key: item.id,
          value: item.id,
          title: item.title,

          children: clearTree(item.children)
        })
      })
      treeData.data = blockTree
      console.log("treeData.data", treeData.data)
    }


  }).catch(() => { message.error("请求异常，请联系管理员") })
}
queryTree()





let value = ref();
watch(value, () => {
  console.log(value.value);
});




const onSelect = (value, node, extra) => {
  console.log(value, node, extra);
  formData.navigation_id = Number(value)
  console.log(value, node, extra);
}







</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}
</style>