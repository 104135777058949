<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>

  <div class='container-page'>
    <div class='title-box'>
      <div class='title'>能耗详情</div>
    </div>
    <div class='main-box'>
      <div class='sensorMessage-box'>
        <div class='right-operation-box'>
          <div class='right-date-box'>
            <a-space direction="vertical"
                     :size="12">
              <a-config-provider :locale="locale">
                <a-range-picker valueFormat='YYYY-MM-DD'
                                :bordered="false"
                                :showArrow="false"
                                class='date-com'
                                v-model:value="value1" />
              </a-config-provider>
            </a-space>
          </div>
          <img class='left-image'
               @click='setSensor'
               src='@/static/demo/sensorDetails/设置.png' />
          <!-- <img class='right-image'
                 src='@/static/demo/sensorDetails/解除预警.png' /> -->
        </div>

        <div class='up-box'>
          <div class='left-box'>
            <div class='left-up-box'>
              <img class='left-up-image'
                   src='@/static/demo/energy/未标题-1.png' />
              <div class='left-item-content'>
                <div class='item-value'>8</div>
                <div class='item-label'>当前警告数</div>
              </div>
            </div>
            <div class='left-down-box'>
              <img class='left-down-image'
                   src='@/static/demo/energy/趋势.png' />
              <div class='left-item-content'>
                <div class='item-value'>上升</div>
                <div class='item-label'>用电趋势</div>
              </div>
            </div>
          </div>
          <div class='box-up-middle'>
            <div>
              <img class='middle-image'
                   src='@/static/demo/sensorDetails/圆.png' />
            </div>
            <div class='now-temperature-value'>155KW

            </div>
            <div class='now-temperature-title'>
              耗电量
            </div>
          </div>
          <div class='right-box'>
            <div id='statisticsChart'
                 style='height:300px;width:100%'
                 :ref='statisticsChart'>
            </div>
          </div>

        </div>

        <div class='down-box'>
          <div class='down-header-box'>
            <view class='header-left-box'>
              <view :class="{
                'selected':item==selectedTitle,
                'un-selected':item!=selectedTitle,
               }"
                    v-for='item in titleList'
                    :key='item.key'
                    @click='selectItem(item)'>{{item}}</view>

            </view>
          </div>

          <div id='sensorChart'
               :ref='sensorChart'
               style='height:300px;width:100%'>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted, onBeforeUpdate, onUnmounted, watch, onBeforeUnmount, nextTick } from "vue";
import { message } from "ant-design-vue";
import * as echarts from "echarts";
import { useRouter, useRoute } from 'vue-router'
import { format } from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;

const route = useRoute();
const router = useRouter();

const url = {
  sensorMessage: '/v1/iot/temperature_sensor/get_by_id',//传感器信息
  temFlow: '/v1/iot/temperature_sensor/data' //传感器echart数据
}

const titleList = ['线路一', '线路二', '线路三', '线路88', '线路5', '线路6']
let selectedTitle = ref()

const selectItem = (titleName) => {
  selectedTitle.value = titleName
}
let statisticsChart = ref()
let statisticsChartOption = {
  // title: {
  //   text: 'Referer of a Website',
  //   subtext: 'Fake Data',
  //   left: 'center'
  // },
  tooltip: {
    trigger: 'item'
  },
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data: [
        { value: 1048, name: 'Search Engine' },
        { value: 735, name: 'Direct' },
        { value: 580, name: 'Email' },
        { value: 484, name: 'Union Ads' },
        { value: 300, name: 'Video Ads' }
      ],
      label: {
        show: true,
        formatter: '{b}: {d}%' // 显示名称和百分比
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
let sensorChart = ref()
//定义echarts图表数据

// let sensorChartOption = {
//   grid: {
//     top: '15%',
//     bottom: '10%',
//     left: '5%',
//     right: '10%',
//     containLabel: true
//   },

// };
let sensorChartOption = {
  xAxis: {
    type: 'category',
    axisLabel: {
      fontSize: 13,
      color: '#2B7BF8',
    },
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value',
    axisLine: {
      lineStyle: {
        color: '#FFFFFF'
      }
    },
    splitLine: {
      lineStyle: {
        color: ['rgba(255, 255, 255, 0.1)'],
        width: 1,
        type: 'solid'
      }
    },
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130, 120, 200, 150, 80, 70, 110, 130],
      type: 'bar',
      // showBackground: true,
      // backgroundStyle: {
      //   color: 'rgba(180, 180, 180, 0.2)'
      // },
      barWidth: '40%',
      // label: { // 添加此部分配置
      //   normal: {
      //     show: true,
      //     position: 'top',
      //     formatter: '{c}', // 显示的数据格式
      //     color: '#000', // 文字颜色
      //     fontSize: 12 // 文字大小
      //   }
      // },
      itemStyle: {
        normal: {
          color: '#2B7BF8',
          barBorderRadius: 5,
        },
        emphasis: {

          barBorderRadius: 5,
          shadowBlur: 20,
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowOffsetX: 0,
          shadowOffsetY: 10
        }
      },
    }
  ]
};

onMounted(async () => {


  nextTick(async () => {
    let chartDom = document.getElementById('sensorChart')
    let chart = echarts.init(chartDom)
    chart.setOption(sensorChartOption)


    let statistDom = document.getElementById('statisticsChart')
    let statis = echarts.init(statistDom)
    statis.setOption(statisticsChartOption)
  })
  // 在组件挂载时检查 sensorId 是否存在

});
</script>

<style lang='less' scoped>
@import "@/assets/css/tablePage.css";
@import "@/assets/css/common.css";
@import "@/assets/customer/energyDetails.css";
</style>