<!-- BreadcrumbComponent.vue -->
<template>
  <a-breadcrumb>
    <template #separator><span style="color: rgb(134, 134, 136);">/</span></template>
    <a-breadcrumb-item v-for="item in breadcrumbList" :key="item.path">
      <router-link style="color: rgb(134, 134, 136);font-size:16px"
        :to="item.path">{{ item.meta.breadcrumb }}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { useRoute } from 'vue-router';
import { computed } from 'vue';

const route = useRoute();

const breadcrumbList = computed(() => {
  console.log(route)
  return route.matched.filter(item => item.meta && item.meta.breadcrumb).map((item, index) => ({
    // path: index === 0 ? '/' : item.path,
    path: item.path,
    meta: item.meta,
  }));
});
</script>



<style lang="less" scoped>
.breadcrumb-box {
  color: rgb(134, 134, 136);
}
</style>