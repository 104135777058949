<!-- eslint-disable vue/no-mutating-props -->

<template>
  <div>
    <a-input-group compact>
      <div class='searchLabel'>
        <label style=''>地区</label>
      </div>
      <a-cascader :show-search="{ filter }"
                  :options="options.region"
                  :placeholder="`请选择区域`"
                  v-model:value="props.value"
                  :showArrow="showArrow"
                  @change="handleChange" />
    </a-input-group>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue'
import { checkTree } from '@/utils/regionTree'
const props = defineProps({
  showArrow: {
    type: Boolean,
    default: true,
  },
  value: {
    type: Array,
    // eslint-disable-next-line vue/require-valid-default-prop
    default: []
  }
});

const emit = defineEmits(['update:value']);

const options = ref({ region: [] });
const value = ref()

const handleChange = (newValue) => {
  emit('update:value', newValue);
};

onMounted(async () => {
  try {
    const treeData = await checkTree();
    options.value.region = treeData[0].children;
  } catch (error) {
    console.error('Error fetching region data:', error);
  }
});


const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};
</script>


 <style lang="less" scoped>
.searchLabel {
  padding: 4px 10px;
  border: 1px solid #dddddd;
  background-color: #f5f4f4;
  width: 50px;
}
</style>





