<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="温控主机信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13 }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">
                <a-select v-if="['host_id'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :options="options.host"
                          placeholder="请选择主机ID" />
                <a-select v-else-if="['send_alert'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :options="[{ label: '是', value: 1 }, { label: '否', value: 0 }]"
                          placeholder="请选择发送警报" />
                <a-input-number v-else-if="['duration','threshold'].includes(item.dataIndex)"
                                v-model:value="baseData[item.dataIndex]"
                                style='width:200px'
                                :placeholder="`请输入${item.title}`" />
                <a-input v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="tableSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>

        </div>
      </template>
      <template v-else-if="column.dataIndex === 'status'">
        <div :style='record.status=="失效"?sizeColor:greenColor'>{{ record.status }}</div>
      </template>
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <div>
          <table class="customerTable">
            <tr>
              <td class='column'
                  style='width:50px;'>关键字</td>
              <td>
                <a-input v-model:value="siftRef.keyword"
                         placeholder="请输入关键字"
                         :bordered="false"
                         style="width:150px"></a-input>
              </td>
              <td class='column'
                  style='width:50px;'>租户</td>
              <td> <a-select style='width:200px'
                          v-model:value="siftRef.tenant_id"
                          :options="options.tenant"
                          :bordered="false"
                          :showArrow="false"
                          defaultValue=''
                          placeholder="选择租户"
                          change-on-select /></td>
              <td class='column'
                  style='width:50px;'>门店</td>
              <td>
                <a-cascader style='width:200px'
                            :bordered="false"
                            :showArrow="false"
                            v-model:value="siftRef.store_id"
                            :options="options.storeAll"
                            defaultValue=''
                            placeholder="选择门店"
                            change-on-select />
              </td>
            </tr>
          </table>

        </div>

        <a-button style="margin:2px"
                  type="primary"
                  @click="sift">搜索</a-button>
        <a-button style="margin:2px"
                  @click="resetSift">重置</a-button>
        <div class='right-buttons'>
          <a-button class="addDataButton"
                    v-if="buttonShow.edit"
                    @click="refresh">刷新</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>

          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>
    </template>
    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import { selector } from '@/utils/selector'
import { useRouter, useRoute } from 'vue-router'

import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/iot/temperature_sensor/add',
  delete: '/v1/iot/temperature_sensor/delete',
  edit: '/v1/iot/temperature_sensor/edit',
  page: '/v1/iot/temperature_sensor/page',
  refresh: '/v1/iot/temperature_sensor/page',
  temperature_sensor_get_by_Id: '/v1/iot/temperature_sensor/get_by_id'
}
const redColor = { color: 'red' }
const greenColor = { color: 'green' }

//确认加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

const siftRef = ref({
  keyword: null,
  tenant_id: null,
  store_id: null
})

// const getButton = () => {
//   let route = useRoute()
//   buttonShow = commonFun.getButtonRules(route.fullPath, url)
// }
// getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      ellipsis: true,
    },
    {
      title: "所属主机",
      dataIndex: "host_alias",
      key: "host_alias",
      ellipsis: true,

    },
    {
      title: "组",
      dataIndex: "group_id",
      key: "group_id",
      ellipsis: true,

    },
    {
      title: "所属主机",
      dataIndex: "host_alias",
      key: "host_alias",
      ellipsis: true,

    },

    {
      title: "别名",
      dataIndex: "alias",
      key: "alias"
    },

    {
      title: "描述",
      dataIndex: "desc",
      key: "desc"
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time"
    },

    {
      title: "操作",
      dataIndex: "operation",
      key: "operation"
    }
  ],

  //弹框字段数据
  modalData: [

    {
      title: "主机ID",
      dataIndex: "host_id",
      key: "host_id",
      ellipsis: true
    },
    {
      title: "组ID",
      dataIndex: "group_id",
      key: "group_id",
      ellipsis: true
    },
    {
      title: "传感器序列号",
      dataIndex: "sensor_sn",
      key: "sensor_sn",
      ellipsis: true
    },
    {
      title: "传感器名称",
      dataIndex: "sensor_name",
      key: "sensor_name",
      ellipsis: true
    },
    {
      title: "模式类型",
      dataIndex: "mode_type",
      key: "mode_type",
      ellipsis: true
    },
    {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
      ellipsis: true
    },

    {
      title: "发送警报",
      dataIndex: "send_alert",
      key: "send_alert",
      ellipsis: true
    },
    {
      title: "传感器阈值",
      dataIndex: "threshold",
      key: "threshold",
      ellipsis: true
    },
    {
      title: "持续时间阈值",
      dataIndex: "duration",
      key: "duration",
      ellipsis: true
    },
    {
      title: "单位",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true
    },
    {
      title: "短信通知电话",
      dataIndex: "sms_alert_phones",
      key: "sms_alert_phones",
      ellipsis: true
    },
    {
      title: "预警电话",
      dataIndex: "tel_alert_phones",
      key: "tel_alert_phones",
      ellipsis: true
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true
    },
  ]
})

//table数据源
const tableSource = reactive({
  //table的基础数据
  data: [],
})

//增加或者编辑的模态框规则
const rules = {

  host_id: [
    {
      required: true,
      message: "请选择主机",
    },
  ],

  group_id: [
    {
      required: true,
      message: "请输入组ID",
    },
  ],
  sensor_sn: [
    {
      required: true,
      message: "请输入传感器SN号",
    },
  ],
  alias: [
    {
      required: true,
      message: "请输入别名",
    },
  ]
}

//选择框数据
const options = reactive({

  tenant: [],
  storeList: [],
  storeAll: [],
  host: []
});

// 数据模型
const baseData = reactive({
  id: null,
  host_id: null,
  group_id: 1,
  sensor_sn: null,
  sensor_name: null,
  mode_type: null,
  alias: null,
  desc: null,
  send_alert: 1,
  threshold: null,
  duration: null,
  unit: '℃',
  sms_alert_phones: [
    ''
  ],
  tel_alert_phones: [
    ''
  ]
})

//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false
})



let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;
    tableSource.data = result.data.data.list.map(item => ({
      id: item.id,
      key: item.id,
      alias: item.alias,
      store_id: item.store_id,
      store_name: item.store_name,
      host_alias: item.host_alias,
      tenant_id: item.tenant_id,
      tenant_name: item.tenant_name,
      desc: item.desc,
      create_time: item.create_time
    }));
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
};

const formRef = ref()


//编辑数据
const editData = async (record) => {
  console.log('record', record);

  try {
    let response = await serviceAxios({
      url: url.temperature_sensor_get_by_Id,
      method: 'get',
      params: { id: record.id } // 确保参数格式正确
    });
    console.log('response.data.data.message', response)
    if (response.data.message == 'success') { // 假设成功响应的状态码为200且返回的data中有一个success标志
      let data = response.data.data;

      if (data) {
        console.log("record", data);

        // 更新 baseData 对象中的属性
        for (let key in baseData) {
          if (data.hasOwnProperty(key)) { // 确保 data 对象中有这个属性
            baseData[key] = data[key];
          }
        }
        baseData.sms_alert_phones = data.sms_alert_phones ? data.sms_alert_phones[0] : ""
        baseData.tel_alert_phones = data.tel_alert_phones ? data.tel_alert_phones[0] : ""
        urlFlag = url.edit;
        showModal.addOrEdit = true;


      } else {
        console.error('No valid data returned from the server.');
      }
    } else {
      console.error('Server did not return a success response.');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
//

//增加数据
const addData = () => {
  resetForm();
  baseData.group_id = 1
  baseData.send_alert = 1
  baseData.unit = '℃'
  showModal.addOrEdit = true;
  urlFlag = url.add
};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};

//确认删除数据
// const handleOkDelete = () => {
//   // 执行删除操作
//   // console.log("执行删除操作");
//   let ids = [];
//   rowData.forEach((item) => {
//     ids.push(item.id);
//   });
//   serviceAxios({
//     url: url.delete,
//     method: "post",
//     data: {
//       ids: ids,
//     },
//   })
//     .then(
//       (result) => {
//         queryPage()
//       }

//     )
//     .catch((err) => {
//       message.error("请求异常，请联系管理员");
//       console.log(err);
//     });
//   showModal.isDeleteModalVisible = false;
// };



//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      host_id: baseData.host_id,
      group_id: baseData.group_id,
      sensor_sn: baseData.sensor_sn,
      sensor_name: baseData.sensor_name,
      mode_type: baseData.mode_type,
      alias: baseData.alias,
      desc: baseData.desc,
      send_alert: baseData.send_alert,
      threshold: baseData.threshold,
      duration: baseData.duration,
      unit: baseData.unit,
      sms_alert_phones: [baseData.sms_alert_phones],
      tel_alert_phones: [baseData.tel_alert_phones]
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch {
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  for (let key in siftRef.value) {
    if (siftRef.value[key]) {
      queryField[key] = key == 'store_id' ? siftRef.value[key][1] : siftRef.value[key]
    }
  }
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  siftRef.value.keyword = null
  siftRef.value.store_id = null
  siftRef.value.tenant_id = null
  queryPage()
}



const refresh = () => {
  queryPage()
}





const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};

const tenantChange = (value) => {
  console.log(value)
  options.storeList = options.storeAll.filter(item => item.data.tenant_id == value)[0].children
}


(async () => {
  options.storeAll = await selector.storesUnderTheBrand(1)
  options.storeList = []
  options.tenant = options.storeAll.map(item => ({
    value: item.data.tenant_id,
    label: item.data.brand_name
  }))
  options.host = await selector.temperature_sensor_host()
})()


</script>
<style lang="less" scoped>
@import "@/static/css/table.css";

.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}

.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
</style>