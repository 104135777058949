//去除字典中的值为null的键
const removeNullValues = (obj) => {
  return Object.keys(obj).reduce((result, key) => {
    if (obj[key] !== null) {
      result[key] = obj[key];
    }
    return result;
  }, {});
}



export default removeNullValues;