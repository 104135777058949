<!-- eslint-disable vue/no-parsing-error -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <div class='container'>
    <div class="title"><img src='@/static/aiopsHome/homeIcon.png'
           alt='Store Image'
           class='home-image-style' />iot智能管理系统</div>
    <div class='content-container'>
      <div class='left-container'>
        <div class='store-message-box'>
          <div class='store-message-title'><img src='@/static/aiopsHome/门店.png'
                 alt='Store Image'
                 class='image-style' />
            <div style="margin-left:5px">门店信息</div>
          </div>
          <div class='store-message-content'>
            <div class='store-message-content-item'>门店名称：上海域禾集控有限公司{{}}</div>
            <div class='store-message-content-item'>地址：上海市闵行区茸锦科技园1103号{{}}</div>
            <div class='store-message-content-item'>门店联系人：路理想{{}}</div>
            <div class='store-message-content-item'>联系人电话：13888888888{{}}</div>
            <div class='store-message-content-item'>监测时长：18天5小时24分{{}}</div>
          </div>

        </div>

        <div class="statistics-message-box">
          <div>
            <div>设备总数</div>
            <div></div>
          </div>
          <div>
            <div>在线率</div>
            <div></div>
          </div>
          <div>
            <div>在线设备</div>
            <div></div>
          </div>
          <div>
            <div>离线设备</div>
            <div></div>
          </div>
          <div>
            <div>异常设备</div>
            <div></div>
          </div>
        </div>
      </div>
      <div class='right-container'>
        <div class='alert-message-box'>
          <div class='message-title'><img src='@/static/aiopsHome/预警.png'
                 alt='Store Image'
                 class='image-style' />
            <div style="margin-left:10px">预警</div>
          </div>
          <div class='alert-text-box'>
            <div class='alert-text-item'>
              <div class='alert-text-item-time'>2024-9-29 12:01:36</div>
              <div class='alert-text-item-status'>严重</div>
              <div class='alert-text-item-storeName'>上海市域禾集控有限公司</div>
              <div class='alert-text-item-message'>大厅冰箱 </div>
              <div class='alert-text-item-message'>设备断电 </div>
            </div>

            <div class='alert-text-item'>
              <div class='alert-text-item-time'>2024-9-29 12:03:36</div>
              <div class='alert-text-item-status'>严重</div>
              <div class='alert-text-item-storeName'>上海市域禾集控有限公司</div>
              <div class='alert-text-item-message'>大厅冰箱 </div>
              <div class='alert-text-item-message'>设备断电 </div>
            </div>

            <div class='alert-text-item'>
              <div class='alert-text-item-time'>2024-9-29 22:01:36</div>
              <div class='alert-text-item-status'>严重</div>
              <div class='alert-text-item-storeName'>上海市域禾集控有限公司</div>
              <div class='alert-text-item-message'>大厅冰箱 </div>
              <div class='alert-text-item-message'>设备断电 </div>
            </div>
          </div>
        </div>
        <div class='alert-message-table'>
          <div class='message-title'><img src='@/static/aiopsHome/设备列表.png'
                 alt='Store Image'
                 class='image-style' />
            <div style="margin-left:10px">设备列表</div>
          </div>
          <div class='sift-box'>
            <div class='sift-title-box'> 
              <div>在线设备</div>
              <div>离线设备</div>
              <div>异常设备</div>
            </div>
            <div>设备名称</div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>

</script>


<style lang='less' scoped>
.container {
  height: 100vh;
  width: 100%;
  background-color: #fdfdfd;
}
.title {
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  margin-left: 24px;
  padding-top: 24px;
  .home-image-style {
    width: 35px;
    height: 35px;
  }
}
.content-container {
  display: flex;
  // flex-direction: column;
}
.left-container {
  width: calc(30vh + 24px);
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.store-message-box {
  width: 30vh;
  height: 230px;
  background-color: #ebeff5;
  border-radius: 8px;
  margin: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .image-style {
    width: 30px;
    height: 30px;
  }

  .store-message-title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 20px;
    // letter-spacing: 1px;
    color: #000000;
    margin: 12px 12px 6px 12px;

    display: flex;
    align-items: center;
    justify-content: left;
  }
  .store-message-content {
    font-size: 16px;
    color: #555;
    margin-left: 12px;
    margin-top: 0px;
  }
  .store-message-content-item {
    margin-top: 6px;
  }
}

.statistics-message-box {
  width: 30vh;
  flex-grow: 1;
  background-color: #ebeff5;
  border-radius: 8px;
  margin: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  text-align: left;
  .device-message-title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 20px;
    // letter-spacing: 1px;
    color: #000000;
    margin: 12px 12px 6px 12px;

    display: flex;
    align-items: center;
    justify-content: left;
  }
}
.right-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.alert-message-box {
  margin: 24px;
  height: 100px;
  background-color: #ebeff5;
  border-radius: 8px;
  display: flex;
  .alert-text-box {
    margin: 5px 0 5px 24px;
    flex-grow: 1;
    text-align: left;
    overflow: hidden;
  }
  .alert-text-item {
    display: flex;
    margin: 12px;
    // justify-content: space-between;
    .alert-text-item-time {
      display: flex;
      align-items: center;
      margin-left: 10px;
      margin-top: 2px;
    }
    .alert-text-item-status {
      color: red;
      margin-left: 10px;
    }
    .alert-text-item-storeName {
      margin-left: 10px;
    }
    .alert-text-item-message {
      margin-left: 10px;
    }
  }
}
.alert-message-table {
  flex-grow: 1;
  margin: 0 24px 24px 24px;
  background-color: #ebeff5;
  border-radius: 8px;
}

.image-style {
  width: 30px;
  height: 30px;
}

.message-title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 20px;
  // letter-spacing: 1px;
  color: #000000;
  margin: 12px 12px 6px 12px;

  display: flex;
  align-items: center;
  justify-content: left;
}
</style>
