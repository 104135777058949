<!-- eslint-disable vue/no-parsing-error -->
 <<template>
      <a-modal :maskClosable="false"
    title="添加客户"
   v-model:open = showModal
    width="700px"
    @ok="handleOkMessage"
    @cancel="handleCancelMessage"
   >
   <div> 
<table class="mailTable"  cellspacing="0" cellpadding="0" >

        <tr>
            <td class="column">品牌名称</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">区域</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>

        <tr>
            <td class="column">门店名称</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">签约公司</td>
            <td><a-input :bordered="false"></a-input></td>
        </tr>
        
         <tr>
            <td class="column">门店地址</td>
            <td colspan='3'><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">门店固话</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">联系人</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>
        
        <tr>
            <td class="column">联系方式</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">计划开通时间</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">实际开通时间</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">付费方式</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">合约签约周期</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">付款状态</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">付费金额</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">发票类型</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">发票号</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">描述</td>
             <td><a-input :bordered="false"></a-input></td>
        </tr>


        <tr>
            <td class="column">产品服务</td>
            <td><a-input :bordered="false"></a-input></td>
            <td class="column">附件</td>
             <td><a-input :bordered="false" placeholder="请输入数据"></a-input></td>
        </tr>



        

        </table>
        </div>
         </a-modal>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { ref } from "vue";

let showModal = ref(true)
</script>

<style lang="less" >
.mailTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.mailTable tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.mailTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 18%;
}
</style>