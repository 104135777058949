<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="1000px"
           v-model:open="modalShow.addOrEdit"
           title="商圈信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="submit"
           :maskClosable="false">
    <table class='customerTable'
           style='width:100%'>

      <tr>

        <td class='column'>商圈名称<span class="icon">*</span></td>
        <td colspan="3"> <a-input :bordered="false"
                   v-model:value="baseData.name"
                   placeholder="请输入商圈名称"></a-input></td>

      </tr>
      <tr>
        <td class='column'>区域<span class="icon">*</span></td>
        <td>
          <a-cascader :showArrow="false"
                      :bordered="false"
                      @change="cascaderOnChange"
                      v-model:value="baseData.regionId"
                      :show-search="{ filter }"
                      :options="options['region']"
                      :placeholder="`请选择区域`" />
        </td>
        <td class='column'>地址(不要输入地区)</td>
        <td>
          <a-input :bordered="false"
                   v-model:value="baseData.address"
                   placeholder="请输入详细地址"></a-input>
        </td>
      </tr>

      <tr>

        <td class='column'>资源信息</td>
        <td colspan="3">
          <a-checkbox-group v-model:value="baseData.source"
                            style="width: 100%">
            <a-row>
              <a-col class='source-item'
                     :span="8">
                <a-checkbox value="power">是否存在可用电源</a-checkbox>
              </a-col>
              <a-col :span="8"
                     class='source-item'>
                <a-checkbox value="space">是否存在可放置设备的空间</a-checkbox>
              </a-col>
              <a-col :span="8"
                     class='source-item'>
                <a-checkbox value="needLine">是否需要拉线</a-checkbox>
              </a-col>
              <a-col :span="8"
                     class='source-item'>
                <a-checkbox value="weakCurrentWell">是否存在可用弱电井</a-checkbox>
              </a-col>
              <a-col :span="8"
                     class='source-item'>
                <a-checkbox value="isMonopolized">是否代理管控</a-checkbox>
              </a-col>

            </a-row>
          </a-checkbox-group>
        </td>

      </tr>
      <tr v-if='baseData.source && baseData.source.includes("isMonopolized")'>
        <td class='column'>
          代理管控价格
        </td>
        <td colspan="3">
          <a-input :bordered="false"
                   v-model:value="baseData.monopolizedPrices"
                   placeholder="请输入代理管控价格"></a-input>
        </td>
      </tr>
      <tr>
        <td class='column'>
          物业联系人名字
        </td>
        <td>
          <a-input :bordered="false"
                   v-model:value="baseData.pmcContactName"
                   placeholder="请输入物业联系人名字"></a-input>
        </td>
        <td class='column'>
          物业联系人电话
        </td>
        <td>
          <a-input :bordered="false"
                   v-model:value="baseData.pmcContactPhone"
                   placeholder="请输入 物业联系人电话"></a-input>
        </td>
      </tr>
      <tr>
        <td class='column'>商圈网络资源</td>
        <td v-if='baseData.providerResources && baseData.providerResources.length == 0'><a class='blue-a'
             @click='addInternetSource'
             style='margin-left:12px'>添加</a></td>
        <td colspan="3">
          <thead v-if='baseData.providerResources && baseData.providerResources.length != 0'>
            <tr>
              <td style='width:110px;'
                  class='internet-source-title'>网络提供商</td>
              <td style='width:120px'
                  class='internet-source-title'>无线信号强度</td>
              <td style='width:120px'
                  class='internet-source-title'>线路是否可用</td>
              <td style='width:300px;'
                  class='internet-source-title'>说明</td>
              <td><a class='blue-a'
                   @click='addInternetSource'>添加</a></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in baseData.providerResources"
                :key='item.key'>
              <td style='width:110px'> <a-select :bordered="false"
                          v-model:value="item.name"
                          :showArrow="false"
                          style='width:100px;margin-left:-12px;'
                          placeholder="请选择网络运营商"
                          :options="options.serviceProvider"
                          @change="handleChangeCustomer"
                          show-search
                          :filter-option="filterOption"></a-select></td>
              <td style='width:120px'> <a-select :bordered="false"
                          v-model:value="item.rssi"
                          :showArrow="false"
                          style='width:100px;margin-left:-12px;'
                          placeholder="请选择强、中、或者弱"
                          :options="options.rssi"
                          @change="handleChangeCustomer"
                          show-search
                          :filter-option="filterOption"></a-select></td>
              <td style='width:120px'> <a-select :bordered="false"
                          v-model:value="item.available"
                          :showArrow="false"
                          style='width:100px;margin-left:-12px;'
                          placeholder="请选择是或者否"
                          :options="options.avaliable"
                          @change="handleChangeCustomer"
                          show-search
                          :filter-option="filterOption"></a-select></td>
              <td style='width:300px'><a-input :bordered="false"
                         v-model:value="item.desc"
                         style='margin-left:-12px;'
                         placeholder="请输入需要备注的信息"></a-input></td>
              <td><a class='blue-a'
                   @click='removeInternetSource(item)'>删除</a></td>
            </tr>
          </tbody>
        </td>
      </tr>
      <tr>
        <td class='column'>
          资源勘查是否完成
        </td>
        <td colspan="3">
          <a-select :bordered="false"
                    v-model:value="baseData.status"
                    :showArrow="false"
                    style='width:400px'
                    placeholder="请选择是或者否"
                    :options="options.avaliable"
                    show-search
                    :filter-option="filterOption"></a-select>
        </td>
      </tr>
      <tr>
        <td class='column'>描述</td>
        <td colspan="3"> <a-input :bordered="false"
                   v-model:value="baseData.desc"
                   placeholder="请输入需要备注的信息"></a-input></td>
      </tr>
    </table>

    <!-- <template v-for="equips in groupFields"
                :key="equips.key">
        <tr>
          <template v-for="item in equips"
                    :key="item.key">

            <td class='column'
                v-if="!['desc'].includes(item.dataIndex)">
              {{item.title}}<span style="color: red;margin-left: 0px;">*</span>
            </td>
            <td v-else
                class='column'>
              {{item.title}}
            </td>

            <td v-if="!['desc'].includes(item.dataIndex)">
              <a-select v-if="['account_id','pay_status','invoice_type_id'].includes(item.dataIndex)"
                        :bordered="false"
                        v-model:value="baseData[item.dataIndex]"
                        style="width:200px"
                        :showArrow="false"
                        :placeholder="`请选择${item.title}`"
                        :options="options[item.dataIndex]"
                        @change="handleChangeCustomer"
                        show-search
                        :filter-option="filterOption"></a-select>
              <a-cascader v-else-if="['contract_id'].includes(item.dataIndex)"
                          :bordered="false"
                          style="width:auto"
                          v-model:value="baseData.contract_message"
                          :options="options[item.dataIndex]"
                          :placeholder="`请选择${item.title}`"
                          @change="contractChange" />

              <a-input-number v-else-if="['pay_amount'].includes(item.dataIndex)"
                              style="width:100%"
                              :bordered="false"
                              v-model:value="baseData[item.dataIndex]"
                              :controls="false"
                              :step="0.01"
                              :placeholder="`请输入${item.title}`"></a-input-number>

              <a-cascader v-else-if="['region_id'].includes(item.dataIndex)"
                          :showArrow="false"
                          :bordered="false"
                          @change="cascaderOnChange"
                          v-model:value="baseData['region_id']"
                          :show-search="{ filter }"
                          :options="options['region']"
                          :placeholder="`请选择区域`" />

              <a-config-provider :locale="locale"
                                 v-else-if="['pay_time'].includes(item.dataIndex)">
                <a-date-picker show-time
                               :bordered=false
                               v-model:value="baseData['pay_time_time']"
                               format="YYYY-MM-DD HH:mm:ss"
                               :placeholder="['请选择日期', '请选择时间']"
                               @change="onPayTimeChange">
                </a-date-picker>
              </a-config-provider>
              <a-input v-else
                       :bordered="false"
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`"></a-input>
            </td>
            <td v-else
                colspan="3">
              <a-input :bordered="false"
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`"></a-input>
            </td>
          </template>
        </tr>
      </template> -->

  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <a-table :columns="fieldAll.columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>

    <template #title>
      <div class='searchDivContainer'>

      </div>
      <div class='searchDivContainer'>
        <div class='searchDivContainer'>

          <table class="searchTable">
            <template v-for="searchField in searchFields"
                      :key="searchField">
              <tr>
                <template v-for="item in searchField"
                          :key="item.key">
                  <td class='column'>
                    {{item.title}}
                  </td>
                  <td>
                    <a-tree-select v-if="['region_id'].includes(item.dataIndex)"
                                   :bordered="false"
                                   :showArrow="false"
                                   v-model:value="searchData['region_id']"
                                   show-search
                                   style="width: 100%"
                                   :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                   :placeholder="`请选择${item.title}`"
                                   allow-clear
                                   :tree-data="options['region']"
                                   tree-node-filter-prop="label">
                    </a-tree-select>
                    <a-input v-else
                             :bordered="false"
                             v-model:value="searchData[item.dataIndex]"
                             :placeholder="`请输入${item.title}`"></a-input>
                  </td>

                </template>
              </tr>
            </template>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    type="primary"
                    @click="resetSift">重置</a-button>
        </div>
        <div class='right-buttons'>
          <!-- <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="openSearchView">{{searchData.buttonValue}}</a-button> -->
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
  <script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { decimalUtils } from "@/utils/decimalUtils";
import { selector } from '@/utils/selector'
import { checkTree } from "@/utils/regionTree";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import dayjs from "dayjs";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;



const url = {
  add: interfaces.business_cluster_add,
  delete: interfaces.business_cluster_delete,
  edit: interfaces.business_cluster_edit,
  page: interfaces.business_cluster_page,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "商圈名", dataIndex: "name", key: "name" },
    { title: "区域", dataIndex: "regionName", key: "regionName" }, //区域的中文名称
    { title: "商圈地址", dataIndex: "address", key: "address" },
    { title: "是否完成资源勘查", dataIndex: "statusName", key: "statusName" },
    { title: "备注", dataIndex: "desc", key: "desc" },
    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }


  ],

  //弹框字段数据
  modalData: [
    [
      { "title": "名称", "dataIndex": "name", "key": "name" },
      { "title": "区域ID", "dataIndex": "regionId", "key": "regionId" },
      { "title": "地址", "dataIndex": "address", "key": "address" },
      { "title": "描述", "dataIndex": "desc", "key": "desc" },
      { "title": "电源", "dataIndex": "power", "key": "power" },
      { "title": "空间", "dataIndex": "space", "key": "space" },
      { "title": "需要线路", "dataIndex": "needLine", "key": "needLine" },
      { "title": "弱电井", "dataIndex": "weakCurrentWell", "key": "weakCurrentWell" },
      { "title": "联系人姓名", "dataIndex": "pmcContactName", "key": "pmcContactName" },
      { "title": "联系人电话", "dataIndex": "pmcContactPhone", "key": "pmcContactPhone" },
      { "title": "是否垄断", "dataIndex": "isMonopolized", "key": "isMonopolized" },
      { "title": "供应商资源", "dataIndex": "providerResources", "key": "providerResources" }
    ]
  ],

  //搜索框字段
  searchFields: [
    { title: "关键字", dataIndex: "keyword", key: "keyword" },
    { title: "区域", dataIndex: "regionId", key: "regionId" },

  ],

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)

//搜索框字段二位数组
const searchFields = commonFun.groupedItems(fieldAll.searchFields, 3)

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",


})



//选择框数据
const options = reactive({
  region_id: [],
  serviceProvider: [],//网络提供商
  rssi: [{ label: '强', value: '强' }, { label: '中', value: '中' }, { label: '弱', value: '弱' }],//网络信号强度
  avaliable: [{ label: '是', value: 1 }, { label: '否', value: 0 }],//是否有可用线路
  resourceSurveyCompleted: [{ label: '是', value: 1 }, { label: '否', value: 0 }]//资源勘察是否完成
});

// 数据模型
let baseData = ref({
  id: "",
  name: "",
  regionId: 0,
  address: "",
  desc: "",
  status: 0,
  power: 0,
  space: 0,
  needLine: 0,
  weakCurrentWell: 0,
  isMonopolized: 0,

  source: [],
  pmcContactName: "",
  pmcContactPhone: "",

  monopolizedPrices: "",
  providerResources: []

})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  contractMessage: false, // 添加里面的合同信息
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};

//定义资源字段
const sourceRef = ref()

let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,

})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []

    result.data.data.list.forEach((item) => {
      let regionNameArray = item.region_friendly_name.data.map(item => item.name);
      let regionIdArray = item.region_friendly_name.data.map(item => item.id);

      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        regionId: regionIdArray,
        regionName: regionNameArray.join(" "),
        addressName: regionNameArray.join(" ") + item.address,
        address: item.address,
        desc: item.desc,


        status: item.status,
        statusName: item.status == 1 ? '是' : '否',
        power: item.power,
        space: item.space,
        needLine: item.need_line,
        weakCurrentWell: item.weak_current_well,
        isMonopolized: item.is_monopolized,

        pmcContactName: item.pmc_contact_name,
        pmcContactPhone: item.pmcContactPhone,

        monopolizedPrices: item.monopolized_prices,
        providerResources: item.provider_resources

      })
    });

    dataSource.data = data;
    // console.log(dataSource.data);
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  baseData.value = {
    id: "",
    name: "",
    regionId: 0,
    address: "",
    desc: "",
    status: 0,
    power: 0,
    space: 0,
    needLine: 0,
    weakCurrentWell: 0,
    isMonopolized: 0,

    source: [],
    pmcContactName: "",
    pmcContactPhone: "",

    monopolizedPrices: "",
    providerResources: []
  }

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  for (let key in baseData.value) {
    baseData.value[key] = record[key];
  }
  baseData.value.source = []

  let sourceFields = ['power', 'space', 'needLine', 'weakCurrentWell', 'isMonopolized']
  sourceFields.forEach(item => {
    if (baseData.value[item] == 1) {
      baseData.value.source.push(item)
    }
  })

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ['name', 'region_id']

  if (!baseData.value.name || baseData.value.name == null) {
    message.error("请输入商圈名称")
    return
  }

  if (!baseData.value.regionId || baseData.value.regionId == null) {
    message.error("请选择区域")
    return
  }

  let data = {
    id: baseData.value.id,
    name: baseData.value.name,
    region_id: baseData.value.regionId[1],
    address: baseData.value.address,
    desc: baseData.value.desc,
    status: baseData.value.status,
    power: baseData.value.source.includes('power') == true ? 1 : 0,
    space: baseData.value.source.includes('space') == true ? 1 : 0,
    need_line: baseData.value.source.includes('needLine') == true ? 1 : 0,
    weak_current_well: baseData.value.source.includes('weakCurrentWell') == true ? 1 : 0,
    pmc_contact_name: baseData.value.pmcContactName,
    pmc_contact_phone: baseData.value.pmcContactPhone,
    is_monopolized: baseData.value.source.includes('isMonopolized') == true ? 1 : 0,
    monopolized_prices: baseData.value.monopolizedPrices,
    provider_resources: baseData.value.providerResources
  }
  serviceAxios({
    url: urlFlag,
    method: "post",
    data: data
  }).then(result => {
    if (result.data.message == 'success') {
      message.success("成功")

      modalShow.addOrEdit = false
      queryPage()
    }
    else {
      message.info(result.data.message)
    }


  }).catch(err => {
    console.log(err)
    message.error("处理数据发生异常，请联系管理员")
  })
}






const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  keyword: null, //关键字
  region_id: null,


  buttonValue: "打开搜索",
  searchViewFlag: false  //判断是否打开搜索框
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      queryField[key] = searchData[key]

    }
  }
  queryField = commonFun.removeNullStringValue(queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,

  }
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null
    }
  }
  console.log(searchData)
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};
//清洗区域树数据
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
      });
    }
  });
  return response;
};
//获取区域树
const getRegionTree = async () => {
  let tree = await checkTree()
  options.region = tree[0]["children"]
};


getRegionTree()













const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};










//添加网络资源信息
const addInternetSource = () => {
  console.log(' baseData.providerResources', baseData.value.providerResources)
  baseData.value.providerResources.push({
    name: null,
    rssi: null,
    available: 0,
    desc: ""
  })
}
//移除网络资源信息
const removeInternetSource = (item) => {
  const index = baseData.value.providerResources.indexOf(item)
  if (index !== -1) {
    baseData.value.providerResources.splice(index, 1);
  }
}


(async () => {
  options.serviceProvider = await selector.networkProvider()
})()

</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}
.source-item {
  padding: 10px 0 10px 12px;
  letter-spacing: 2px;
}
.internet-source-title {
  background-color: #edf1f3;
}
</style>