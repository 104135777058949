<template>
  <a-modal :maskClosable="false" v-model:visible="showModal.show" title="添加项目" @ok="handleOk" @cancel="handleCancel">
    <a-form :model="formData" ref="formRef" :rules=rules>
      <a-form-item v-for="item in columns" :key="item.key" :label=item.title :name=item.dataIndex>
        <a-input v-model:value="formData[item.dataIndex]" />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal :maskClosable="false" v-model:visible="columnShowModal" title="选择列显示" @ok="columnHandleOk"
    @cancel="columnHandleCancel">
    <a-form>
      <a-form-item label="选择列">
        <a-checkbox-group v-model:value="selectedColumns">
          <a-checkbox v-for="column in columns" :key="column.key" :value="column.key" :rules=rules>
            {{ column.title }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-table :columns="filteredColumns" :data-source="dataSource.data" :row-selection="rowSelection"
    :pagination="pagination" @change="handleTableChange" bordered>
    <template #bodyCell="{ column, record }">
      <template v-if="['name'].includes(column.dataIndex)">
        <div>
          {{ record.name }}
        </div>
      </template>
      <!-- <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a @click="edit(record.key)">编辑</a>
          <a @click="deleteUser(record.key)">删除</a>
        </div>
      </template> -->
    </template>
    <template #title>
      <div class='searchDivContainer'>
        <!-- <a-cascader
          v-model:value="value"
          :options="options"
          placeholder="Please select"
          change-on-select
        />
        <a-input style="width:150px"></a-input>
        <a-button type="primary">搜索</a-button> -->
        <a-button type="primary" @click="columnShowModal = true">筛选列显示</a-button>
        <div class="right-buttons">
          <a-button class="addDataButton" type="primary" @click="addData">添加</a-button>
          <a-button class="addDataButton" type="primary" @click="editData">编辑数据</a-button>
          <a-button class="addDataButton" type="primary" @click="deleteData('')">删除数据</a-button>
        </div>

      </div>
    </template>
  </a-table>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { reactive, ref, computed } from "vue";

import serviceAxios from "../../utils/serviceAxios";

import { message } from "ant-design-vue";
import interfaces from "@/config";

let formRef = ref();
let showModal = reactive({
  show: false,
});
const columns = [
  { title: "入户方式", dataIndex: "name", key: 1 },
  { title: "接入用户数", dataIndex: "accessUsersNumber", key: 2 },
];

let formData = reactive({
  "id": "",
  "key": "",
  "name": "",
})
let dataSource = reactive({
  data: ""
});
const rules = {
  name: [{ required: true, message: "必填", trigger: "blur" }],
};
//分页模型
const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (pagination) => {
  const { current, pageSize } = pagination;
  queryData(current, pageSize);
  // tableData.value = newData;
  // 更新分页的总数（如果后端返回了总数）
  // pagination.value.total = newData.total;
};

// table选择框
let rowData = [];
let selectedRowKey = ref()
const rowSelection = ref({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log(rowData);
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
});


//重置数据模型
const resetForm = () => {
  for (let key in formData) {
    console.log(key)
    formData[key] = ""
  }

}



//判断是添加还是修改数据
let url = ""
//添加
const addData = () => {
  resetForm()
  showModal.show = true

  url = interfaces.accessModeAdd
  // formRef.value.resetFields();

};
//编辑数据
const editData = () => {
  console.log();
  if (rowData.length != 1) {
    message.error("编辑数据时请选择一条数据");
  } else {
    url = interfaces.accessModeEdit
    formData["id"] = rowData[0]["id"]
    formData["key"] = rowData[0]["key"]
    columns.forEach((item) => {
      if (item.dataIndex != "load_rate") {
        formData[item.dataIndex] = rowData[0][item.dataIndex]
      }

    })
    showModal.show = true;

  }
};

const handleOk = () => {
  console.log("handleOk");
  console.log(formRef.value)
  console.log(formData)

  formRef.value.validate().then(() => {
    serviceAxios({
      url: url,
      method: "post",
      data: formData
    }).then(() => {
      // message.success("success")
      selectedRowKey.value = []
      queryData(pagination.current, pagination.pageSize);
    }).catch(() => {
      message.error("请求异常，请联系管理员")
    })


  }).catch(() => { message.error("表单验证失败，请检查输入信息") })

  showModal.show = false;
};

const handleCancel = () => {
  setTimeout(() => {
    showModal.show = false;
  }, 100);
};



//删除数据
const deleteData = () => {
  let deleteData = [];
  let jsonData = JSON.parse(JSON.stringify(rowData));
  jsonData.forEach((item) => {
    deleteData.push(item.id);
  });
  let params = {
    ids: deleteData,
  };

  serviceAxios({
    url: interfaces.accessModeDelete,
    method: "post",
    data: params,
  })
    .then((res) => {
      if (res.status == 200) {
        message.success("删除成功");
        queryData(pagination.current, pagination.pageSize);
      }
    })
    .catch((res) => {
      console.log(res);
    });
};




// queryData(pagination.current,pagination.pageSize);
//查询数据
const queryData = (current, pageSize) => {
  serviceAxios({
    url: interfaces.accessModePage,
    method: "get",
    params: {
      page_num: current,
      page_size: pageSize,
    },
  })
    .then((result) => {
      if (result.status == 200) {
        console.log(result);
        pagination.total = result.data.data.total;
        pagination.current = current;
        pagination.pageSize = pageSize;
        let scpoeData = [];
        result.data.data.list.forEach((item) => {
          let dataObj = {};
          dataObj.id = item.id;
          dataObj.key = item.id;
          columns.forEach((item1) => {
            try {
              dataObj[item1.dataIndex] = item[item1.dataIndex]
            }
            catch {
              dataObj[item1.dataIndex] = ""
              console.log(item1.dataIndex)
            }
          })
          scpoeData.push(dataObj);
        });
        dataSource.data = scpoeData;
        console.log("dataSource.data ", dataSource.data);
      }
    })
    .catch((err) => {
      console.log(err);
    });

};
queryData(pagination.current, pagination.pageSize);



// 用户选择的列键的数组
const selectedColumns = ref([1, 2, 3, 4, 7, 8]); // 初始选择所有列
// 控制模态框显示与隐藏的响应式引用
const columnShowModal = ref(false);

// 计算属性，根据用户选择的列来筛选列
const filteredColumns = computed(() => {
  return columns.filter((column) =>
    selectedColumns.value.includes(column.key)
  );
});

// 处理模态框确定按钮点击事件
const columnHandleOk = () => {
  columnShowModal.value = false;
  console.log(selectedColumns);
};

// 处理模态框取消按钮点击事件
const columnHandleCancel = () => {
  columnShowModal.value = false;
};













</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}
</style>