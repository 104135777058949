<!-- eslint-disable vue/no-v-model-argument -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="modalShow.addOrEdit"
           title="发票信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="submit"
           :maskClosable="false">
    <table class='mailTable'>
      <template v-for="equips in groupFields"
                :key="equips.key">
        <tr>
          <template v-for="item in equips"
                    :key="item.key">

            <td class='column'
                v-if="['type','name','code'].includes(item.dataIndex)">
              {{item.title}}<span style="color: red;margin-left: 0px;">*</span>
            </td>
            <td v-else
                class='column'>
              {{item.title}}
            </td>

            <td v-if="['type'].includes(item.dataIndex)">
              <a-select :bordered="false"
                        v-model:value="baseData[item.dataIndex]"
                        style="width:200px"
                        :showArrow="false"
                        :placeholder="`请选择${item.title}`"
                        :options="options[item.dataIndex]"
                        @change="handleChangeCustomer"
                        :filter-option="filterOption"></a-select>
            </td>

            <td v-else>
              <a-input :bordered="false"
                       v-model:value="baseData[item.dataIndex]"
                       :placeholder="`请输入${item.title}`"></a-input>
            </td>
          </template>
        </tr>

      </template>

    </table>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete"
           @cancel="handleCancelDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='searchDivContainer'>
    <div class='right-buttons'>
      <a-button class="addDataButton"
                v-if="buttonShow.add"
                type="primary"
                @click="openSearchView">{{searchData.buttonValue}}</a-button>
      <a-button class="addDataButton"
                v-if="buttonShow.add"
                type="primary"
                @click="addData">添加</a-button>
      <a-button class="addDataButton"
                v-if="buttonShow.delete"
                @click="deleteData">删除数据</a-button>
    </div>
  </div>

  <a-table :columns="fieldAll.columns"
           :data-source="dataSource.data"
           :row-selection="rowSelection"
           :pagination="pagination"
           @change="handleTableChange"
           bordered
           style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>

    <template #title>

      <div class='searchDivContainer'>
        <div class='searchDivContainer'
             v-if="searchData.searchViewFlag">
          <table class="searchTable">
            <template v-for="searchField in searchFields"
                      :key="searchField">
              <tr>
                <template v-for="item in searchField"
                          :key="item.key">
                  <td>
                    {{item.title}}
                  </td>
                  <td>
                    <a-select v-if="['type'].includes(item.dataIndex)"
                              :bordered="false"
                              :showArrow="false"
                              v-model:value="searchData[item.dataIndex]"
                              show-search
                              :placeholder="`请选择${item.title}`"
                              style="width: 100px"
                              :options="options['type']"
                              :filter-option="filterOption"
                              @focus="handleFocus"
                              @blur="handleBlur"
                              @change="handleChange"></a-select>
                    <a-input v-else
                             :bordered="false"
                             v-model:value="searchData[item.dataIndex]"
                             :placeholder="`输入${item.title}`"></a-input>
                  </td>
                </template>
              </tr>
            </template>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
        </div>

      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </a-table>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { checkTree } from "@/utils/regionTree";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import dayjs from "dayjs";

const url = {
  add: interfaces.finance_account_add,
  delete: interfaces.finance_account_delete,
  edit: interfaces.finance_account_edit,
  page: interfaces.finance_account_page,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "科目名称", dataIndex: "name", key: "name" },
    { title: "类型", dataIndex: "type", key: "type" },

    { title: "科目编码", dataIndex: "code", key: "code" },
    { title: "排序", dataIndex: "order", key: "order" },
    { title: "等级", dataIndex: "level", key: "level" },
    { title: "父科目名称", dataIndex: "parent_name", key: "parent_name" },
    { title: "父科目ID", dataIndex: "parent_id", key: "parent_id" },
    { title: "备注", dataIndex: "desc", key: "desc" },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", width: "120px", ellipsis: true },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
    // { title: "品牌", dataIndex: "customer_names", key: "customer_names", width: "150px", ellipsis: true },
    // { title: "地区", dataIndex: "regions_str", key: "regions_str", width: "100px", width: "150px", ellipsis: true },
    // { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode", width: "100px", ellipsis: true },
    // { title: "收费金额", dataIndex: "unit_price", key: "unit_price", width: "100px", ellipsis: true },
    // { title: "产品包", dataIndex: "is_package_name", key: "is_package_name", width: "100px", ellipsis: true },
    // { title: "产品包服务", dataIndex: "children_name", key: "children_name", width: "150px", ellipsis: true },
    // { title: "描述", dataIndex: "desc", key: "desc" },
    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

  ],

  //弹框字段数据
  modalData: [
    { title: "类型", dataIndex: "type", key: "type" },
    { title: "科目名称", dataIndex: "name", key: "name" },
    { title: "科目编码", dataIndex: "code", key: "code" },
    { title: "排序", dataIndex: "order", key: "order" },

    { title: "父科目ID", dataIndex: "parent_id", key: "parent_id" },
    { title: "备注", dataIndex: "desc", key: "desc" },
  ],

  //搜索框字段
  searchFields: [
    { title: "关键字", dataIndex: "keyword", key: "keyword" },
    { title: "类型", dataIndex: "type", key: "type" },
    { title: "编码", dataIndex: "code", key: "code" },
    { title: "层级", dataIndex: "level", key: "level" },
    { title: "父科目id", dataIndex: "parent_id", key: "parent_id" },
  ],

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)

//搜索框字段二位数组
const searchFields = commonFun.groupedItems(fieldAll.searchFields, 3)

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",


})

//增加或者编辑的模态框规则
const rules = {
  name: [{ required: true, message: "", }],
  // product_type: [{ required: true, message: "", }],
  customer_ids: [{ required: true, message: "", }],
  is_package: [{ required: true, message: "", }],
  // product_sub_type: [{ required: true, message: "", }],
  // billing_mode: [{ required: true, message: "", }],
  unit_price: [{ required: true, message: "", }],
  region: [{ required: true, message: "", }],
}

//选择框数据
const options = reactive({
  type: []
});

// 数据模型
const baseData = reactive({
  id: "",
  key: "",
  type: "", //类型
  code: "", //科目编码
  name: "", //科目名称
  order: "",  //排序
  level: "",
  parent_id: "",
  parent_name: "",
  des: "", //备注
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,

})

const queryPage = () => {
  console.log("queryField", queryField)
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach((item) => {
      data.push({
        id: item.id,
        key: item.id,
        type: item.type, //类型
        code: item.code, //科目编码
        name: item.name, //科目名称
        order: item.order,  //排序
        level: item.level,
        parent_id: item.parent_id,
        parent_name: item.parent_name,
        des: item.des, //备注

      })
    });

    dataSource.data = data;
    // console.log(dataSource.data);
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
    // if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
    //   baseData[key] = [];
    // }
  }

  checkedKeys.value = [];

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  console.log("record", record)
  modalShow.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData.id = record.id;

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.addOrEdit = true;
  urlFlag = url.add
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ["name", "type", "code"]
  let flag = commonFun.checkFields(baseData, mustInputFields)

  // await Promise.all([formRef.value.validate()])
  if (flag == true) {
    let data = {
      id: baseData.id,
      name: baseData.name,
      code: baseData.code,
      order: baseData.order,
      type: baseData.type,
      parent_id: baseData.parent_id,
      des: baseData.des, //备注
    }

    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }
  else {
    message.error("请填写完整信息");
  }




};








const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  keyword: null, //关键字
  type: null, //类型 /finance_account_type/selector 的内容
  code: null, //编码
  level: null, //层级
  parent_id: null, //父科目id
  buttonValue: "打开搜索",
  searchViewFlag: false  //判断是否打开搜索框
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      queryField[key] = searchData[key]

    }
  }
  queryField = commonFun.removeNullValues(queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,

  }
  for (let key in searchData) {
    if (!['buttonValue', 'searchViewFlag'].includes(key)) {
      searchData[key] = null
    }
  }
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};
//清洗区域树数据
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
      });
    }
  });
  return response;
};
//获取区域树
const getRegionTree = async () => {
  const tree = await checkTree()
  console.log("结构树数据", tree)
  options.region = clearRegionalTree(tree)
};


getRegionTree()
const getBilling_mode = () => {
  serviceAxios({
    url: interfaces.product_billing_mode,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.billing_mode = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getBilling_mode()






const getCustomer_names = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = []
      //data.push({
      //        label: "全部",
      //        value: "全部",
      //      });
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.customer_ids = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getCustomer_names()


//查询非产品包的产品
const queryNotPackage = () => {
  let params = {
    is_package: 0,
    page_num: 1,
    page_size: 9999,
  }
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: params
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        })
      })
      options.children_ids = data
    })
}
queryNotPackage()

const type_selector = () => {
  // finance_account_type_selector
  serviceAxios({
    url: interfaces.finance_account_type_selector,
    method: 'get',

  }).then(
    result => {

      options.type = result.data.data.map(item => ({
        label: item.name,
        value: item.value
      }))


    }
  ).catch(error => { console.log(error) })
}
type_selector()

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>
<style lang="less">
.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
  width: 11%;
}
</style>