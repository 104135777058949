<!-- eslint-disable vue/multi-word-component-names -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-multiple-template-root -->
<template>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="1500px"
           v-model:open="modalShow.addOrEdit"
           title="AIOps产品信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="submit"
           :maskClosable="false">
    <div class='juzhong-box'>

      <table class='customerTable'>
        <tr>
          <td class='column'>产品名称<span class='icon'>*</span></td>
          <td>

            <!-- <a-input :bordered="false"
                   v-model:value="baseData.name"
                   :placeholder="`请输入产品名称`"></a-input> -->
            <a-select v-model:value="baseData.name"
                      placeholder="请输入或选择"
                      show-search
                      allow-clear
                      style="width: 200px"
                      :filter-option="filterOption"
                      :options='options.template'
                      @change="(value,option)=>templateChange(value,option,index,'template')"
                      @search="(value)=>onSearch(value,index,'template')"
                      @blur="(value,option)=>onBlur(value,index,'template')">
            </a-select>

          </td>
          <td class='column'>品牌<span class='icon'>*</span></td>
          <td> <a-select :bordered="false"
                      v-model:value="baseData.customer_ids"
                      style="width:200px"
                      :placeholder="`请选择品牌`"
                      :options="options.customer_ids"
                      @change="handleChangeCustomer"
                      :filter-option="filterOption"></a-select></td>
        </tr>

        <tr>
          <td class='column'>地区<span class='icon'>*</span></td>
          <td> <a-tree-select :bordered="false"
                           :showArrow="false"
                           v-model:value="baseData.region"
                           show-search
                           style="width:100%"
                           :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                           placeholder="选择区域"
                           allow-clear
                           multiple
                           :tree-data="options['region']"
                           :fieldNames="{children:'children', label:'title', value: 'key' }"
                           tree-node-filter-prop="title">
            </a-tree-select></td>
          <td class='column'>收费类型<span class='icon'>*</span></td>
          <td><a-select style="width: 200px"
                      :bordered="false"
                      v-model:value="baseData.billing_mode"
                      show-search
                      :placeholder="`请选择收费类型`"
                      :options="options.billing_mode"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur"
                      @change="handleChange"></a-select></td>
        </tr>
        <!-- <tr>
          <td class='column'>地区</td>
          <td colspan="3">{{baseData.regions_str}}</td>
        </tr> -->
        <tr>

          <td class='column'>默认产品包<span class='icon'>*</span></td>
          <td colspan="3"><a-select style="width: 200px"
                      :bordered="false"
                      v-model:value="baseData.isDefaultProductPackage"
                      show-search
                      :placeholder="`请选择收费类型`"
                      :options="options.isDefaultProductPackage"
                      :filter-option="filterOption"
                      @focus="handleFocus"
                      @blur="handleBlur"></a-select></td>
        </tr>

        <tr>
          <td class='column'>产品<span class='icon'>*</span></td>
          <td colspan="3">
            <table class='customerTable'>
              <thead>
                <tr>
                  <td class='sub-item-title'>子产品名称<span class='icon'>*</span></td>
                  <td class='sub-item-title'>产品类型<span class='icon'>*</span></td>
                  <td class='sub-item-title'>计量单位<span class='icon'>*</span></td>
                  <td class='sub-item-title'>单价<span class='icon'>*</span></td>
                  <td class='sub-item-title'>数量<span class='icon'>*</span></td>
                  <td class='sub-item-title'>总价(元)</td>
                  <td class='sub-item-title'><a class='blue-a'
                       @click='addSub'>增加</a></td>
                </tr>
              </thead>
              <tbody v-if='baseData.subProduct'>
                <tr v-for='item,index in baseData.subProduct'
                    :key='item.key'>
                  <td class='sub-item-title'>

                    <div>
                      <a-select v-model:value="item.name"
                                placeholder="请输入或选择"
                                show-search
                                allow-clear
                                style="width: 200px"
                                :filter-option="filterOption"
                                :options='options.subTemplate'
                                @change="(value,option)=>templateChange(value,option,index,'sub')"
                                @search="(value)=>onSearch(value,index,'sub')"
                                @blur="(value,option)=>onBlur(value,index,'sub')">
                      </a-select>

                    </div>
                  </td>
                  <td class='sub-item-title'>
                    <a-select style="width: 150px"
                              :bordered="false"
                              v-model:value="item.product_sub_type"
                              show-search
                              :placeholder="`请选择产品子类`"
                              :options="options.subProductType"
                              :filter-option="filterOption"
                              @focus="handleFocus"
                              @blur="handleBlur"></a-select>
                  </td>
                  <td class='sub-item-title'>

                    <a-select style="width: 100px"
                              :showArrow="false"
                              :bordered="false"
                              v-model:value="item.billing_mode"
                              show-search
                              :placeholder="`请选择`"
                              :options="options.billing_mode"
                              :filter-option="filterOption"
                              @focus="handleFocus"
                              @blur="handleBlur"
                              @change="handleChange"></a-select>

                    <!-- <a-select v-if='item.product_sub_type == "设备租赁"'
                            :bordered="false"
                            style='width:100px'
                            v-model:value="item.device_id"
                            show-search
                            placeholder="选择设备"
                            :options="options.devices"
                            :filter-option="filterOption"></a-select> -->
                  </td>
                  <td class='sub-item-title'>

                    <a-input-number :bordered="false"
                                    :controls="false"
                                    style="width:100px"
                                    v-model:value="item.unit_price"
                                    :step="0.01"
                                    @blur='computedPrice(index,"subProduct")'
                                    :placeholder="`请输入单价`"></a-input-number>
                  </td>
                  <td class='sub-item-title'>

                    <a-input-number :bordered="false"
                                    :controls="false"
                                    style="width:100px"
                                    @blur='computedPrice(index,"subProduct")'
                                    v-model:value="item.quantity"
                                    :placeholder="`请输入数量`"></a-input-number>
                  </td>
                  <td class='sub-item-title'>{{item.all_price}}</td>
                  <td class='sub-item-title'><a class='blue-a'
                       @click='removeSub(item)'>删除</a></td>
                </tr>
              </tbody>
              <!-- <tr v-if='baseData.unit_price&& baseData.unit_price != 0 && baseData.unit_price != ""'>
              <td colspan="6"
                  style="text-align: right;font-weight:600;font-size:18px">
                产品总价：{{baseData.unit_price}} 元
              </td>
            </tr> -->
            </table>
          </td>

        </tr>
        <tr>
          <td class='column'>租赁产品</td>
          <td colspan="3">
            <a class='blue-a'
               v-if='baseData.devices && baseData.devices.length == 0'
               @click='addDevice'>增加</a>
            <table class='customerTable'
                   v-if='baseData.devices && baseData.devices.length != 0'>
              <thead>
                <tr>
                  <td class='sub-item-title'>子产品名称<e class='icon'>*</e>
                  </td>
                  <td class='sub-item-title'>产品类型<span class='icon'>*</span></td>
                  <td class='sub-item-title'>设备<span class='icon'>*</span></td>
                  <td class='sub-item-title'>计量单位<span class='icon'>*</span></td>
                  <td class='sub-item-title'>单价<span class='icon'>*</span></td>
                  <td class='sub-item-title'>数量<span class='icon'>*</span></td>
                  <td class='sub-item-title'>总价(元)</td>
                  <td class='sub-item-title'
                      style='width:200px'><a class='blue-a'
                       @click='addDevice'>增加</a></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for='item,index in baseData.devices'
                    :key='item.key'>
                  <td class='sub-item-title'>
                    <!-- <a-input :bordered="false"
                           v-model:value="item.name"
                           style='width:auto'
                           :placeholder="`请输入产品名称`"></a-input> -->

                    <a-select v-model:value="item.name"
                              placeholder="请输入或选择"
                              show-search
                              allow-clear
                              style="width: 200px"
                              :filter-option="filterOption"
                              :options='options.deviceTemplate'
                              @change="(value,option)=>templateChange(value,option,index,'device')"
                              @search="(value)=>onSearch(value,index,'device')"
                              @blur="(value,option)=>onBlur(value,index,'device')">
                    </a-select>

                  </td>
                  <td class='sub-item-title'>
                    <div style='width:100px'> 设备租赁</div>

                  </td>
                  <td class='sub-item-title'>

                    <!-- <a-select :bordered="false"
                            style='width:100px'
                            v-model:value="item.device_id"
                            show-search
                            placeholder="选择设备"
                            :options="options.devices"
                            :filter-option="filterOption"></a-select> -->
                    <table>
                      <thead>
                        <tr>
                          <td>设备名称</td>
                          <td>数量</td>
                          <td><a class='blue-a'
                               style='width:100px'
                               @click='addSubDevices(index)'>添加设备</a></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for='device in item.devices'
                            :key='device.id'>
                          <td>
                            <a-select :bordered="false"
                                      style='width:auto;min-width:100px'
                                      v-model:value="device.device_id"
                                      show-search
                                      placeholder="选择设备"
                                      :options="options.devices"
                                      :filter-option="filterOption"></a-select>
                          </td>
                          <td> <a-input-number :bordered="false"
                                            :controls="false"
                                            style="width:100px"
                                            v-model:value="device.quantity"
                                            @blur='computedPrice(index,"device")'
                                            :placeholder="`请输入数量`"></a-input-number></td>
                          <td> <a class='blue-a'
                               style='width:100px'
                               @click='removeSubDevices(device,index)'>删除设备</a></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <a-select style="width: 100px"
                              :showArrow="false"
                              :bordered="false"
                              v-model:value="item.billing_mode"
                              show-search
                              :placeholder="`请选择`"
                              :options="options.billing_mode"
                              :filter-option="filterOption"
                              @focus="handleFocus"
                              @blur="handleBlur"
                              @change="handleChange"></a-select>
                  </td>
                  <td class='sub-item-title'>

                    <a-input-number :bordered="false"
                                    :controls="false"
                                    style="width:100px"
                                    v-model:value="item.unit_price"
                                    :step="0.01"
                                    @blur='computedPrice(index,"device")'
                                    :placeholder="`请输入单价`"></a-input-number>
                  </td>
                  <td class='sub-item-title'>

                    <a-input-number :bordered="false"
                                    :controls="false"
                                    style="width:100px"
                                    @blur='computedPrice(index,"device")'
                                    v-model:value="item.quantity"
                                    :placeholder="`请输入数量`"></a-input-number>
                  </td>
                  <td class='sub-item-title'>{{item.all_price}}</td>
                  <td class='sub-item-title'>
                    <a class='blue-a'
                       @click='removeDevice(item)'>删除</a>
                  </td>
                </tr>
              </tbody>
              <!-- <tr v-if=' baseData.unit_price&& baseData.unit_price != 0 && baseData.unit_price != ""'>
              <td colspan="6"
                  style="text-align: right;font-weight:600;font-size:18px">
                产品总价：{{baseData.unit_price}} 元
              </td>
            </tr> -->
            </table>
          </td>

        </tr>
        <tr>
          <td class='column'>产品价格</td>
          <td colspan="3">
            <div v-if='baseData.unit_price'
                 style='font-size:18px;font-weight:600'> {{baseData.unit_price}}元</div>
          </td>
        </tr>
        <tr>
          <td class='column'>备注</td>
          <td colspan="3"><a-input :bordered="false"
                     v-model:value="baseData.desc"
                     :placeholder="`请输入备注`"></a-input></td>
        </tr>
      </table>
    </div>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="modalShow.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>

  <TableComponent :columns="fieldAll.columns"
                  :data-source="dataSource.data"
                  :row-selection="rowSelection"
                  :pagination="pagination"
                  @change="handleTableChange"
                  style=" overflow: hidden !important; white-space: nowrap;  text-overflow: ellipsis;">
    <template #bodyCell="{ column, record }">
      <template v-if="['names'].includes(column.dataIndex)">
        <div>
          <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
        </div>
      </template>
      <template v-else-if="column.dataIndex === 'operation'">
        <div class="editable-row-operations">
          <a class='editable-row-a'
             @click="editData(record)">编辑</a>
          <a class='editable-row-a'
             @click="deleteData(record.key)">删除</a>
        </div>
      </template>
    </template>

    <template #title>

      <div class='searchDivContainer'>
        <div class='searchDivContainer'>

          <table class="searchTable">
            <tr>
              <td style='width:50px'
                  class='column'>
                区域
              </td>
              <td>
                <a-tree-select :bordered="false"
                               :showArrow="false"
                               v-model:value="searchData.region"
                               show-search
                               style="width:100%"
                               :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                               placeholder="选择区域"
                               allow-clear
                               :tree-data="options['region']"
                               :fieldNames="{children:'children', label:'title', value: 'key' }"
                               tree-node-filter-prop="title">
                </a-tree-select>
              </td>
              <td style='width:100px'
                  class='column'>
                关键字查询
              </td>
              <td style='width:150rpx'>
                <a-input :bordered="false"
                         v-model:value="searchData.keyword"
                         :placeholder="`输入关键字`"></a-input>
              </td>
              <td style='width:50px'
                  class='column'>
                品牌
              </td>
              <td>
                <a-select :bordered="false"
                          :showArrow="false"
                          v-model:value="searchData.customer_ids"
                          show-search
                          placeholder="选择品牌"
                          style="width: 120px"
                          :options="options['customer_ids']"
                          :filter-option="filterOption"
                          @focus="handleFocus"
                          @blur="handleBlur"
                          @change="handleChange"></a-select>
              </td>
              <!-- <td style='width:120px'
                  class='column'>
                是否是产品包
              </td>
              <td style='width:120px'>
                <a-select :bordered="false"
                          :showArrow="false"
                          v-model:value="searchData.is_package"
                          show-search
                          placeholder="选择产品包"
                          style="width: 120px"
                          :options="options['is_package']"
                          :filter-option="filterOption"
                          @focus="handleFocus"
                          @blur="handleBlur"
                          @change="handleChange"></a-select>
              </td> -->
            </tr>
            <tr>

            </tr>
          </table>
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    type="primary"
                    @click="resetSift">重置</a-button>
        </div>
        <div class='right-buttons'>
          <!-- <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="openSearchView">{{searchData.buttonValue}}</a-button> -->
          <a-button class="addDataButton"
                    v-if="buttonShow.add"
                    type="primary"
                    @click="addData">添加</a-button>
          <a-button class="addDataButton"
                    v-if="buttonShow.delete"
                    @click="deleteData">删除数据</a-button>
        </div>
      </div>

    </template>
    <!-- <template #footer>Footer</template> -->
  </TableComponent>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import serviceAxios from "@/utils/serviceAxios";
import { commonFun } from "@/utils/commonFun";
import { checkTree } from "@/utils/regionTree";
import { decimalUtils } from "@/utils/decimalUtils";
import { message } from "ant-design-vue";
import { reactive, ref, watch } from "vue";
import interfaces from "@/config";
import { selector } from '@/utils/selector';
import { useRouter, useRoute } from 'vue-router';
import dayjs from "dayjs";

const url = {
  add: interfaces.productAdd,
  delete: interfaces.productDelete,
  edit: interfaces.productEdit,
  page: interfaces.productPage,
}


//按钮的显示
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})



const getButtonRules = () => {
  let route = useRoute()
  if (localStorage.getItem("id") != 1 && localStorage.getItem("id") != 2) {
    let rules = JSON.parse(localStorage.getItem("rules"))
    rules = rules[route.fullPath]
    if (rules != undefined) {
      for (let key in buttonShow) {
        buttonShow[key] = rules.includes(url[key]) ? true : false
      }
    }
    else {
      for (let key in buttonShow) {
        buttonShow[key] = false
      }
    }

  }
}

// getButtonRules()


const fieldAll = reactive({
  // table列名数据
  columns: [
    { title: "产品名称", dataIndex: "name", key: "name", fixed: "left", width: "150px", ellipsis: true },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", width: "120px", ellipsis: true },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type", width: "150px", ellipsis: true },
    { title: "品牌", dataIndex: "customer_names", key: "customer_names", width: "150px", ellipsis: true },
    { title: "地区", dataIndex: "regions_str", key: "regions_str", width: "100px", width: "150px", ellipsis: true },
    { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode", width: "100px", ellipsis: true },
    { title: "收费金额", dataIndex: "unit_price", key: "unit_price", width: "100px", ellipsis: true },
    { title: "产品包", dataIndex: "is_package_name", key: "is_package_name", width: "100px", ellipsis: true },
    { title: "产品包服务", dataIndex: "children_name", key: "children_name", width: "150px", ellipsis: true },
    { title: "是否为默认产品包", dataIndex: "isDefaultProductPackageName", key: "isDefaultProductPackageName" },
    { title: "描述", dataIndex: "desc", key: "desc" },
    { title: "操作", dataIndex: "operation", key: "operation", fixed: "right", width: "120px", ellipsis: true }

  ],

  //弹框字段数据
  modalData: [
    { title: "产品名称", dataIndex: "name", key: "name" },
    // { title: "所属类型", dataIndex: "product_type", key: "product_type", },
    // { title: "产品类型", dataIndex: "product_sub_type", key: "product_sub_type" },
    { title: "品牌", dataIndex: "customer_ids", key: "customer_ids" },
    { title: "地区", dataIndex: "region", key: "region" },
    { title: "收费类型", dataIndex: "billing_mode", key: "billing_mode" },
    { title: "收费金额", dataIndex: "unit_price", key: "unit_price" },

    { title: "是否为套餐包", dataIndex: "is_package", key: "is_package" },
    { title: "是否为默认产品包", dataIndex: "isDefaultProductPackage", key: "isDefaultProductPackage" },
    { title: "子产品", dataIndex: "children_ids", key: "children_ids" },
    { title: "描述", dataIndex: "desc", key: "desc" },
  ]

})

// 弹框字段的二维数组
const groupFields = commonFun.groupedItems(fieldAll.modalData, 2)



//table数据源
const dataSource = reactive({
  //table的基础数据
  data: "",



})

//增加或者编辑的模态框规则
const rules = {
  name: [{ required: true, message: "", }],
  // product_type: [{ required: true, message: "", }],
  customer_ids: [{ required: true, message: "", }],
  is_package: [{ required: true, message: "", }],
  // product_sub_type: [{ required: true, message: "", }],
  // billing_mode: [{ required: true, message: "", }],
  unit_price: [{ required: true, message: "", }],
  region: [{ required: true, message: "", }],
}

//选择框数据
const options = reactive({
  template: [],
  subTemplate: [],
  deviceTemplate: [],
  product_type: [],
  product_sub_type: [],
  region: [],
  customer_ids: [],
  billing_mode: [],
  product_sub_type_option: "信息化工程",
  children_ids: [], //非产品包的产品
  is_package: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
  subProductType: [],//产品子类
  isDefaultProductPackage: [{ label: "是", value: 1 }, { label: "否", value: 0 }],
  devices: []
});

// 数据模型
const baseData = reactive({
  name: "",
  product_type: "",
  product_sub_type: "",
  customer_names: "",
  customer_ids: "",
  region: "",
  billing_mode: "",
  unit_price: "",
  // info: "",
  is_package: "",
  isDefaultProductPackage: "",
  children_ids: [],
  subProduct: [],
  devices: [],
  desc: "",

  regions_str: ''
})


//弹框显示
const modalShow = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  searchView: false,
  unit_price: false, // 收费金额是否可编辑
})

let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
  product_type: "行业信息化",
  // product_sub_type: 'AIOps',
  is_package: 1,
})

const queryPage = () => {
  serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  }).then(result => {
    pagination.total = result.data.data.total;

    // 更新表格数据
    let data = []
    result.data.data.list.forEach((item) => {
      let customer_name = "";
      let customer_label = [];
      let customer_ids = [];
      let regions_str = []
      let children_ids = []
      let children_name = []
      item.customers.forEach((cus) => {
        customer_label.push(cus.name);
        customer_ids.push(cus.id);
      });
      item.regions.forEach(region => {
        regions_str.push(region.name)
      })
      if (item.is_package == 1) {
        item.children.forEach(child => {
          children_ids.push(child.id)
          children_name.push(child.name)
        })
        children_name = children_name.join(" ")
      }
      else {
        children_name = ""
      }
      regions_str = regions_str.join(" ")
      customer_name = customer_label.join(" ")
      data.push({
        id: item.id,
        key: item.id,
        name: item.name,
        product_type: item.product_type,
        product_sub_type: item.product_sub_type,
        billing_mode: item.billing_mode,
        unit_price: decimalUtils.ensureAtLeastTwoDecimals(item.unit_price),
        info: item.info,
        desc: item.desc,
        // children: item.children,
        children_ids: children_ids,
        children_name: children_name,
        is_package: item.is_package,
        is_package_name: item.is_package == 0 ? "否" : "是",
        isDefaultProductPackage: item.is_default,
        isDefaultProductPackageName: item.is_default == 0 ? "否" : "是",

        customer_names: customer_name == '' ? "全部品牌" : customer_name,
        customer_label: customer_label,
        customer_ids: customer_ids,
        customers: item.customers,
        subProduct: item.children.filter(sub => sub.devices == undefined), //子产品
        devices: item.children.filter(sub => sub.devices != undefined),//租赁产品

        regions_str: regions_str,
        regions: item.regions,
      });
    });
    dataSource.data = data;
    // console.log(dataSource.data);
  }).catch(err => { console.log(err), message.error("获取数据失败，请联系管理员") })
}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
    if (['children_ids', 'customer_names', 'customer_ids', "region"].includes(key)) {
      baseData[key] = [];
    }
  }

  checkedKeys.value = [];

};

const formRef = ref()


//编辑数据
const editData = (record) => {
  urlFlag = url.edit
  modalShow.addOrEdit = true;
  // //modalShow.unit_price = true
  for (let key in baseData) {
    baseData[key] = record[key];
  }
  baseData.info = "";

  baseData.customer_ids = record.customer_ids.length == 0 ? ['全部'] : record.customer_ids;
  let region = [];
  record.regions.forEach((item) => {
    region.push(item.id);
  });


  checkedKeys.value = region;
  baseData.region = region;
  baseData.id = record.id;

  baseData.children_ids = record.children_ids
  console.log('basedata', baseData)
  // all_price
  baseData.devices.forEach(item => {
    item["all_price"] = item.quantity * item.unit_price
  })

  baseData.subProduct.forEach(item => {
    item['all_price'] = item.quantity * item.unit_price
  })

};


//增加数据
const addData = () => {
  resetForm();
  modalShow.unit_price = false;
  baseData.is_package = 0;
  baseData.isDefaultProductPackage = 0;
  modalShow.addOrEdit = true;
  urlFlag = url.add
  baseData.subProduct = []
  baseData.devices = []
  baseData.subProduct.push({
    name: null,
    product_sub_type: null,
    billing_mode: null,
    quantity: null,
    unit_price: '',
    all_price: '',
    device_id: null,
  })
};



//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})

//删除数据
const deleteData = (data) => {

  if (typeof (data) == 'number') {
    modalShow.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      modalShow.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};

//确认删除数据
const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: interfaces.productDelete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      result => {
        if (result.data.message == 'success') {
          deleteFlag = {
            flag: "",
            data: ""
          }
          queryPage()
          queryNotPackage()
          message.success("删除成功");

        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      console.log(err)
      message.error("请求异常，请联系管理员");
    });
  modalShow.isDeleteModalVisible = false;
};



//提交
const submit = async () => {
  let mustInputFields = ["name", "customer_ids", "region", "billing_mode", "unit_price",]
  let flag = commonFun.checkFields(baseData, mustInputFields)

  // await Promise.all([formRef.value.validate()])
  if (flag == true) {
    let customer_ids = []
    console.log(baseData.customer_ids, 'baseData.customer_ids')
    customer_ids = typeof (baseData.customer_ids) == 'number' ? [baseData.customer_ids] : baseData.customer_ids

    let children = baseData.subProduct.concat(baseData.devices)
    console.log('children', children)
    // children.forEach(item => {
    //   if (typeof (item.name) == 'string') { }
    //   else {
    //     item.name = item.name[0]
    //   }
    // })
    let data = {
      id: baseData.id,
      name: baseData.name,
      product_type: "行业信息化",
      product_sub_type: "AIOps",
      customer_ids: customer_ids,
      region_ids: baseData.region,
      billing_mode: baseData.billing_mode,
      unit_price: baseData.unit_price,
      operator_id: Number(localStorage.getItem("id")),
      is_default: baseData.isDefaultProductPackage,
      // children_ids: baseData.children_ids,
      desc: baseData.desc == null ? "" : baseData.desc,
      children: children
    }

    serviceAxios({
      url: urlFlag,
      method: "post",
      data: data
    }).then(result => {
      if (result.data.message == 'success') {
        message.success("成功")

        modalShow.addOrEdit = false
        queryPage()
        queryNotPackage()
      }
      else {
        message.info(result.data.message)
      }


    }).catch(err => {
      console.log(err)
      message.error("处理数据发生异常，请联系管理员")
    })
  }
  else {
    message.error("请填写完整信息");
  }




};








const handleChangeSelect = (dataIndex, value) => {

};

//搜索数据
const searchData = reactive({
  region: [],
  keyword: "",
  customer_ids: null,
  is_package: null,
  searchViewFlag: false
})

//打开搜索框
const openSearchView = () => {
  if (searchData.buttonValue == '打开搜索') {
    searchData.buttonValue = '关闭搜索'
    searchData.searchViewFlag = true
  } else {
    searchData.buttonValue = '打开搜索'
    searchData.searchViewFlag = false

  }

}

//搜索
const sift = () => {
  queryField["region_id"] = searchData.region
  queryField["keyword"] = searchData.keyword
  if (searchData.customer_ids !== '') {
    queryField["customer_id"] = searchData.customer_ids == '全部' ? null : searchData.customer_ids

  }
  if (searchData.is_package !== '') {
    queryField["is_package"] = searchData.is_package
  }

  console.log("queryField", queryField);
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: 10,
    product_type: "行业信息化",
    product_sub_type: 'AIOps',
    is_package: 1,
  }
  searchData.region = []
  searchData.keyword = ""
  searchData.customer_ids = null
  searchData.is_package = null
  queryPage()
}

//region 事件

const expandedKeys = ref(["0-0-0", "0-0-1"]);
const selectedKeys = ref(["0-0-0", "0-0-1"]);
const checkedKeys = ref(["0-0-0", "0-0-1"]);
watch(expandedKeys, () => {
  console.log("expandedKeys", expandedKeys);
});
watch(selectedKeys, () => {
  console.log("selectedKeys", selectedKeys);
});
watch(checkedKeys, () => {
  console.log("checkedKeys", checkedKeys);
});
const handleCheck = (checked, { node }) => {
  console.log("checked", checked);
  console.log("node", node);
  let checkData = []
  checkData = node.dataRef.title == "全国" ? [5000] : checked
  baseData.region = checkData;
  console.log("baseData", baseData);
};
//清洗区域树数据
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        title: item.label,
        key: item.value,
        value: item.value,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        title: item.label,
        key: item.value,
        value: item.value,
      });
    }
  });
  return response;
};
//获取区域树
const getRegionTree = async () => {
  let tree = await checkTree()

  options.region = clearRegionalTree(tree)
  console.log("结构树数据", options.region)
};


getRegionTree()
const getBilling_mode = () => {
  serviceAxios({
    url: interfaces.product_billing_mode,
    method: "get",
  })
    .then((res) => {
      let data = [];
      res.data.data.forEach((item) => {
        data.push({
          label: item.name,
          value: item.value,
        });
      });
      options.billing_mode = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getBilling_mode()






const getCustomer_names = () => {
  serviceAxios({
    url: interfaces.customerPage,
    method: "get",
    params: {
      type: "行业客户",
      page_num: 1,
      page_size: 99999,
    },
  })
    .then((res) => {
      let data = []

      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
        });
      });
      options.customer_ids = data;
    })
    .catch(() => {
      message.error("请求异常，请联系管理员");
    });
};
getCustomer_names()


//查询非产品包的产品
const queryNotPackage = () => {
  let params = {
    is_package: 0,
    page_num: 1,
    page_size: 9999,
  }
  serviceAxios({
    url: interfaces.productPage,
    method: "get",
    params: params
  })
    .then((res) => {
      let data = [];
      res.data.data.list.forEach((item) => {
        data.push({
          label: item.name,
          value: item.id,
          unitPrice: item.unit_price
        })
      })
      options.children_ids = data
    })
}

const priceChange = () => {
  let all_price = 0;
  let subProductComplutedList = baseData.subProduct.filter(item => item.unit_price && item.quantity);
  subProductComplutedList.forEach(item => {
    all_price += item.quantity * item.unit_price
  });

  let deviceComplutedList = baseData.devices.filter(item => item.unit_price && item.quantity);
  deviceComplutedList.forEach(item => {
    all_price += item.quantity * item.unit_price
  });
  baseData.unit_price = all_price;
}

//数量和价格失去焦点重新计算价格
const computedPrice = (index, type) => {
  if (type == 'subProduct') {
    if (baseData.subProduct[index].unit_price && baseData.subProduct[index].quantity) {
      baseData.subProduct[index].all_price = baseData.subProduct[index].unit_price * baseData.subProduct[index].quantity
    };
  }
  else {
    if (baseData.devices[index].unit_price && baseData.devices[index].quantity) {
      baseData.devices[index].all_price = baseData.devices[index].unit_price * baseData.devices[index].quantity
    };
  }
  console.log('index', index, baseData.subProduct[index]);

  priceChange()
}

//增加子产品
const addSub = () => {

  baseData.subProduct.push({
    name: '',
    product_sub_type: null,
    billing_mode: null,
    quantity: null,
    unit_price: '',
    all_price: '',
  })
}


//删除子产品
const removeSub = (item) => {
  if (baseData.subProduct.length > 1) {
    const index = baseData.subProduct.indexOf(item);
    if (index !== -1) {
      baseData.subProduct.splice(index, 1);
    }
    priceChange();
  }
  else {
    message.error('必须保留一个产品')
  }

}
//增加设备
const addDevice = () => {
  baseData.devices.push({
    name: '',
    product_sub_type: "设备租赁",
    billing_mode: null,
    quantity: 1,
    unit_price: '',
    all_price: '',
    devices: [{
      device_id: '',
      quantity: 1,
    }]
  })
}

//删除设备
const removeDevice = (item) => {
  const index = baseData.devices.indexOf(item);
  console.log('移除租赁产品', item)
  if (index !== -1) {
    baseData.devices.splice(index, 1);
  }
  priceChange();
}

//添加子设备
const addSubDevices = (index) => {
  baseData.devices[index]['devices'].push({
    id: "",
    device_id: '',
    quantity: 1,
  })
}

//删除子设备
const removeSubDevices = (item, itemIndex) => {
  const index = baseData.devices[itemIndex]['devices'].indexOf(item);
  console.log('移除租赁产品', item)
  if (index !== -1) {
    baseData.devices[itemIndex]['devices'].splice(index, 1);
  }
}
(async () => {
  options.subProductType = await selector.subProductType()
  options.subProductType = options.subProductType.filter(item => item.label != '设备租赁')
  options.deviceLease = options.subProductType.filter(item => item.label == '设备租赁')
  options.devices = await selector.devices();
  options.template = await selector.template();
  options.subTemplate = await selector.subTemplate();
  options.deviceTemplate = await selector.deviceTemplate();
  // console.log('选择器数据', options.template, options.subTemplate, options.deviceTemplate)
})()


//模板selector改变
const templateChange = (value, option, index, type) => {
  console.log(value, option)
  if (type == 'sub') {
    baseData.subProduct[index].product_sub_type = option.data.product_sub_type
    baseData.subProduct[index].name = option.data.name
    baseData.subProduct[index].quantity = 1
    onblurSubValue.value[index] = value
    console.log('change', "onblurSubValue.value[index]", onblurSubValue.value[index])

  }
  else if (type == 'device') {
    console.log('baseData.devices', baseData.devices)
    baseData.devices[index].product_sub_type = option.data.product_sub_type
    baseData.devices[index].name = option.data.name
    baseData.devices[index].quantity = 1
    baseData.devices[index].devices = option.data.devices
    onblurDeviceValue.value[index] = value
  }
  else if (type == 'template') {
    baseData.name = option.data.name

  }
}

let onblurSubValue = ref([])  //sub产品
let onblurDeviceValue = ref([]) //设备产品
let onblurValue = ref([]) //设备产品
const onBlur = (value, index, type) => {
  console.log('失去焦点', value, index, type, onblurSubValue.value)
  console.log('baseData.subProduct[index].name', baseData.subProduct[index], onblurSubValue.value)

  if (type == 'sub') {
    baseData.subProduct[index].name = onblurSubValue.value[index]

  }
  else if (type == 'device') {
    baseData.devices[index].name = onblurDeviceValue.value[index]
  }

  else if (type == 'template') {
    baseData.name = onblurValue.value
  }

  console.log('baseData.subProduct[index].name', baseData.subProduct[index], onblurSubValue.value)

}

const onSearch = (value, index, type) => {
  console.log('搜索', value, index, type)
  if (value != '') {
    if (type == 'sub') {
      onblurSubValue.value[index] = value
      console.log('搜索sub', "onblurSubValue.value[index]", onblurSubValue.value[index])
    }
    else if (type == 'device') {
      onblurDeviceValue.value[index] = value
    }

    else if (type == 'template') {
      onblurValue.value = value
    }
  }

}

const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};


</script>
<style lang="less" scoped>
@import "@/static/css/table.css";
@import "@/assets/css/common.css";

.searchDivContainer {
  display: flex;
  // align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-top: 0px;
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */

  //   position: fixed;
  // top: 10px; /* 调整此值以设定距离顶部的距离 */
  // right: 10px; /* 调整此值以设定距离右侧的距离 */
  // z-index: 1000; /* 确保按钮位于其他内容之上，根据需要调整 */
}

.addDataButton {
  margin-top: 0px;
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-operations {
  // margin-bottom: 8px;
}
.editable-row-a {
  margin: 2px;
  color: #1890ff;
}

.searchTable {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
}
.searchTable tr td {
  width: 150px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.searchTable tr td.column {
  background-color: #eff3f6;
  color: #393c3e;
}

.sub-item-title {
  text-align: center;
  white-space: nowrap;
}

.template-box {
  display: flex;
  align-items: center;
}
</style>